// import React from "react";
import React, { Component } from "react";
import "../mainContent/MainContent.scss";
import Table from "../dataset/table/Table";
import "./MainContent.scss";

export class MainContent extends Component {
  constructor() {
    super();
    this.state = {
      datasetId: "",
    };
  }

  componentWillMount() {
    this.setState({
      datasetId: this.props.match.params.id,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      datasetId: nextProps.match.params.id,
    });
  }

  render() {
    return (
      <div>
        <Table sedatasetId={this.state.datasetId}></Table>
      </div>
    );
  }
}

export default MainContent;
