const backgroundColors = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
];

const assigneeReporterBackgroundColorMapping = [];

export const backgroundEvaluator = (index, name) => {
  let backgroundColor = backgroundColors?.[0];

  const alreadyExists = assigneeReporterBackgroundColorMapping.find(
    (item) => item.name === name
  );

  if (index > backgroundColors?.length - 1) {
    let newIndex = index - backgroundColors?.length;
    backgroundColor = backgroundEvaluator(newIndex, name);
  } else {
    if (alreadyExists) {
      backgroundColor = alreadyExists.backgroundColor;
    } else {
      assigneeReporterBackgroundColorMapping.push({
        name,
        backgroundColor: backgroundColors[index],
      });
      backgroundColor = backgroundColors[index];
    }
  }

  return backgroundColor;
};

export const assigneeReporterEvaluator = (name) => {
  let formattedName = "AB";

  if (name?.split(" ")?.[1]?.[0]) {
    formattedName =
      name?.split(" ")?.[0]?.[0]?.toUpperCase() +
      name?.split(" ")?.[1]?.[0]?.toUpperCase();
  } else {
    formattedName = name?.[0]?.toUpperCase();
  }

  return formattedName;
};

export const filterFormDataGetter = (data, cardData) => {
  let filterformDataToSend = { ...data };

  if (Object.values(filterformDataToSend).every((value) => value === "")) {
    for (let key in filterformDataToSend) {
      if (filterformDataToSend.hasOwnProperty(key)) {
        filterformDataToSend[key] = cardData[key];
      }
    }
  }

  return filterformDataToSend;
};
