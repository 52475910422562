import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAllDataSets } from "../../services/datasetService";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Link } from "react-router-dom";

const DatasetDataList = () => {
  const history = useHistory();

  const [dataSetList, setDataSetList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);

  useEffect(() => {
    fetchDataSetList();
  }, []);

  const fetchDataSetList = () => {
    let obj = {
      SortCriteria: [
        {
          sort_field: "name",
          sort_type: "asc",
        },
      ],
    };
    setIsLoading(true);
    fetchAllDataSets(obj)
      .then((response) => {
        if (response.success === true) {
          setDataSetList(response.data || []);
          setTotalRecordCount(response.total_count);
        }
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsLoading(false));
  };

  const indexOfLastRecord = activePage * 10;
  const indexOfFirstRecord = indexOfLastRecord - 10;
  const displayData = dataSetList.slice(indexOfFirstRecord, indexOfLastRecord);

  const renderTableHeader = displayData.map((dataSet, index) => {
    return (
      <tr key={index}>
        <td>{indexOfFirstRecord + index + 1}</td>
        <td>{dataSet.name}</td>
        <td>{dataSet.description}</td>
        <td>{dataSet.author}</td>
        <td>
          {dataSet.created_date
            ? moment(dataSet.created_date).format("L")
            : null}
        </td>
        <td>
          {dataSet.updated_date
            ? moment(dataSet.updated_date).format("L")
            : null}
        </td>

        <td>
          <div className="action-icons text-start">
            <Link to={`dataset/${dataSet._id.$oid}`}>
              <FontAwesomeIcon
                icon={"fa fa-pen-to-square"}
                className="me-2"
                title="Edit"
                size="lg"
              />
            </Link>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="d-help py-2 flex-wrap">
        <h5>Dataset Field Editor</h5>
        <Link to={`dataset`}>
            <button
              type="button"
              className="btn btn-success btn-md ms-2 my-auto text-nowrap me-0"
            >
              &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-plus" />
              <span className="ms-1">&nbsp;&nbsp;Add Dataset&nbsp;&nbsp;</span>
            </button>
        </Link>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th className="text-nowrap">Sr. No.</th>
                  <th className="text-nowrap">Dataset Name</th>
                  <th>Description</th>
                  <th>Author</th>
                  <th className="text-nowrap">Created Date</th>
                  <th className="text-nowrap">Last Updated Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderTableHeader}</tbody>
            </table>
          </div>

          <div className="dynamic_pagination justify-content-between">
            {totalRecordCount > 0 && (
              <div className="my-2">
                Showing {(activePage - 1) * 10 + 1} to{" "}
                {activePage * 10 > totalRecordCount
                  ? totalRecordCount
                  : activePage * 10}{" "}
                of {totalRecordCount} entries{" "}
              </div>
            )}
            {totalRecordCount > 10 && (
              <Pagination
                pageRangeDisplayed={3}
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={totalRecordCount}
                onChange={(pageNo) => setActivePage(pageNo)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatasetDataList;
