import React, { useEffect, useState } from "react";
import "./TimeLineView.scss";
import { formatDateTime } from "../../../services/utils";

const TimeLine = ({ pageFields, logdata }) => {
  const [timeLineSteps, setSteps] = useState();

  useEffect(() => {
    let keysToExtract = pageFields
      ?.filter((field) => field?.status === true)
      .map((item) => item?.name);

    const stepperData = logdata?.map((obj) => {
      const extractedObj = {
        updated_by: obj?.updated_by,
        updated_date: obj?.edited_date,
        status: keysToExtract.map((key) => obj[key] || []),
      };
      return extractedObj;
    });
    setSteps(stepperData);
  }, [pageFields, logdata]);

  return (
    <>
      <div className = {`col-md-12 ${timeLineSteps?.length && 'timeline'}`} >
        {timeLineSteps?.map((statusDetail, index) => (
          <div key={index} className={`step`}>
            <div className="d-flex flex-column align-items-center">
            <div className="timeline-data">
              <div>
                {statusDetail?.status?.map((label) => (
                  <div className="timeline-status" key={label} >{label}</div>
                ))}
              </div>
              <div className="timeline-label">{statusDetail?.updated_by || "-"}</div>
              <div className="timeline-label">{statusDetail?.updated_date ? formatDateTime(statusDetail?.updated_date ) : "-"}</div>
              </div> 
              <div
                className={`timeline-circle ${
                  timeLineSteps?.length - 1 === index ? "active-timeline" : ""
                }`}
              >
                {index + 1}
              </div>   
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TimeLine;
