import React, { useEffect } from "react";
import Routes from "../../routes";
import { getHeartBeat } from "../../services/heartBeatService";
import "../../styles/mediaQuery.scss";
import "./App.scss";
import "../../styles/variable.scss";

import { ToastContainer } from "react-toastify";

function App() {
  useEffect(() => {
    getHeartBeat()
      .then((resp) => {})
      .catch(() => {});
  }, []);
  return (
    <div className="App">
      <Routes></Routes>
      <ToastContainer autoClose={4000} />
    </div>
  );
}

export default App;
