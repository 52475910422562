export const productDefaultColumnHeaderData = [
  {
    value: "product_name",
    label: "Product Name",
    dataType: "string",
  },
  {
    value: "product_description",
    label: "Product Description",
    dataType: "string",
  },

  {
    value: "product_short_description",
    label: "Product Short Description",
    dataType: "string",
  },
  {
    value: "product_type",
    label: "Product Type",
    dataType: "string",
  },
  {
    value: "latitude",
    label: "Latitude",
    dataType: "number",
  },
  {
    value: "longitude",
    label: "Longitude",
    dataType: "number",
  },
  {
    value: "sku_id",
    label: "SKU ID",
    dataType: "string",
  },
  {
    value: "price",
    label: "Min Price",
    dataType: "number",
  },
  {
    value: "max_price",
    label: "Max Price",
    dataType: "number",
  },
  {
    value: "available_quantity",
    label: "Available Quantity",
    dataType: "number",
  },
  {
    value: "is_available",
    label: "Is Available",
    dataType: "string",
  },
  {
    value: "size",
    label: "Size",
    dataType: "number",
  },
  {
    value: "uom",
    label: "Unit Of Measurement",
    dataType: "string",
  },
  {
    value: "minimum_unit_value",
    label: "Minimum Unit Value",
    dataType: "number",
  },
  {
    value: "supplier_name",
    label: "Supplier Name",
    dataType: "string",
  },
  {
    value: "brand_name",
    label: "Brand Name",
    dataType: "string",
  },
  {
    value: "product_status",
    label: "Product Status",
    dataType: "string",
  },
  {
    value: "unit_of_pricing",
    label: "Unit Of Pricing",
    dataType: "number",
  },
  {
    value: "is_gst_applicable",
    label: "Is GST Applicable",
    dataType: "string",
  },
  {
    value: "gst_percentage",
    label: "GST (%)",
    dataType: "number",
  },
  {
    value: "additional_charges",
    label: "Additional Charges",
    dataType: "number",
  },
];
