import { React } from "react";
import "../../header/Header.scss";
import flashcorpLogo from "../../../../images/logo.png";
import "./StandardLayout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logOut } from "../../../../services/appService";
import { toast } from "react-toastify";
import {getIconImage} from "../../../../services/utils";

function ShareAppHeader() {
  const baseURL = process.env.REACT_APP_URL;
  const user = JSON.parse(localStorage.getItem("user-info")) || "";
  const logoImage =
    JSON.parse(localStorage.getItem("logoImage")) || flashcorpLogo;
  const tenant_website =
    JSON.parse(localStorage.getItem("tenant_website")) || baseURL;

  const loggingOut = (e) => {
    logOut()
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          localStorage.clear();
          window.location.href = tenant_website;
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <div
        className={`header ${
          user?.default_layout === "minimalLayout" && "me-0"
        }`}
      >
        <div className="nav_menu m-0">
          <div className="nav toggle"></div>

          <nav className={`standard-nav standard-navbar-nav nav`}>
            <div className="m-0 p-0 d-flex">
              <div className="navbar nav_title">
                <div className="fc-logo m-0 p-0">
                  <img className="p-2" src={getIconImage(logoImage)} alt="FC"/>
                </div>
              </div>
            </div>
            <div className="navbar-right p-0 ms-2">
              <div className="cursor-pointer me-4" onClick={() => loggingOut()}>
                <FontAwesomeIcon
                  color="#6c757d"
                  className="p-2"
                  icon="fa-solid fa-xmark"
                  size="xl"
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default ShareAppHeader;
