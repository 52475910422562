import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  addProductToWishlist,
  filterData,
  removeProductFromWishlist,
  sendEmailToSubAdmin,
  getSingleProductDetails,
} from "../../../../../services/appService";
import "./ProductView.scss";
import ImageGallery from "react-image-gallery";
import RelatedProducts from "../relatedProducts/RelatedProducts";
import { useHistory } from "react-router";
import Loader from "../../../../dataset/Loader/Loader";
import dummyImage from "../../../../../images/dummy-image.jpg";
import { Modal, OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSection from "../../../CustomSection";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomModal from "../../../../application/customModal/CustomModal";
import { AddToCart } from "../AddToCart";

function ProductView(props) {
  const history = useHistory();
  const [productFields, setProductFields] = useState({});
  const [hidePriceInformation, setHidePriceInformation] = useState(true);
  const [imageSlider, setImageSlider] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { product_id } = useParams();
  const [vendorDetails, setVendorDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [addWishlist, setaddWishlist] = useState(false);
  const [removeWishlist, setRemoveWishlist] = useState(false);
  const [showImgSlider, setShowImgSlider] = useState(false);
  const [wishListOptions, setWishListOptions] = useState([]);
  const [inputWishlist, setInputWishlist] = useState("");
  const [inputWishlistId, setInputWishlistId] = useState("");
  const [getQuoteModal, setGetQuoteModal] = useState(false);
  const [details, setDetails] = useState({ name: "", number: "" });
  const [error, setError] = useState({});
  const [value, setValue] = React.useState(0);
  const tenant_Id = JSON.parse(localStorage.getItem("tenantId"));
  const user_id = JSON.parse(localStorage.getItem("userid"));
  const addWishlistButton = history.location.search.includes("?id=");
  const [wishlistDropdownValue, setWishlistDropdownValue] = useState("");
  const appId = JSON.parse(localStorage.getItem("store_app_Id"));

  useEffect(() => {
    callProductDetails();
  }, [history.location.pathname]);

  useEffect(() => {
    setRelatedProducts(
      productFields?.related_product ? productFields.related_product : []
    );
    setVendorDetails(
      productFields?.vendor_details?.length >= 1
        ? productFields?.vendor_details
        : []
    );
    if (productFields?.product_images) {
      setImageSlider(
        productFields?.product_images?.map((images) => {
          return {
            original: images,
            thumbnail: images,
          };
        })
      );
    }
  }, [productFields, loader]);

  const callProductDetails = () => {
    let bodyData = {
      id: product_id,
      tenant_id: tenant_Id,
    };
    setLoader(true);
    getSingleProductDetails(bodyData)
      .then((response) => {
        if (response.data) {
          setHidePriceInformation(response?.hidePriceInformation === "yes");
          setProductFields(response.data);
          fetchWishListDetails(response.data.store_id);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);

        if (error) {
        }
      });
  };

  const fetchWishListDetails = (store_id) => {
    let bodyData = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: user_id,
          filter_field: "user_id",
        },
        {
          filter_type: "text",
          type: "equal",
          filter: store_id,
          filter_field: "store_id",
        },
      ],
    };
    filterData(bodyData, "wishlist")
      .then((response) => {
        if (response.data.length) {
          let arrValue = [];
          response.data.map((value, index) => {
            let objVal = {
              label: value?.wishlist_name,
              value: value?._id?.$oid,
            };
            arrValue.push(objVal);
          });
          setWishListOptions(arrValue);
        }
      })
      .catch(() => {});
  };

  const shareAppLink = () => {
    setShowTooltip(true);
    let urlGenerated = `${window.location.origin}/share_product/${product_id}/${tenant_Id}`;
    navigator.clipboard.writeText(urlGenerated);
  };

  const handleSelect = (option) => {
    setWishlistDropdownValue(option?.label);
    setInputWishlistId(option?.value);
  };

  const storeWishlistData = () => {
    if (inputWishlist.length || wishlistDropdownValue.length) {
      let objBody = {
        product_id: product_id,
        wishlist_name: wishlistDropdownValue
          ? wishlistDropdownValue
          : inputWishlist,
        dataset_name: "wishlist",
        wishlist_id: inputWishlistId,
        store_id: productFields?.store_id,
      };
      addProductToWishlist(objBody, tenant_Id)
        .then((response) => {
          if (response) {
            toast.success("Product Added To Wishlist Successfully");
            setInputWishlist("");
            setShow(false);
            fetchWishListDetails(productFields?.store_id);
          }
        })
        .catch((err) => {
          setInputWishlist("");
          setShow(false);
          if (err) {
            toast.error(err?.response?.data?.message);
          }
        });
    } else {
      toast.error("Wishlist Name Cannot Be Empty");
    }
  };

  const renderVendorDetails = (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Vendor Name</th>
          <th>Maximum Price</th>
          <th>Minimum Price</th>
          <th>Available Days</th>
        </tr>
      </thead>
      <tbody>
        {vendorDetails?.map((value, index) => (
          <tr key={index}>
            <td className="text-capitalize">{value?.vendorData?.label}</td>
            <td>{value?.max_value}</td>
            <td>{value?.min_value}</td>
            <td>{value?.available_in}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleClose = () => {
    setShow(false);
    setaddWishlist(false);
    setShowImgSlider(false);
    setWishlistDropdownValue("");
    setInputWishlistId("");
    setInputWishlist("");
  };

  const removeFromWishlist = () => {
    setIsLoading(true);
    let objData = {
      product_id: product_id,
      wishlist_id: props.history.location.search.split("=")[1],
    };
    removeProductFromWishlist(objData, tenant_Id)
      .then((response) => {
        if (response.success) {
          props.history.goBack();
          toast.success("Item Removed Successfully");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (showTooltip) setTimeout(() => setShowTooltip(false), 1500);
  }, [showTooltip]);

  useEffect(() => {
    setWishlistDropdownValue("");
    setInputWishlistId("");
    setInputWishlist("");
  }, [show]);

  const routeToParentStore = () => {
    props.history.push(
      `/app_category_list/${appId}/${productFields?.store_id}`
    );
  };

  const validateFormField = () => {
    let formIsValid = true;
    let error = {};
    if (details.number === "") {
      formIsValid = false;
      error["numberError"] = "Please Enter Your Number";
    }

    if (details.name?.trim() === "") {
      formIsValid = false;
      error["nameError"] = "Please Enter Your Name";
    }
    setError(error);
    return formIsValid;
  };

  const sendEmail = () => {
    if (validateFormField()) {
      let sendEmailBody = {
        user_detail: {
          name: details.name,
          contact: details.number,
          email: details.email || "",
        },
      };
      sendEmailToSubAdmin(sendEmailBody, product_id)
        .then((res) => {
          if (res.success) {
            setGetQuoteModal(false);
            setDetails({ name: "", number: "" });
            toast.success(res.message);
          }
        })
        .catch((err) => {
          setGetQuoteModal(false);
          setDetails({ name: "", number: "" });
          toast.error(err.response.data.message);
        });
    }
  };
  const handleDetails = (e) => {
    const data = { ...details };
    data[e.target.name] = e.target.value;
    setDetails(data);
  };

  const hideModal = () => {
    setGetQuoteModal(false);
    setError({});
    setDetails({ name: "", number: "" });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className="w-100"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="pb-2  p-0 product-view">
      {loader ? (
        <Loader />
      ) : (
        <div className="catergory-wrap-screen">
          <div className="d-help py-2">
            <div className="d-flex">
              <div className="bread-crum d-help me-2">
                <p
                  className="m-0 text-underline cursor font-13"
                  onClick={() => routeToParentStore()}
                >
                  Product Categories
                </p>

                <span className=" ms-2">/</span>
                <p
                  className="m-0 ms-2 text-underline cursor font-13"
                  onClick={() => props.history.goBack()}
                >
                  {productFields?.categories.map((data, index) => {
                    return <span key={index}>{data.label}</span>;
                  })}
                </p>

                <span className=" ms-2">/</span>

                <h6 className="m-0 font-13 ms-2">
                  {productFields?.product_name}
                </h6>
              </div>
            </div>

            <button
              className="btn btn-secondary btn-md"
              onClick={() => props.history.goBack()}
            >
              <FontAwesomeIcon icon="fa fa-arrow-left" className="me-1" />
              Back
            </button>
          </div>

          {Object.keys(productFields).length ? (
            <>
              <div className="product-data">
                <div className="row product-sub-head">
                  <div className="col-md-12 col-sm-12 text-end">
                    {productFields.get_quote && (
                      <button
                        className="share-wrapper me-2 getQuoteButton"
                        onClick={() => setGetQuoteModal(true)}
                      >
                        <FontAwesomeIcon
                          icon="fa fa-envelope-o"
                          className="me-2"
                          aria-hidden="true"
                        />
                        Get Quote
                      </button>
                    )}

                    <AddToCart productData={productFields} />

                    <OverlayTrigger
                      show={showTooltip}
                      placement="bottom"
                      overlay={
                        <Tooltip
                          className="mt-2"
                          id="overlay-example"
                          {...props}
                        >
                          Link Copied
                        </Tooltip>
                      }
                    >
                      <button
                        className="btn btn-md btn-secondary ms-1 mb-0 me-1"
                        onClick={() => {
                          shareAppLink();
                        }}
                      >
                        <FontAwesomeIcon icon="fa fa-link" className="me-2" />
                        <span>Copy Link</span>
                      </button>
                    </OverlayTrigger>
                    {addWishlistButton ? (
                      <button
                        className="share-wrapper bg-danger"
                        onClick={() => {
                          setRemoveWishlist(true);
                        }}
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          icon={"fa fa-trash"}
                        />
                        <span>Remove From Wishlist</span>
                      </button>
                    ) : (
                      <button
                        className="share-wrapper"
                        onClick={() => {
                          setShow(true);
                          setaddWishlist(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={"fa fa-heart"}
                          className="me-2"
                        />
                        <span>Add To Wishlist</span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-5 mb-4">
                        <div className="img-wrapper">
                          {imageSlider.length >= 1 ? (
                            <ImageGallery
                              items={imageSlider}
                              showPlayButton={false}
                              showFullscreenButton={false}
                              onClick={() => {
                                setShow(true);
                                setShowImgSlider(true);
                              }}
                            />
                          ) : (
                            <ImageGallery
                              items={[
                                {
                                  original: dummyImage,
                                  thumbnail: dummyImage,
                                },
                              ]}
                              showPlayButton={false}
                              showFullscreenButton={false}
                              showThumbnails={false}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7">
                        <div className="content-wrapper font-14 text-capitalize">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <p className="mb-3">
                                  <h5 className="text-dark fw-bold ">
                                    {productFields?.product_name.length > 1
                                      ? productFields?.product_name
                                      : "-"}
                                  </h5>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <p className="mb-3">
                                  <span className="text-dark ">
                                    {productFields?.product_short_description
                                      .length > 1
                                      ? productFields?.product_short_description
                                      : "-"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <p className="mb-3">
                                  <span className="text-dark fw-bold ">
                                    {productFields.get_quote ? (
                                      <button
                                        className="btn btn-light border btn-md fw-bold"
                                        onClick={() => setGetQuoteModal(true)}
                                      >
                                        <FontAwesomeIcon
                                          icon={"fa fa-envelope-o"}
                                          className="me-2"
                                          aria-hidden="true"
                                        />
                                        Get Quote
                                      </button>
                                    ) : (
                                      <span>
                                        {hidePriceInformation ? null : (
                                          <>
                                            &#x20b9;&nbsp;
                                            {productFields?.price?.toLocaleString(
                                              "en-IN"
                                            )}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <hr />

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <p className="mb-3">
                                  <span className="text-dark fw-bold d-flex flex-wrap align-items-center">
                                    {productFields.product_tag?.length > 0 ? (
                                      productFields.product_tag.map(
                                        (tags, index) => (
                                          <div
                                            className="custom-tag"
                                            key={index}
                                          >
                                            <FontAwesomeIcon
                                              icon={"fa fa-tag"}
                                              className="me-2"
                                            />
                                            <span>{tags.text}</span>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div className="custom-tag">
                                        <FontAwesomeIcon
                                          icon={"fa fa-tag"}
                                          className="me-2"
                                        />
                                        <span>No Tags</span>
                                      </div>
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <hr />

                            <div className="row">
                              <div className="col-md-2 col-sm-12 mb-2">
                                SKU ID
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                <span className="text-dark fw-bold text-break">
                                  {productFields?.sku_id.length >= 1
                                    ? productFields?.sku_id
                                    : "-"}
                                </span>
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                In stock
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                <span className="text-dark fw-bold text-break">
                                  {productFields?.available_quantity.length >= 1
                                    ? productFields?.available_quantity
                                    : "0"}
                                </span>
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                Product Size
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                <span className="text-dark fw-bold text-break">
                                  {productFields?.size.length >= 1
                                    ? productFields?.size
                                    : "-"}
                                </span>
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                Supplier
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                <span className="text-dark fw-bold text-break">
                                  {productFields?.supplier_name.length > 1
                                    ? productFields?.supplier_name
                                    : "-"}
                                </span>
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                Brand
                              </div>
                              <div className="col-md-2 col-sm-12 mb-2">
                                <span className="text-dark fw-bold text-break">
                                  {productFields?.brand_name.length > 1
                                    ? productFields?.brand_name
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <AppBar
                    position="static"
                    color="default"
                    className="shadow-none"
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Product More Details"
                      variant="fullWidth"
                      indicatorColor="secondary"
                      textColor="secondary"
                    >
                      <Tab label="Specification" {...a11yProps(0)} />
                      <Tab label="Additional Information" {...a11yProps(1)} />
                      <Tab label="Brochures" {...a11yProps(2)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <CustomSection
                      htmlCode={
                        productFields?.product_description.length > 1
                          ? productFields?.product_description
                          : "No Specification Available"
                      }
                      columenWidth={12}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <CustomSection
                      htmlCode={
                        productFields?.additional_info?.length > 1
                          ? productFields?.additional_info
                          : "No Additional Information Available"
                      }
                      columenWidth={12}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {productFields?.product_brochure ? (
                      productFields.product_brochure?.map(
                        (file_name, index) => {
                          let file = file_name?.split("/");
                          return (
                            <div className="p-4 border">
                              <a
                                href={productFields?.product_brochure?.[index]}
                                target="_blank"
                              >
                                <FontAwesomeIcon
                                  icon={"file-pdf"}
                                  className="view-icon"
                                />
                                {file[file?.length - 1]}
                              </a>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <CustomSection
                        htmlCode={"No brochure found for this product"}
                        columenWidth={12}
                      />
                    )}
                  </TabPanel>
                </div>
              </div>
              {vendorDetails?.length >= 1 ? (
                <div className="vendor-details mt-2">
                  <h5>Vendor Details</h5>
                  {renderVendorDetails}
                </div>
              ) : null}
              <div className="related-products mt-2">
                <RelatedProducts
                  relatedProductsData={relatedProducts}
                  margin={30}
                  nav={false}
                  items={4}
                  clickValue="product_view"
                ></RelatedProducts>
              </div>
            </>
          ) : null}
          <Modal
            show={show}
            onHide={handleClose}
            className={addWishlist ? null : "product-map-modal"}
          >
            <Modal.Header closeButton>
              {addWishlist ? <h5>Add to wishlist</h5> : null}
            </Modal.Header>
            <Modal.Body>
              {showImgSlider ? (
                <ImageGallery
                  items={imageSlider}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              ) : null}
              {addWishlist ? (
                <div className="col-12">
                  <label className="fw-bold">Select Wishlist</label>
                  <Select
                    options={wishListOptions}
                    onChange={(e) => handleSelect(e)}
                    isDisabled={inputWishlist}
                  />
                  <div className="create-wishlist my-4">
                    <label className="fw-bold">
                      Or Enter New Wishlist Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={inputWishlist}
                      onChange={(e) => {
                        setInputWishlist(e.target.value);
                      }}
                      disabled={wishlistDropdownValue}
                    ></input>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-success btn-md"
                      onClick={() => {
                        storeWishlistData();
                      }}
                    >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
                      &nbsp;&nbsp;Submit&nbsp;&nbsp;
                    </button>
                  </div>
                </div>
              ) : null}
            </Modal.Body>
          </Modal>
          <Modal show={getQuoteModal} onHide={() => hideModal()} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <b>Enter Details</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Name
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="name"
                    value={details.name}
                    onChange={handleDetails}
                  />
                  {error.nameError && (
                    <div className="errorMsg text-danger">
                      {error.nameError}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Phone No.
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Your Phone Number"
                    name="number"
                    value={details.number}
                    onChange={handleDetails}
                  />
                  <div className="errorMsg text-danger">
                    {error.numberError}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3">
                  Email
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                    name="email"
                    value={details?.email}
                    onChange={handleDetails}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  className="btn btn-primary btn-md me-0"
                  onClick={sendEmail}
                >
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <CustomModal
            modalState={removeWishlist}
            modalTitle={"Are You Sure?"}
            modalDiscription={"Do you want to remove this item from wishlist."}
            hideModal={() => setRemoveWishlist(false)}
            onDeleteClick={removeFromWishlist}
            isLoading={isLoading}
            delText="Yes"
            cancelText="No"
          />
        </div>
      )}
    </div>
  );
}

export default ProductView;
