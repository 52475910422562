import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Add all the icon sets to the library
library.add(fas, far);
export function IconDropdown({ onChange, value, name }) {
  const [selectedIcon, setSelectedIcon] = useState(value);

  useEffect(() => {
    setSelectedIcon(value);
  }, [value]);

  const iconNames_fas = Object.keys(library.definitions.fas).map((key) => {
    return key;
  });
  const iconNames_far = Object.keys(library.definitions.far).map((key) => {
    return key;
  });

  const iconNameList = [...iconNames_fas, ...iconNames_far];
  const iconNameListOption = iconNameList.map((icon) => {
    return {
      label: icon
        ?.split("-")
        .map((icon) => {
          return icon.charAt(0).toUpperCase() + icon.slice(1);
        })
        ?.join(" "),
      value: icon,
    };
  });

  const formatOptionLabel = ({ value, label }) => (
    <div className="d-flex my-1 align-items-center px-1" title={label}>
      <FontAwesomeIcon icon={value} className="me-2" />
      <span className="textOverFlowEllipsis">{label}</span>
    </div>
  );

  const CustomOption = ({ innerProps, label, value }) => (
    <div {...innerProps}>
      <div className="d-flex my-1 align-items-center px-2" title={label}>
        <FontAwesomeIcon icon={value} className="me-2" />
        <span className="textOverFlowEllipsis">{label}</span>
      </div>
    </div>
  );

  return (
    <div style={{ minWidth: "30%" }}>
      <Select
        components={{
          Option: CustomOption,
        }}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix={"react-select"}
        name={name}
        menuPosition="fixed"
        classNames={{
          control: () => "searchableDropdownControl",
          menuPortal: () => "searchableDropdownMenuPortal",
          singleValue: () => "searchableDropdownValue",
          multiValue: () => "searchableDropdownValue",
          menuList: () => "searchableDropdownMenuList",
        }}
        options={iconNameListOption}
        value={selectedIcon}
        isClearable
        onChange={(e, action) => {
          onChange(e || "", action);
          setSelectedIcon(e || "");
        }}
      ></Select>
    </div>
  );
}
