import React, { useEffect, useState } from "react";
import "./rootcategory.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function RootCategory(props) {
  const [expand, setExpand] = useState(false);
  const explorer = props?.explorer;

  useEffect(() => {
    if (props.selectedCategory === explorer?.category_id) {
      setExpand(true);
    }
  }, [props?.explorer?.subCategorys]);

  const pushData = (explorerData) => {
    setExpand(!expand);
    props.returnDataToDescriptionCategory(explorerData);
  };

  const returnFolderData = (returnedObjdata) => {
    props.returnDataToDescriptionCategory(returnedObjdata);
  };

  const disableselect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <div className="rootCategory">
      <div
        tabIndex={0}
        onMouseDown={(e) => disableselect(e)}
        onSelect={(e) => disableselect(e)}
        onClick={() => pushData(explorer)}
        className={`ps-1 mb-0 pointer ${
          explorer.category_id === props.selectedCategory
            ? "selectedCategory"
            : ""
        }`}
      >
        {explorer?.subCategorys?.length ? (
          <>
            {expand ? (
              <FontAwesomeIcon icon={"fa fa-minus-square-o"} className="me-1" />
            ) : (
              <FontAwesomeIcon icon={"fa fa-plus-square-o"} className="me-1" />
            )}
          </>
        ) : null}
        {explorer?.subCategorys?.length ? (
          <FontAwesomeIcon
            icon={"fa fa-folder"}
            className="me-1 folderIconColor"
          />
        ) : (
          <FontAwesomeIcon
            icon={"fa fa-folder-open"}
            className="me-1 folderIconColor"
          />
        )}

        <span
          className={`${
            explorer.category_status ? "text-dark" : "disableCategory"
          }`}
        >
          {explorer?.category_name}
        </span>
      </div>
      <div
        style={{ display: expand ? "block" : "none" }}
        className="ms-2 openCategoryLine"
      >
        {explorer?.subCategorys?.map((exp, index) => {
          return (
            <div className="text-nowarp" key={index}>
              <div className="d-flex">
                <span className="text-nowrap">--</span>
                <RootCategory
                  explorer={exp}
                  selectedCategory={props.selectedCategory}
                  returnDataToDescriptionCategory={returnFolderData}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RootCategory;
