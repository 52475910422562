import React, { useContext, useEffect, useState } from "react";
import { RoutesContext } from "../../RoutesContext";
import { headerSearchBox } from "../../services/appService";
import DisplayComponent from "../react_engine/components/DisplayComponent";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../dataset/Loader/Loader";
import { executeAppForTempStorage, uploadAppSession } from "../../services/API";
import { toast } from "react-toastify";

const HeaderSearch = (props) => {
  const { routesData } = useContext(RoutesContext);
  const { id, flowId, logicId } = useParams();
  const [showDisplay, setShowDisplay] = useState(true);
  const [flowObject, setFlowObject] = useState(null);
  const [appSessionId, setAppSessionId] = useState();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const referenceName = searchParams.get("reference_name");
  const searchText = searchParams.get("search_text");

  useEffect(() => {
    callApiUponSearchClick();
  }, [id, flowId, logicId, referenceName, searchText]);
  const callApiUponSearchClick = () => {
    const appIds = id;
    const flowIds = flowId;
    const logicIds = logicId;

    let body = {
      app_id: appIds,
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    };
    uploadAppSession(body).then((response) => {
      setAppSessionId(response?.data);
      const appSessionId = response?.data;
      let data = {
        data: searchText || "",
        reference_name: referenceName,
        app_session_id: appSessionId,
      };
      setShowDisplay(true);
      executeAppForTempStorage(data)
        .then(() => {
          headerSearchBox(appIds, flowIds, logicIds, appSessionId)
            .then((response) => {
              response?.data?.map((item) => {
                setFlowObject(item);
              });
            })
            .finally(() => {
              setShowDisplay(false);
            });
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    });
  };
  return (
    <div>
      <div>
        <div className="d-flex justify-content-end py-2 px-3">
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        {showDisplay ? (
          <Loader />
        ) : (
          <DisplayComponent
            appSessionId={appSessionId}
            displayComponentData={flowObject}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderSearch;
