import React, { useEffect, useState } from "react";
import ReactFileReader from "react-file-reader";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Select } from "antd";
import {
  createCategory,
  fetchAttributeSetList,
  setCategorySet,
  updateCategory,
  uploadFile,
} from "../../../../services/appService";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DescriptionCategory(props) {
  const [showData, setShowData] = useState({
    category_status: true,
    includes_in_menu: true,
    includes_in_search: true,
    subCategorys: [],
    category_name: "",
    category_description: "",
    category_list_type: "list_type",
    category_image: "",
    category_id: "",
  });
  const [errors, setErrors] = useState({});
  const [new_objectId, setnew_objectId] = useState("");
  const [isSaving, setisSaving] = useState(false);
  const [uploadingImage, setuploadingImage] = useState(false);
  const [loadingAttribute, setLoadingAttribute] = useState(true);
  const [attributeOptions, setAttributeOptions] = useState([]);
  let perms = useParams();

  useEffect(() => {
    if (!props?.contentData?.category_id) {
      setShowData({
        category_status: true,
        includes_in_menu: true,
        includes_in_search: true,
        subCategorys: [],
        category_name: "",
        category_description: "",
        category_list_type: "list_type",
        category_image: "",
        category_id: uuidv4(),
      });
    } else {
      setErrors({});
      if (props?.contentData?.category_list_type) {
        setShowData(props?.contentData);
      } else {
        setShowData({ ...props?.contentData, category_list_type: "list_type" });
      }
    }
  }, [props.contentData]);

  useEffect(() => {
    if (new_objectId) {
      updateRootCategory();
    }
  }, [new_objectId]);

  useEffect(() => {
    let rootObj = {
      fields: {
        store_id: perms.store_id,
        category_name: props.explorer.category_name,
        category_status: props.explorer.category_status,
        category_image: props.explorer.category_image,
        includes_in_menu: props.explorer.includes_in_menu,
        includes_in_search: props.explorer.includes_in_search,
        category_description: props.explorer.category_description,
        category_list_type: props.explorer.category_list_type,
        category_id: props.explorer.category_id,
        parent_category_id: props.explorer.parent_category_id,
        subCategorys: props.explorer.subCategorys,
        default_attribute_set: props.explorer.default_attribute_set,
      },
    };

    if (props.explorer?.subCategorys?.length) {
      setRootCategory(rootObj);
    } else if (props.contentData?.objId) {
      setRootCategory(rootObj);
    }
  }, [props.explorer]);

  useEffect(() => {
    setLoadingAttribute(true);
    fetchAttributeSetList()
      .then((response) => {
        setLoadingAttribute(false);
        setAttributeOptions(
          response.data.map((attribute) => {
            return {
              value: attribute._id.$oid,
              label: attribute.attribute_name,
            };
          })
        );
      })
      .catch((err) => {
        setLoadingAttribute(false);
      });
  }, []);

  const handleFiles = (files) => {
    if (files.fileList[0].size >= 1048576) {
      setErrors({ fileSize: "*File size must be less than 1MB." });
    } else {
      setErrors({ ...errors, fileSize: "" });
      let bodyObj = {};
      if (props.contentData.category_id) {
        bodyObj = {
          path: `${perms.store_id}/${props.contentData.category_id}`,
          file: [files.base64],
        };
      } else {
        bodyObj = {
          path: `${perms.store_id}/${showData.category_id}/`,
          file: [files.base64],
        };
      }

      setuploadingImage(true);
      uploadFile(bodyObj)
        .then((response) => {
          setuploadingImage(false);
          if (response.success === true) {
            setShowData({
              ...showData,
              category_image: response.data[0],
            });
          } else if (response.success === false) {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          setuploadingImage(false);
          toast.error(err.message);
        });
    }
  };

  const createCategoryData = (bodyObj) => {
    setisSaving(true);
    createCategory(bodyObj)
      .then((resp) => {
        setisSaving(false);
        if (resp.success === true) {
          toast.success(resp.message);
          setnew_objectId(resp?.object_id);
        } else if (resp.success === false) {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        setisSaving(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const updateCategoryData = (bodyObj) => {
    setisSaving(true);
    updateCategory({ ...bodyObj, object_id: showData.objId })
      .then((resp) => {
        setisSaving(false);
        if (resp.success === true) {
          updateRootCategory();
          toast.success(resp.message);
        } else if (resp.success === false) {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        setisSaving(false);
        toast.error(err.message);
      });
  };

  const updateRootCategory = () => {
    if (props.explorer.category_id === props.selectedCategory) {
      if (!props.contentData?.category_id) {
        props.setExplorer({
          ...props.explorer,
          subCategorys: [
            ...props.explorer?.subCategorys,
            {
              ...showData,
              parentCategoryId: props.selectedCategory,
              objId: new_objectId,
            },
          ],
        });
      }
    } else {
      props.setExplorer({
        ...props.explorer,
        subCategorys: props.explorer?.subCategorys?.map(iterate),
      });
    }
  };

  const setRootCategory = (rootObj) => {
    setCategorySet(rootObj)
      .then((resp) => {
        if (resp.success === true) {
          if (!props.contentData.category_id) {
            setShowData({
              enable: true,
              category_status: true,
              includes_in_menu: true,
              includes_in_search: true,
              subCategorys: [],
              category_name: "",
              category_description: "",
              category_list_type: "list_type",
              category_image: "",
              iconImage: "",
              category_id: uuidv4(),
            });
          }
        } else if (resp.success === false) {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        setisSaving(false);
        toast.error(err.message);
      });
  };

  const submitData = () => {
    if (validForm()) {
      setisSaving(true);
      let bodyObj = {
        fields: {
          category_id: showData.category_id,
          category_name: showData.category_name,
          category_description: showData.category_description,
          category_list_type: showData.category_list_type,
          includes_in_menu: showData.includes_in_menu,
          includes_in_search: showData.includes_in_search,
          category_status: showData.category_status,
          subCategorys: showData.subCategorys,
          category_image: showData.category_image,
          parent_category_id: props.selectedCategory,
          store_id: perms.store_id,
          default_attribute_set: showData.default_attribute_set,
        },
      };

      if (props.contentData?.category_id) {
        if (props.contentData?.category_id === props.rootId) {
          toast.error("Root Category cannot be updated.");
          setisSaving(false);
        } else {
          updateCategoryData(bodyObj);
        }
      } else {
        createCategoryData(bodyObj);
      }
    }
  };

  const validForm = () => {
    let valid = true;
    if (!showData.category_name) {
      setErrors({ ...errors, category_name: "*Please enter category name" });
      valid = false;
    } else {
      setErrors({});
    }
    return valid;
  };

  const iterate = (sdata) => {
    if (sdata.category_id === props.selectedCategory) {
      if (!props.contentData?.category_id) {
        return {
          ...sdata,
          subCategorys: [
            ...sdata?.subCategorys,
            {
              ...showData,
              parentCategoryId: props.selectedCategory,
              objId: new_objectId,
            },
          ],
        };
      } else {
        return { ...props.contentData, ...showData };
      }
    } else {
      return { ...sdata, subCategorys: sdata?.subCategorys?.map(iterate) };
    }
  };

  return (
    <div className="enable-categoty">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label">Enable Category</label>
          </div>
          <div className="col-md-8">
            <FontAwesomeIcon
              icon={
                showData.category_status
                  ? "fa fa-toggle-on"
                  : "fa fa-toggle-off"
              }
              role="button"
              disabled={true}
              className={`stauspointer togglebtn ${
                showData.category_status ? "text-success" : "text-danger"
              }`}
              aria-hidden="true"
              name="enable_category"
              onClick={() =>
                setShowData({
                  ...showData,
                  category_status: !showData.category_status,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label">Include in Menu</label>
          </div>
          <div className="col-md-8">
            <FontAwesomeIcon
              icon={
                showData.includes_in_menu ? "fa-toggle-on" : "fa-toggle-off"
              }
              role="button"
              disabled={true}
              className={`fa togglebtn ${
                showData.includes_in_menu ? "text-success" : "text-danger"
              }`}
              aria-hidden="true"
              onClick={() =>
                setShowData({
                  ...showData,
                  includes_in_menu: !showData.includes_in_menu,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label">Include in Search</label>
          </div>
          <div className="col-md-8">
            <FontAwesomeIcon
              icon={
                showData.includes_in_search
                  ? "fa fa-toggle-on "
                  : "fa fa-toggle-off"
              }
              role="button"
              disabled={true}
              className={`stauspointer fa togglebtn ${
                showData.includes_in_search ? "text-success" : "text-danger"
              }`}
              aria-hidden="true"
              onClick={() =>
                setShowData({
                  ...showData,
                  includes_in_search: !showData.includes_in_search,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label required">Category Name</label>
          </div>
          <div className="col-md-8">
            <input
              disabled={
                props.rootId === props.selectedCategory &&
                props.contentData?.category_id
              }
              type="text"
              className="form-control"
              value={showData?.category_name}
              name="category_name"
              placeholder="Category Name"
              onChange={(e) => {
                setShowData({ ...showData, [e.target.name]: e.target.value });
              }}
            ></input>
            <div className="errorMsg text-danger">{errors?.category_name}</div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label">Default Attribute Set</label>
          </div>
          <div className="col-md-8 selectCategory">
            <Select
              disabled={
                props.rootId === props.selectedCategory &&
                props.contentData?.category_id
              }
              loading={loadingAttribute}
              options={attributeOptions}
              placeholder={`${
                loadingAttribute ? "Loading.." : "Select Attribute Set"
              }`}
              className="w-100"
              value={showData?.default_attribute_set}
              onChange={(value, option) =>
                setShowData({
                  ...showData,
                  default_attribute_set: option,
                })
              }
            ></Select>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="file" className="mb-2 me-sm-2">
              Upload Category Image
            </label>
          </div>
          <div className="col-md-8">
            <div className="d-flex">
              <ReactFileReader
                disabled={
                  props.rootId === props.selectedCategory &&
                  props.contentData?.category_id
                }
                fileTypes={[".jpg", ".png", ".gif"]}
                handleFiles={handleFiles}
                base64={true}
              >
                <>
                  <button className="btn btn-primary btn-md">
                    {uploadingImage ? (
                      <>
                        <div
                          className="spinner-border text-light btnLoader"
                          role="status"
                        ></div>
                        Uploading..
                      </>
                    ) : (
                      "Upload"
                    )}
                    <FontAwesomeIcon
                      icon="fa-solid fa-upload"
                      className="ms-1"
                    />
                  </button>
                  {showData?.category_image ? (
                    <span className="mx-2">
                      <img
                        src={showData?.category_image}
                        alt="logo-img"
                        width="50px"
                        height="50px"
                      />
                    </span>
                  ) : null}
                </>
              </ReactFileReader>
            </div>

            <div className="errorMsg text-danger">{errors?.fileSize}</div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label">List Type</label>
          </div>
          <div className="col-md-8">
            <Form.Select
              disabled={
                props.rootId === props.selectedCategory &&
                props.contentData?.category_id
              }
              className="form-control"
              value={showData?.category_list_type}
              name="category_list_type"
              onChange={(e) => {
                setShowData({ ...showData, [e.target.name]: e.target.value });
              }}
            >
              <option value="list_type" selected>
                List View
              </option>
              <option value="map_type">Map View</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-4">
            <label className="col-form-label">Description</label>
          </div>
          <div className="col-md-8">
            <textarea
              disabled={
                props.rootId === props.selectedCategory &&
                props.contentData?.category_id
              }
              rows="6"
              placeholder="Description..."
              className="form-control"
              value={showData?.category_description}
              name="category_description"
              onChange={(e) => {
                setShowData({ ...showData, [e.target.name]: e.target.value });
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3 d-flex justify-content-end align-items-end">
        <button
          type="button"
          className="btn btn-md btn-success m-0 "
          onClick={submitData}
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              <div
                className="spinner-border spinner-border-sm text-light btnLoader"
                role="status"
              ></div>
              Saving..
            </>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
}

export default DescriptionCategory;
