import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cloneTenantData, getTenantDetails } from "../../services/appService";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TenantClone() {
  const [tenantList, settenantList] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [loading, setloading] = useState(true);
  const [isCloning, setisCloning] = useState(false);
  const [errors, seterrors] = useState({});
  const [fields, setfields] = useState({
    source: "",
    destination: "",
  });

  useEffect(() => {
    getTenentData();
  }, []);

  const getTenentData = () => {
    setloading(true);
    getTenantDetails()
      .then((response) => {
        if (response.success === true) {
          settenantList(response.data);
          settotalCount(response.total_count);
          setloading(false);
        } else if (response.success === false) {
          toast.error(response.data.message);
          setloading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response?.message);
        setloading(false);
      });
  };

  const changeHandler = (e) => {
    setfields({ ...fields, [e.target.name]: e.target.value });
  };
  const submitData = () => {
    if (validForm()) {
      let bodyObj = {
        from_tenant_id: fields.source,
        to_tenant_id: fields.destination,
        object_id_flag: "True",
      };
      setisCloning(true);
      cloneTenantData(bodyObj)
        .then((response) => {
          setisCloning(false);
          if (response.success === true) {
            toast.success(response.message);
            setfields({
              source: "",
              destination: "",
            });
          } else if (response.success === false) {
            toast.success(response.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setisCloning(false);
        });
    }
  };

  const validForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields["source"]) {
      formIsValid = false;
      errors["source"] = "*Please select source tenant.";
    }
    if (!fields["destination"]) {
      formIsValid = false;
      errors["destination"] = "*Please select destination tenant.";
    }

    seterrors(errors);
    return formIsValid;
  };

  return (
    <div className={`col-md-12 pb-4 bg-white `}>
      <div className="d-help p-2">
        <h5>Clone Tenant</h5>
      </div>
      <div className="x_panel">
        <div>
          <div className="x_content p-2">
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Source Tenant
              </label>
              <div className="col-md-9 col-sm-9  ">
                <Form.Select
                  className="form-control"
                  name="source"
                  value={fields.source}
                  onChange={changeHandler}
                >
                  <option value="" color="red">
                    {loading ? "Loading.." : "--Select--"}
                  </option>
                  {tenantList
                    .filter(
                      (tenant) => tenant?.tenant_id !== fields.destination
                    )
                    .map((tenant, index) => {
                      return (
                        <option key={index} value={tenant?.tenant_id}>
                          {tenant?.tenant_name}
                        </option>
                      );
                    })}
                </Form.Select>
                <div className="errorMsg text-danger">{errors?.source}</div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Destination Tenant
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Form.Select
                  className="form-control"
                  name="destination"
                  value={fields.destination}
                  onChange={changeHandler}
                >
                  <option value="">
                    {loading ? "Loading.." : "--Select--"}
                  </option>
                  {tenantList
                    .filter((tenant) => tenant?.tenant_id !== fields.source)
                    .map((tenant, index) => {
                      return (
                        <option key={index} value={tenant?.tenant_id}>
                          {tenant?.tenant_name}
                        </option>
                      );
                    })}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {errors?.destination}
                </div>
              </div>
            </div>

            <div className="float-end">
              <button
                disabled={isCloning}
                type="button"
                className="btn btn-success btn-md m-0"
                onClick={submitData}
              >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-clone" />&nbsp;&nbsp;
                {isCloning ? (
                  <>
                    <div className="spinner-border text-light btnLoader"></div>
                    Cloning
                  </>
                ) : (
                  "Clone"
                )}&nbsp;&nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenantClone;
