import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Form } from "react-bootstrap";

const DisplayBuilderCustomSection = ({
  displayBuilder,
  columnWidthList,
  onChange,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const editorChangeHandler = (e) => {
    const data = { ...displayChanges };
    data["htmlCode"] = e;
    setDisplayChanges(data);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-12 mt-2">
        <div className="form-group">
          <h6 htmlFor="htmlCode">Custom Section</h6>
          <ReactQuill
            modules={modules}
            id="htmlCode"
            name="htmlCode"
            value={displayChanges?.htmlCode}
            theme="snow"
            onChange={(e) => editorChangeHandler(e)}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderCustomSection;
