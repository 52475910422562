import React, { Component } from "react";
import {
  fetchAllDataSets,
  searchDatasetByName,
} from "../../../services/datasetService";
import "./DatasetTable.scss";
import Loader from "../../dataset/Loader/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import { Pagination } from "../../dataset/pagination/Pagination";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export class DatasetTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      getDataSetHeader: [
        "Dataset Name",
        "Description",
        "Author",
        "Created Date",
        "Last Updated Date",
        "Actions",
      ],
      getDataSetRecord: [],
      getTotalRecordCount: 0,
      searchValue: "",
      currentPage: 1,
      dataPerPage: 10,
      totalPageNumber: 0,
      pageNumbers: [],
      loading: true,
      errorMsg: false,
      urlValue: "",
      isWrite: false,
      isDatasetsWrite: false,
    };
  }
  componentDidMount() {
    this.getPrivilages();
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["Dataset  Editor"] && this.state.urlValue === "/datasets") {
          this.setState({ isWrite: data["Dataset  Editor"]?.write });
        } else if (
          data["Datasets"] &&
          this.state.urlValue === "/dataset_list"
        ) {
          this.setState({ isDatasetsWrite: data["Datasets"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true, isDatasetsWrite: true });
    }
  };
  componentWillMount() {
    this.mounted = true;
    this.setState({
      urlValue: this.props.match.url,
    });
    this.fetchAllData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  fetchAllData() {
    this.setState({ loading: true });
    let obj = {
      SortCriteria: [
        {
          sort_field: "name",
          sort_type: "asc",
        },
      ],
    };
    fetchAllDataSets(obj)
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            this.setState({
              getDataSetRecord: response.data,
              getTotalRecordCount: response.total_count,
              loading: false,
              error: false,
            });
          } else {
            this.setState({ errorMsg: true, loading: false }, () => {
              toast.error(response.message);
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          toast.error(err.message);
        });
      });
  }

  getEnteredDataSet = (event) => {
    this.setState(
      {
        searchValue: event.target.value,
      },
      () => {
        if (this.state.searchValue.length) {
          searchDatasetByName(this.state.searchValue)
            .then((response) => {
              if (response) {
                this.setState({
                  getDataSetRecord: response.data,
                  getTotalRecordCount: response.total_count,
                  errorMsg: false,
                });
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        } else {
          this.fetchAllData();
        }
      }
    );
  };

  render() {
    const setHeaderData =
      (this.state.isDatasetsWrite && this.state.urlValue === "/dataset_list") ||
      (this.state.isWrite && this.state.urlValue === "/datasets")
        ? this.state.getDataSetHeader.map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ))
        : this.state.getDataSetHeader
            .filter((header) => header !== "Actions")
            .map((header, index) => (
              <th className="text-nowrap" key={index}>
                {header}
              </th>
            ));

    // Pagination
    const { currentPage, dataPerPage } = this.state;

    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = this.state.getDataSetRecord.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    const renderTableHeader = currentData.map((records, index) => {
      return (
        <tr key={index}>
          <td>{records.name}</td>
          <td>{records.description}</td>
          <td>{records.author}</td>
          <td>
            {records.created_date
              ? moment(records.created_date).format("L")
              : null}
          </td>
          <td>
            {records.updated_date
              ? moment(records.updated_date).format("L")
              : null}
          </td>

          {(this.state.isDatasetsWrite &&
            this.state.urlValue === "/dataset_list") ||
          (this.state.isWrite && this.state.urlValue === "/datasets") ? (
            <td>
              <div className="action-icons text-start">
                <Link
                  to={
                    this.state.urlValue === "/dataset_list"
                      ? `/datatable/${records._id.$oid}`
                      : `dataset/${records._id.$oid}`
                  }
                >
                  <FontAwesomeIcon
                    icon={"fa fa-pen-to-square"}
                    className="me-2"
                    title="Edit"
                    size="lg"
                  />
                </Link>
              </div>
            </td>
          ) : null}
        </tr>
      );
    });
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className="d-help py-2 flex-wrap">
          <h5>
            {this.state.urlValue === "/datasets"
              ? "Dataset Field Editor"
              : "Datasets"}
          </h5>

          <div className="d-flex">
            <div className="input-group input-group-sm mb-0">
              <span className="input-group-text" id="basic-addon1">
                <FontAwesomeIcon icon={"fa fa-search"} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search Dataset By Name"
                onChange={(event) => this.getEnteredDataSet(event)}
                value={this.state.stateValue}
              />
            </div>
            {this.state.urlValue !== "/datasets" ? (
              <>
                {this.state.isDatasetsWrite ? (
                  <Link to={`dataset`}>
                    <button
                      type="button"
                      className="btn btn-success btn-md ms-2 my-auto text-nowrap me-0"
                    >
                      &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-plus" />
                      <span className="ms-1">&nbsp;&nbsp;Add Dataset&nbsp;&nbsp;</span>
                    </button>
                  </Link>
                ) : null}
              </>
            ) : null}
          </div>
        </div>

        <div className="card-box">
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr className="head-row">{setHeaderData}</tr>
              </thead>
              <tbody>{renderTableHeader}</tbody>
            </table>
            {this.state.errorMsg === true ? (
              <p className="no_table_records">Internal issue ...</p>
            ) : this.state.errorMsg === false &&
              this.state.getTotalRecordCount === 0 ? (
              <p className="no_table_records">No matching records found</p>
            ) : null}
          </div>

          {this.state.getTotalRecordCount >= 10 ? (
            <div className="dataTables_wrapper mt-2">
              <Pagination
                sendRowDataLength={this.state.getDataSetRecord.length}
                onSelectLanguage={this.receiveCurrentPageNumber}
              ></Pagination>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default DatasetTable;
