import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DisplayFile = ({ value, field }) => {
  let filesList = value?.files ? value?.files : value;
  return (
    <div>
      {filesList?.length > 0
        ? filesList?.map((fileList, i) => {
            return (
              <a href={fileList} target="_blank" download="true" key={i}>
                <FontAwesomeIcon
                  icon={"fa fa-download"}
                  className="me-2"
                  aria-hidden="true"
                />
              </a>
            );
          })
        : "-"}
    </div>
  );
};

export default DisplayFile;
