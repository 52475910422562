import React, { useEffect, useState, useContext } from "react";
import TimeLine from "./TimeLine";
import TimeLineTable from "./TimeLineTable";
import { AppLoader } from "../../dataset/Loader/AppLoader";
import { fetchAuditLogData } from "../../../services/appService";

const TimeLineView = ({
  actions,
  data,
  openModelData,
  showTable,
  showTimeLine,
  openInlineFormTableModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [auditLogData, setAuditLogData] = useState();
  const [actionData, setActionData] = useState({});

  useEffect(() => {
    getTimeLineData();
  }, [data]);

  const getTimeLineData = () => {
    const timeline = actions?.actionDetails.filter(
      (action) => action.actionType === "TimeLineView"
    )[0];
    setLoading(true);
    fetchAuditLogData(timeline?.datasetName || "")
      .then((res) => {
        let auditData = res?.data?.filter(
          (obj) => obj?.record_id === data?._id?.$oid
        );
        setAuditLogData(auditData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setActionData(actions);
  }, [actions]);

  if ((!auditLogData || auditLogData.length === 0) && !loading) {
    return (
      <div className="row">
        <div className="col-md-12 text-center">No Data</div>
      </div>
    );
  }

  return (
    <>
      <div className="p-2 position-relative minimumHeight timeline-view ">
        {loading && <AppLoader />}
        {showTimeLine && (
          <TimeLine
            pageFields={actionData?.page_field}
            logdata={auditLogData}
          />
        )}
        {showTable && (
          <TimeLineTable
            auditLogData={auditLogData}
            rowData={data}
            pageFields={actionData?.page_field}
            openModelData={openModelData}
            openInlineFormTableModal={openInlineFormTableModal}
          />
        )}
      </div>
    </>
  );
};

export default TimeLineView;
