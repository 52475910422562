import { inputTypeKey } from "../../../common/model/Model";

export const getFieldDefaultValue = (field) => {
  if (field.data_type === inputTypeKey.checkBox) {
    return field.default_value ? field.default_value?.split(",") : [];
  } else if (field.data_type === inputTypeKey.fileUpload) {
    return [];
  } else if (field.data_type === inputTypeKey.searchableDropdown) {
    return field.default_value ? field.default_value?.split(",") : [];
  } else if (field.data_type === inputTypeKey.dynamicDependentDropdown) {
    return field.default_value ? field.default_value?.split(",") : [];
  } else if (field.data_type === inputTypeKey.toggleButton) {
    return field?.default_value === "true"
      ? field?.checked_text || true
      : field?.unchecked_text || false;
  } else if (field.data_type === inputTypeKey.calculatedField) {
  return []
} else {
    return "";
  }
};
