import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";

const SearchCategory = ({ categoryObject, activeHierarchy, isLoading }) => {
  const [categoryOptionList, setCategoryOptionList] = useState([]);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState({});
  const history = useHistory();

  useEffect(() => {
    listHierarchies(categoryObject);
  }, [categoryObject]);

  useEffect(() => {
    if (selectedCategoryOption?.value !== activeHierarchy) {
      setSelectedCategoryOption({});
    }
  }, [activeHierarchy]);

  const listHierarchies = (hierarchy) => {
    let hierarchyList = [];
    let removeFirst = false;
    const listAllParentCategory = (toFindData) => {
      if (removeFirst) {
        hierarchyList.push({
          label: toFindData?.category_name,
          value: toFindData?.category_id,
          CategoryId: toFindData?.objId,
        });
      }
      removeFirst = true;
      toFindData?.subCategorys?.forEach((data) => {
        listAllParentCategory(data);
      });
    };
    listAllParentCategory(hierarchy);
    setCategoryOptionList(hierarchyList);
  };

  return (
    <Select
      classNamePrefix={"react-select"}
      menuPosition="fixed"
      classNames={{
        control: (state) => 'searchableDropdownControl',
        menuPortal: () => 'searchableDropdownMenuPortal',
        singleValue: () => 'searchableDropdownValue',
        multiValue: () => 'searchableDropdownValue',
        menuList: () => 'searchableDropdownMenuList'
      }}
      className="mx-1 mb-2"
      isLoading={isLoading}
      options={categoryOptionList}
      placeholder="Search Category"
      value={selectedCategoryOption?.label ? selectedCategoryOption : ""}
      onChange={(e) => {
        if (e) {
          history.push(history.location.pathname + "?activeKey=" + e.value);
        } else {
          history.push(history.location.pathname);
        }
        setSelectedCategoryOption(e || "");
      }}
      isClearable
    />
  );
};

export default SearchCategory;
