import React, { useState } from "react";
import { useEffect } from "react";
import ReactFileReader from "react-file-reader";
import { toast } from "react-toastify";
import {
  getProductDetails,
  updateProductDetails,
} from "../../../../services/appService";
import Loader from "../../../dataset/Loader/Loader";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProductImages(props) {
  const [fileUploadView, setFileUploadView] = useState([]);
  const [videoUpload, setVideoUpload] = useState([]);
  const [product_url, setProductUrl] = useState("");
  const product_id = props.sendObjectId ? props.sendObjectId : "";
  const [disable, setDisable] = useState(true);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [currentClick, setCurrentClick] = useState("");
  const [brochureUploadView, setBrochureUploadView] = useState([]);
  const [brochureNameList, setBrochureNameList] = useState([]);

  useEffect(() => {
    if (product_id?.length) {
      callProductDetails();
    }
  }, [product_id]);

  // fetch product details
  const callProductDetails = () => {
    getProductDetails(product_id)
      .then((response) => {
        setDisable(false);
        if (response.data) {
          setFileUploadView(
            response.data?.product_images ? response.data.product_images : []
          );
          setProductUrl(response.data.product_url);
          setBrochureUploadView(response.data.product_brochure || []);
          setBrochureNameList(response.data.brochure_name_list || []);

          setVideoUpload(
            response.data?.product_videos
              ? response.data?.product_videos[0]
              : []
          );
        }
      })
      .catch((err) => {
        if (err) {
          toast.error(err.response.data.message);
        }
        setDisable(false);
      });
  };

  // upload product images
  const uploadProductImages = (logoValue) => {
    let baseValue = [];
    if (logoValue.base64.length) {
      logoValue.base64.map((data, index) => {
        let stringLength = data.length - "data:image/png;base64,".length;
        let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
        if (sizeInBytes <= 10000000) {
          baseValue.push(data);
        } else {
          toast.error("Image Size Is More Than 10Mb");
        }
      });
    }

    if (fileUploadView.length) {
      setFileUploadView([...fileUploadView, ...baseValue]);
    } else {
      setFileUploadView(baseValue);
    }
  };

  // upload product videos
  const uploadProductVideo = (logoValue) => {
    setUploadingVideo(true);
    if (logoValue.fileList[0].size >= 51000000) {
      toast.error("Video File Cannot Be More Than 50Mb");
      setUploadingVideo(false);
    } else {
      let baseValue = logoValue.base64;
      setVideoUpload(baseValue);
      setUploadingVideo(false);
    }
  };

  // remove image functionality
  const removeImage = (index) => {
    setShow(true);
    setImageIndex(index);
    setCurrentClick("image");
  };

  // on confirmation popup remove image from popup
  const removeImageVideoData = () => {
    setShow(false);
    if (currentClick === "image") {
      const updatedImageData = fileUploadView.filter(
        (value, i) => i !== imageIndex
      );
      setFileUploadView(updatedImageData);
    } else {
      setVideoUpload([]);
    }
    setCurrentClick("");
  };

  const removeVideo = () => {
    setShow(true);
  };

  // change Input Value
  const changeInputValue = (e) => {
    let targetValue = e.target.value;
    setProductUrl(targetValue);
  };

  // save images/video data
  const submitImagesData = () => {
    setIsResponseLoading(true);
    getProductDetails(product_id)
      .then((response) => {
        if (response.data) {
          saveImageData(response.data);
        }
      })
      .catch((err) => {
        if (err) {
          toast.error(err.response.data.message);
        }
        setIsResponseLoading(false);
      });
  };

  const saveImageData = (productsData) => {
    let productData = productsData;
    productData["product_images"] = fileUploadView;
    productData["product_url"] = product_url;
    productData["product_videos"] = [videoUpload];
    productData["product_brochure"] = brochureUploadView;
    productData["brochure_name_list"] = brochureNameList;

    let bodyObj = {
      object_id: product_id,
      fields: productData,
    };
    updateProductDetails(bodyObj)
      .then((response) => {
        if (response.success) {
          toast.success(response.message);
          props.returnToProductImages("product-specification");
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("Internal Server Error");
        } else {
          toast.error("File Size More Than 10Mb.");
        }
      })
      .finally(() => {
        setIsResponseLoading(false);
      });
  };

  const fileRead = (event) => {
    const xyz = Array.from(event.fileList).map((files) => {
      return files.name;
    });

    event.fileList.forEach((element) => {
      const fileName = element.name;
      const fileExtension = fileName?.split(".").pop();
      const allowExtension = ["pdf", "application/pdf"];

      if (allowExtension.includes(fileExtension)) {
        if (element.size >= 10000000) {
          toast.error("PDF File Can Not Be More Than 10MB");
        } else {
          setBrochureUploadView([...brochureUploadView, ...event?.base64]);
          setBrochureNameList([...brochureNameList, ...xyz]);
        }
      } else {
        toast.error("Please Select PDF Files Only");
      }
    });
  };

  const removeBrochure = (delElem) => {
    setBrochureNameList(
      brochureNameList.filter((_, index) => index !== delElem)
    );
    setBrochureUploadView(
      brochureUploadView.filter((_, index) => index !== delElem)
    );
  };

  const handleClose = () => setShow(false);

  return disable ? (
    <div className="p-3">
      <div className="text-center">
        <div className="spinner-border"></div>
      </div>
    </div>
  ) : (
    <div className="image-video-product">
      <div className="form-group row">
        <label className="col-form-label col-md-4 col-sm-4">
          Upload Product Images
        </label>
        <div className="col-md-8 col-sm-8">
          <div className="d-inline-block">
            <ReactFileReader
              className="d-inline"
              handleFiles={uploadProductImages}
              multipleFiles={true}
              base64={true}
              fileTypes={[".png", ".jpeg", ".jfif", ".jpg"]}
            >
              <button className="btn btn-primary btn-md">Upload Images </button>
              <span className="text-dark ms-2 text-small">
                (Max Size 10Mb per image*)
              </span>
            </ReactFileReader>
          </div>
          {fileUploadView
            ? fileUploadView?.map((data, index) => (
                <React.Fragment key={index}>
                  <span className="product-image">
                    <img
                      src={data}
                      alt="logo-img"
                      style={{
                        width: "80px",
                        height: "80px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        objectFit: "cover",
                      }}
                    />
                    <span
                      className="close-icon"
                      onClick={() => removeImage(index)}
                    >
                      <FontAwesomeIcon icon="fa fa-close" />
                    </span>
                  </span>
                </React.Fragment>
              ))
            : null}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-form-label col-md-4 col-sm-4">
          Upload Product Brochure
        </label>
        <div className="col-md-8 col-sm-8">
          <div className="d-inline-block">
            <ReactFileReader
              base64={true}
              fileTypes={[".pdf"]}
              multipleFiles={true}
              handleFiles={fileRead}
            >
              <button
                className="btn btn-primary btn-md "
                title="pdf files only"
              >
                Upload Brochure
              </button>
              <span className="text-dark ms-2 text-small">
                (Max Size 10Mb*)
              </span>
            </ReactFileReader>
          </div>

          {brochureNameList?.map((file_name, index) => {
            return (
              <div className="brochure" key={index}>
                <FontAwesomeIcon icon={"file-pdf"} className="pdf-file" />
                <span>{file_name}</span>

                <span
                  className="remove-brochure"
                  onClick={() => removeBrochure(index)}
                >
                  <FontAwesomeIcon icon="fa fa-close" />
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-form-label col-md-4 col-sm-4">
          Upload Product Video
        </label>

        <div className="col-md-8 col-sm-8">
          <div className="d-inline-block">
            <ReactFileReader
              handleFiles={uploadProductVideo}
              multipleFiles={false}
              base64={true}
              fileTypes={[".mp4"]}
            >
              <button className="btn btn-primary btn-md">Upload Video</button>
              <span className="text-dark ms-2 text-small">
                (Max Size 50mb video*)
              </span>
            </ReactFileReader>
          </div>
          {uploadingVideo ? (
            <p className="mt-3">
              <span
                className="spinner-border text-dark btnLoader"
                role="status"
              ></span>
              Uploading..
            </p>
          ) : null}
          {videoUpload.length >= 1 ? (
            <span className="product-image">
              <video
                style={{
                  width: "80px",
                  height: "80px",
                  display: "block",
                }}
                autoPlay
              >
                <source src={videoUpload} type="video/mp4"></source>
              </video>

              <span
                className="close-icon video-close"
                onClick={() => removeVideo()}
              >
                <FontAwesomeIcon icon="fa fa-close" />
              </span>
            </span>
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-form-label col-md-4 col-sm-4">Video Url</label>
        <div className="col-md-8 col-sm-8">
          <input
            type="text"
            name="product_url"
            className="form-control"
            value={product_url}
            onChange={changeInputValue}
          ></input>
        </div>
      </div>
      <div className="text-end">
        <button
          type="button"
          className="btn btn-success btn-md m-0"
          onClick={(e) => submitImagesData(e)}
          disabled={isResponseLoading}
        >
          {isResponseLoading && (
            <div className="spinner-border text-light btnLoader spinner-border-sm"></div>
          )}
          &nbsp;&nbsp;Next&nbsp;&nbsp;
          <FontAwesomeIcon icon="fa fa-arrow-right" className="ms-1" />&nbsp;&nbsp;
        </button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Do you want to delete Image/Video ?</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-md btn-secondary"
              onClick={removeImageVideoData}
            >
              Yes
            </button>
            <button
              className="btn btn-md btn-danger"
              onClick={() => setShow(false)}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductImages;
