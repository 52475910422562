import { useEffect, useState } from "react";
import {
  QueryBuilder,
  ValueEditor,
  formatQuery,
  parseMongoDB,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.scss";
import "bootstrap/scss/bootstrap.scss";
import Select from "react-select";
import "./QueryBuilder.scss";
import { Form, Offcanvas } from "react-bootstrap";
import { getRedisKeys } from "../../../../services/API";

const initialQuery = { combinator: "and", rules: [] };

export const CustomValueEditor = (props) => {
  /** TODO: RP#01: reactSelect1 is added on purpose since we do not currently want to show a dropdown. Will revisit in future.  */
  if (props.type === "reactSelect1") {
    return (
      <div title="Redis Value">
        <Select
          components={{
            Option: CustomOption,
          }}
          classNamePrefix={"react-select"}
          options={props.values?.map(({ name, label }) => ({
            label,
            value: name,
          }))}
          value={props.values?.find((data) => data.name === props.value)}
          onChange={(e) => props.handleOnChange(e?.value || "")}
        />
      </div>
    );
  }
  return <ValueEditor {...props} />;
};

const QueryBuilderComponent = (props) => {
  const [query, setQuery] = useState(initialQuery);
  const [loading, setLoading] = useState(true);
  const [redisKeysList, setRedisKeysList] = useState([]);
  const [selectedDataSet, setSelectedDataSet] = useState("");
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [fieldsList, setFieldsList] = useState([]);

  useEffect(() => {
    setSelectedDataSet(props.value?.dataset || "");
    if (props.value?.mongoQuery) {
      setQuery(
        parseMongoDB(
          props.value?.mongoQuery ? JSON.parse(props.value?.mongoQuery) : {}
        )
      );
    } else {
      setQuery(initialQuery);
    }

    if(props.value?.dataset){
      setShowOffCanvas(true);
    }
    getRedisKeyList();
    return () => {
      setSelectedDataSet("");
    };
  }, []);

  const changeDatasetValue = (dataSetName = "") => {
    setSelectedDataSet(dataSetName);
    setQuery(initialQuery);
    let changedValue = {};
    changedValue["dataset"] = dataSetName;
    changedValue["mongoQuery"] = "{}";
    changedValue["field_Keys"] = [];
    props.onChange(props.keyName, changedValue);
  };

  const getRedisKeyList = () => {
    getRedisKeys()
      .then((response) => {
        if (response.data.length) {
          let appendData = response.data.map((key) => ({
            label: key,
            name: `{${key}}`,
          }));
          setRedisKeysList(appendData);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const setConfigFieldsList = (fields = []) => {
    if (fields.length) {
      setShowOffCanvas(true);
      setFieldsList(
        fields.map(({ name, display_name, data_type }) => ({
          name,
          data_type,
          label: display_name,
          values: redisKeysList,
          valueEditorType: "reactSelect",
        }))
      );
    }
  };

  const queryOnChange = (query) => {
    setQuery(query);
    let changedValue = {};
    changedValue["dataset"] = selectedDataSet;
    changedValue["mongoQuery"] = query.rules.length
      ? formatQuery(query, "mongodb")
      : "{}";
    changedValue["field_Keys"] = extractValues(query)?.map((data) =>
      data.replace("{", "").replace("}", "")
    );
    props.onChange(props.keyName, changedValue);
  };

  const extractValues = (rule) => {
    let values = [];
    if (rule.value !== undefined) {
      values.push(rule.value);
    }
    if (rule.rules !== undefined && rule.rules.length > 0) {
      for (let subRule of rule.rules) {
        values = values.concat(extractValues(subRule));
      }
    }
    return values;
  };

  return (
    <>
      <Form.Select
        className="w-100"
        size="sm"
        value={selectedDataSet}
        onChange={(e) => {
          changeDatasetValue(e.target.value);
        }}
      >
        <option value="" selected disabled>
          Please select
        </option>
        {props.options
          ? Object.keys(props.options).map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))
          : []}
      </Form.Select>

      {loading ? (
        <div className="set_height d-flex mt-2 justify-content-center align-items-center border rounded">
          <div className="loader-circle-sm" />
        </div>
      ) : (
        <div
          role="button"
          className="border set_height rounded p-1 mt-2 node-submenu"
          title="Click to Change Query"
          onClick={() => setConfigFieldsList(props.options?.[selectedDataSet])}
        >
          {query.rules.length ? formatQuery(query, "mongodb") : "{}"}
        </div>
      )}

      <Offcanvas
        show={showOffCanvas}
        onHide={() => setShowOffCanvas(!showOffCanvas)}
        placement={"end"}
      >
        <Offcanvas.Header className="border-bottom bg-white" closeButton>
          <Offcanvas.Title className="flex-grow-1">Build a MongoDB Find Query</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative bg-white pt-3">
          <div className="queryBuilderParent">
            <QueryBuilderBootstrap>
              <QueryBuilder
                controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
                fields={fieldsList}
                query={query}
                onQueryChange={queryOnChange}
                controlElements={{ valueEditor: CustomValueEditor }}
              />
            </QueryBuilderBootstrap>
            <div className="border set_height rounded p-1 mt-3 node-submenu">
              {query.rules.length ? formatQuery(query, "mongodb") : "{}"}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default QueryBuilderComponent;

const CustomOption = ({ innerProps, label }) => {
  return (
    <div {...innerProps} className="border-bottom">
      <div
        title={label}
        role="article"
        className="dropdown-item textOverFlowEllipsis px-1 py-1 defaultCursor"
      >
        {label}
      </div>
    </div>
  );
};
