import React from "react";
import { Modal } from "react-bootstrap";

function KanbanCardPreview({ show, onHide, displayData }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Your Card Will Look Like This</Modal.Header>
      <Modal.Body>
        <div className="col-md-12 kanban-card-preview mb-2 rounded">
          <div className="py-2 m-2">
            <div className="border-bottom border-secondary border-opacity-25 pb-2">
              {displayData?.key_mapping?.label && (
                <div
                  className="d-flex align-items-center m-1"
                  title={displayData?.key_mapping?.label}
                >
                  <span className="fw-bold">
                    {displayData?.key_mapping?.label}
                  </span>
                </div>
              )}
            </div>

            {displayData?.key_mapping?.title && (
              <div className="kanban-card-title fw-bold text-dark m-1">
                {displayData?.key_mapping?.title}
              </div>
            )}

            {displayData?.key_mapping?.description && (
              <div className="kanban-card-description m-1">
                {displayData?.key_mapping?.description}
              </div>
            )}

            {(displayData?.key_mapping?.from_section ||
              displayData?.key_mapping?.to_section) && (
              <div className="from-to d-flex align-items-center justify-content-between m-1 flex-wrap">
                {displayData?.key_mapping?.from_section && (
                  <div className="d-flex align-items-center">
                    <div className="kanban-card-from-section m-1">
                      {displayData?.key_mapping?.from_section}
                    </div>
                  </div>
                )}
                {displayData?.key_mapping?.to_section && (
                  <div className="d-flex align-items-center">
                    <div className="kanban-card-to-section m-1">
                      {displayData?.key_mapping?.to_section}
                    </div>
                  </div>
                )}
              </div>
            )}

            {displayData?.key_mapping?.tags && (
              <div className="kanban-card-tags d-flex flex-wrap">
                <div
                  className={`m-1 bg-dark-subtle text-dark rounded p-1 px-2`}
                >
                  {displayData?.key_mapping?.tags}
                </div>
              </div>
            )}

            {displayData?.key_mapping?.assignees && (
              <div className="border-top border-secondary border-opacity-25 pt-2">
                <div className="kanban-card-assignees text-end">
                  <div
                    className={`bg-primary text-white rounded-circle kanban-card-assignee ms-2`}
                  >
                    <div className="d-flex align-items-center justify-content-center mx-2 kanban-card-assignee-name">
                      {"AB"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default KanbanCardPreview;
