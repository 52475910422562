import React, { useEffect, useState } from "react";
import RootCategory from "./rootCategory/RootCategory";
import DescriptionCategory from "./descriptionCategory/DescriptionCategory";
import { getCategorySet } from "../../../services/appService";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SubCategoryLisitng(props) {
  let perms = useParams();
  const [explorer, setExplorer] = useState({});
  const [rootId, setrootId] = useState("");
  const [loading, setloading] = useState(true);
  const [descriptionData, setDescriptionData] = useState({});
  const [sendForEdit, setsendForEdit] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [storeId, setStoreId] = useState(perms.store_id);

  useEffect(() => {
    getRootCategoryList();
  }, []);

  const getRootCategoryList = () => {
    setloading(true);
    getCategorySet(perms.store_id)
      .then((response) => {
        setloading(false);
        if (response.success === true) {
          if (response.data.length) {
            setExplorer(response.data[0]);
          } else {
            let id = uuidv4();
            setExplorer({
              store_id: perms.store_id,
              category_name: "Root Category",
              category_status: true,
              category_image: "",
              includes_in_menu: true,
              includes_in_search: true,
              category_description: "",
              category_list_type: "list_type",
              category_id: id,
              parent_category_id: id,
              subCategorys: [],
            });
          }
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        setloading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    setSelectedCategory(
      descriptionData?.category_id
        ? descriptionData?.category_id
        : explorer?.category_id
    );
    setsendForEdit(descriptionData);
  }, [descriptionData]);

  useEffect(() => {
    setrootId(explorer?.category_id);
    setSelectedCategory(
      descriptionData?.category_id
        ? descriptionData?.category_id
        : explorer?.category_id
    );
  }, [explorer]);

  useEffect(() => {
    setDescriptionData(explorer);
  }, []);

  const backToCategoryListing = (e) => {
    props.history.push(`/store/${storeId}`);
  };

  const backtoStore = () => {
    props.history.push("/stores");
  };
  const returnFolderData = (returnedObjdata) => {
    setsendForEdit(returnedObjdata);
    setDescriptionData(returnedObjdata);
  };

  const addSubCategory = () => {
    setsendForEdit({});
  };

  return (
    <div className={`col-md-12 pb-4 px-2 catlog-main`}>
      <div className="button-wrapper w-100 d-help py-2">
        <div className="d-help flex-wrap">
          <div className="bread-crum d-help me-2">
            <p
              className="m-0 me-2 text-underline font-13"
              onClick={backtoStore}
            >
              All Stores
            </p>
            <span>/</span>
            <p
              className="m-0 me-2 ms-2 text-underline font-13"
              onClick={backToCategoryListing}
            >
              Store
            </p>
            <span>/</span>
            <h6 className="m-0 ms-2 font-13 ">Product Categories</h6>
          </div>
        </div>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-sm btn-secondary m-0 "
            onClick={backToCategoryListing}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
      </div>
      <div className="subCategory_listing bg-white border p-2 ">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              <div>
                <button
                  disabled={!selectedCategory}
                  type="button"
                  className="btn btn-md btn-primary mt-2"
                  onClick={addSubCategory}
                >
                  <FontAwesomeIcon icon="fa-solid fa-plus" className="me-1" />
                  Add Sub Category
                </button>
              </div>

              <div className="category-design mt-4 table-responsive p-2">
                {loading ? (
                  <div className="text-center d-block mt-2">
                    <div className="spinner-border " role="status"></div>
                  </div>
                ) : (
                  <RootCategory
                    explorer={explorer}
                    selectedCategory={selectedCategory}
                    returnDataToDescriptionCategory={returnFolderData}
                  ></RootCategory>
                )}
              </div>
            </div>
            <div className="col-md-7 border-start ps-3">
              <DescriptionCategory
                explorer={explorer}
                setExplorer={setExplorer}
                contentData={sendForEdit}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                getRootCategoryList={getRootCategoryList}
                rootId={rootId}
                setloading={setloading}
              ></DescriptionCategory>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubCategoryLisitng;
