import React, { useState, useEffect, useMemo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { getAnimationType } from "./Markers";
import building from "../../../images/mapcity.png";
import start from "../../../images/start_icon_pin.png";
import end from "../../../images/end_icon_pin.png";

const TripMap = ({ apiKey, zoom, center, displayData, itemData }) => {
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    calculateRoute();
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const mapStyles = useMemo(
    () => ({
      height: "530px",
      width: "100%",
    }),
    []
  );

  const handleMarkerClick = (id, data) => {
    mapRef?.panTo({ lat:data?.lat, lng:data?.lng });
    setInfoWindowData({id,...data});
    setIsOpen(true);
  };

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    itemData?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const calculateRoute = async () => {
    const directionService = new window.google.maps.DirectionsService();
    const origin = new window.google.maps.LatLng(
      itemData[0]?.lat,
      itemData[0]?.lng
    );
    const destination = new window.google.maps.LatLng(
      itemData[itemData.length - 1]?.lat,
      itemData[itemData.length - 1]?.lng
    );

    const waypoints = itemData.slice(1, -1).map((data) => ({
      location: new window.google.maps.LatLng(data.lat, data.lng),
      stopover: true,
    }));

    directionService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirection(result);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  const svgMarker = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: "#0d6efd",
    fillOpacity: 1,
    strokeWeight: 5,
    strokeColor: "white",
    scale: 8,
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          center={center}
          defaultCenter={center}
          defaultZoom={zoom}
          onLoad={onMapLoad}
          options={{
            streetViewControl: false,
          }}
        >
          {itemData?.map((data, i) => (
            <Marker
              key={i}
              position={{ lat: data?.lat, lng: data?.lng }}
              onClick={() => {
                handleMarkerClick(i, data);
              }}
              options={{
                icon:
                  i === 0 ? start : i === itemData.length - 1 ? end : svgMarker,
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(0, 0),
                animation:getAnimationType(displayData?.markerAnimation)
              }}
            >
              {isOpen && infoWindowData?.id === i && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <div className="map-info-window p-1">
                    {Object.keys(infoWindowData).map((key, index) => {
                      return <p>{infoWindowData?.key}</p>;
                    })}
                    <img
                      src={building}
                      className="info-image border rounded-2"
                    ></img>
                    {infoWindowData &&
                      Object.keys(infoWindowData).map((key, index) =>
                        typeof infoWindowData[key] == "object" ? (
                          <div key={index} className="marker-info">
                            <div>
                              <label className="control-label text-left">
                                {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                              </label>
                              <span className="ms-2">
                                {key === "_id" ? infoWindowData[key].$oid : infoWindowData[key].toString()}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div key={index} className="marker-info">
                            <div>
                              <label className="control-label text-left">
                                {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                              </label>
                              <span className="ms-2">
                                {infoWindowData[key]}
                              </span>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
          {direction && (
            <DirectionsRenderer
              directions={direction}
              options={{
                suppressMarkers: true,
              }}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default TripMap;
