import axios from "axios";
import config from "../config";
import { getAccessToken } from "./utils";

// Generate axios instance
const appInstance = axios.create({
  baseURL: config.env("REACT_APP_URL"),
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

appInstance.interceptors.request.use(async (req) => {
  let token = await getAccessToken();
  req.headers = {
    ...req.headers,
    Authorization: token,
  };
  return req;
});

appInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    throw err;
  }
);

export function getCancelTokenRef() {
  return axios.CancelToken.source();
}

export function cancelRequest(cancelTokenRef) {
  cancelTokenRef.cancel();
}

export function isRequestCancel(error) {
  return axios.isCancel(error);
}

export default appInstance;
