import React from "react";
import { Fade } from "react-bootstrap";
import dummyImage from "../../../../images/dummy-image.jpg";

const SingleCategory = ({ onCategoryClick, data, visible }) => {
  return (
    <Fade in={visible}>
      <div
        title={data.category_name}
        className="col-md-4 col-12 col-sm-6 col-lg-3 p-0"
        onClick={() => {
          onCategoryClick(data);
        }}
      >
        <div className="category-card-design m-1">
          <div className="img-wrapper">
            <img
              src={
                data?.category_image?.length ? data.category_image : dummyImage
              }
            />
          </div>
          <div className="content-wrapper w-100 flex-grow-1">
            <span className="font-14 fw-bold mb-1 text-dark text-capitalize text-nowrap">
              {data?.category_name}
            </span>
          </div>
          <div className="ms-1 badge-wrapper">
            <span>
              {data?.total_products > 99 ? "99+" : data?.total_products || 0}
            </span>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default SingleCategory;
