import React, { Component } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getOrderDetails, updateCustomerSubscription } from "../../services/appService";
import "./payment.scss";
import { toast } from "react-toastify";


function Payment(props) {
  const { payment_session_id } = useParams();
  const tenantId = JSON.parse(localStorage.getItem("tenantId"));

  useEffect(() => {
    let parent = document.getElementById("drop_in_container");
    if (payment_session_id == "") {
      toast.error("No Payment specified");
      return
    };
    parent.innerHTML = "";
    const cashfree = new window.Cashfree(payment_session_id);
    var checkedValue = ["order-details", "card", "upi", "netbanking", "app", "paylater", "credicardemi", "cardlessemi"];
    var styleElements = document.getElementsByClassName("style-dropin");
    let style = {};
    for (var i = 0; i < styleElements.length; i++) {
      if (styleElements[i].value) {
        style[styleElements[i].id] = styleElements[i].value;
      }
    }
    cashfree.drop(parent, {
      onSuccess: success,
      onFailure: failure,
      components: checkedValue,
      style: style,
    });
  });

  const success = function (data) {
    
    if (data.order && data.order.status == "PAID") {
      getOrderDetails(data.order.orderId,tenantId).then((response) => {
        if (response.data.order_status == "PAID") {
          const paymentDetails = response.data.paymentDetails;
          if (paymentDetails.length > 0 && paymentDetails[0].payment_status==="SUCCESS") {
            let payload = {"tenant_id":tenantId , "app_group_id":response.data.app_group_id, "order_id": response.data.order_id};
            updateCustomerSubscription(payload).then((res)=>{
              if(res.success){
                toast.success(res.message);
              }
            })
            toast.success(paymentDetails[0].payment_message);
          }
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        props.history.push(`/app-dashboard`);
      })
    } 
  };
  const failure = function (data) {
    getOrderDetails(data.order.orderId,tenantId).then((response) => {
      if (response.data.order_status != "PAID") {
        const paymentDetails = response.data.paymentDetails;
        if (paymentDetails.length > 0 && paymentDetails[0].payment_status==="FAILED") {
          toast.error(paymentDetails[0].payment_message);
        }
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      //props.history.push(`/app-dashboard`);
    })
  }

  return (
    <div className={`col-md-12 pb-4 bg-white `}>

      <div className="style-container" style={{ display: "none" }}>
        <p className="order-token">Style your Dropin</p>
        <input className="style-dropin" type="text"
          id="backgroundColor" placeholder="Background Color"></input>
        <input className="style-dropin" type="text" id="theme" placeholder="Theme"></input>
        <input className="style-dropin" type="text" id="color" placeholder="Color"></input>
        <input className="style-dropin" type="text" id="errorColor" placeholder="Error Color"></input>
        <input className="style-dropin" type="text" id="fontSize" placeholder="Font Size"></input>
        <input className="style-dropin" type="text" id="fontFamily" placeholder="Font Family"></input>
      </div>
      <div className="container"><h4>Please do not reload the page.</h4></div>
      <hr />
      <div className="dropin-parent" id="drop_in_container">
        Your drop will come here
      </div>
    </div>
  );
}

export default Payment;