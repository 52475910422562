import React, { useContext, useEffect, useState } from "react";
import { executeLogicByName } from "../../../services/API";
import TableHeaderButton from "./TableHeaderButton";
import "./TableSummary.scss";
import { RoutesContext } from "../../../RoutesContext";

const TableSummary = ({
  summaryData,
  executeAppObject,
  objData,
  formConfig,
  formFieldsList,
  pageFieldsList,
  actionButtonList,
  appSessionId,
  tableName,
  pageId,
  sysFilter,
  currentTableData,
  routePageToOneStepBack,
  multipleActions,
  setMultipleActions,
  reloadComponentData,
  addComponentToReloadArray,
  component_id,
  isCacheEnabled,
  tempStorageCall,
}) => {
  const [summaryValues, setSummaryValues] = useState();
  const { cachedData, setCacheData } = useContext(RoutesContext);
  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setSummaryValues(cachedData?.[component_id]?.["table_summary"]);
      return;
    }
    getDataByLogic();
  }, []);
  const getDataByLogic = () => {
    let obj = {
      app_session_id: appSessionId,
      logic_name: summaryData?.tableSummaryLogic || "",
      reference_name: summaryData?.summaryLogicVariableName || "",
    };
    executeLogicByName(obj).then((response) => {
      const [fieldsValue] = response?.data?.value?.data;
      setSummaryValues(fieldsValue);
      let data = cachedData;
      data[component_id] = {
        ...data[component_id],
        table_summary: fieldsValue,
      };
      setCacheData(data);
    });
  };
  return (
    <div className="border-bottom">
      <div className="d-flex justify-content-between summaryPanel">
        <div className="d-flex flex-wrap mt-1 overallContent">
          {summaryData?.summaryFields?.map((item, index) => {
            let hasBorder = (index + 1) % 3 === 0;
            return (
              <div
                className={`d-flex justify-content-around ps-1 ${
                  !hasBorder ? "borderClass" : ""
                } sectionField`}
              >
                <div className="summaryLabel">{item?.displayName}:</div>
                <div className="text-center sectionFieldData">
                  <b className="summaryValue">{summaryValues?.[item.value]}</b>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TableSummary;
