import React, { Component } from "react";
import {
  mentionUserEmail,
  upsertComment,
} from "../../../../services/appService";
import { toast } from "react-toastify";
import { inputTypeKey } from "../../../common/model/Model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RelationTable from "../RelationTable";
import CommentBox from "../CommentBox";
import LineItemTable from "../LineItemTable";

class ViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: [],
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      loading: false,
      valueData: "",
      enteredValue: "",
      emailList: [],
    };
  }

  componentDidMount() {
    this.callUserEmails();
  }

  callUserEmails() {
    mentionUserEmail()
      .then((response) => {
        if (response) {
          this.setState({ userName: response.data });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  onChange = (e) => {
    const enteredValue = e.target.value;
    this.setState({ enteredValue }, () => {
      let valueData = enteredValue.replace(/ *\([^)]*\) */g, "");
      // let emailList = valueData.match(/(?<=\[)[^\][]*(?=])/g);
      let emailList = valueData;
      this.setState({ valueData, emailList });
    });
  };

  onBackClick() {
    this.props.changeViewPageState(false);
  }

  addComment = () => {
    this.setState({ loading: true });
    const obj = {
      object_id: this.props?.viewData?._id,
      comments: this.state.enteredValue
        .replace(/[\[\']+/g, "")
        .replace(/[\] \']+/g, " "),
      email_list: this.state.emailList,
      user_name: this.state.userInfo.Name,
      dataset_name: this.props.datasetName,
    };
    upsertComment(obj)
      .then((response) => {
        if (response) {
          toast.success(response.message);
          this.setState({ loading: false });
          this.setState({ enteredValue: "" });
          this.props.changeViewPageState();
        }
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ loading: false });
      });
  };

  render() {
    const commentObj = this.props.actions.actionDetails.find(
      (data) => data.actionName === "View"
    );

    let pageFieldsData = this.props?.pageFieldsList?.filter(
      (field) => !field?.show_key_to_table || field?.show_key_to_table !== "no"
    );

    let [tableWidth] = this.props.viewWidthState;
    return (
      <div className="bg-white">
        <div className="row m-2 mt-0">
          {pageFieldsData?.map((element, index) =>
            element.data_type === "arr_obj" ||
            element.data_type === "lineItems" ? (
              <div className="col-md-12 mb-2 p-0" key={index}>
                <h5 className="text-nowrap">
                  {element?.display_name}{" "}
                  {this.props.viewData?.[element.name]?.length > 0 &&
                    `(${this.props.viewData?.[element.name]?.length})`}
                </h5>
                <LineItemTable
                  fields={element?.line_items_form_page_fields || []}
                  tableData={this.props.viewData?.[element.name] || []}
                />
              </div>
            ) : (
              <div
                key={index}
                className={`${
                  tableWidth > 60 ? "col-md-12" : "col-md-6"
                } mb-1 p-0 mb-3x align-items-center d-flex`}
              >
                <div className="col-md-3 custom-label border-0 mb-0 p-0 view-content-label">
                  {`${element.display_name}`}
                </div>
                <div className="col-md-1">:</div>
                <div className="col-md-8 border-0 mb-0">
                  {element.data_type === "file" ? (
                    this.props.viewData[element.name] &&
                    this.props.viewData[element.name].length ? (
                      this.props.viewData[element.name].map((data, index) => (
                        <p>
                          <a
                            key={index}
                            href={data}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="download"
                          >
                            <FontAwesomeIcon
                              icon={"fa fa-download"}
                              className="mb-0 ms-2"
                              aria-hidden="true"
                            />&nbsp;{data.split("/").pop()}
                          </a>
                        </p>
                      ))
                    ) : (
                      <p className="custom-content m-0 view-content-data">No File/s Uploaded</p>
                    )
                  ) : element.data_type === inputTypeKey.searchableDropdown ? (
                    <p className="custom-content m-0 view-content-data">
                      {Array.isArray(this.props.viewData?.[element.name])
                        ? this.props.viewData?.[element.name]?.join(",")
                        : this.props.viewData?.[element.name] || "-"}
                    </p>
                  ) : element.data_type === inputTypeKey.largeText ? (
                    <p className="custom-content m-0 view-content-data">
                      {this.props.viewData[element.name]?.replace(
                        /<\/?[^>]+(>|$)/g,
                        ""
                      ) || "-"}
                    </p>
                  ) : element.data_type === inputTypeKey.hyperlink ? (
                    <a rel="noreferrer" target="_blank" href={this.props.viewData?.[element.name]}>
                      {this.props.viewData?.[element.name]}
                    </a>
                  ) : element.data_type === inputTypeKey.inlineTableForm ? (
                    this.props.viewData?.[element.name]?.length > 0 &&
                    <p className="custom-content m-0 fw-bold view-content-data"
                      title="Click For More Details"
                      role="button"
                      onClick={() => {
                        this.props.openInlineFormTableModal(
                          element,
                          this.props.viewData?.[element.name]
                        );
                      }}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" />&nbsp;{this.props.viewData?.[element.name]?.length} item/s 
                    </p>
                  ) : element.data_type === inputTypeKey.checkList ? (
                    this.props.viewData?.[element.name]?.length > 0 &&
                  <p className="custom-content m-0 fw-bold view-content-data"
                    title="Click For More Details"
                    role="button"
                    onClick={() => {
                      this.props.openCheckListModal(
                        element,
                        this.props.viewData?.[element.name]
                      );
                    }}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" />&nbsp;{this.props.viewData?.[element.name]?.length} item/s 
                    <p>{this.props.viewData?.[element.name]?.filter((item) => item.status === "Yes").length} <FontAwesomeIcon icon="fa-regular fa-thumbs-up" />&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.props.viewData?.[element.name]?.filter((item) => item.status === "No").length} <FontAwesomeIcon icon="fa-regular fa-thumbs-down" /></p>
                  </p>
                  ) : (
                    <p className="custom-content m-0 view-content-data">
                      {this.props.viewData?.[element.name] &&
                      (!isNaN(this.props.viewData[element.name]) ||
                        !this.props.viewData[element.name]?.includes("None"))
                        ? this.props.viewData[element.name]
                        : "-"}
                    </p>
                  )}
                </div>
              </div>
            )
          )}
        </div>

        {tableWidth !== 100 && (
          <div className="p-2">
            <CommentBox
              userName={this.props.userName}
              onChange={this.onChange}
              commentData={this.state.enteredValue}
            />
          </div>
        )}

        <div className="control-btn text-end m-3">
          <button
            className="btn btn-success btn-md m-0"
            disabled={!this.state.enteredValue}
            onClick={() => this.addComment()}
          >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
            {this.state.loading && (
              <div className="spinner-border text-light btnLoader me-1 spinner-border-sm"></div>
            )}
            &nbsp;&nbsp;Submit&nbsp;&nbsp;
          </button>
        </div>
      </div>
    );
  }
}

export default ViewPage;
