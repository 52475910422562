import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const LineItemTable = ({ tableData, fields }) => {
  const fieldList = fields?.filter(
    (field) => field?.show_key_to_table !== "no"
  );
  return (
    <div className="table-responsive border">
      <table className="table mb-0">
        <thead>
          <tr>
            <th className="text-nowrap">Sr. No.</th>
            {fieldList?.map((field, index) => (
              <th className="text-nowrap" key={index}>
                {field?.display_name}
              </th>
            ))}
            <th className="text-nowrap">Total Count</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.length ? (
            tableData?.map((data, index) => {
              return (
                <tr key={index}>
                  <td className="text-nowrap">{index + 1}</td>
                  {fieldList?.map((field, subIndex) => {
                    if (field.data_type === "file") {
                      return (
                        <td key={subIndex}>
                          {data?.[field?.name]?.map((subData) => (
                            <a href={subData} target="_blank" download="true">
                              <FontAwesomeIcon
                                icon={"fa fa-download"}
                                className="me-2"
                                aria-hidden="true"
                                color="red"
                              />
                            </a>
                          ))}
                        </td>
                      );
                    } else {
                      return (
                        <td key={index + subIndex}>
                          {data?.[field?.name] || "-"}
                        </td>
                      );
                    }
                  })}
                  <td className="text-nowrap">{data?.totalCount}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={fields?.length + 2}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LineItemTable;
