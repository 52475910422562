import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./GoogleMap.scss";
import AutocompleteComponent from "../store/product/productMap/AutoFill";
import { getTenantDetail } from "../../../services/appService";
import { toast } from "react-toastify";
import { Col, Collapse } from "react-bootstrap";
import AppLoader from "../../dataset/Loader/AppLoader";
import { reactAppExecutionVP } from "../../../services/API";
import TripMap from "./TripMap";
import CurrentStatusMap from "./CurrentStatusMap";
import AutocompleteSearch from "./AutoComplete";
import { GoogleMap } from "@react-google-maps/api";

const GoogleMapComponent = ({
  columnWidth,
  displayData,
  appSessionId,
  objData,
  filterApply
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [center, setCenter] = useState({ lat: 21.14, lng: 79.08 });
  const [zoom, setZoom] = useState(6);
  const [mapKey, setMapKey] = useState("");
  const [componetLoading, setComponentLoading] = useState(false);
  const [toggleMapView, setToggleMapView] = useState(true);
  const [itemData, setItemData] = useState();
  const [searchData, setSearchData] = useState([]);

  const mapStyles = useMemo(
    () => ({
      height: "530px",
      width: "100%",
    }),
    []
  );

  useEffect(() => {
    if (filterApply) {
      getMapData();
    }
  }, [filterApply]);

  useEffect(() => {
    let tenant_name = JSON.parse(localStorage.getItem("query_param"));
    getTenantDetail(tenant_name)
      .then((response) => {
        if (
          response.data[0]?.map_key &&
          response.data[0].map_key !== undefined
        ) {
          setMapKey(response.data[0]?.map_key);
          getMapData();
        } else {
          toast.error("Please Enter Google Map Api Key");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const getMapData = () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setComponentLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data.length > 0 && Object.keys(response.data[0]).length > 0) {
          setItemData(response.data[0][displayData.refValue].value);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  const fetchAutoFillData = (data, zoom = 15) => {
    let longValue = data.split(",")[1];
    let latValue = data.split(",")[0];
    let objdata = { ...center };
    objdata.lat = parseFloat(latValue);
    objdata.lng = parseFloat(longValue);
    setZoom(zoom);
    setCenter(objdata);
  };

  useEffect(() => {
    let searchData = itemData?.map((data) => {
      return { ...data, ["searchText"]: Object.values(data).join(",") };
    });
    setSearchData(searchData);
  }, [itemData]);

  return (
    <Col md={columnWidth || "12"} className={"px-0"}>
      <div className={`h-100 mx-1 `}>
        <div className="main-content bg-white py-1 px-1 mb-2">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
            <h5 className="table-heading">{displayData?.mapTitle}</h5>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title="Refresh"
                  onClick={getMapData}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-refresh"}
                    className={`arrow-icon ${
                      toggleMapView ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={toggleMapView ? "Collapse Map" : "Expand Map"}
                  onClick={() => setToggleMapView(!toggleMapView)}
                  aria-controls="map-toggle"
                  aria-expanded={toggleMapView}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      toggleMapView ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>

          <Collapse in={toggleMapView}>
            <div id="map-toggle">
              <div className="position-relative">
                {componetLoading && <AppLoader />}
                <div className="row mb-3">
                  {(mapKey && itemData && displayData?.mapType === "cs_map") && (
                    <div className="col-4">
                      <AutocompleteSearch
                        data={searchData}
                        autoFill={fetchAutoFillData}
                        setCenter={setCenter}
                        setZoom={setZoom}
                      ></AutocompleteSearch>
                    </div>
                  )}
                  <div className="col-4 ms-auto">
                    <AutocompleteComponent
                      apiKey={mapKey}
                      autoFill={fetchAutoFillData}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="map-view">
                      {mapKey && itemData ? (
                        displayData?.mapType === "cs_map" ? (
                          <CurrentStatusMap
                            apiKey={mapKey}
                            zoom={zoom}
                            center={center}
                            displayData={displayData}
                            objData={objData}
                            itemData={itemData}
                            setCenter={setCenter}
                            setZoom={setZoom}
                          />
                        ) : displayData?.mapType === "trip_map" ? (
                          <TripMap
                            apiKey={mapKey}
                            zoom={zoom}
                            center={center}
                            displayData={displayData}
                            objData={objData}
                            itemData={itemData}
                          />
                        ) : null
                      ) : (
                        <>
                          <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom={zoom}
                            center={center}
                            defaultCenter={center}
                            defaultZoom={zoom}
                            options={{
                              streetViewControl: false
                            }}
                          />
                          <div className="position-absolute">
                            <AppLoader></AppLoader>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Col>
  );
};

export default GoogleMapComponent;
