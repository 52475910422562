import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

function RelationshipBuilder(props) {
  const [relationsList, setRelationsList] = useState(props?.relations || []);

  useEffect(() => {
    if (relationsList?.length) {
      props?.onRelationshipChange(relationsList);
    }
  }, [relationsList]);

  const addRelation = () => {
    const relationId = uuidv4();
    const fieldMappingId = uuidv4();
    let obj = {
      relationshipName: "",
      childDatasetName: "",
      id: relationId,
      fieldsMapping: [
        {
          parentField: "",
          childField: "",
          id: fieldMappingId,
        },
      ],
    };

    setRelationsList([...relationsList, obj]);
  };

  const removeRelation = (relationIndex) => {
    let newRelations = [...relationsList];
    newRelations = newRelations.filter((_, i) => i !== relationIndex);
    setRelationsList(newRelations);
  };

  const changeValue = (event, relationIndex) => {
    let newRelations = [...relationsList];
    let name = event.target.name;
    let value = event.target.value;
    newRelations[relationIndex][name] = value;
    setRelationsList(newRelations);
  };

  const changeFieldMappingValue = (event, relationIndex, fieldIndex) => {
    let newFieldsMapping = [...relationsList];
    let name = event.target.name;
    let value = event.target.value;
    newFieldsMapping[relationIndex]["fieldsMapping"][fieldIndex][name] = value;
    setRelationsList(newFieldsMapping);
  };

  const addFieldMapping = (relationIndex) => {
    const fieldMappingId = uuidv4();
    let newFieldsMapping = [...relationsList];
    let obj = {
      parentField: "",
      childField: "",
      id: fieldMappingId,
    };
    newFieldsMapping[relationIndex]["fieldsMapping"] = [
      ...newFieldsMapping[relationIndex]["fieldsMapping"],
      obj,
    ];

    setRelationsList(newFieldsMapping);
  };

  const removeFieldMapping = (relationIndex, fieldIndex) => {
    let newFieldsMapping = [...relationsList];
    newFieldsMapping[relationIndex]["fieldsMapping"] = newFieldsMapping[
      relationIndex
    ]["fieldsMapping"].filter((_, i) => i !== fieldIndex);
    setRelationsList(newFieldsMapping);
  };

  return (
    <>
      <div className="d-flex col-12 align-items-center">
        <div className="me-2 fw-semibold fs-6">Relationships</div>
        <Button
          variant="outline-success"
          size="sm"
          className="m-0"
          onClick={addRelation}
          title="Add New Relation"
        >
          Add
        </Button>
      </div>
      <>
        {relationsList?.map((relation, index) => {
          return (
            <div
              className="d-flex justify-content-between mt-2 border p-2 rounded actionButtonContainer"
              key={relation?.id}
            >
              <div className="flex-grow-1">
                <div className="row">
                  <div className="col-md-6 col-sm-6 form-group">
                    <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
                      Relationship Name
                    </label>
                    <div className="col-md-8 col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Relationship Name"
                        name="relationshipName"
                        onChange={(e) => {
                          changeValue(e, index);
                        }}
                        value={relation.relationshipName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 form-group">
                    <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
                      Child Dataset Name
                    </label>
                    <div className="col-md-8 col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Child Dataset Name"
                        name="childDatasetName"
                        onChange={(e) => {
                          changeValue(e, index);
                        }}
                        value={relation.childDatasetName}
                      />
                    </div>
                  </div>
                </div>
                {relation?.fieldsMapping.map((field, fieldIndex) => {
                  return (
                    <div className="row" key={field?.id}>
                      <div className="col-md-4 col-sm-4 form-group">
                        <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
                          Parent Field
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Parent Field"
                          name="parentField"
                          onChange={(e) => {
                            changeFieldMappingValue(e, index, fieldIndex);
                          }}
                          value={field.parentField}
                        />
                      </div>
                      <div className="col-md-4 col-sm-4 form-group">
                        <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
                          Child Field
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Child Field"
                          name="childField"
                          onChange={(e) => {
                            changeFieldMappingValue(e, index, fieldIndex);
                          }}
                          value={field.childField}
                        />
                      </div>

                      <div className="col-md-4 col-sm-4 d-flex align-items-center justify-content-start mt-4">
                        <FontAwesomeIcon
                          icon={"fa fa-plus-circle"}
                          role="button"
                          color="green"
                          onClick={() => addFieldMapping(index)}
                          className="m-1 mt-2"
                          title="Add Field Mapping"
                        />
                        {relation?.fieldsMapping?.length > 1 && (
                          <FontAwesomeIcon
                            icon={"fa fa-minus-circle"}
                            role="button"
                            color="red"
                            onClick={() =>
                              removeFieldMapping(index, fieldIndex)
                            }
                            className="m-1 mt-2"
                            title="Remove Field Mapping"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="ms-2 d-flex justify-content-center flex-wrap align-items-center flex-column">
                <FontAwesomeIcon
                  icon={"fa fa-plus-circle"}
                  role="button"
                  color="green"
                  onClick={addRelation}
                  className="m-1"
                  title="Add New Relation"
                />
                <FontAwesomeIcon
                  icon={"fa fa-minus-circle"}
                  role="button"
                  color="red"
                  onClick={() => removeRelation(index)}
                  className="m-1"
                  title="Remove Relation"
                />
              </div>
            </div>
          );
        })}
      </>
    </>
  );
}

export default RelationshipBuilder;
