import React, { useContext, useMemo, useState } from "react";
import CustomModalForm from "../components/CustomModalForm";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { filterFormDataGetter } from "./kanbanCardUtils";
import { reactAppFormExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";

const AddUpdateKanbanCard = ({
  lane,
  boardData,
  setBoardData,
  setIsAddingCard,
  setIsUpdatingCard,
  card,
  objData,
  appSessionId,
  submitFormPayload,
}) => {
  const [modalShow, setModalShow] = useState(true);
  const [cardData, setCardData] = useState({
    id: uuidv4(),
  });
  const { filterFormData } = useContext(FilterFormContext);

  useMemo(() => {
    if (card) {
      setCardData(card);
    } else {
      setCardData({ ...cardData, ...filterFormData });
    }
  }, []);

  const handleAddUpdateKanbanCard = async () => {
    try {
      if (card?.id) {
        setIsUpdatingCard(false);
      } else {
        setIsAddingCard(false);
      }

      let newCardIds = [];

      if (boardData.lanes?.[lane.id]?.cardIds?.includes(cardData.id)) {
        newCardIds = boardData.lanes?.[lane.id]?.cardIds;
      } else {
        newCardIds = [...boardData.lanes?.[lane.id]?.cardIds, cardData.id];
      }

      let newCards = { ...boardData?.cards, [cardData.id]: cardData };

      const newBoardData = {
        ...boardData,
        lanes: {
          ...boardData.lanes,
          [lane.id]: {
            ...boardData.lanes?.[lane.id],
            cardIds: newCardIds,
          },
        },
        cards: newCards,
      };

      //when the filter is not applied then this data will be send otherwise the filterform data will be send
      let filterformDataToSend = filterFormDataGetter(filterFormData, cardData);

      const kanbanStructure = {
        kanban_structure: newBoardData,
        card_data: cardData,
        lane_id: lane?.id,
        card_id: cardData?.id,
        board_id: newBoardData.boardId,
        ...filterformDataToSend,
        ...cardData,
      };

      const newSubmitFormPayload = {
        ...submitFormPayload,
        data: {
          ...submitFormPayload.data,
          formData: [
            {
              ...submitFormPayload.data.formData?.[0],
              ...kanbanStructure,
            },
          ],
        },
      };

      setBoardData(newBoardData);

      const res = await reactAppFormExecutionVP(newSubmitFormPayload);
      if (res.status === 200) {
        setBoardData(newBoardData);
      }
    } catch (error) {
      setBoardData(boardData);
      toast.error(error.message);
    }
  };

  const handleModalOnHide = () => {
    setModalShow(false);
    if (card?.id) {
      setIsUpdatingCard(false);
    } else {
      setIsAddingCard(false);
    }
  };

  return (
    <CustomModalForm
      show={modalShow}
      onHide={handleModalOnHide}
      modalTitle={card?.id ? "Edit" : "Add"}
      pageFields={objData?.page?.page_fields?.[0]}
      formData={cardData}
      setFormData={setCardData}
      onSubmitClick={handleAddUpdateKanbanCard}
      submitButtonIcon={
        card?.id ? (
          <FontAwesomeIcon icon={"fa fa-pencil"} />
        ) : (
          <FontAwesomeIcon icon={"fa fa-add"} />
        )
      }
      submitButtonText={card?.id ? "Update" : "Add"}
      appSessionId={appSessionId}
    />
  );
};

export default AddUpdateKanbanCard;
