import React, { useEffect, useState } from "react";
import ReactFileReader from "react-file-reader";
import { toast } from "react-toastify";
import Select from "react-select";

const DisplayBuilderGroupList = ({ logicList, displayBuilder, onChange }) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const handleFiles = (files) => {
    let data = { ...displayChanges };

    if (files) {
      if (files.fileList[0].size >= 500000) {
        toast.error("File size must be less than 500kb.");
      } else {
        data.dafaultAvtar = files.base64;
      }
    } else {
      data.dafaultAvtar = "";
    }
    setDisplayChanges(data);
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Default Avatar</label>
        <div>
          <div className="d-flex">
            <ReactFileReader
              fileTypes={[".jpg", ".png", ".gif"]}
              handleFiles={(e) => handleFiles(e)}
              multipleFiles={false}
              base64={true}
            >
              <button className="btn btn-primary btn-md m-0">Upload</button>
            </ReactFileReader>{" "}
            {displayChanges.dafaultAvtar ? (
              <span
                title="Click To Clear Image"
                className="ms-2"
                onClick={() => handleFiles()}
              >
                <img
                  src={displayChanges.dafaultAvtar}
                  alt="file-img"
                  height={"30"}
                  width={"30"}
                />
              </span>
            ) : null}
          </div>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Default Avatar Key</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter Key"
            name="dafaultAvtarKey"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.dafaultAvtarKey}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Display Key List</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter Key"
            name="displayKeyList"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.displayKeyList}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Fetch Key List</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter Key"
            name="fetchKeyList"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.fetchKeyList}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Display Time Key</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter Key"
            name="displayTimeKey"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.displayTimeKey}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderGroupList;
