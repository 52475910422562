import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { getApiLogs } from "../../services/appService";
import Loader from "../dataset/Loader/Loader";
import "./ApiLogs.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApiLogs = (props) => {
  const [tableHeadings, setTableHeadings] = useState([
    "Host name",
    "Host ID",
    "Logs",
    "Apikey",
  ]);

  const [apiLogs, setApiLogs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecords, setTotalRecords] = useState(0);

  const [dataPerPage, setdataPerPage] = useState(10);

  useEffect(() => {
    setIsLoading(true);
    getApiLogs(currentPage).then((response) => {
      if (response.success) {
        setApiLogs(response.data);
        setTotalRecords(response.total_count);
        setIsLoading(false);
      }
    });
  }, [currentPage]);

  const setHeaderData = tableHeadings.map((header, index) => (
    <th key={index} className="text-nowrap head-row">
      {header}
    </th>
  ));

  const setTableData = apiLogs.map((data, index) => {
    return (
      <tr key={index} className="text-nowrap">
        <td>{data.host_name}</td>
        <td>{data.host_id}</td>
        <td>{data.count}</td>
        <td>
          {data.api_key.substring(0, 2)}**********
          {data.api_key.substring(
            parseInt(data.api_key.length),
            parseInt(data.api_key.length - 4)
          )}
        </td>
      </tr>
    );
  });

  const receiveCurrentPageNumber = (langValue) => {
    setCurrentPage(langValue);
  };

  return (
    <div>
      <div className="d-help py-2 flex-wrap">
        <h5 className="m-0">API logs</h5>
        <button
          type="button"
          className="btn btn-secondary btn-sm m-0"
          onClick={(e) => {
            e.preventDefault();
            props?.history?.goBack();
          }}
        >
          <FontAwesomeIcon icon="fa fa-arrow-left" />
          <span className="ms-1">Back</span>
        </button>
      </div>
      <div>
        <div className="table-responsive">
          {isLoading ? (
            <Loader />
          ) : (
            <table className="table table-bordered my-2">
              <thead>
                <tr>{setHeaderData}</tr>
              </thead>
              <tbody>
                {apiLogs.length ? (
                  setTableData
                ) : (
                  <tr>
                    <td colSpan="12">
                      <h5>No records found</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        {totalRecords > 10 && (
          <div className="dynamic_pagination my-2">
            <Pagination
              pageRangeDisplayed={3}
              activePage={currentPage}
              itemsCountPerPage={10}
              totalItemsCount={totalRecords}
              onChange={receiveCurrentPageNumber}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ApiLogs;
