import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

export const filterQueryRecords = (
  body,
  datasetId,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls(datasetId).filterQueryRecords,
    body,
    config
  );
};

export const deleteSingleMultipleRecords = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().deleteSingleMultipleRecords,
    body,
    config
  );
};

export const clearAllDataFromDataset = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().clearAllDataFromDataset,
    body,
    config
  );
};

export const importExcelData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().importExcelData, body, config);
};

export const saveModalData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().saveModalData, body, config);
};

export const getRecordDataById = (
  data,
  datasetId,
  config = null,
  axiosInstance = axios
) => {
  if (data.id) {
    var urlName = data.dataset_name;
    return axiosInstance.get(
      serviceUrls(datasetId).getRecordDataById +
      "&id=" +
      data.id +
      "&dataset_name=" +
      urlName,
      config
    );
  } else {
    return axiosInstance.get(
      serviceUrls().getRecordDataById +
      "&dataset_id=" +
      datasetId +
      "&record_id=" +
      data,
      config
    );
  }
};

export const getRecordData = (data, config = null, axiosInstance = axios) => {
  if (data.id) {
    return axiosInstance.get(
      serviceUrls().getRecordDataById +
      "&id=" +
      data.id +
      "&dataset_name=fc_app_pages",
      config
    );
  }
};
