import ReactQuill from "react-quill";
import ToggleSwitch from "../../togglebutton/ToggleButton";
import CheckList from "../checkList/CheckList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "../contexts/FormContext";
import CustomCheckBox from "./fieldsComponent/CustomCheckBox";
import CustomDate from "./fieldsComponent/CustomDate";
import CustomTime from "./fieldsComponent/CustomTime";
import CustomTextBox from "./fieldsComponent/CustomTextBox";
import CustomSingleSelectDropdown from "./fieldsComponent/CustomSingleSelectDropdown";
import CustomNumberTextBox from "./fieldsComponent/CustomNumberTextBox";
import CustomRadioButton from "./fieldsComponent/CustomRadioButton";
import "./inlineTableForm.scss";
import CustomFile from "./fieldsComponent/CustomFile";
import SearchableDropDown from "./fieldsComponent/SearchableDropDown";
import ToggleButton from "./fieldsComponent/ToggleButton";
import DynamicDependentDropdown from "./fieldsComponent/DynamicDependentDropdown";
import { inputTypeKey } from "../../common/model/Model";
import CalculatedField from "./fieldsComponent/CalculatedField/CalculatedField";

const GetEditField = ({ ...props }) => {
  let { field } = props;
  if (field.data_type === inputTypeKey.checkBox) {
    return <CustomCheckBox {...props} />;
  } else if (field.data_type === inputTypeKey.dateTime) {
    return <CustomDate {...props} />;
  } else if (field.data_type === inputTypeKey.time) {
    return <CustomTime {...props} />;
  } else if (field.data_type === inputTypeKey.textBox) {
    return <CustomTextBox {...props} />;
  } else if (field.data_type === inputTypeKey.singleSelectStaticDropdownlist) {
    return <CustomSingleSelectDropdown {...props} />;
  } else if (field.data_type === inputTypeKey.numberTextBox) {
    return <CustomNumberTextBox {...props} />;
  } else if (field.data_type === inputTypeKey.radioButton) {
    return <CustomRadioButton {...props} />;
  } else if (field.data_type === inputTypeKey.fileUpload) {
    return <CustomFile {...props} />;
  } else if (field.data_type === inputTypeKey.searchableDropdown) {
    return <SearchableDropDown {...props} />;
  } else if (field.data_type === inputTypeKey.toggleButton) {
    return <ToggleButton {...props} />;
  } else if (field.data_type === inputTypeKey.dynamicDependentDropdown) {
    return <DynamicDependentDropdown {...props} />;
  } else if (field.data_type === inputTypeKey.calculatedField) {
    return <CalculatedField {...props} />;
  }
};

export default GetEditField;
