import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "../../components/react_engine/contexts/FormContext";
import { handleAutoFillData } from "../react_engine/components/ShareAppAutoFill";
import { componentType, conversionType } from "../common/model/Model";
import { toast } from "react-toastify";
import { reactAppFormExecutionVP } from "../../services/API";
import { Modal } from "react-bootstrap";
import CustomForm from "../react_engine/components/CustomForm";
import { getRecordDataById } from "../../services/tableConfigService";

function CustomTextBox(props) {
  const { appSessionId, elementData, onChange, value, readonly } = props;
  const {
    addFormData,
    name,
    autoFillReference,
    autoFillLogic,
    convertValue,
    initialDisable,
    autoFill,
  } = elementData;

  const { setAutoFillData } = useContext(FormContext);
  const [autoFillLoading, setAutoFillLoading] = useState(false);
  const [changeValue, setChangeValue] = useState(value || "");
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [formConfigData, setFormConfigData] = useState({});
  const [modalFormData, setModalFormData] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);

  useEffect(() => {
    if (!value) setChangeValue("");
  }, [value]);

  const handleSearch = (searchDetails) => {
    if (autoFillReference) {
      handleAutoFillData(
        searchDetails,
        name,
        appSessionId,
        autoFillLogic,
        autoFillReference,
        setAutoFillData,
        setAutoFillLoading
      );
    }
  };

  const fieldValueChange = (e) => {
    let value = e.target.value;
    let valueData = value;
    if (convertValue === conversionType.upperCase) {
      valueData = value.toUpperCase();
    }
    if (convertValue === conversionType.lowerCase) {
      valueData = value.toLowerCase();
    }
    setChangeValue(valueData);
    onChange(valueData);
  };

  const addSubmitForm = () => {
    let objectBody = {
      app_session_id: appSessionId,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      data: {
        page_fields: [
          formConfigData.page_fields.map((field) => {
            return {
              is_required: field.is_required,
              display_name: field.display_name,
              name: field.name,
            };
          }),
        ],
        formData: [modalFormData],
        logic_to_send: [formConfigData.logic_name_value || ""],
      },
      page_type: componentType.formComponent,
      store_output_key: formConfigData.form_output_key || "",
    };
    setSubmitResponseLoading(true);
    reactAppFormExecutionVP(objectBody)
      .then((response) => {
        toast.success(response.message);
        setModalState(false);
        const valueData = modalFormData[name];
        setChangeValue(valueData);
        onChange(valueData);
        if (valueData) handleSearch({ [name]: valueData });
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setSubmitResponseLoading(false));
  };

  const getPageDetails = () => {
    if (addFormData) {
      setModalState(true);
      let objData = {
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        dataset_name: "fc_app_pages",
        id: addFormData,
      };
      setIsFormLoading(true);
      getRecordDataById(objData)
        .then((response) => {
          if (response.success === true) {
            let {
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              formGroupData,
            } = response.data || {};
            setFormConfigData({
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              formGroupData,
            });
          }
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setIsFormLoading(false));
    }
  };

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        disabled={readonly ? readonly : initialDisable === "yes"}
        value={autoFill === "yes" ? changeValue : value}
        onChange={fieldValueChange}
      />
      {autoFill === "yes" && (
        <div
          role="button"
          className="input-group-text"
          onClick={() => {
            if (readonly !== true) handleSearch({ [name]: changeValue });
          }}
        >
          <span>
            {autoFillLoading ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              <FontAwesomeIcon icon={"fa fa-search"} />
            )}
          </span>
        </div>
      )}
      {addFormData && (
        <div
          className="input-group-text"
          role="button"
          accordion
          onClick={getPageDetails}
        >
          <FontAwesomeIcon icon={"fa fa-plus"} />
        </div>
      )}
      {addFormData ? (
        <Modal
          scrollable
          centered
          size="lg"
          show={modalState}
          onHide={() => {
            setModalState(false);
            setModalFormData({});
            setFormConfigData({});
          }}
          backdrop={submitResponseLoading ? "static" : true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="position-relative react_ui px-2">
              {isFormLoading && (
                <div className="set_height d-flex justify-content-center align-items-center">
                  <span>
                    <div className="spinner-border" />
                  </span>
                </div>
              )}
              <CustomForm
                formGroupData={formConfigData.formGroupData || []}
                pageFields={formConfigData.page_fields || []}
                isEdit={false}
                setFormData={setModalFormData}
                formData={modalFormData}
                appSessionId={appSessionId}
                formTitle={""}
                isResponseLoading={submitResponseLoading}
                onSubmitClick={addSubmitForm}
                submitButtonText={"Submit"}
                submitButtonIcon={<FontAwesomeIcon icon="fa fa-check-double" />}
              />
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </div>
  );
}

export default CustomTextBox;
