import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  fetchAttributeSetList,
  fetchCategoryByStoreDetails,
  getProductDetails,
  updateProductDetails,
} from "../../../../services/appService";
import CustomForm from "../../../react_engine/components/CustomForm";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomizedProduct = ({
  storeId,
  categoryId,
  sendObjectId,
  returnToProductImages,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [pageFields, setPageFields] = useState([]);
  const [productDetails, setproductDetails] = useState({});

  useEffect(() => {
    fetchProductDetails();
    window.scrollTo(0, 0);
  }, []);

  // get product Details
  const fetchProductDetails = () => {
    setIsLoading(true);
    getProductDetails(sendObjectId)
      .then((response) => {
        setproductDetails(response.data);
        getCategoryList();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  // get categorySetList to find default attribute set
  const getCategoryList = () => {
    setIsLoading(true);
    fetchCategoryByStoreDetails(storeId)
      .then((response) => {
        response?.data?.map((data) => {
          if (data._id.$oid === categoryId) {
            fetchAttributList(data);
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response?.message);
      });
  };

  // fetch custom fields List
  const fetchAttributList = (defaultAttribute) => {
    fetchAttributeSetList()
      .then((response) => {
        let fields = response?.data?.find(
          (attribute) =>
            attribute?._id?.$oid ===
            defaultAttribute?.default_attribute_set?.value
        );
        setPageFields(fields?.custom_fields || []);
      })
      .catch((error) => {
        toast.error(error.response?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // saving product changes data
  const saveCustomizedProductDetails = () => {
    let bodyObj = {
      object_id: sendObjectId,
      fields: productDetails,
    };
    setIsResponseLoading(true);
    updateProductDetails(bodyObj)
      .then((response) => {
        if (response.success) {
          returnToProductImages("images-videos");
          toast.success(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
      })
      .finally(() => {
        setIsResponseLoading(false);
      });
  };

  return (
    <>
      <div className="row customized-product">
        <div className="col-md-12 p-3">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border"></div>
            </div>
          ) : (
            <>
              {pageFields?.length ? (
                <CustomForm
                  pageFields={pageFields || []}
                  isEdit={false}
                  setFormData={(e) => {
                    setproductDetails({ ...productDetails, ...e });
                  }}
                  formData={productDetails}
                  isResponseLoading={isResponseLoading}
                  onSubmitClick={() => saveCustomizedProductDetails()}
                  submitButtonText={"Next"}
                />
              ) : Object?.keys(productDetails).length ? (
                <>
                  <div className="text-center py-3 noAttributesRecord">
                    No Attributes Selected.
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-success btn-md"
                      onClick={() => {
                        returnToProductImages("images-videos");
                      }}
                    >
                      &nbsp;&nbsp;Next&nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon="fa fa-arrow-right"
                        className="ms-1"
                      />&nbsp;&nbsp;
                    </button>
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomizedProduct;
