import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useHistory } from "react-router-dom";

function RelatedProducts(props) {
  const productsData = props;
  const clickValue = props?.clickValue;
  const history = useHistory();

  const options = {
    responsiveClass: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const routeToView = (products) => {
    if (clickValue === "product_map") {
      props.selectedMapdataValue(products);
    }
    if (clickValue === "product_view") {
      history.push(`/app_product_view/${products?.value}`);
    }
  };
  const relatedProductSlider = productsData.relatedProductsData?.length ? (
    <>
      <h5>Related Products</h5>
      <OwlCarousel
        className="owl-theme mt-3"
        loop={productsData.items > 4 ? true : false}
        autoplay={true}
        margin={productsData?.margin}
        nav={productsData?.nav}
        items={productsData?.items}
        {...options}
      >
        {productsData.relatedProductsData?.map((products, index) =>
          products.product_data.product_status === true ? (
            <div
              className="item"
              key={index}
              onClick={() => {
                routeToView(products);
              }}
            >
              <div className="carousal-img-wrapper">
                <p className="m-0 text-center font-14">
                  {products.product_data.product_name}
                </p>
                <div className="related-img-wrapper">
                  <img src={products?.product_data.product_images[0]}></img>
                </div>
              </div>
            </div>
          ) : null
        )}
      </OwlCarousel>
    </>
  ) : null;
  return <div>{relatedProductSlider}</div>;
}

export default RelatedProducts;
