import React, { useContext, useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import { RoutesContext } from "../../RoutesContext";
import { fetchSearchAppsFlows } from "../../services/appService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { executeAppForTempStorage, uploadAppSession } from "../../services/API";
import { toast } from "react-toastify";

const SearchBar = () => {
  const { routesData, setRoutesData } = useContext(RoutesContext);
  const [optionData, setOptionData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchTypeValue, setSearchTypeValue] = useState("");
  const history = useHistory();
  const role = JSON.parse(localStorage.getItem("role"));

  useEffect(() => {
    fetchSearchAppsFlows().then((response) => {
      const [configuration] = response?.data;
      if (role === "Admin") {
        setOptionData(configuration?.searchConfiguration);
      } else {
        const filteredData = configuration?.searchConfiguration?.filter(
          (obj) => {
            return obj?.roleName?.some((roleObj) => roleObj.label === role);
          }
        );
        setOptionData(filteredData);
      }
    });
  }, [routesData?.searchId]);
  const handleSearchClick = (event) => {
    event.preventDefault();
    let selectedValue = optionData?.find(
      (item) => searchTypeValue === item?.searchName
    );
    let [firstData] = optionData;

    let appId = selectedValue
      ? selectedValue.appName.value
      : firstData.appName.value;

    let flowId = selectedValue
      ? selectedValue.appFlow.value
      : firstData.appFlow.value;
    let logicId = selectedValue
      ? selectedValue?.appLogic?.value
      : firstData?.appLogic?.value;
    let logicVariableName = selectedValue
      ? selectedValue?.logicVariableName
      : firstData?.logicVariableName;

    setRoutesData({
      ...routesData,
      selectedValue: selectedValue ? selectedValue : firstData,
    });
    history.push(
      `/search/${appId}/${flowId}/${logicId}?reference_name=${logicVariableName}&search_text=${searchText}`
    );
  };
  return (
    optionData?.length > 0 && (
      <form onSubmit={handleSearchClick}>
        <div className="d-flex">
          <div className="me-2">
            <Form.Select
              onChange={(e) => {
                setSearchTypeValue(e.target.value);
              }}
            >
              {optionData?.map((item) => (
                <option value={item?.searchName} key={item?.searchName}>
                  {item.searchName}
                </option>
              ))}
            </Form.Select>
          </div>
          <div>
            <div className="input-group input-group-sm mb-0">
              <input
                type="text"
                className="form-control"
                style={{ height: "35px" }}
                placeholder="Search Here"
                name="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <span
                className="input-group-text cursor-pointer"
                id="basic-addon1"
                type="submit"
                onClick={(e) => handleSearchClick(e)}
              >
                <FontAwesomeIcon icon={"fa fa-search"} />
              </span>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default SearchBar;
