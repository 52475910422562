import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "../../contexts/FormContext";
import { handleAutoFillData } from "../ShareAppAutoFill";
import { Form, InputGroup, Spinner } from "react-bootstrap";

function CustomTextBox({ value = "", disabled = false, field, onChange }) {
  const { setAutoFillData, appSessionId } = useContext(FormContext);
  const [autoFillLoading, setAutoFillLoading] = useState(false);

  const handleSearch = (searchDetails) => {
    if (field?.autoFillReference) {
      handleAutoFillData(
        searchDetails,
        field?.name,
        appSessionId,
        field?.autoFillLogic,
        field?.autoFillReference,
        setAutoFillData,
        setAutoFillLoading
      );
    }
  };

  return (
    <InputGroup className="fieldMinimumWidth fieldMaximumWidth">
      <Form.Control
        size="sm"
        type="text"
        onChange={(e) => {
          if (!disabled) {
            onChange(e.target.value);
          }
        }}
        value={value}
        disabled={disabled ? disabled : field?.initialDisable === "yes"}
      />
      {field?.autoFill === "yes" && !disabled && (
        <InputGroup.Text
          role="button"
          onClick={() => handleSearch({ [field?.name]: value })}
        >
          {autoFillLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <FontAwesomeIcon icon={"fa fa-search"} />
          )}
        </InputGroup.Text>
      )}
    </InputGroup>
  );
}

export default CustomTextBox;
