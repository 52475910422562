import React, { Component } from "react";
import "./SubscriptionForm.scss";
import {
  addSubscription,
  getTenantSubscription,
} from "../../services/appService";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class SubscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        comments: "",
        contact: "",
        contact_name: "",
        data_file: "",
        description: "",
        email: "",
        first_name: "",
        last_name: "",
        name: "",
        subscription_charges: "",
        subscription_end_date: "",
        subscription_frequency: "",
        subscription_net_charges: "",
        subscription_start_date: "",
        subscription_status: "Inactive",
        store_plan: "",
        tenant_id: "",
        tenant_name: "",
        tenant_url: "",
        tenant_website: "",
        total_apps: 0,
        total_users: 0,
        used_storage: 0,
        number_of_store: 0,
        number_of_product: 0,
      },
      frequency: [
        { name: "Monthly", value: "monthly" },
        { name: "Quarterly", value: "quarterly" },
        { name: "Half Yearly", value: "half_yearly" },
        { name: "Yearly", value: "yearly" },
        { name: "Three Years", value: "three_years" },
        { name: "Five Years", value: "five_years" },
        { name: "Forever", value: "forever" },
      ],
      subscription: [
        { name: "Freemium Plan", value: "freemium_plan" },
        { name: "Business Plan", value: "business_plan" },
        { name: "Enterprise Plan", value: "enterprise_plan" },
      ],
      errors: {},
      cardData: {},
      isSubscriptionLoading: true,
    };
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    if (event.target.name === "store_plan") {
      if (event.target.value === "freemium_plan") {
        this.setState({
          field: {
            ...this.state.field,
            number_of_store: 1,
            number_of_product: 25,
          },
        });
      }
      if (event.target.value === "business_plan") {
        this.setState({
          field: {
            ...this.state.field,
            number_of_store: 5,
            number_of_product: 100,
          },
        });
      }
      if (event.target.value === "enterprise_plan") {
        this.setState({
          field: {
            ...this.state.field,
            number_of_store: "",
            number_of_product: "",
          },
        });
      }
    }
    this.setState({
      fields,
    });
  }

  componentDidMount() {
    let params = this.props.match.params;
    this.getSubscription(params.tenant_id, params.tenant_name);
  }

  resetFormData = () => {
    this.setState({
      field: {
        comments: "",
        contact: "",
        contact_name: "",
        data_file: "",
        description: "",
        email: "",
        first_name: "",
        last_name: "",
        name: "",
        subscription_charges: "",
        subscription_end_date: "",
        subscription_frequency: "",
        subscription_net_charges: "",
        subscription_start_date: "",
        subscription_status: "Inactive",
        store_plan: "",
        tenant_id: "",
        tenant_name: "",
        tenant_url: "",
        tenant_website: "",
        number_of_store: 0,
        number_of_product: 0,
      },
    });
  };

  validationFormData() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter name.";
    }
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*Please enter first name.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Please enter last name.";
    }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "*Please enter description.";
    }
    if (!fields["subscription_frequency"]) {
      formIsValid = false;
      errors["subscription_frequency"] = "*Please select frequency.";
    }
    if (!fields["subscription_charges"]) {
      formIsValid = false;
      errors["subscription_charges"] = "*Please enter charges.";
    }
    if (!fields["subscription_net_charges"]) {
      formIsValid = false;
      errors["subscription_net_charges"] = "*Please enter net charges.";
    }
    if (!fields["subscription_start_date"]) {
      formIsValid = false;
      errors["subscription_start_date"] = "*Please enter start date.";
    }
    if (!fields["contact"]) {
      formIsValid = false;
      errors["contact"] = "*Please enter contact number.";
    }
    var emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter user email.";
    } else if (!emailPattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email address.";
    }
    if (!fields["comments"]) {
      formIsValid = false;
      errors["comments"] = "*Please enter comments.";
    }
    if (!fields["store_plan"]) {
      formIsValid = false;
      errors["store_plan"] = "*Please select subscription type.";
    }
    if (!fields["number_of_store"]) {
      formIsValid = false;
      errors["number_of_store"] = "*Please enter number of store.";
    }
    if (!fields["number_of_product"]) {
      formIsValid = false;
      errors["number_of_product"] = "*Please enter number of product.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submitData = (event) => {
    event.preventDefault();
    if (this.validationFormData()) {
      let subData = {
        comments: this.state.field.comments,
        contact: this.state.field.contact,
        description: this.state.field.description,
        email: this.state.field.email,
        first_name: this.state.field.first_name,
        last_name: this.state.field.last_name,
        name: this.state.field.name,
        subscription_charges: this.state.field.subscription_charges,
        subscription_end_date: this.state.field.subscription_end_date,
        subscription_frequency: this.state.field.subscription_frequency,
        subscription_net_charges: this.state.field.subscription_net_charges,
        subscription_start_date: this.state.field.subscription_start_date,
        subscription_status: this.state.field.subscription_status,
        store_plan: this.state.field.store_plan,
        number_of_store: this.state.field.number_of_store,
        number_of_product: this.state.field.number_of_product,
      };

      let subscription = {
        object_id: this.props.match.params.id,
        fields: subData,
      };

      addSubscription(subscription)
        .then((res) => {
          if (res.success === true) {
            toast.success(res.message);
            this.props.history.push(`/subscriptionlist`);
          } else if (res.success === false) {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  handleSubStatus = (e) => {
    let currentData = this.state.field;
    if (e.target.checked) {
      currentData[e.target.name] = "Active";
      this.setState({ currentData });
    } else {
      currentData[e.target.name] = "Inactive";
      this.setState({ currentData });
    }
  };

  getSubscription = (tenant_id, tenant_name) => {
    this.setState({ isSubscriptionLoading: true });
    getTenantSubscription({ id: tenant_id, name: tenant_name })
      .then((response) => {
        this.setState({
          cardData: response.data,
          isSubscriptionLoading: false,
        });
        this.setState({
          field: response.data,
        });
      })
      .catch((err) => {
        this.setState({ isSubscriptionLoading: false }, () => {
          toast.error(err);
        });
      });
  };

  render() {
    return (
      <div className="col-md-12 col-sm-12 mt-3 bg-white">
        <div className="d-help p-2">
          <h5>Subscription </h5>
          <div className="float-end">
            <button
              className="btn btn-md btn-secondary"
              onClick={this.props.history.goBack}
            >
              Back
            </button>
          </div>
        </div>
        <div className="container-fluid x_panel p-3 ">
          <div className="row d-flex ">
            <div className="col-md-3 mx-auto">
              <div className="subscription-card mt-2 text-center p-3 mb-3 bg-body rounded ">
                <h5>
                  <FontAwesomeIcon
                    icon={"fa fa-th"}
                    className="card-icon"
                    aria-hidden="true"
                  />
                  Total Apps
                </h5>
                {this.state.isSubscriptionLoading ? (
                  <div className="text-center">
                    <div className="spinner-border btnLoader"></div>
                  </div>
                ) : (
                  <span className="card-data">
                    <h5>
                      {this.state.cardData.total_apps
                        ? this.state.cardData.total_apps
                        : 0}
                    </h5>
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 mx-auto">
              <div className="subscription-card mt-2 text-center p-3 mb-3 bg-body rounded">
                <h5>
                  <FontAwesomeIcon
                    icon={"fa fa-users"}
                    className="card-icon"
                    aria-hidden="true"
                  />
                  Total Users
                </h5>

                {this.state.isSubscriptionLoading ? (
                  <div className="text-center">
                    <div className="spinner-border btnLoader"></div>
                  </div>
                ) : (
                  <span className="card-data">
                    <h5>
                      {this.state.cardData.total_users
                        ? this.state.cardData.total_users
                        : 0}
                    </h5>
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 mx-auto subs">
              <div className="subscription-card mt-2 text-center p-3 mb-3 bg-body rounded">
                <h5>
                  <FontAwesomeIcon
                    icon={"fa fa-database"}
                    className="card-icon"
                  />
                  Storage
                </h5>
                {this.state.isSubscriptionLoading ? (
                  <div className="text-center">
                    <div className="spinner-border btnLoader"></div>
                  </div>
                ) : (
                  <div className="progress-container">
                    <div className="progress mb-2 ms-3 me-3">
                      <div
                        className="progress-bar bg-green"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow="20"
                        style={{ width: "20%" }}
                      >
                        {this.state.cardData.used_storage}GB
                      </div>
                    </div>
                    <div className="storage">10GB</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="d-help">
            <h5>Add Subscription</h5>
          </div>
          <div className="x_panel mx-auto">
            <div className="x_content p-2 ">
              <form onSubmit={this.submitData}>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    First Name
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      name="first_name"
                      value={this.state.field.first_name}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.first_name}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Last Name
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      name="last_name"
                      value={this.state.field.last_name}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.last_name}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Contact Number
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Contact Number"
                      name="contact"
                      value={this.state.field.contact}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.contact}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Email
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      value={this.state.field.email}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.email}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Subscription Name
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subscription Name"
                      name="name"
                      value={this.state.field.name}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.name}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Subscription Description
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <textarea
                      className="form-control"
                      placeholder="Enter Description"
                      name="description"
                      value={this.state.field.description}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.description}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Frequency
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <Form.Select
                      className="form-control"
                      name="subscription_frequency"
                      value={this.state.field.subscription_frequency}
                      defaultValue={this.state.default}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    >
                      <option
                        value=""
                        disabled={this.defaultDisabled ? true : false}
                      >
                        Select
                      </option>
                      {this.state.frequency.map((plan, index) => {
                        return (
                          <>
                            <option value={plan.value} key={index}>
                              {plan.name}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                    <div className="errorMsg text-danger">
                      {this.state.errors.subscription_frequency}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Subscription Type
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <Form.Select
                      className="form-control"
                      name="store_plan"
                      value={this.state.field.store_plan}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    >
                      <option
                        value=""
                        disabled={this.defaultDisabled ? true : false}
                      >
                        Select
                      </option>
                      {this.state.subscription.map((type, index) => {
                        return (
                          <option value={type.value} key={index}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <div className="errorMsg text-danger">
                      {this.state.errors.store_plan}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Store
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Number of Store"
                      name="number_of_store"
                      value={this.state.field.number_of_store}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.number_of_store}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Product
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Number of Product"
                      name="number_of_product"
                      value={this.state.field.number_of_product}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.number_of_product}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Actual Subscription Charges
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon
                            icon={"fa fa-inr"}
                            className="rupes_icon"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <input
                        type="Number"
                        className="form-control"
                        placeholder="Enter Actual Subscription Charges"
                        name="subscription_charges"
                        min={0}
                        value={this.state.field.subscription_charges}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                    </div>
                    <div className="errorMsg text-danger">
                      {this.state.errors.subscription_charges}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Net Subscription Charges
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon
                            icon={"fa fa-inr"}
                            className="rupes_icon"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <input
                        type="Number"
                        className="form-control"
                        placeholder="Enter Subscription Net Charges"
                        name="subscription_net_charges"
                        min={0}
                        value={this.state.field.subscription_net_charges}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                    </div>
                    <div className="errorMsg text-danger">
                      {this.state.errors.subscription_net_charges}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Subscription Start Date
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="date"
                      className="form-control"
                      name="subscription_start_date"
                      value={this.state.field.subscription_start_date}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.subscription_start_date}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Subscription Status
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        name="subscription_status"
                        onChange={this.handleSubStatus}
                        checked={
                          this.state.field.subscription_status === "Inactive"
                            ? false
                            : true
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Comments
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <textarea
                      className="form-control"
                      placeholder="Enter Comments"
                      name="comments"
                      value={this.state.field.comments}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.comments}
                    </div>
                  </div>
                </div>

                <div className="float-end">
                  <button
                    onClick={this.resetFormData}
                    type="reset"
                    className="btn btn-danger btn-md m-1"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    onClick={this.submitData}
                    className="btn btn-success btn-md m-1"
                  >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                    Submit&nbsp;&nbsp;
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
