import React from "react";
import Autocomplete from "react-google-autocomplete";

function AutoFill(props) {
  return (
    <Autocomplete
      apiKey={props?.apiKey}
      onPlaceSelected={(place) =>
        place?.geometry?.location !== undefined && props.autoFill(place?.geometry?.location?.toUrlValue(6))
      }
      options={{
        types: ["(regions)"],
        componentRestrictions: { country: "in" },
      }}
      className="form-control"
    />
  );
}

export default AutoFill;
