import React, { useContext, useEffect, useRef, useState } from "react";
import { HierarchyContext } from "./HierarchyTableContext";
import { v4 as uuId } from "uuid";
import {
  executeAppForActions,
  getLastNodeData,
  reactAppFormExecutionVP,
} from "../../../services/API";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { getRecordDataById } from "../../../services/tableConfigService";
import CustomForm from "../components/CustomForm";

const RootHierarchyModal = () => {
  const [formData, setFormData] = useState({});
  const [hierarchyConfigObject, setHierarchyConfigObject] = useState({});
  const [newHierarchyObject, setNewHierarchyObject] = useState({});
  const [rootHierarchy, setRootHierarchy] = useState({});
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const [initialHierarchyLoading, setInitialHierarchyLoading] = useState(true);
  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const {
    appSessionId,
    reloadData,
    hierarchyModalObject,
    setHierarchyModalObject,
    displayConfig,
  } = useContext(HierarchyContext);

  let isParentEditable =
    hierarchyModalObject.modalData?.sub_hierarchy_childs?.length === 0;

  if (displayConfig?.parentInlineEdit === "yes") {
    isParentEditable = true;
  }

  useEffect(() => {
    getHierarchyPageDetails();
  }, [displayConfig?.hierarchyFormId]);

  useEffect(() => {
    if (Object.keys(hierarchyConfigObject)?.length > 0) {
      if (hierarchyModalObject?.showModal) {
        setError("");
        setInitialHierarchyLoading(true);
        getHierarchyObject().then(() => {
          inputRef?.current?.focus();
          if (hierarchyModalObject?.modalState === "Update") {
            getHierarchyEditObject();
            let {
              hierarchy_name,
              hierarchy_id,
              parent_hierarchy_order,
              parent_hierarchy_id,
            } = hierarchyModalObject.modalData;
            setNewHierarchyObject({
              hierarchy_id,
              hierarchy_name,
              parent_hierarchy_order,
              parent_hierarchy_id,
            });
          } else if (hierarchyModalObject?.modalState === "Delete") {
            getHierarchyEditObject();
          } else {
            setInitialHierarchyLoading(false);
            let id = uuId();
            setNewHierarchyObject({
              hierarchy_name: "",
              parent_hierarchy_id: hierarchyModalObject?.selectedHierarchy,
              hierarchy_id: id,
              sub_hierarchy_childs: [],
              parent_hierarchy_order: [
                ...hierarchyModalObject?.parentIds,
                ...[id],
              ],
            });
          }
        });
      }
    }

    return () => {
      setSubmitResponseLoading(false);
      setNewHierarchyObject({});
      setRootHierarchy({});
      setFormData({});
    };
  }, [
    hierarchyModalObject?.showModal,
    setNewHierarchyObject,
    hierarchyConfigObject,
  ]);

  const handelModalClose = () => {
    setHierarchyModalObject({});
  };

  let path = hierarchyModalObject?.hierarchyPath;
  let hierarchyPath = path;
  if (path?.includes("/")) {
    hierarchyPath = path?.substring(0, path?.lastIndexOf("/"));
  }

  const handleChange = (e) => {
    let obj = { ...newHierarchyObject };
    obj.hierarchy_name = e.target.value;
    setNewHierarchyObject(obj);
    if (
      !newHierarchyObject?.hierarchy_name?.includes(",") ||
      newHierarchyObject?.hierarchy_name?.length === 0
    ) {
      inputRef.current.classList.remove("border-danger");
      setError("");
    }
  };

  const getHierarchyObject = async () => {
    if (hierarchyConfigObject?.selectedHierarchyLogic?.label) {
      setInitialHierarchyLoading(true);
      await getLastNodeData(
        appSessionId,
        hierarchyConfigObject?.selectedHierarchyLogic?.label
      )
        .then((res) => {
          let [ResponseData] = res?.data?.value?.data?.data || [];
          if (ResponseData?._id?.$oid) {
            setRootHierarchy(ResponseData);
          } else {
            let rootData = {
              hierarchy_name: "Root Category",
              hierarchy_id: hierarchyModalObject?.initialRootId,
              parent_hierarchy_id: hierarchyModalObject?.initialRootId,
              sub_hierarchy_childs: [],
              parent_hierarchy_order: [hierarchyModalObject?.initialRootId],
            };
            setRootHierarchy(rootData);
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setInitialHierarchyLoading(false);
        });
    }
  };

  const updateHierarchy = (rootHierarchyData) => {
    let updatedHierarchy = iterateToFindAndUpdate({ ...rootHierarchyData });
    updateInDataBase(updatedHierarchy);
  };

  const iterateToFindAndUpdate = (toFindData) => {
    if (toFindData.hierarchy_id === hierarchyModalObject?.selectedHierarchy) {
      if (hierarchyModalObject?.modalState === "Update") {
        Object.assign(toFindData, newHierarchyObject);
      } else {
        toFindData.sub_hierarchy_childs?.push(newHierarchyObject);
      }
    }

    toFindData.sub_hierarchy_childs = toFindData?.sub_hierarchy_childs?.map(
      iterateToFindAndUpdate
    );
    return toFindData;
  };

  const updateInDataBase = (updatedHierarchy) => {
    let objectBody = {
      app_session_id: appSessionId,
      logic_name: hierarchyConfigObject?.updateHierarchyLogic?.label,
      reference_name: hierarchyConfigObject?.updateHierarchyReference,
      data: [updatedHierarchy],
    };
    setIsResponseLoading(true);
    if (hierarchyConfigObject?.updateHierarchyLogic?.label) {
      executeAppForActions(objectBody)
        .then(() => {
          if (hierarchyModalObject?.setToggleRow) {
            hierarchyModalObject?.setToggleRow(true);
          }
          reloadData();
          handelModalClose();
        })
        .catch((error) => {
          toast.error(error?.message);
        })
        .finally(() => {
          setIsResponseLoading(false);
        });
    }
  };

  const getHierarchyPageDetails = () => {
    let objData = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      id: displayConfig?.hierarchyFormId,
    };
    if (displayConfig?.hierarchyFormId) {
      getRecordDataById(objData)
        .then((response) => {
          if (response.success === true) {
            let {
              hierarchyTreeConfig,
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              updateLogic,
            } = response?.data;

            setHierarchyConfigObject({
              ...{
                page_fields,
                form_disclaimer,
                form_output_key,
                logic_name_value,
                updateLogic,
              },
              ...hierarchyTreeConfig,
            });
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {});
    }
  };

  const onSubmitClick = () => {
    let objectBody = {
      data: {
        page_fields: [
          hierarchyConfigObject?.page_fields?.map((field) => {
            return {
              is_required: field.is_required,
              display_name: field?.display_name,
              name: field?.name,
            };
          }),
        ],
        formData: [
          {
            ...formData,
            ...newHierarchyObject,
            hierarchy_childs:
              hierarchyModalObject?.modalState === "Add"
                ? []
                : hierarchyModalObject.modalData?.sub_hierarchy_childs?.map(
                    (hierarchy) => hierarchy?.hierarchy_id
                  ) || [],
          },
        ],
        logic_to_send: [
          hierarchyConfigObject?.updateLogic?.label
            ? formData?._id?.$oid
              ? hierarchyConfigObject?.updateLogic?.label
              : hierarchyConfigObject?.logic_name_value
            : hierarchyConfigObject?.logic_name_value,
        ],
      },
      app_session_id: appSessionId,
      store_output_key: hierarchyConfigObject?.form_output_key,
    };

    if (newHierarchyObject?.hierarchy_name?.includes(",")) {
      inputRef.current.classList.add("border-danger");
      setError("Comma Not Allowed.");
    } else if (newHierarchyObject?.hierarchy_name?.length === 0) {
      inputRef.current.classList.add("border-danger");
      setError("Please Enter Category Name.");
    } else {
      setSubmitResponseLoading(true);
      if (isParentEditable || hierarchyModalObject?.modalState === "Add") {
        reactAppFormExecutionVP(objectBody)
          .then((response) => {
            if (response) {
              toast.success(response?.message);
              updateHierarchy(rootHierarchy);
            }
          })
          .catch((error) => {
            toast.error(error?.message);
          })
          .finally(() => {
            setSubmitResponseLoading(false);
          });
      } else {
        updateHierarchy(rootHierarchy);
      }
    }
  };

  const getHierarchyEditObject = () => {
    setInitialHierarchyLoading(true);
    if (displayConfig?.editObjectLogic) {
      getLastNodeData(appSessionId, displayConfig?.editObjectLogic)
        .then((res) => {
          let [ResponseData] = res?.data?.value?.data?.data || [];
          if (ResponseData?._id?.$oid) {
            setFormData(ResponseData);
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setInitialHierarchyLoading(false);
        });
    }
  };

  const onHierarchyDelete = () => {
    let object = formData?._id?.$oid
      ? formData
      : hierarchyModalObject.modalData;

    let objectBody = {
      app_session_id: appSessionId,
      logic_name: displayConfig?.hierarchyDeleteLogic,
      reference_name: displayConfig?.hierarchyDeleteReference,
      data: [object],
    };
    setSubmitResponseLoading(true);
    if (hierarchyConfigObject?.updateHierarchyLogic?.label) {
      executeAppForActions(objectBody)
        .then(() => {
          if (hierarchyModalObject?.setToggleRow) {
            hierarchyModalObject?.setToggleRow(true);
          }
          let updatedHierarchy = deleteHierarchy(
            rootHierarchy,
            hierarchyModalObject?.selectedHierarchy
          );
          updateInDataBase(updatedHierarchy);
        })
        .catch((error) => {
          toast.error(error?.message);
        })
        .finally(() => {
          setSubmitResponseLoading(false);
        });
    }
  };

  const deleteHierarchy = (rootNode, hierarchyId) => {
    if (rootNode.hierarchy_id === hierarchyId) {
      return null;
    }

    const updatedChild = rootNode.sub_hierarchy_childs.map((childNode) =>
      deleteHierarchy(childNode, hierarchyId)
    );

    rootNode.sub_hierarchy_childs = updatedChild.filter(
      (childNode) => childNode !== null
    );

    return rootNode;
  };

  return (
    <Modal
      show={hierarchyModalObject?.showModal || false}
      onHide={handelModalClose}
      backdrop={submitResponseLoading || isResponseLoading ? "static" : true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{hierarchyModalObject?.modalState}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative react_ui px-2">
          {initialHierarchyLoading && (
            <div className="hierarchyForm">
              <span>
                <div className="spinner-border spinner-border-sm" />
                <span className="ms-1">Please Wait...</span>
              </span>
            </div>
          )}

          {["Add", "Update"].includes(hierarchyModalObject?.modalState) && (
            <>
              <div className="mb-3">
                <div className="input-group mb-0">
                  <div className="input-group-text w-50 justify-content-end">
                    <div className="textOverflow">
                      {hierarchyModalObject?.modalState === "Update"
                        ? path?.includes("/")
                          ? "/" + hierarchyPath
                          : "/Root Hierarchy"
                        : hierarchyPath?.length
                        ? "/" + path
                        : path?.length
                        ? path
                        : "/Root Hierarchy"}
                    </div>
                  </div>
                  <input
                    ref={inputRef}
                    type="text"
                    className="form-control"
                    value={newHierarchyObject?.hierarchy_name || ""}
                    placeholder="Category Name"
                    onChange={handleChange}
                  />
                </div>
                {error && (
                  <div className="feedback text-end text-danger">{error}</div>
                )}
              </div>

              {(isParentEditable ||
                hierarchyModalObject?.modalState === "Add") && (
                <CustomForm
                  pageFields={hierarchyConfigObject?.page_fields || []}
                  isEdit={false}
                  setFormData={setFormData}
                  formData={formData}
                  appSessionId={appSessionId}
                  formTitle={hierarchyConfigObject?.form_disclaimer}
                />
              )}

              <div className="text-end">
                <button
                  disabled={submitResponseLoading || isResponseLoading}
                  className="btn btn-success btn-md m-0"
                  type="button"
                  onClick={() => onSubmitClick()}
                >&nbsp;&nbsp;
                  {submitResponseLoading || isResponseLoading ? (
                    <div className="spinner-border spinner-border-sm text-light" />
                  ) : (
                    <FontAwesomeIcon icon="fa fa-check-double" />
                  )}
                  <span className="ms-1">&nbsp;&nbsp;Submit&nbsp;&nbsp;</span>
                </button>
              </div>
            </>
          )}

          {hierarchyModalObject?.modalState === "Delete" && (
            <div>
              <div className="mb-2">
                Are You Sure To Delete{" "}
                <span className="fw-bolder">
                  {hierarchyModalObject.modalData?.hierarchy_name}
                </span>{" "}
                ?
                <br />
                <br />
                <span className="fw-bolder text-danger">Note:- </span>
                <div>
                  Deleting Parent Hierarchy will remove its child data too.
                </div>
                <br />
              </div>
              <div className="text-end">
                <button
                  disabled={isResponseLoading}
                  className="btn btn-secondary btn-md me-1 "
                  type="button"
                  onClick={handelModalClose}
                >
                  <span className="ms-1">No</span>
                </button>
                <button
                  disabled={submitResponseLoading || isResponseLoading}
                  className="btn btn-danger btn-md"
                  type="button"
                  onClick={onHierarchyDelete}
                >
                  {(submitResponseLoading || isResponseLoading) && (
                    <div className="spinner-border spinner-border-sm text-light" />
                  )}
                  <span className="ms-1">Yes</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RootHierarchyModal;
