import { createContext, useState } from "react";

export const NodeContext = createContext();

export const NodeContextProvider = (props) => {
  const [flag, setFlag] = useState(false);

  return (
    <NodeContext.Provider
      value={{
        flag,
        setFlag,
      }}
    >
      {props.children}
    </NodeContext.Provider>
  );
};
