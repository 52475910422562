import "./errorpage.scss";
import forbidden304error from "../../images/forbidden304error.png";
const ErrorPage = (props) => {
  return (
    <div>
      <div className="col-md-12 bg-light">
        <div className="container error-container bg-light">
          <div>
            <div>
              <img src={forbidden304error} alt="403" className="img-fluid" />
            </div>
            <p>The requested URL {props.location.pathname} was not found.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
