import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getRecordDataById } from "../../../../services/tableConfigService";
import { saveAppResonseByJson } from "../../../../services/datasetService";
import { checkSameValueData } from "../../../../services/appService";
import Loader from "../../../dataset/Loader/Loader";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class EditPage extends Component {
  constructor() {
    super();
    this.state = {
      field: {
        name: "",
        description: "",
      },
      errors: {},
      appId: "",
      visual_id: "",
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      isLoading: false,
      isSpinnerLoading: false,
    };
  }

  componentWillMount() {
    this.getPropsData();
  }

  getPropsData() {
    this.setState(
      {
        appId: this.props.match.params.appId,
        visual_id: this.props.match.params.id,
      },
      () => {
        this.loadEditData();
      }
    );
  }

  loadEditData() {
    this.setState({
      isLoading: true,
    });
    let objData = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "fc_app_pages",
      id: this.state.visual_id,
    };
    getRecordDataById(objData)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            field: {
              name: response.data.function_name,
              description: response.data.description,
            },
            appId: response.data.app_id,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  // change fields
  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }

  // validate form date
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter name.";
    }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "*Please enter description";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submitAddDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      this.setState({
        isSpinnerLoading: true,
      });
      let objData = {
        dataset_name: "fc_app_pages",
        fields: {
          function_key: "function_name",
          function_value: this.state.field.name,
          app_id: this.state.appId,
          object_id: this.state.visual_id,
        },
      };

      checkSameValueData(objData)
        .then((response) => {
          if (response.data) {
            toast.error(`Page name already exist.`);
          } else {
            let obj = {
              tenant_id: this.state.tenant_Id,
              dataset_name: "fc_app_pages",
              object_id:
                this.state.visual_id === "" ? "" : this.state.visual_id,
              fields: {
                function_name: this.state.field.name,
                description: this.state.field.description,
                app_id: this.state.appId,
              },
            };
            saveAppResonseByJson(obj)
              .then((response) => {
                if (response.success === true) {
                  this.setState(
                    {
                      objId: response.object_id,
                    },
                    () => {
                      this.props.history.push(
                        `/app_page/page_editor/${response.object_id}`
                      );
                    }
                  );
                }
              })
              .catch((err) => {
                this.setState({ errorMessage: err.message });
                if (this.state.errorMessage) {
                  toast.error(this.state.errorMessage);
                }
              });
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.setState({
            isSpinnerLoading: false,
          });
        });
    }
  }

  render() {
    return this.state.isLoading ? (
      <Loader />
    ) : (
      <div>
        <div className="d-help py-2">
          <h5>Edit Page</h5>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        <div className="x_panel">
          <div>
            <div className="x_content p-2">
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Name
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    name="name"
                    value={this.state.field.name}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.name}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Description
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="description"
                    value={this.state.field.description}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.description}
                  </div>
                </div>
              </div>
              <div className="float-end">
                <button
                  onClick={(event) => this.submitAddDetails(event)}
                  type="submit"
                  className="btn btn-success btn-md m-0"
                >
                  {this.state.isSpinnerLoading && (
                    <div className="spinner-border spinner-border-sm text-light"></div>
                  )}&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
                  <span className="ms-1">&nbsp;&nbsp;Save & Next&nbsp;&nbsp;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPage;
