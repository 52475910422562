import React, {useEffect, useState } from "react";

const CustomSection =(props)=>{

  const [htmlCode,setHtmlCode]=useState('');

  useEffect(()=>{
    setHtmlCode(props?.htmlCode)
  },[])

  return (
    <div className={`col-md-${props?.columenWidth} pb-3`}>
      <div
        className="bg-white main-content h-100 p-3"
        dangerouslySetInnerHTML={{ __html: htmlCode }}
      ></div>
    </div>
  );

}

export default CustomSection;
