import React from "react";
import "./customtime.scss";

function CustomTime(props) {
  return (
    <div className={`${props?.className || ""}`}>
      <div className="d-flex customTime w-100">
        <input
          type="time"
          className="form-control timeFields"
          disabled={props?.readonly}
          value={props?.value}
          onChange={(e) => {
            props?.onChange(e.target.value);
          }}
        ></input>
        <div className="nowButton my-auto">
          <button
            disabled={props?.readonly}
            type="button"
            className="btn btn-dark me-0 ms-2 btn-md mb-0 "
            onClick={() => {
              const current = new Date();
              let hour =
                current.getHours() < 10
                  ? "0" + current.getHours()
                  : current.getHours();
              let minutes =
                current.getMinutes() < 10
                  ? "0" + current.getMinutes()
                  : current.getMinutes();
              props.onChange(hour + ":" + minutes);
            }}
          >
            Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomTime;
