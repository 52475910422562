import axios from "axios";
import config from "../config";


// Generate axios instance
const nojwtInstance = axios.create({
  baseURL: config.env("REACT_APP_URL"),
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});


nojwtInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    throw err;
  }
  );
  
  export default nojwtInstance