import React, { useEffect, useState } from "react";
import { executeLogicByName } from "../../../services/API";
import AppLoader from "../../dataset/Loader/AppLoader";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./printableView.scss";
import Handlebars from "handlebars";

const PrintableView = ({
  template,
  filterApply,
  columnWidth,
  displayData,
  appSessionId
}) => {
  const [printData, setPrintData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPrintData();
  }, []);

  useEffect(() => {
    const source = template;
    const print = Handlebars.compile(source);
    const renderedInvoice = print(printData);
    document.getElementById("invoice-container").innerHTML = renderedInvoice;
  }, [printData]);

  const getPrintData = () => {
    setLoading(true);
    let obj = {
      app_session_id: appSessionId,
      logic_name: displayData?.componentLogic,
      reference_name: displayData?.refValue,
    };
    executeLogicByName(obj)
      .then((response) => {
        if (response) {
          let [data, _] = response.data.value.data || {};
          if (data) {
            setPrintData(data);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterApply) {
      getPrintData();
    }
  }, [filterApply]);


  const printDocument = () => {
    window.print();
  };

  return (
    <div className={`col-md-${columnWidth} pb-3 position-relative  `}>
      {loading && <AppLoader />}
      <div className="bg-white main-content h-100">
        <div className="col-md-12 text-end mt-2">
          <button
            className="btn btn-md btn-success mb-2"
            onClick={printDocument}
            title="Click to print"
          >
            <FontAwesomeIcon icon="fa fa-print"/>
          </button>
        </div>
        <div id="invoice-container" className="content-to-print"></div>
      </div>
    </div>
  );
};

export default PrintableView;
