export const getMarker=(type,rotate,direction)=>{
    const customMarker = {
        path:"M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
        fillColor: "yellow",
        fillOpacity: 1,
        strokeWeight: 0.5,
        scale: .5,
        rotation: direction === "yes" ? rotate : 0
      };
    
    
    const customTruckMarker = {
        path:"M 5 2 L 5 3 L 5 8 L 15 8 L 15 2 L 14 1 L 6 1 Z M 5 9 L 5 29 L 15 29 L 15 9 L 5 9 Z M 5 11 L 4 11 L 4 15 L 5 15 Z M 5 22 L 4 22 L 4 26 L 5 26 M 15 11 L 15 11 L 15 15 L 16 15 L 16 11 Z M 15 22 L 15 22 L 15 26 L 16 26 L 16 22 Z M 6 10 L 6 28 L 6 28 M 6 1 L 7 0 L 13 0 L 14 1 M 6 28 L 14 28 L 14 10 L 6 10 M 6 4 L 6 3 L 9 3 L 9 4 L 6 4 M 11 3 L 14 3 L 14 4 L 11 4 L 11 3 M 7 12 L 13 12 M 7 14 L 13 14 M 7 16 L 13 16 M 7 18 L 13 18 M 7 20 L 13 20 M 7 22 L 13 22 M 7 24 L 13 24 M 7 26 L 13 26",
        fillColor: "black",
        fillOpacity: 1,
        strokeColor: "white",
        strokeWeight: 0.5,
        scale:1 ,
        rotation:direction === "yes" ? rotate : 0
    };


    if (type === "Car") {
        return customMarker;
      } else if (type === "Truck") {
        return customTruckMarker;
      } else {
        return null;
    }
}

export const getAnimationType = (type) => {
  switch (type) {
    case 'bounce':
      return window.google.maps.Animation.BOUNCE;
    case 'drop':
      return window.google.maps.Animation.DROP;
    default:
      return null;
  }
};

