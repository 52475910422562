import React, { useState } from "react";
import * as _ from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomNodeUpload from "./CustomNodeUpload";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NodeMenu(props) {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  if (window.location.pathname.includes("/app_logic/logic_editor")) {
    delete props.nodes["Add Page"];
  }

  // construct menu from JSON of node types
  return (
    <div className="NodeMenu ">
      <h5>Node Menu<div className="float-right">{!isOpen && <FontAwesomeIcon icon="fa fa-solid fa-caret-down" onClick={toggle}/>}{isOpen && <FontAwesomeIcon icon="fa fa-solid fa-caret-up" onClick={toggle}/>}</div></h5>
      <div>Drag-and-drop nodes to build a workflow.</div>
      {isOpen ? (
        <>
          <hr />
          <div className="node-submenu px-1">
            <Accordion>
              {_.map(props.nodes, (items, section) =>
                section !== "Flow Control" ? (
                  <Accordion.Item
                    className="mb-1"
                    eventKey={section + ""}
                    key={`node-menu-${section}`}
                  >
                    <Accordion.Header>
                      <h6 className="mb-0">{section}</h6>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <ul>
                        {_.map(items, (item, index) => {
                          const data = { ...item }; // copy so we can mutate
                          const config = data.options;
                          delete data.options;
                          return (
                            <NodeMenuItem
                              key={data.node_key + index || data.filename + index}
                              nodeInfo={data}
                              config={config}
                            />
                          );
                        })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null
              )}
            </Accordion>
          </div>
        </>
      ): null}
      <CustomNodeUpload onUpload={props.onUpload} />
    </div>
  );
}

/**
 * Format docstring with newlines into tooltip content
 * @param string - node docstring
 * @returns {array} - array of strings and HTML elements
 */
function formatTooltip(string) {
  const split = string.split("\n");
  const out = [];
  split.forEach((line, i) => {
    out.push(line);
    out.push(<br key={i} />);
  });
  out.pop();
  return out;
}

function NodeMenuItem(props) {
  if (!props.nodeInfo.missing_packages) {
    const tooltip = props.nodeInfo.doc
      ? formatTooltip(props.nodeInfo.doc)
      : "This node has no documentation.";
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={<NodeTooltip message={tooltip} />}
      >
        <li
          className="NodeMenuItem"
          draggable={true}
          onDragStart={(event) => {
            event.dataTransfer.setData(
              "storm-diagram-node",
              JSON.stringify(props)
            );
          }}
          style={{ color: props.nodeInfo.color }}
        >
          {props.nodeInfo.name}
        </li>
      </OverlayTrigger>
    );
  } else {
    let tooltip =
      "These Python modules could not be imported:\n\n" +
      props.nodeInfo.missing_packages.join("\n");
    tooltip = formatTooltip(tooltip);
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={<NodeTooltip message={tooltip} />}
      >
        <li className="NodeMenuItem invalid">{props.nodeInfo.filename}</li>
      </OverlayTrigger>
    );
  }
}

// Overlay with props has to use ref forwarding
const NodeTooltip = React.forwardRef((props, ref) => {
  return (
    <Tooltip {...props} ref={ref}>
      <div style={{ textAlign: "left" }}>{props.message}</div>
    </Tooltip>
  );
});
