import React, { useEffect, useState } from "react";
import "./CategoryCardLisitng.scss";
import { useHistory } from "react-router-dom";
import { fetchCategoryByStoreDetails } from "../../../../../src/services/appService";
import Loader from "../../../dataset/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

function CategoryCardListing(props) {
  const history = useHistory();
  const store_id = props.match.params.store_id;
  const [category_list, setCategory_list] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const backToPage = (e) => {
    props.history.push(`/store/${store_id}`);
  };

  const backToStore = () => {
    history.push("/stores");
  };

  useEffect(() => {
    fetchCategoryByStoreId();
  }, []);

  const fetchCategoryByStoreId = () => {
    setIsLoading(true);
    fetchCategoryByStoreDetails(store_id)
      .then((response) => {
        if (response.data) {
          setCategory_list(response.data || []);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const routeToProductList = (data) => {
    history.push(
      `/product_list/${store_id}/${data._id.$oid}?category_list_type=${data?.category_list_type}`
    );
  };
  return (
    <div className="catlog-main">
      <div className="d-help py-2 flex-wrap">
        <div className="d-help responsive-heading">
          <div className="bread-crum d-help me-2">
            <p
              className="m-0 me-2 text-underline font-13"
              onClick={backToStore}
            >
              All Stores
            </p>
            <span>/</span>
            <p
              className="m-0 ms-2 me-2 text-underline font-13"
              onClick={backToPage}
            >
              Store
            </p>
            <span>/</span>
            <h6 className="m-0 ms-2 font-13">Product Catalog</h6>
          </div>
        </div>
        <button className="btn btn-secondary btn-sm m-0" onClick={backToPage}>
          <FontAwesomeIcon icon="fa fa-arrow-left" />
          <span className="ms-1">Back</span>
        </button>
      </div>

      <div className="row px-2 mt-2">
        {category_list?.length > 0 ? (
          category_list
            .filter((data) => data.category_status === true)
            .map((data, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 p-0 my-1">
                <div
                  title={data.category_name}
                  className="card-category text-capitalize mx-1"
                  onClick={(e) => {
                    routeToProductList(data);
                  }}
                  key={index}
                >
                  <h5 className="text-ellipsis w-80">{data.category_name}</h5>
                  <div className="listing-badge">{data.total_products}</div>
                </div>
              </div>
            ))
        ) : isLoading ? (
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-center set_height border rounded bg-white">
              <div className="spinner-border me-1" />
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-center set_height ps-2 font-weigh-bold font-14 mb-0 border rounded">
              <div className="text-center text-secondary">
                <span>No Categories Found</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CategoryCardListing;
