import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Loader from "../../../dataset/Loader/Loader";
import "./ProductType.scss";
import {
  fetchCategoryByStoreDetails,
  fetchproductByCategory,
  getProductDetails,
  updateProductDetails,
} from "../../../../services/appService";
import { toast } from "react-toastify";
import { fetchlogicDataByDataset } from "../../../../services/appService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProductSpecification(props) {
  const history = useHistory();
  const storeId = props.storeId;
  const product_id = props.sendObjectId ? props.sendObjectId : "";
  const [disable, setDisable] = useState(true);
  const [isResponseLoading, setisResponseLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [storeCategoryData, setStoreCategoryData] = useState([]);
  const [storeProductData, setStoreProductData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorListLoading, setVendorListLoading] = useState(true);
  const vendorObj = {
    vendorData: [],
    available_in: 0,
    min_value: 0,
    max_value: 0,
  };
  const [vendorDetailsArr, setVendorDetailsArr] = useState([vendorObj]);

  useEffect(() => {
    if (product_id?.length) {
      callProductDetails();
    }
  }, [product_id]);

  // fetch product details
  const callProductDetails = () => {
    setDisable(true);
    getProductDetails(product_id)
      .then((response) => {
        if (response.data) {
          setVendorDetailsArr(
            response.data.vendor_details?.length >= 1
              ? response.data.vendor_details
              : [vendorObj]
          );
          setStoreCategoryData(
            response.data.related_categories
              ? response.data?.related_categories?.map((related) => {
                  related.category_data._id = related?.category_data?._id.$oid;
                  return related;
                })
              : []
          );
          setStoreProductData(
            response.data.related_product
              ? response.data.related_product?.map((related) => {
                  related.product_data._id = related?.product_data?._id.$oid;
                  return related;
                })
              : []
          );
        }
      })
      .catch((err) => {
        if (err) {
          toast.error(err?.message);
        }
      })
      .finally(() => {
        setDisable(false);
      });
  };

  useEffect(() => {
    fetchCategoryByStore();
    fetchVendorData();
  }, []);

  // fetch category data
  const fetchCategoryByStore = () => {
    fetchCategoryByStoreDetails(storeId)
      .then((response) => {
        let optionData = [];
        if (response.data && response.data?.length) {
          response.data.map((data, index) => {
            let objData = {
              value: data._id.$oid,
              label: data.category_name,
              category_data: data,
            };
            optionData.push(objData);
          });
        }
        setCategories(
          optionData?.filter(
            (data) =>
              data?.category_data?.category_list_type === props?.prodCatListType
          )
        );
      })
      .catch((err) => {
        if (err) {
          toast.error(err?.message);
        }
      })
      .finally(() => {
        setIsCategoryLoading(false);
      });
  };

  // fetch vendorData
  const fetchVendorData = () => {
    let datasetName = "vendorprofile";
    fetchlogicDataByDataset(datasetName)
      .then((response) => {
        if (response.data?.length) {
          let vendortempData = [];
          response.data.map((data, index) => {
            let objData = {
              label: `${data.first_name} ${data.last_name}`,
              value: data._id.$oid,
            };
            vendortempData.push(objData);
          });
          setVendorList(vendortempData);
        }
      })
      .catch((err) => {
        if (err) {
          setVendorListLoading(false);
        }
      })
      .finally(() => {
        setVendorListLoading(false);
      });
  };

  // change product on categories
  const handleChange = (selectedOption) => {
    setStoreCategoryData(
      selectedOption
        ? selectedOption?.map((related) => {
            related.category_data._id = related?.value;
            return related;
          })
        : selectedOption
    );
  };

  // handle vendor data
  const handleDataValue = (selectedOption, index) => {
    let tempData = [...vendorDetailsArr];
    tempData[index]["vendorData"] = selectedOption;
    setVendorDetailsArr(tempData);
  };

  // store product data
  const handleData = (selectedOption) => {
    setStoreProductData(
      selectedOption
        ? selectedOption?.map((related) => {
            related.product_data._id = related?.value;
            return related;
          })
        : selectedOption
    );
  };

  // useEffect for mappingData
  useEffect(() => {
    let categoryDetails = [];
    storeCategoryData?.map((data, index) => {
      categoryDetails.push(data.value);
    });
    let body = {
      category_id: categoryDetails,
    };
    fetchproductByCategory(body, storeId)
      .then((response) => {
        if (response.data && response.data?.length) {
          let productOptionsData = [];
          response.data.map((data, index) => {
            let bodydata = {
              label: data.product_name,
              value: data._id.$oid,
              product_data: data,
            };
            productOptionsData.push(bodydata);
            setProducts(productOptionsData);
          });
        }
      })
      .catch((err) => {
        if (err) {
          setProductsLoading(false);
        }
      })
      .finally(() => {
        setProductsLoading(false);
      });
  }, [storeCategoryData]);

  // store final data
  const storeData = () => {
    setisResponseLoading(true);
    getProductDetails(product_id)
      .then((response) => {
        if (response.data) {
          saveProductDetailsData(response.data);
        }
      })
      .catch((err) => {
        if (err) {
          toast.error(err?.response?.data?.message);
        }
        setisResponseLoading(false);
      });
  };

  const saveProductDetailsData = (data) => {
    let sendVendorDataToObj = [...vendorDetailsArr].filter(
      (item) => Object.keys(item.vendorData).length !== 0
    );
    let productData = data;
    productData["related_categories"] = storeCategoryData;
    productData["related_product"] = storeProductData;
    productData["vendor_details"] = sendVendorDataToObj;
    let bodyObj = {
      object_id: product_id,
      fields: productData,
    };
    updateProductDetails(bodyObj)
      .then((response) => {
        if (response.success) {
          toast.success(response.message);
          history.goBack();
        }
      })
      .catch((error) => {
        if (error) {
          setisResponseLoading(false);
          toast.error(error?.message);
        }
      })
      .finally(() => {
        setisResponseLoading(false);
      });
  };

  const removeObjectFromVendorArr = (indexValue) => {
    if (vendorDetailsArr.length > 1) {
      let arrData = [...vendorDetailsArr];
      arrData.splice(indexValue, 1);
      setVendorDetailsArr(arrData);
    }
  };

  const changeVendorObjDetails = (objValue, indexValue, passParams) => {
    let tempArr = [...vendorDetailsArr];
    tempArr[indexValue][passParams] = objValue;
    setVendorDetailsArr(tempArr);
  };

  return disable ? (
    <div className="p-3">
      <div className="text-center">
        <div className="spinner-border"></div>
      </div>
    </div>
  ) : (
    <div className="mt-3 product-specification">
      <div className="form-group row">
        <label className="control-label col-md-3 col-sm-3">
          Related Categories
        </label>
        <div className="col-md-9 col-sm-9 ">
          <Select
            options={categories}
            isMulti={true}
            onChange={handleChange}
            value={storeCategoryData}
            isLoading={isCategoryLoading}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="control-label col-md-3 col-sm-3">
          Product Alternatives
        </label>
        <div className="col-md-9 col-sm-9 ">
          <Select
            options={products}
            isMulti
            onChange={handleData}
            value={storeProductData}
            isLoading={productsLoading}
          />
        </div>
      </div>

      <div className="col-md-12 background-border mb-3">
        <div className="row">
          <div className="col-md-3">
            <label>Select Vendor</label>
          </div>
          <div className="col-md-3">
            <label>Minimum Price Value</label>
          </div>{" "}
          <div className="col-md-3">
            <label>Maximum Price Value</label>
          </div>
          <div className="col-md-2">
            <label>Available In Days</label>
          </div>
          <div className="col-md-1 pe-0">
            <label>Actions</label>
          </div>
        </div>
        {vendorDetailsArr.map((data, index) => (
          <div className="row pt-4 align-items-center" key={index}>
            <div className="col-md-3">
              <Select
                options={vendorList}
                isMulti={false}
                onChange={(e) => handleDataValue(e, index)}
                value={data.vendorData}
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                isLoading={vendorListLoading}
              />
            </div>

            <div className="col-md-3">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Minimum Value"
                value={data.min_value}
                onChange={(e) => {
                  changeVendorObjDetails(e.target.value, index, "min_value");
                }}
              ></input>
            </div>
            <div className="col-md-3">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Maximum Value"
                value={data.max_value}
                onChange={(e) => {
                  changeVendorObjDetails(e.target.value, index, "max_value");
                }}
              ></input>
            </div>

            <div className="col-md-2">
              <input
                type="text"
                className="form-control"
                value={data.available_in}
                onChange={(e) => {
                  changeVendorObjDetails(e.target.value, index, "available_in");
                }}
              ></input>
            </div>
            <div className="col-md-1">
              <div className="actions d-flex cursor">
                <FontAwesomeIcon
                  role="button"
                  icon={"fa fa-plus"}
                  className="me-2 text-success"
                  onClick={() =>
                    setVendorDetailsArr((oldArray) => [...oldArray, vendorObj])
                  }
                />
                <FontAwesomeIcon
                  role="button"
                  icon={"fa fa-minus"}
                  className="text-danger"
                  onClick={() => {
                    removeObjectFromVendorArr(index);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-end">
        <button
          type="button"
          className="btn btn-success btn-md me-0"
          onClick={() => {
            storeData();
          }}
          disabled={
            isResponseLoading ||
            isCategoryLoading ||
            productsLoading ||
            vendorListLoading
          }
        >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
          {isResponseLoading && (
            <div className="spinner-border text-light btnLoader me-1 spinner-border-sm"></div>
          )}
          &nbsp;&nbsp;Submit&nbsp;&nbsp;
        </button>
      </div>
    </div>
  );
}

export default ProductSpecification;
