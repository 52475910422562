import React, { useState, useMemo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { getMarker,getAnimationType } from "./Markers";
import building from "../../../images/mapcity.png";

const CurrentStatusMap = ({ apiKey, zoom, center, displayData, itemData}) => {

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const mapStyles = useMemo(() => ({
    height: "530px",
    width: "100%",
  }), []);

  const handleMarkerClick = (id, data) => {
    mapRef?.panTo({ lat:data?.lat, lng:data?.lng });
    setInfoWindowData({id,...data});
    setIsOpen(true);
  };

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    itemData?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  return (
    <>                
      {isLoaded && (
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={mapStyles}
          zoom={zoom}
          center={center}
          defaultCenter={center}
          defaultZoom={zoom}
          onLoad={onMapLoad}
          options={{
            streetViewControl: false
          }}
        >
          {itemData?.map((data, i) => (
            <Marker
              key={i}
              position={{ lat: data?.lat, lng: data?.lng }}
              onClick={() => {
                handleMarkerClick(i, data);
              }}
              options={{
                icon: getMarker(displayData?.markerType,data?.heading,displayData?.mapDirection),
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
                animation:getAnimationType(displayData?.markerAnimation)
              }}  
            >
              {isOpen && infoWindowData?.id === i && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <div className="map-info-window p-1" >
                    <img src={building} className="info-image border rounded-2"  ></img>
                    {infoWindowData &&
                      Object.keys(infoWindowData).map((key, index) =>
                        typeof infoWindowData[key] == "object" ? (
                          <div key={index} className="marker-info">
                            <div>
                              <label className="control-label text-left">
                                {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                              </label>
                              <span className="ms-2">
                              {key === "_id" ? infoWindowData[key].$oid : infoWindowData[key].toString()}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div key={index} className="marker-info">
                            <div>
                              <label className="control-label text-left">
                                {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                              </label>
                              <span className="ms-2">
                                {infoWindowData[key]}
                              </span>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      )}
    </>
  );
};

export default CurrentStatusMap;


