import React from "react";
import CustomForm from "./CustomForm";
import { Modal } from "react-bootstrap";

const CustomModalForm = ({
  modalTitle,
  pageFields,
  formData,
  setFormData,
  onSubmitClick,
  submitButtonText,
  submitButtonIcon,
  appSessionId,
  show,
  onHide,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomForm
          pageFields={pageFields}
          formData={formData}
          setFormData={setFormData}
          onSubmitClick={onSubmitClick}
          submitButtonText={submitButtonText}
          submitButtonIcon={submitButtonIcon}
          appSessionId={appSessionId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CustomModalForm;
