import React, { useEffect, useState, useContext } from "react";
import dummyImage from "../../../images/dummySummary.png";
import * as API from "../../../services/API";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reactAppExecutionVP } from "../../../services/API";
import { Collapse } from "react-bootstrap";
import { Col } from "react-bootstrap";
import AppLoader from "../../dataset/Loader/AppLoader";
import "./SummaryCard.scss";
import { RoutesContext } from "../../../RoutesContext";
import { formatCurrency } from "../../../services/utils";

const SummaryCard = ({
  displayData,
  appSessionId,
  summaryPageFields,
  objData,
  filterApply,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [summaryCardData, setSummaryCardData] = useState([]);
  const [summaryCardLoading, setSummaryCardLoading] = useState(false);
  const [toggleSummaryView, setToggleSummaryView] = useState(true);
  const [pageFields, setSummaryPageFields] = useState(summaryPageFields || []);
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const history = useHistory();
  const { component_id, isCacheEnabled, autoReloadComponent, autoReloadDelay } =
    displayData;

  const handleReadMore = (summaryDetails, refName) => {
    let data = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      data: summaryDetails,
      reference_name: refName,
      app_session_id: appSessionId,
    };
    appExecutionEngineSummary(data);
  };

  const appExecutionEngineSummary = (data) => {
    API.executeAppForTempStorage(data)
      .then((response) => {
        if (response) {
          setTempStorageCall(true);
          history.push(
            `/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setSummaryCardData(cachedData?.[component_id]?.data);
      setSummaryPageFields(cachedData?.[component_id]?.fields);
      return;
    }
    getSummaryData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (filterApply) {
      getSummaryData();
    }
  }, [filterApply]);

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(() => getSummaryData(), autoReloadDelay);
      return () => clearInterval(intervalId);
    }
  }, []);

  const getSummaryData = () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setSummaryCardLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayData?.refValue;
          })?.[displayData.refValue];
          setSummaryCardData(componentData?.value?.data);
          setSummaryPageFields(componentData?.value?.fields);
          let data = cachedData;
          data[component_id] = {
            data: componentData?.value?.data,
            fields: componentData?.value?.fields,
          };
          setCacheData(data);
          setTempStorageCall(false);
        }
        clearFilterApply();
      })
      .catch((err) => {
        toast.error(err.message);
        setSummaryCardLoading(false);
      })
      .finally(() => {
        setSummaryCardLoading(false);
      });
  };

  return (
    <Col md={displayData.columenWidth || 12} className="my-1 px-0">
      <div
        className={`chart-border px-3 bg-white py-2 position-relative  mx-1`}
      >
        <div className="summaryCardHeader">
          <div className="d-flex justify-content-end align-items-center mb-2 mt-1">
            <div className="d-flex align-items-center ">
              <button
                className="arrowDownContainer header-icon-button icon-hover-effect"
                title="Refresh"
                onClick={getSummaryData}
              >
                <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
              </button>
              <button
                className="arrowDownContainer header-icon-button icon-hover-effect"
                title={
                  toggleSummaryView ? "Collapse Summary" : "Expand Summary"
                }
                onClick={() => setToggleSummaryView(!toggleSummaryView)}
                aria-controls="summary-toggle"
                aria-expanded={toggleSummaryView}
              >
                <FontAwesomeIcon
                  icon={"fa fa-angle-down"}
                  className={`arrow-icon ${
                    toggleSummaryView ? "arrow-icon-up" : "arrow-icon-down"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
        {summaryCardLoading && <AppLoader />}
        <Collapse in={toggleSummaryView}>
          {summaryCardData?.length === 0 ? (
            <div className="text-center zeroData">
              {!summaryCardLoading && "No data to display"}
            </div>
          ) : (
            <div id="summary-toggle" className="summaryContent">
              <div className="col-12 d-flex flex-wrap">
                {summaryCardData?.map((summaries, index) => (
                  <div
                    className={`col-lg-${displayData?.summaryCard} col-md-${displayData?.summaryCard} col-sm-6 col-12 pt-2 px-0`}
                    key={index}
                  >
                    <div className="border h-100 mx-1 bg-white summaryCard">
                      <div className="d-flex justify-content-between border-bottom p-2">
                        <div className="d-flex align-items-center w-80">
                          <div className="text-black ps-2 d-flex align-items-center w-100">
                            <img
                              className={`${
                                !summaries?.image?.[0] && "dummy_image"
                              }`}
                              src={summaries?.image?.[0] || dummyImage}
                              alt=""
                              width="30px"
                              height="30px"
                            />
                            <h6 className="mb-0 ps-1 textOverFlowEllipsis">
                              {summaries?.[displayData?.summaryTitle]}
                            </h6>
                          </div>
                        </div>
                        {displayData?.transitionAppId &&
                        displayData?.transitionPageId ? (
                          <div className="d-flex align-items-center pe-2">
                            <FontAwesomeIcon
                              icon="fa-solid fa-arrow-up-right-dots"
                              onClick={() =>
                                handleReadMore(
                                  summaries,
                                  displayData?.keyToSend
                                )
                              }
                              size="md"
                              className="text-primary"
                              cursor="pointer"
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="bg-white m-2">
                        {pageFields?.map((field) => {
                          return field.show_on_summary === "yes" ? (
                            <div
                              className="m-1 p-1 d-flex col-12"
                              key={field.name}
                            >
                              <div className="d-flex col-6 m-0 p-0 summaryCardLabelContent">
                                {field.display_name}
                              </div>
                              <div className="d-flex col-6 m-0 p-0 summaryCardValueContent">
                                {displayData?.displayCurrencyFormat
                                  ? formatCurrency(summaries[field.name])
                                  : summaries[field.name] || "-"}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Collapse>
      </div>
    </Col>
  );
};
export default SummaryCard;
