import React, { useEffect, useState } from "react";
import ViewPage from "./ViewPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomForm from "../../components/CustomForm";
import { reactAppFormExecutionVP } from "../../../../services/API";
import { toast } from "react-toastify";
import { actionTypes, componentType } from "../../../common/model/Model";
import CommentBox from "../CommentBox";
import {
  mentionUserEmail,
  upsertComment,
} from "../../../../services/appService";
import TimeLineView from "../../TimeLineView/TimeLineView";
import RelationTable from "../RelationTable";

const ViewUpdateAdd = ({
  actions,
  formFieldsList = [],
  viewFieldsList,
  viewUpdateAddData,
  changeViewPageState,
  datasetName,
  viewWidthState,
  viewUpdateAddState,
  appSessionId,
  currentActionDetails,
  reloadComponentData,
  formConfig,
  openModelData,
  openInlineFormTableModal,
  openCheckListModal
}) => {
  const [formData, setFormData] = useState({});
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [commentData, setCommentData] = useState("");
  const [emailList, setEmailList] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    callUserEmails();
  }, []);

  const callUserEmails = () => {
    mentionUserEmail()
      .then((response) => {
        if (response) {
          setUserData(response.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (viewUpdateAddState === "Update") {
      setFormData(viewUpdateAddData);
    } else {
      setFormData({});
    }
  }, [viewUpdateAddState, viewUpdateAddData]);

  const onChange = (event) => {
    const enteredValue = event.target.value;
    let valueData = enteredValue.replace(/ *\([^)]*\) */g, "");
    setCommentData(enteredValue);
    setEmailList(valueData);
  };

  const onSubmitButtonClick = () => {
    let objectBody = {
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      data: {
        page_fields: [
          formFieldsList.map((field) => {
            return {
              is_required: field.is_required,
              display_name: field?.display_name,
              name: field?.name,
            };
          }),
        ],
        formData: [formData],
        logic_to_send: [currentActionDetails?.logicNameValue],
      },
      app_session_id: appSessionId,
      page_type: componentType.formComponent,
      store_output_key: currentActionDetails?.referenceValue,
    };
    setSubmitResponseLoading(true);
    reactAppFormExecutionVP(objectBody)
      .then((response) => {
        toast.success(response?.message);
        const obj = {
          object_id: viewUpdateAddData?._id,
          comments: commentData
            .replace(/[\[\']+/g, "")
            .replace(/[\] \']+/g, " "),
          email_list: emailList,
          user_name: userInfo.Name,
          dataset_name: datasetName,
        };
        {
          commentData &&
            ["Update"].includes(viewUpdateAddState) &&
            currentActionDetails.showCommentBox === "yes" &&
            upsertComment(obj)
              .then((response) => {
                if (response) {
                  toast.success(response.message);
                  setCommentData("");
                }
              })
              .catch((err) => {
                toast.error(err.message);
              });
        }
        reloadComponentData();
        changeViewPageState();
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setSubmitResponseLoading(false);
      });
  };

  return (
    <div className="ms-md-2">
      <div className="viewHeight border">
        <div className="viewTitleHeader bg-white">
          <div className="d-help border-bottom p-1 viewBackground">
            <h6 className="ps-2 fw-bold m-0">
              {viewUpdateAddState + " Details"}
            </h6>
            <button
              className="btn btn-md m-0 btn-light"
              onClick={() => changeViewPageState()}
            >
              <span className="m-0">
                <FontAwesomeIcon icon={"fa fa-close"} />
              </span>
            </button>
          </div>
        </div>
        {["View"].includes(viewUpdateAddState) && (
          <ViewPageTab
            actions={actions}
            viewData={viewUpdateAddData}
            pageFieldsList={viewFieldsList}
            changeViewPageState={changeViewPageState}
            datasetName={datasetName}
            viewWidthState={viewWidthState}
            relationData={actions?.relations}
            userName={userData}
            openModelData={openModelData}
            openInlineFormTableModal={openInlineFormTableModal}
            openCheckListModal={openCheckListModal}
          />
        )}

        {[actionTypes.timeLineView].includes(
          currentActionDetails.actionType
        ) && (
          <TimeLineView
            actions={actions}
            data={viewUpdateAddData}
            openModelData={openModelData}
            showTable={true}
            showTimeLine={true}
            openInlineFormTableModal={openInlineFormTableModal}
          />
        )}

        {["Update"].includes(viewUpdateAddState) && (
          <div className="p-2 rightMainContainer">
            <CustomForm
              formGroupData={formConfig?.formGroupData}
              pageFields={formFieldsList}
              isEdit={viewUpdateAddState === "Update"}
              setFormData={setFormData}
              formData={formData}
              appSessionId={appSessionId}
              formTitle={""}
            />
          </div>
        )}
        {["Update"].includes(viewUpdateAddState) && (
          <div className="p-2 rightMainContainer">
            {currentActionDetails.showCommentBox !== "yes" && (
              <CommentBox
                userName={userData}
                onChange={onChange}
                commentData={commentData}
                onSubmitClick={onSubmitButtonClick}
                loading={submitResponseLoading}
              />
            )}
            <div className="control-btn text-end ">
              <button
                className="btn btn-success btn-md m-0"
                onClick={() => onSubmitButtonClick()}
              >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
                {submitResponseLoading && (
                  <div className="spinner-border text-light btnLoader me-1 spinner-border-sm"></div>
                )}
                &nbsp;&nbsp;Update&nbsp;&nbsp;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewUpdateAdd;

export const ViewPageTab = ({
  actions,
  viewData,
  pageFieldsList,
  changeViewPageState,
  datasetName,
  viewWidthState,
  userName,
  relationData,
  openModelData,
  openInlineFormTableModal,
  openCheckListModal
}) => {
  const [activeTab, setActiveTab] = useState("details");
  return (
    <div className="p-2">
      <div className="rightMainContainer px-1 pt-1 d-flex border border-bottom-0">
        <div
          role="button"
          className={`p-2 ${activeTab === "details" ? "tab_active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </div>
        <div
          role="button"
          className={`p-2 ${activeTab === "history" ? "tab_active" : ""}`}
          onClick={() => setActiveTab("history")}
        >
          History
        </div>
        {relationData?.map((relation, index) => {
          return (
            <div
              role="button"
              className={`p-2 ${activeTab === relation.id ? "tab_active" : ""}`}
              key={index}
              onClick={() => setActiveTab(relation.id)}
            >
              {relation?.relationshipName ?? ""}
            </div>
          );
        })}
      </div>
      <div className="clearfix" />

      {activeTab === "details" && (
        <ViewPage
          actions={actions}
          viewData={viewData}
          pageFieldsList={pageFieldsList}
          changeViewPageState={changeViewPageState}
          datasetName={datasetName}
          viewWidthState={viewWidthState}
          relationData={actions?.relations}
          userName={userName}
          openInlineFormTableModal={openInlineFormTableModal}
          openCheckListModal={openCheckListModal}
        />
      )}

      {activeTab === "history" && (
        <TimeLineView
          actions={actions}
          data={viewData}
          openModelData={openModelData}
          showTable={true}
          showTimeLine={false}
        />
      )}

      {relationData
        ?.filter((data) => data.id === activeTab)
        ?.map((relation, index) => {
          return (
            <RelationTable
              key={index}
              viewData={viewData}
              relationData={relation}
            />
          );
        })}
    </div>
  );
};
