import React, { Component } from "react";
import AppPages from "../createApp/appPages/AppPages";
import AppLogic from "../createApp/appLogic/AppLogic";
import AppFlow from "../createApp/appFlow/AppFlow";
import Accordion from "react-bootstrap/Accordion";
import AppDetails from "../createApp/appDetails/AppDetails";
import { Link } from "react-router-dom";
import "./CreateApp.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class CreateApp extends Component {
  constructor() {
    super();
    this.state = {
      showNextData: 0,
      sendAppPagevalue: "",
      appId: "",
    };
  }

  componentDidMount() {
    this.setAppId();
  }

  setAppId() {
    if (this.props.match.params.app_id !== undefined) {
      this.setState(
        {
          appId: this.props.match.params.app_id,
          showNextData: 1,
        },
        () => {
          localStorage.setItem("app_id", JSON.stringify(this.state.appId));
        }
      );
    }
  }

  saveAppid = (responseId, responseName) => {
    this.setState(
      { appId: responseId, appName: responseName, showNextData: 1 },
      () => {
        this.props.history.replace(`/create_app/${this.state.appId}`);
      }
    );
  };

  clickedAccordion(content) {
    this.setState({
      sendAppPagevalue: content,
    });
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-end py-2">
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item className="mb-2" eventKey="0">
            <Accordion.Header>
              <h5 className="mb-0">App Details</h5>
            </Accordion.Header>
            <Accordion.Body>
              <AppDetails
                returnAppIdName={this.saveAppid}
                sendUrlValue={this.props.match}
              />
            </Accordion.Body>
          </Accordion.Item>
          {this.state.showNextData === 1 ? (
            <>
              <Accordion.Item className="mb-2" eventKey="1">
                <Accordion.Header>
                  <h5 className="mb-0">App Pages</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <AppPages
                    sendvalueData={"pageList"}
                    sendAppId={this.state.appId}
                    history={this.props.history}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="2">
                <Accordion.Header>
                  <h5 className="mb-0">App Logics</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <AppLogic
                    sendvalueData={"appLogicList"}
                    sendAppId={this.state.appId}
                    history={this.props.history}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="3">
                <Accordion.Header>
                  <h5 className="mb-0">App Flows</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <AppFlow
                    sendvalueData={"appFlowList"}
                    sendAppId={this.state.appId}
                    history={this.props.history}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : null}
        </Accordion>
      </div>
    );
  }
}

export default CreateApp;
