import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";

const DatasetField = (props) => {
  const [dataset, setdataset] = useState(
    props.value ? props.value["dataset"] : ""
  );
  const [valueset, setvalueset] = useState(
    props.value ? props.value["dataset_column"] : ""
  );

  useEffect(() => {
    setvalueset(null);
  }, [dataset]);

  useEffect(() => {
    if (valueset) {
      let changedValue = {};
      changedValue["dataset"] = dataset;
      changedValue["dataset_column"] = valueset;
      props.onChange(props.keyName, changedValue);
    }
  }, [valueset]);

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Form.Label>Dataset</Form.Label>
          <Form.Select
            name={"Datasetname"}
            value={dataset}
            onChange={(e) => setdataset(e.target.value)}
          >
            <option value="" selected disabled>
              Please select
            </option>
            {props.options
              ? Object.keys(props.options).map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))
              : []}
          </Form.Select>
        </Col>
        <Col md={6}>
          <Form.Label>Valueset</Form.Label>
          <Form.Select
            value={valueset}
            onChange={(e) => setvalueset(e.target.value)}
          >
            <option value="" selected disabled>
              Please select
            </option>
            {dataset
              ? props.options[dataset].map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))
              : []}
          </Form.Select>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DatasetField;
