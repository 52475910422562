import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { autoReloadComponentDelaysList } from "../../../common/model/Model";

const DisplayBuilderProgressList = ({
  logicList,
  displayBuilder,
  columnWidthList,
  onChange,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const autoReloadDelays = useMemo(
    () =>
      autoReloadComponentDelaysList?.map((data, index) => (
        <option key={index} value={data.value}>
          {data.label}
        </option>
      )),
    []
  );

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columnWidthTable}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columnWidthTable"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Progress List Title
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter key"
            name="progressListTitle"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.progressListTitle}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Progress Title Key
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter key"
            name="progressTitleKey"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.progressTitleKey}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Progress Key</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter key"
            name="progressKey"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.progressKey}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Progress Compare Key
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter key"
            name="progressCompareKey"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.progressCompareKey}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Display Labels</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Progress,Exceed,Remains"
            name="displayProgressLabel"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.displayProgressLabel}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Progress Color</label>
        <div>
          <input
            type="color"
            name="progressColor"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.progressColor || "#27d343"}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Progress Exceed Color</label>
        <div>
          <input
            type="color"
            name="progressExceedColor"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.progressExceedColor || "#e44d42"}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="col-md-9 col-sm-9 ">
          <label className="form-label p-0 mt-2">
            <input
              className="me-2"
              type="checkbox"
              onChange={(e) => {
                checkBoxChangeValue(e);
              }}
              name="autoReloadComponent"
              checked={displayChanges?.autoReloadComponent || false}
            />
            Auto reload
          </label>
        </div>
        {displayChanges.autoReloadComponent && (
          <div className="col-md-9 col-sm-9 ">
            <label className="form-label p-0 mt-2">Select delay</label>
            <Form.Select
              className="form-control"
              name="autoReloadDelay"
              value={displayChanges.autoReloadDelay}
              onChange={(e) => {
                changeValue(e);
              }}
            >
              <option value="">--Select--</option>
              {autoReloadDelays}
            </Form.Select>
          </div>
        )}
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="d-flex">
          <input
            className="me-2"
            type="checkbox"
            onChange={(e) => {
              checkBoxChangeValue(e);
            }}
            name="isCacheEnabled"
            checked={displayChanges?.isCacheEnabled}
          />
          <label className="form-label p-0 mt-2">Enable Cache</label>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderProgressList;
