import React, { Component } from "react";
import AddDetails from "../../../dataset_editor/dataset_structure/add_details/AddDetails";
import SortData from "../../../dataset_editor/dataset_structure/sort/SortData";
import {
  fetchAttributeDetails,
  storeAttributeDetails,
} from "../../../../services/appService";
import { toast } from "react-toastify";
import Loader from "../../../dataset/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class CustomField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveFormFieldData: {},
      getPageFieldsDetails: [],
      getPageFieldArray: [],
      sendAttributeData: [],
      attributeId: this.props.match.params.attributeId
        ? this.props.match.params.attributeId
        : "",
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      attribute_name: "",
      attribute_description: "",
      sendCustomFields: [],
      buttonFlag: false,
      loading: false,
      storeId: this.props.match.params.storeId,
    };
    this.receiveSortData = this.receiveSortData.bind(this);
    this.sendAppData = this.sendAppData.bind(this);
  }

  componentDidMount() {
    this.fetchAttributeData();
  }

  fetchAttributeData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetchAttributeDetails(this.state.attributeId)
          .then((response) => {
            if (response.data) {
              this.setState({
                attribute_name: response.data.attribute_name,
                attribute_description: response.data.attribute_description,
                sendCustomFields: response.data.custom_fields,
                loading: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }

  getFormFieldData = (data) => {
    this.setState({
      getPageFieldsDetails: [...this.state.getPageFieldsDetails, data.field],
    });
    this.state.getPageFieldArray.push(data.field);
    this.state.getPageFieldArray = [];
  };

  onClickFetchData = (event) => {
    this.formFieldComponent.submitFormFieldData(event);
  };

  receiveSortData() {
    this.getMetaData.sendAppDataToParent();
  }

  returnSortFieldData() {
    this.getMetaData.sendAppDataToParent();
  }

  sendAppData(data) {
    this.setState(
      {
        sendAttributeData: data,
      },
      () => {
        this.saveAttributeDetails();
      }
    );
  }

  saveAttributeDetails() {
    let objData = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "attributes",
      object_id: this.state.attributeId,
      fields: {
        attribute_name: this.state.attribute_name,
        attribute_description: this.state.attribute_description,
        store_id: this.state.storeId,
        custom_fields: this.state.sendAttributeData,
      },
    };
    this.setState({
      buttonFlag: true,
    });
    storeAttributeDetails(objData)
      .then((response) => {
        this.setState(
          {
            buttonFlag: false,
          },
          () => {
            if (response) {
              toast.success("Data Saved Successfully");
              this.props.history.push(`/attributes_set/${this.state.storeId}`);
            }
          }
        );
      })
      .catch(() => {
        this.setState(
          {
            buttonFlag: false,
          },
          () => {
            toast.error("Internal Server Error");
          }
        );
      });
  }

  backToCrudAttribute() {
    this.props.history.push(
      `/edit-attibuteSet/${this.state.storeId}/${this.state.attributeId}`
    );
  }

  render() {
    return (
      <div className={`col-md-12 pb-4 catlog-main`}>
        <div className="button-wrapper w-100 d-help my-3">
          <h5 className="mb-0">Custom Fields</h5>
          <button
            type="button"
            className="btn btn-secondary btn-md me-0"
            onClick={() => this.props.history.goBack()}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" className="me-1" />
            Back
          </button>
        </div>

        <div className="row">
          <div className="add-field col-md-6">
            <AddDetails
              onRef={(ref) => (this.formFieldComponent = ref)}
              formFieldData={this.getFormFieldData}
            ></AddDetails>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-md btn-secondary me-0"
                onClick={this.onClickFetchData}
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" className="me-1" />
                Add Field
              </button>
            </div>
          </div>
          <div className="sort-field col-md-6">
            <SortData
              onRef={(ref) => (this.getMetaData = ref)}
              sendFieldArray={this.state.getPageFieldArray}
              sendFieldsData={this.state.sendCustomFields}
              sendAppData={this.sendAppData}
            ></SortData>
            {this.state.loading ? (
              <Loader></Loader>
            ) : this.state.getPageFieldsDetails?.length ||
              this.state.sendCustomFields?.length ? (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-md btn-success me-0"
                  onClick={() => this.returnSortFieldData()}
                  disabled={this.state.buttonFlag}
                >
                  Submit
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomField;
