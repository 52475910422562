import React from "react";

function ToggleButton({ field, onChange, value, disabled = false }) {
  let checked_text = field?.checked_text || true;
  let unchecked_text = field?.unchecked_text || false;
  return (
    <div className="toggleSwitch">
      <div className="d-flex">
        <label
          className={`switch me-2 mb-0 ${disabled ? "disableSwitch" : ""}`}
        >
          <input
            disabled={disabled}
            type="checkbox"
            checked={value === checked_text ? true : false}
            onChange={(e) => {
              let changedData = e.target.checked
                ? checked_text
                : unchecked_text;
              onChange(changedData);
            }}
          ></input>
          <span className="slider round"></span>
        </label>
        <span className="toggleSwitchText"> {value}</span>
      </div>
    </div>
  );
}

export default ToggleButton;
