import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actionTypes, inputTypeKey } from "../../common/model/Model";

const TimeLineTable = ({ auditLogData, pageFields, openModelData,openInlineFormTableModal }) => {
  const [logData, setLogData] = useState([]);

  useEffect(() => {
    setLogData(auditLogData);
  }, [auditLogData]);

  const headers = !logData || logData.length === 0 ? [] : Object?.keys(logData[0])?.map((header) => header);

  const formatHeaderLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getInlineStyle = (field,name) => {
    const matchedTag = field?.tags?.find((data) => data.name === name);
    const textColor =
      field?.textColor === "Dark" ? "text-secondary" : "text-white";
    if (matchedTag) {
      return (
        <span
          className={textColor}
          style={{
            backgroundColor: matchedTag.color,
            textShadow: "1px 0px 2px #000000",
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          {name}
        </span>
      );
    } else {
      return <span>{name}</span>;
    }
  };

  const checkForValueChange = (logData,item,heading,index) =>{
    const currentValue =item?.[heading];
    if(index > 0){
      if(logData[index-1][heading] !== currentValue && currentValue!== ''){
        return <span className="value-change" >{item?.[heading]}</span>
      }
      else{
        return currentValue || '-'
      }
    }
    else {
      return currentValue || '-'
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="tableTheme1 table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {headers?.map((header, index) => (
                    <th className="text-nowrap" key={index}>
                      {formatHeaderLabel(header)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {logData?.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers?.map((heading, i) => {
                      let header = pageFields.filter((item) => item.name === heading)[0] ||[];
                      return header.length > 0 ? (
                        header.data_type === "arr_obj" ||
                        header.data_type === "lineItems" ? (
                          <td key={i} className={header.name}>
                            {item?.[header.name]?.length ? (
                              <div
                                title="Click For More Details"
                                role="button"
                                onClick={() => {
                                  openModelData(
                                    header,
                                    item?.[header.name]
                                  );
                                }}
                                className="fw-bold"
                              >
                                {item?.[header.name]?.length}
                              </div>
                            ) : (
                              0
                            )}
                          </td>
                        )
                        :
                        header.data_type === inputTypeKey?.inlineTableForm ? (
                          <td key={i}>
                          {item?.[header.name]?.length ? (
                            <div
                              className="fw-bold"
                              title="Click For More Details"
                              role="button"
                              onClick={() => {
                                openInlineFormTableModal(
                                  header,
                                  item?.[header.name]
                                );
                              }}
                            >
                              {item?.[header.name]?.length}
                            </div>
                          ) : (
                            0
                          )}
                        </td>
                        ) 
                        
                        
                        : header.data_type === "file" ? (
                          <td key={i}>
                            {item?.[header.name]?.length > 0
                              ? item?.[header?.name]?.map((fileList, i) => {
                                  return (
                                    <a
                                      href={fileList}
                                      target="_blank"
                                      download="true"
                                      key={i}
                                    >
                                      <FontAwesomeIcon
                                        icon={"fa fa-download"}
                                        className="me-2"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  );
                                })
                              : "-"}
                          </td>
                        ) : header.data_type === "largetext" ? (
                          <td key={i}>
                            {item?.[header.name] && item?.[header.name]?.length
                              ? item?.[header.name]?.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )
                              : "-"}
                          </td>
                        ) : header.data_type ===
                          inputTypeKey.searchableDropdown ? (
                          <td key={i}>
                            {Array.isArray(item?.[header.name])
                              ? item?.[header.name]?.join(",")
                              : item?.[header.name] || "-"}
                          </td>
                        ) : (
                          <td key={i}>
                          {item?.[header?.name].length
                              ? getInlineStyle(header,item?.[header?.name])
                              : checkForValueChange(logData,item,heading,rowIndex)}
                          </td>
                        )
                      ) : (
                        <td key={i}>
                          {heading === "_id"
                            ? item?.[heading].$oid
                            : checkForValueChange(logData,item,heading,rowIndex)}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeLineTable;
