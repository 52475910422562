import React, { useEffect, useState } from "react";
import { getStoreDetails } from "../../services/appService";
import Loader from "../dataset/Loader/Loader";

const StorePrivileges = (props) => {
  const [storeLoading, setstoreLoading] = useState(true);
  const [storeListtData, setstoreListtData] = useState([]);
  const [storeListtHeaderData] = useState(["Store Name", "", "Read", "Write"]);
  const [storePrivilegetData, setstorePrivilegetData] = useState({
    manage_all_product: { read: [], write: [] },
    manage_own_product: { read: [], write: [] },
  });

  useEffect(() => {
    fetchStoreListingData();
  }, []);

  useEffect(() => {
    if (props.isPrivilegeChanging) {
      setstorePrivilegetData(props.storePrivilegeData);
    } else {
      let storePrivilegetDataCopy = { ...storePrivilegetData };
      storeListtData.map((store) => {
        storePrivilegetDataCopy.manage_all_product.read.push(store._id.$oid);
        storePrivilegetDataCopy.manage_all_product.write.push(store._id.$oid);
        storePrivilegetDataCopy.manage_own_product.read.push(store._id.$oid);
        storePrivilegetDataCopy.manage_own_product.write.push(store._id.$oid);
      });
      setstorePrivilegetData(storePrivilegetDataCopy);
    }
  }, [storeListtData, props.isPrivilegeChanging]);

  useEffect(() => {
    props.setStorePrivilegeData(storePrivilegetData);
  }, [storePrivilegetData]);

  const fetchStoreListingData = () => {
    setstoreLoading(true);
    getStoreDetails()
      .then((response) => {
        if (response.data.length) {
          setstoreLoading(false);
          setstoreListtData(response.data);
        } else {
          setstoreLoading(false);
        }
      })
      .catch(() => {
        setstoreLoading(false);
      });
  };

  const updateStorePrivilegeTableData = (e, store_id, store_conditon) => {
    let storePrivilegetDataCopy = { ...storePrivilegetData };
    if (e.target.checked) {
      if (e.target.value === "write") {
        storePrivilegetDataCopy[store_conditon]?.["read"].push(store_id);
      }
      storePrivilegetDataCopy[store_conditon][e.target.value]?.push(store_id);
    } else {
      storePrivilegetDataCopy[store_conditon][e.target.value] =
        storePrivilegetDataCopy[store_conditon]?.[e.target.value]?.filter(
          (storedata) => storedata !== store_id
        );
    }
    setstorePrivilegetData(storePrivilegetDataCopy);
  };

  const setStorePrivilegeTableData = storeListtData.map((store, index) => {
    return (
      <React.Fragment key={index}>
        <tr>
          <td rowSpan={2}>{store.store_name}</td>
          <td>Manage All Products</td>
          <td>
            <input
              className="table-checkbox pointer"
              type="checkbox"
              onChange={(e) => {
                updateStorePrivilegeTableData(
                  e,
                  store._id.$oid,
                  "manage_all_product"
                );
              }}
              checked={storePrivilegetData?.manage_all_product?.read?.includes(
                store._id.$oid
              )}
              value={"read"}
              disabled={storePrivilegetData?.manage_all_product?.write?.includes(
                store._id.$oid
              )}
            />
          </td>
          <td>
            <input
              className="table-checkbox pointer"
              type="checkbox"
              onChange={(e) => {
                updateStorePrivilegeTableData(
                  e,
                  store._id.$oid,
                  "manage_all_product"
                );
              }}
              checked={storePrivilegetData?.manage_all_product?.write?.includes(
                store._id.$oid
              )}
              value={"write"}
            />
          </td>
        </tr>
        <tr>
          <td>Add Product</td>
          <td>
            <input
              className="table-checkbox pointer"
              type="checkbox"
              onChange={(e) => {
                updateStorePrivilegeTableData(
                  e,
                  store._id.$oid,
                  "manage_own_product"
                );
              }}
              checked={storePrivilegetData?.manage_own_product?.read?.includes(
                store._id.$oid
              )}
              value={"read"}
              disabled={storePrivilegetData?.manage_own_product?.write?.includes(
                store._id.$oid
              )}
            />
          </td>
          <td>
            <input
              className="table-checkbox pointer"
              type="checkbox"
              onChange={(e) => {
                updateStorePrivilegeTableData(
                  e,
                  store._id.$oid,
                  "manage_own_product"
                );
              }}
              checked={storePrivilegetData?.manage_own_product?.write.includes(
                store._id.$oid
              )}
              value={"write"}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  });

  const setStorePrivilegeHeaderData = storeListtHeaderData.map(
    (header, index) => {
      return (
        <th className="table_head" key={index}>
          {header}
        </th>
      );
    }
  );

  return (
    <table
      
      className="table  table-bordered bulk_action no-footer w-100 mb-0"
    >
      <thead>
        <tr className="head-row">{setStorePrivilegeHeaderData}</tr>
      </thead>
      <tbody>
        {storeLoading ? (
          <tr>
            <td colSpan={setStorePrivilegeHeaderData.length}>
              <Loader></Loader>
            </td>
          </tr>
        ) : (
          <>
            {storeListtData.length ? (
              setStorePrivilegeTableData
            ) : (
              <tr className="no_table_records">
                <td colSpan={setStorePrivilegeHeaderData.length}>
                  No Records Found
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default StorePrivileges;
