import React, { useEffect, useState } from "react";
import { getCategorySet } from "../../../../../src/services/appService";
import { useHistory, Link, useParams } from "react-router-dom";
import Select from "react-select";
import "./Categories.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import ProductList from "../product/ProductList";
import SearchCategory from "./SearchCategory";
import SingleCategory from "./SingleCategory";
import ProductMap from "../product/productMap/ProductMap";

function Categories({ storeComponentData, appSessionId }) {
  const history = useHistory();
  const params = useParams();
  const [storeListLoading, setStoreListLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState({});
  const [categorySetObject, setCategorySetObject] = useState({});
  const [categorySetLoading, setCategorySetLoading] = useState(false);
  const [activeHierarchy, setActiveHierarchy] = useState("");
  const [activeHierarchyObject, setActiveHierarchyObject] = useState([]);
  const [categoryStepper, setCategoryStepper] = useState([]);
  const [optionsLabelData, setOptionLabelData] = useState([]);
  const appId = params?.id;
  const flowId = params?.flowId;
  const store_id = params?.storeId;
  const [visible, setVisible] = useState(false);
  const activeKey = new URLSearchParams(history?.location?.search).get(
    "activeKey"
  );

  useEffect(() => {
    const optionData = storeComponentData?.data.page.storeComponentData;
    setOptionLabelData(optionData);
    let [store, ..._] = optionData;
    setSelectedStore(store);
    getData(optionData);
    localStorage.setItem("store_app_Id", JSON.stringify(appId));
  }, []);

  useEffect(() => {
    if (Object.keys(selectedStore).length) {
      getData();
    }
  }, [store_id]);

  const getData = (labelData) => {
    if (labelData) {
      let selectedOption = labelData?.filter((item) => item.value === store_id);
      setSelectedStore(selectedOption[0]);
    }
    setCategorySetLoading(true);
    getCategorySet(store_id)
      .then((response) => {
        let [hierarchyObject] = response?.data;
        setCategorySetObject(hierarchyObject || []);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setCategorySetLoading(false);
      });
  };

  const openWhislist = () => {
    history.push(`/wishlist/${store_id}`);
  };
  const handleSelect = (event) => {
    setSelectedStore(event);
    history.push(`/app_ui/${appId}/${flowId}/${event.value}`);
  };

  useEffect(() => {
    if (categorySetObject) {
      iterateToFind(categorySetObject, [
        {
          label: "Home",
          value: categorySetObject?.category_id,
          CategoryId: categorySetObject?.objId,
        },
      ]);
    }
    if (activeKey) {
      setActiveHierarchy(activeKey);
    } else {
      setActiveHierarchy(categorySetObject?.category_id);
    }
  }, [activeHierarchy, categorySetObject, activeKey]);

  useEffect(() => {
    setActiveHierarchy("");
  }, [store_id]);

  const iterateToFind = (toFindData, pathList = []) => {
    if (toFindData.category_id === activeHierarchy) {
      setActiveHierarchyObject(toFindData);
      setCategoryStepper(pathList);
      setVisible(false);
      setTimeout(() => {
        setVisible(true);
      }, 140);
    } else {
      toFindData?.subCategorys?.forEach((data) =>
        iterateToFind(data, [
          ...pathList,
          {
            label: data?.category_name,
            value: data?.category_id,
            CategoryId: data?.objId,
          },
        ])
      );
    }
  };

  return (
    <div>
      <div className="row px-2">
        <div className="col-md-3 col-sm-6 p-0">
          <Select
            classNamePrefix={"react-select"}
            menuPosition="fixed"
            classNames={{
              control: (state) => "searchableDropdownControl",
              menuPortal: () => "searchableDropdownMenuPortal",
              singleValue: () => "searchableDropdownValue",
              multiValue: () => "searchableDropdownValue",
              menuList: () => "searchableDropdownMenuList",
            }}
            isLoading={storeListLoading}
            options={optionsLabelData}
            className="mx-1 mb-2"
            placeholder="Select Store"
            value={selectedStore}
            onChange={handleSelect}
          />
        </div>
        <div className="col-md-3 col-sm-6 p-0">
          <SearchCategory
            isLoading={categorySetLoading}
            activeHierarchy={activeHierarchy}
            categoryObject={categorySetObject}
          />
        </div>
        <div className="col-md-6 col-sm-12 p-0 text-end">
          <button
            className="btn btn-info btn-md text-white me-1 mb-2"
            onClick={openWhislist}
          >
            <FontAwesomeIcon icon="fa fa-heart" className="me-1" />
            <span>View Wishlist</span>
          </button>
        </div>
      </div>
      <div className="bg-white rounded border-radius-large">
        {categorySetLoading || storeListLoading ? (
          <div className="d-flex align-items-center justify-content-center set_height rounded  border-radius-large m-2">
            <div className="loader-circle me-1" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="d-flex align-items-center flex-grow-1 h-100 border-bottom">
                  <nav className="breadcrumbContainer p-2">
                    {categoryStepper?.map((stepper, index) => {
                      if (index < categoryStepper?.length - 1) {
                        return (
                          <React.Fragment key={index}>
                            <span className="crumb">
                              <Link
                                to={
                                  history.location.pathname +
                                  "?activeKey=" +
                                  stepper.value
                                }
                              >
                                {stepper?.label}
                              </Link>
                            </span>
                            <FontAwesomeIcon
                              icon="fa fa-angle-right"
                              className="mx-1"
                            />
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <span className="crumb active" key={index}>
                            {stepper?.label}
                          </span>
                        );
                      }
                    })}
                  </nav>
                </div>
              </div>
            </div>
            {activeHierarchyObject?.category_description && (
              <div className="categoryDescription border-bottom p-2">
                {activeHierarchyObject?.category_description}
              </div>
            )}

            {activeHierarchyObject?.subCategorys?.length > 0 && (
              <div className="row hierarchy px-3 my-1">
                {activeHierarchyObject?.subCategorys?.map((data, index) => (
                  <SingleCategory
                    visible={visible}
                    onCategoryClick={(data) => {
                      history.push(
                        history.location.pathname +
                          "?activeKey=" +
                          data.category_id
                      );
                    }}
                    key={index}
                    data={data}
                  />
                ))}
              </div>
            )}
            {activeHierarchyObject?.category_list_type === "list_type" &&
              activeHierarchyObject?.objId && (
                <ProductList
                  activeHierarchyObject={activeHierarchyObject}
                  storeId={store_id}
                  category_id={activeHierarchyObject?.objId}
                />
              )}

            {activeHierarchyObject?.category_list_type === "map_type" && (
              <ProductMap
                activeHierarchyObject={activeHierarchyObject}
                storeId={store_id}
                category_id={activeHierarchyObject?.objId}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Categories;
