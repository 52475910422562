import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function CustomSingleSelectDropdown({
  field,
  value = [],
  onChange,
  disabled = false,
}) {
  const onchangeValue = (e) => {
    let changeValue = e.target.value;
    onChange(changeValue);
  };

  let boolEnumList = field?.enter_values?.split(",") || [];

  return (
    <div className="d-flex fieldMinimumWidth fieldMaximumWidth">
      <Form.Select
        size="sm"
        className="form-control"
        value={value}
        onChange={onchangeValue}
        disabled={disabled}
      >
        <option value={""}>--Select--</option>
        {boolEnumList?.map((data, index) => (
          <option key={index} value={data}>
            {data}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}

export default CustomSingleSelectDropdown;
