import * as echarts from "echarts";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutesContext } from "../../RoutesContext";
import { toast } from "react-toastify";
import { reactAppExecutionVP } from "../../services/API";
import AppLoader from "../dataset/Loader/AppLoader";

const generateChartOptions = ({
  titleText,
  chartType,
  categories,
  series,
  pieData,
  lineData,
  scatterData,
  doughnutData,
  doubleBarData,
  radarData,
}) => {
  const baseOptions = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    series: [
      {
        name: "Sample Series",
        type: chartType?.value,
        data: series,
        ...(chartType?.value === "pie" && {
          radius: "55%",
          center: ["50%", "50%"],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 2,
          },
        }),
        ...(chartType.label === "Smooth Line" && {
          smooth: true,
        }),
        ...(chartType.label === "Area Line" && {
          smooth: true,
          areaStyle: {},
        }),
        ...(chartType.value === "doughnut" && {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 2,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "30",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
        }),
      },
    ],
  };

  if (categories && ["bar", "line"].includes(chartType.value)) {
    baseOptions.xAxis = {
      data: categories,
    };
  }

  if (["bar", "line"].includes(chartType.value)) {
    baseOptions.yAxis = {};
  }

  if (chartType.value === "pie" && pieData) {
    baseOptions.series[0].data = pieData;
  }

  if (chartType.value === "line" && lineData) {
    baseOptions.series[0].data = lineData;
  }

  if (chartType.value === "doughnut" && doughnutData) {
    baseOptions.series[0].data = doughnutData;

    baseOptions.legend = {
      orient: "horizontal",
      top: "5%",
      data: categories,
    };
  }

  if (chartType.value === "doubleBar" && doubleBarData) {
    baseOptions.series = [
      {
        name: "Series 1",
        type: "bar",
        data: doubleBarData.series1,
      },
      {
        name: "Series 2",
        type: "bar",
        data: doubleBarData.series2,
      },
    ];
    baseOptions.xAxis = {
      data: categories,
    };
    baseOptions.yAxis = {};
  }

  return baseOptions;
};

const BasicEcharts = ({
  componentData,
  displayData,
  objData,
  appSessionId,
  filterApply,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const chartRef = useRef(null);
  const [toggleChartView, setToggleChartView] = useState(true);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const { component_id, isCacheEnabled } = displayData;
  const { cachedData, setCacheData } = useContext(RoutesContext);

  const [chartType, setChartType] = useState();

  useEffect(() => {
    setChartType(displayData.echartType);
  }, [displayData.echartType]);

  useEffect(() => {
    if (!graphData) {
      return;
    }
    const myCharts = echarts.init(chartRef.current);

    const filteredData = graphData?.series_data?.filter(
      (item) => item[graphData.x_axis_category_key]
    );

    let uniqueDates = Array.from(
      new Set(filteredData?.map((item) => item[graphData.x_axis_category_key]))
    );

    let y_axis_value = filteredData?.map((item) => item.value);

    let pieData = uniqueDates?.map((item, index) => ({
      value: y_axis_value[index],
      name: item,
    }));

    let dataForRadar = uniqueDates?.map((item) => ({
      name: item,
      max: 10,
    }));

    let options = {};

    if (chartType?.value === "bar") {
      options = generateChartOptions({
        titleText: "Bar Chart",
        chartType: chartType,
        categories: uniqueDates,
        series: y_axis_value,
      });
    } else if (chartType?.value === "pie") {
      options = generateChartOptions({
        titleText: "Pie Chart",
        chartType: chartType,
        pieData: uniqueDates?.map((item, index) => ({
          value: y_axis_value[index],
          name: item,
        })),
      });
    } else if (chartType?.label === "Line") {
      options = generateChartOptions({
        titleText: "Line Chart",
        chartType: chartType,
        categories: uniqueDates,
        lineData: y_axis_value,
      });
    } else if (chartType?.label === "Smooth Line") {
      options = generateChartOptions({
        titleText: "Line Chart",
        chartType: chartType,
        categories: uniqueDates,
        lineData: y_axis_value,
      });
    } else if (chartType?.label === "Area Line") {
      options = generateChartOptions({
        titleText: "Line Chart",
        chartType: chartType,
        categories: uniqueDates,
        lineData: y_axis_value,
      });
    } else if (chartType?.value === "doughnut") {
      options = generateChartOptions({
        titleText: "Doughnut Chart",
        chartType: chartType,
        doughnutData: pieData,
      });
    }
    options = {
      ...options,
      lazyUpdate: true,
    };

    myCharts.setOption(options);

    return () => {
      myCharts.dispose();
    };
  }, [chartType, graphData]);

  const abortController = new AbortController();
  const signal = abortController.signal;
  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setGraphData(cachedData?.[component_id]);
      return;
    }
    getGraphData();
    return () => {
      abortController.abort();
    };
  }, []);

  const getGraphData = async () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setGraphLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayData?.refValue;
          })?.[displayData.refValue];
          setGraphData(componentData?.value?.data);
          let data = cachedData;
          data[component_id] = componentData?.value?.data;
          setCacheData(data);
          setTempStorageCall(false);
        }
        clearFilterApply();
      })
      .catch((err) => {
        toast.error(err.message);
        setGraphLoading(false);
      })
      .finally(() => {
        setGraphLoading(false);
      });
  };

  return (
    <Col md={displayData.columenWidth} className="my-1 px-0">
      <div className={`h-100 position-relative  mx-1 main-content bg-white mb-2`}>
        <div className="py-1 px-1">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">
                  {displayData?.echartTitle}
                </h5>
              </div>
              <div className="d-flex align-items-center">
                {displayData?.transitionAppId &&
                displayData?.transitionPageId ? (
                  <div className="d-flex align-items-center transition-header-icon">
                    <Link
                      to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                    >
                      <button
                        className="header-icon-button icon-hover-effect"
                        title={displayData?.transitionActionTitle ?? ""}
                      >
                        <FontAwesomeIcon
                          icon={
                            displayData?.transitionSelectedIcon?.value ??
                            "external-link"
                          }
                          size="md"
                        />
                      </button>
                    </Link>
                  </div>
                ) : null}

                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={toggleChartView ? "Collapse Chart" : "Expand Chart"}
                  onClick={() => setToggleChartView(!toggleChartView)}
                  aria-controls="chart-toggle"
                  aria-expanded={toggleChartView}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      toggleChartView ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {graphLoading && <AppLoader />}
        <Collapse in={toggleChartView}>
          <div
            ref={chartRef}
            style={{
              height: "400px",
            }}
          />
        </Collapse>
      </div>
    </Col>
  );
};
export default BasicEcharts;
