import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  generateAppLink,
  generateAuthKey,
  getAllLinks,
  getRoleData,
  getUserEmails,
  updateLinkStatus,
} from "../../../services/appService";
import Loader from "../../dataset/Loader/Loader";
import { Form } from "react-bootstrap";
import { Refresh } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShareApp = () => {
  const user = "user";
  const role = "role";
  const [authKey, setAuthKey] = useState("");
  const [hearderKeys, setHearderKeys] = useState("");
  const [shareAppType, setshareAppType] = useState("");
  const [role_email, setrole_email] = useState("");
  const [tableShowType, settableShowType] = useState(user);
  const [emailList, setEmailList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [linksHistory, setLinksHistory] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isGeneratingLoading, setisGeneratingLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appName = queryParams.get("appName");
  let history = useHistory();
  let searchQuery = history?.location?.search;
  const app_id = new URLSearchParams(searchQuery).get("app_id");

  const generateAuth = () => {
    generateAuthKey()
      .then((response) => {
        if (response.success === true) {
          setAuthKey(response.api_key);
          setrole_email("");
          setshareAppType("");
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const getEmails = () => {
    getUserEmails()
      .then((response) => {
        if (response.success === true) {
          setEmailList(response.data);
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const generateLink = () => {
    let bodyObj = {
      api_key: authKey,
      app_id: "test",
      app_name: appName,
      select_type: shareAppType,
      app_id: app_id,
      logic_key: hearderKeys.split(","),
    };

    shareAppType === user
      ? (bodyObj["email"] = role_email)
      : (bodyObj[role] = role_email);

    setisGeneratingLoading(true);
    generateAppLink(bodyObj)
      .then((response) => {
        if (response.success === true) {
          getLinkHistory();
          settableShowType(shareAppType);
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setisLoading(false);
        setisGeneratingLoading(false);
      });
  };

  const handleCopy = (copy_text) => {
    navigator.clipboard.writeText(copy_text);
  };

  const changeLinkStatus = (historyofLinks) => {
    let bodyObj = {
      object_id: historyofLinks._id.$oid,
      status: historyofLinks.link_status === "active" ? "inactive" : "active",
    };
    setisLoading(true);
    updateLinkStatus(bodyObj).then((response) => {
      if (response.success === true) {
        getLinkHistory();
      }
    });
  };
  const refreshRole = (data) => {
    let bodyObj = {
      api_key: data.api_key,
      app_name: data.app_name,
      select_type: data.select_type,
      role: data.role,
      object_id: data._id.$oid,
      app_id: app_id,
    };
    setisLoading(true);
    generateAppLink(bodyObj)
      .then((response) => {
        setisLoading(false);
        if (response.success === true) {
          getLinkHistory();
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    getEmails();
    getLinkHistory();
    fetchRolesData();
  }, []);

  const fetchRolesData = () => {
    getRoleData()
      .then((response) => {
        if (response.success === true) {
          setRoleList(response.data);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const getLinkHistory = () => {
    setisLoading(true);
    getAllLinks(app_id)
      .then((response) => {
        if (response.success === true) {
          setLinksHistory(response.data);
        } else {
          setLinksHistory([]);
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const getRoleName = (role_id) => {
    let role_name = "";
    roleList.map((role) => {
      if (role._id.$oid === role_id) {
        role_name = role.role_name;
      }
    });
    return role_name;
  };

  return (
    <>
      <div className="d-help py-2">
        <h5>Share App</h5>
        <button
          type="button"
          className="btn btn-secondary btn-sm m-0"
          onClick={(e) => {
            e.preventDefault();
            history?.goBack();
          }}
        >
          <FontAwesomeIcon icon="fa fa-arrow-left" />
          <span className="ms-1">Back</span>
        </button>
      </div>

      <div className="border border-secondary border-opacity-25 p-3 bg-white">
        <div className="form-group row">
          <label className="col-form-label col-md-3 col-sm-12 required">
            Auth
          </label>
          <div className="col-md-9 col-sm-12">
            <div className="input-group m-0">
              <input
                type="text"
                className="form-control"
                placeholder="Auth Id"
                value={authKey}
                disabled
              />
              <button
                className="btn btn-secondary m-0"
                type="button"
                onClick={generateAuth}
              >
                Generate Auth
              </button>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-md-3 col-sm-12 required">
            Select Type
          </label>
          <div className="col-md-9 col-sm-12">
            <Form.Select
              name="select_type"
              onChange={(e) => {
                setshareAppType(e.target.value);
                setrole_email("");
              }}
              disabled={!authKey}
              value={shareAppType}
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={user}>User</option>
              <option value={role}>Role</option>
            </Form.Select>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-12 col-md-3 required">
            {shareAppType === user ? "User" : "Role"}
          </label>
          <div className="col-md-9 col-sm-12">
            <Form.Select
              onChange={(e) => {
                setrole_email(e.target.value);
              }}
              disabled={!authKey}
              value={role_email}
            >
              <option disabled value={""}>
                Select
              </option>
              {shareAppType === user &&
                emailList.map((userEmail) => {
                  return (
                    <option key={userEmail.id} value={userEmail.display}>
                      {userEmail.display}
                    </option>
                  );
                })}
              {shareAppType === role &&
                roleList.map((role) => {
                  return (
                    <option key={role.id} value={role._id.$oid}>
                      {role.role_name}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-md-3 col-sm-12">
            Parameter Keys
          </label>
          <div className="col-md-9 col-sm-12">
            <input
              disabled={!authKey}
              type="text"
              className="form-control"
              placeholder="key1,key2,..."
              name="privilegeName"
              value={hearderKeys}
              onChange={(e) => setHearderKeys(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-ms-12">
            <div className="text-end">
              <button
                disabled={
                  !authKey ||
                  !shareAppType ||
                  !role_email ||
                  isGeneratingLoading
                }
                className="btn btn-success btn-md m-0"
                type="button"
                onClick={() => generateLink()}
              >
                {isGeneratingLoading && (
                  <div className="spinner-border text-light"></div>
                )}&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-link" />
                <span className="ms-1">&nbsp;&nbsp;Generate Link&nbsp;&nbsp;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-help pt-3 pb-2">
        <h5>History</h5>
        <div>
          <div className="input-group input-group-sm mb-0">
            <label className="input-group-text" for="inputGroupSelect01">
              Type:
            </label>
            <Form.Select
              name="select_type"
              onChange={(e) => settableShowType(e.target.value)}
              value={tableShowType}
            >
              <option value={user}>User</option>
              <option value={role}>Role</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered mb-0">
          <thead className="head-row">
            <tr>
              {tableShowType === role && <th>Role</th>}
              <th>User</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              linksHistory
                .filter((link) => link?.select_type === tableShowType)
                .map((historyofLinks, index) => {
                  return (
                    <tr key={index}>
                      {tableShowType === role && (
                        <td>{getRoleName(historyofLinks.role)}</td>
                      )}
                      <td>
                        {tableShowType === role
                          ? historyofLinks.user_link.map((link, linkIndex) => {
                              return (
                                <p key={linkIndex}>
                                  <span>{link?.email}</span>
                                  <br></br>
                                </p>
                              );
                            })
                          : historyofLinks.email}
                      </td>
                      <td>
                        {tableShowType === role ? (
                          historyofLinks.user_link.map((link) => {
                            return (
                              <p>
                                <span>
                                  {link?.api_link?.substring(0, 50) + "..."}
                                </span>
                                <FontAwesomeIcon
                                  icon={"fa fa-copy"}
                                  className={`add_remove_size pointer ms-2`}
                                  onClick={() => handleCopy(link?.api_link)}
                                />
                                <br></br>
                              </p>
                            );
                          })
                        ) : (
                          <p>
                            <span>
                              {historyofLinks?.api_link?.substring(0, 50) +
                                "..."}
                            </span>
                            <FontAwesomeIcon
                              icon={"fa fa-copy"}
                              className={`add_remove_size pointer ms-2`}
                              onClick={() =>
                                handleCopy(historyofLinks?.api_link)
                              }
                            />
                          </p>
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          className={`toggle-link toggleTableBtnSize px-2 togglebtn pointer ${
                            historyofLinks.link_status === "active"
                              ? "text-success"
                              : "text-danger"
                          }`}
                          icon={
                            historyofLinks.link_status === "active"
                              ? "fa fa-toggle-on"
                              : "fa fa-toggle-off"
                          }
                          onClick={() => changeLinkStatus(historyofLinks)}
                        />
                        {tableShowType == role && (
                          <Refresh
                            className="mb-3"
                            role="button"
                            onClick={() => refreshRole(historyofLinks)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}

            {linksHistory.filter((link) => link?.select_type === tableShowType)
              .length === 0 && (
              <tr>
                <td colSpan={4} className="no_table_records">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isLoading && <Loader></Loader>}
      </div>
    </>
  );
};

export default ShareApp;
