import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getSingleProductDetails } from "../../../services/appService";
import ImageGallery from "react-image-gallery";
import { useHistory } from "react-router";
import Loader from "../../dataset/Loader/Loader";
import dummyImage from "../../../images/dummy-image.jpg";
import { Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSection from "../../../components/react_engine/CustomSection";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";

function Shareproduct(props) {
  const history = useHistory();
  const [productFields, setProductFields] = useState({});
  const [imageSlider, setImageSlider] = useState([]);
  const [loader, setLoader] = useState(true);
  const { product_id, tenant_Id } = useParams();
  const [show, setShow] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const [value, setValue] = useState(0);
  const [hidePriceInformation, setHidePriceInformation] = useState(true);

  useEffect(() => {
    callProductDetails();
  }, [history.location.pathname]);

  useEffect(() => {
    if (productFields?.product_images) {
      setImageSlider(
        productFields?.product_images?.map((images) => {
          return {
            original: images,
            thumbnail: images,
          };
        })
      );
    }
  }, [productFields, loader]);

  const callProductDetails = () => {
    let bodyData = {
      id: product_id,
      tenant_id: tenant_Id,
    };
    setLoader(true);
    getSingleProductDetails(bodyData)
      .then((response) => {
        if (response.data) {
          setHidePriceInformation(response?.hidePriceInformation === "yes");
          setProductFields(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className="w-100 sharedHeight"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="pb-2 p-0 bg-white product-view sharedHeight">
      {loader ? (
        <Loader />
      ) : (
        <div className="well">
          <div className="catergory-wrap-screen">
            {Object.keys(productFields).length ? (
              <>
                <div className="product-sub-head py-2">
                  <div className="d-help mb-3">
                    <h5 className="fw-bold mt-3">
                      {productFields?.product_name.length > 1
                        ? productFields?.product_name
                        : "No Description Available"}
                    </h5>
                    {productFields.get_quote ? (
                      <h5 className="fw-bold mt-3 product_price_head"></h5>
                    ) : (
                      <h5 className="fw-bold mt-3 product_price_head">
                        {!hidePriceInformation && (
                          <>
                            &#x20b9;&nbsp;
                            {productFields?.price?.toLocaleString("en-IN") ||
                              "0"}
                          </>
                        )}
                      </h5>
                    )}
                  </div>
                  {/* <h6 className="mt-2">
                    {productFields?.product_description.length > 1
                      ? productFields?.product_description
                      : "No Description Available"}
                  </h6> */}
                </div>
                <div className="product-data">
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-5 mb-4">
                          <div className="img-wrapper">
                            {imageSlider.length >= 1 ? (
                              <ImageGallery
                                items={imageSlider}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                onClick={() => {
                                  setShow(true);
                                }}
                              />
                            ) : (
                              <ImageGallery
                                items={[
                                  {
                                    original: dummyImage,
                                    thumbnail: dummyImage,
                                  },
                                ]}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                showThumbnails={false}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-7">
                          <div className="content-wrapper font-14 text-capitalize">
                            <div className="col-12">
                              <div className="row">
                                {/* <div className="col-md-6 col-6">
                                  <p className="mb-3">Product Name </p>
                                </div> */}
                                <div className="col-md-12 col-12">
                                  <p className="mb-3">
                                    <h5 className="text-dark fw-bold ">
                                      {productFields?.product_name.length > 1
                                        ? productFields?.product_name
                                        : "-"}
                                    </h5>
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                {/* <div className="col-md-6 col-6">
                                  <p className="mb-3">
                                    Product Short Description
                                  </p>
                                </div> */}
                                <div className="col-md-12 col-12">
                                  <p className="mb-3">
                                    <span className="text-dark fw-bold ">
                                      {productFields?.product_short_description
                                        .length > 1
                                        ? productFields?.product_short_description
                                        : "-"}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                {/* <div className="col-md-6 col-6">
                                  <p className="mb-3">Tags</p>
                                </div> */}
                                <div className="col-md-12 col-12">
                                  <p className="mb-3">
                                    <span className="text-dark fw-bold d-flex flex-wrap">
                                      {productFields.product_tag?.length > 0 ? (
                                        productFields.product_tag.map(
                                          (tags, index) => (
                                            <div
                                              className="custom-tag"
                                              key={index}
                                            >
                                              <FontAwesomeIcon
                                                icon={"fa fa-tag"}
                                                className="me-2"
                                              />
                                              <span>{tags.text}</span>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <div className="custom-tag">
                                          <FontAwesomeIcon
                                            icon={"fa fa-tag"}
                                            className="me-2"
                                          />
                                          <span>No Tags</span>
                                        </div>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-2 col-sm-12 mb-2">
                                  SKU ID
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  <span className="text-dark fw-bold text-break">
                                    {productFields?.sku_id.length >= 1
                                      ? productFields?.sku_id
                                      : "-"}
                                  </span>
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  In stock
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  <span className="text-dark fw-bold text-break">
                                    {productFields?.available_quantity.length >=
                                    1
                                      ? productFields?.available_quantity
                                      : "0"}
                                  </span>
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  Product Size
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  <span className="text-dark fw-bold text-break">
                                    {productFields?.size.length >= 1
                                      ? productFields?.size
                                      : "-"}
                                  </span>
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  Supplier
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  <span className="text-dark fw-bold text-break">
                                    {productFields?.supplier_name.length > 1
                                      ? productFields?.supplier_name
                                      : "-"}
                                  </span>
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  Brand
                                </div>
                                <div className="col-md-2 col-sm-12 mb-2">
                                  <span className="text-dark fw-bold text-break">
                                    {productFields?.brand_name.length > 1
                                      ? productFields?.brand_name
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <AppBar
                          position="static"
                          color="default"
                          className="shadow-none"
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Product More Details"
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                          >
                            <Tab label="Specification" {...a11yProps(0)} />
                            <Tab
                              label="Additional Information"
                              {...a11yProps(1)}
                            />
                            <Tab label="Brochures" {...a11yProps(2)} />
                          </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                          <CustomSection
                            htmlCode={
                              productFields?.product_description.length > 1
                                ? productFields?.product_description
                                : "No Specification Available"
                            }
                            columenWidth={12}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <CustomSection
                            htmlCode={
                              productFields?.additional_info?.length > 1
                                ? productFields?.additional_info
                                : "No Additional Information Available"
                            }
                            columenWidth={12}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          {productFields?.product_brochure ? (
                            productFields.product_brochure?.map(
                              (file_name, index) => {
                                let file = file_name?.split("/");
                                return (
                                  <div className="p-4 border">
                                    <a
                                      href={
                                        productFields?.product_brochure?.[index]
                                      }
                                      target="_blank"
                                    >
                                      <FontAwesomeIcon
                                        icon={"file-pdf"}
                                        className="view-icon"
                                      />
                                      {file[file?.length - 1]}
                                    </a>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <div className="p-4 text-center border">
                              No brochure found for this product!
                            </div>
                          )}
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <Modal
              show={show}
              onHide={handleClose}
              className="product-map-modal"
            >
              <Modal.Header className="border-0" closeButton></Modal.Header>
              <Modal.Body closeButton>
                <ImageGallery
                  items={imageSlider}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
}

export default Shareproduct;
