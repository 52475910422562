import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official/";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import ChartsConfiguration from "./ChartsConfiguration";
import { Col } from "react-bootstrap";
import highcharts3d from "highcharts/highcharts-3d";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "react-bootstrap/Collapse";
import "./Charts.scss";
import AppLoader from "../dataset/Loader/AppLoader";
import { reactAppExecutionVP } from "../../services/API";
import { toast } from "react-toastify";
import { RoutesContext } from "../../RoutesContext";

NoDataToDisplay(Highcharts);
highcharts3d(Highcharts);

const Charts = ({
  componentData,
  displayData,
  objData,
  appSessionId,
  filterApply,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [chartOptions, setChartOptions] = useState({
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: componentData?.y_axis_title ?? "",
      },
    },
    xAxis: {
      title: {
        text: componentData?.x_axis_title,
      },
      categories: componentData?.x_axis_categories ?? "",
    },
    lang: {
      noData: "No data to display",
    },
  });
  const [toggleChartView, setToggleChartView] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const { component_id, isCacheEnabled, autoReloadComponent, autoReloadDelay } = displayData;

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setGraphData(cachedData?.[component_id]);
      return;
    }
    getGraphData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (filterApply) {
      getGraphData();
    }
  }, [filterApply]);

  const getGraphData = async () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setGraphLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayData?.refValue;
          })?.[displayData.refValue];
          setGraphData(componentData?.value?.data);
          let data = cachedData;
          data[component_id] = componentData?.value?.data;
          setCacheData(data);
          setTempStorageCall(false);
        }
        clearFilterApply();
      })
      .catch((err) => {
        toast.error(err.message);
        setGraphLoading(false);
      })
      .finally(() => {
        setGraphLoading(false);
      });
  };

  useEffect(() => {
    updateChart();

    return () => {
      setChartOptions({});
    };
  }, []);

  useEffect(() => {
    setChartOptions({});
    updateChart();
  }, [graphData?.series]);

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(
        () => getGraphData(),
        autoReloadDelay
      );
      return () => clearInterval(intervalId);
    }
  }, []);

  const updateChart = () => {
    if (graphData?.series) {
      let chartOption = chartOptions;
      let reqSeries = JSON.parse(JSON.stringify(graphData?.series));
      let chartConfiguration = new ChartsConfiguration(
        graphData?.x_axis_categories,
        reqSeries
      );
      const { configuration, series } = chartConfiguration.get();

      chartOption = {
        ...chartOption,
        ...configuration,
        ...series,
        title: {
          text: "",
        },
        yAxis: {
          title: {
            text: graphData?.y_axis_title ?? "",
          },
        },
        xAxis: {
          title: {
            text: graphData?.x_axis_title,
          },
          categories: graphData?.x_axis_categories ?? "",
        },
      };
      setChartOptions(chartOption);
    }
  };

  return (
    <Col md={displayData.columenWidth} className="my-1 px-0">
      <div className={`h-100 position-relative  mx-1 main-content bg-white mb-2`}>
        <div className="py-1 px-1">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">{graphData?.chart_title}</h5>
              </div>
              <div className="d-flex align-items-center">
                {displayData?.transitionAppId &&
                displayData?.transitionPageId ? (
                  <div className="d-flex align-items-center transition-header-icon">
                    <Link
                      to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                    >
                      <button
                        className="header-icon-button icon-hover-effect"
                        title={displayData?.transitionActionTitle ?? ""}
                      >
                        <FontAwesomeIcon
                          icon={
                            displayData?.transitionSelectedIcon?.value ??
                            "external-link"
                          }
                          size="md"
                        />
                      </button>
                    </Link>
                  </div>
                ) : null}
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title="Refresh"
                  onClick={getGraphData}
                  aria-controls="chart-toggle"
                >
                  <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
                </button>
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={toggleChartView ? "Collapse Chart" : "Expand Chart"}
                  onClick={() => setToggleChartView(!toggleChartView)}
                  aria-controls="chart-toggle"
                  aria-expanded={toggleChartView}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      toggleChartView ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {graphLoading && <AppLoader />}
        <Collapse in={toggleChartView}>
          <div id="chart-toggle">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        </Collapse>
      </div>
    </Col>
  );
};

export default Charts;
