class ChartsConfigurations {
  constructor(xAxisValues, series) {
    const chartNameConfig = {
      Line: "line",
      Bar: "column",
      bar: "column",
      "3D Stacked Column": "threeDStackedColumn",
      "Stacked Column": "stackedColumn",
      "3D Column": "threeDColumn",
      Pie: "pie",
      "Donut Pie": "donutPie",
    };
    const s_copy = JSON.parse(JSON.stringify(series));
    this.series = s_copy.map((s) => {
      s.type = chartNameConfig[s.type] ?? s.type;
      return s;
    });

    this.xAxisValues = xAxisValues;
    this.stackedConfiguration = {
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
    this.threeDConfiguration = {
      chart: {
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          depth: 50,
          viewDistance: 25,
        },
      },
    };

    this.donutPieConfiguration = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
    };

    this.pieConfiguration = {
      chart: {
        type: "pie",
      },
    };
  }

  parseSeries = (series) => {
    return series.map((s) => {
      if (s.type.toLowerCase().includes("column")) {
        s.type = "column";
      }
      return s;
    });
  };

  getSeries = (chartName = "") => {
    if (chartName === "donutPie") {
      return {
        series: [
          {
            name: this.xAxisValues ? this.xAxisValues[0] : "",
            colorByPoint: true,
            data: this.parseSeries(this.series),
            size: "60%",
            innerSize: "40%",
          },
        ],
      };
    } else if (chartName === "pie") {
      return {
        series: [
          {
            name: this.xAxisValues ? this.xAxisValues[0] : "",
            colorByPoint: true,
            data: this.parseSeries(this.series),
          },
        ],
      };
    } else {
      return {
        series: this.parseSeries(this.series),
      };
    }
  };

  getConfiguration = (chartName = "") => {
    let lowercaseChartName = chartName.toLowerCase();
    let response = {};
    if (lowercaseChartName.includes("threed")) {
      response = {
        ...response,
        ...this.threeDConfiguration,
      };
    }

    if (lowercaseChartName.includes("stacked")) {
      response = {
        ...response,
        ...this.stackedConfiguration,
      };
    }

    if (chartName === "donutPie") {
      response = this.donutPieConfiguration;
    }

    if (chartName === "pie") {
      response = this.pieConfiguration;
    }

    return response;
  };

  get = () => {
    const confiurationNeededCharts = [
      "stackedColumn",
      "threeDStackedColumn",
      "threeDColumn",
      "donutPie",
      "pie",
    ];
    if (confiurationNeededCharts.includes(this.series[0].type)) {
      return {
        configuration: this.getConfiguration(this.series[0].type),
        series: this.getSeries(this.series[0].type),
      };
    }

    return {
      configuration: this.getConfiguration(),
      series: this.getSeries(),
    };
  };
}

export default ChartsConfigurations;
