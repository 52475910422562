import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const AddTags = ({ value = [], onChange }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(value);
  }, []);

  const handleNewField = (event) => {
    event.preventDefault();
    setData([...data, { name: "", color: "" }]);
    onChange(data);
  };

  const handleTagChange = (index, fieldName, value) => {
    const updatedTags = [...data];
    updatedTags[index][fieldName] = value;
    setData(updatedTags);
    onChange(data);
  };

  const handleTagDelete = (indexOfRow) => {
    let tagList = [...data];
    const filteredTags = tagList?.filter((data, index) => indexOfRow !== index);
    setData(filteredTags);
    onChange(filteredTags);
  };
  return (
    <div className="form-group row m-2">
      <div className="bg-white border p-2 mb-3">
        <div className="d-flex justify-content-between mb-2">
          <h5>Tags</h5>
          <button
            className="btn btn-primary btn-md me-0"
            onClick={(e) => handleNewField(e)}
          >
            Add Tags
          </button>
        </div>
        {data.length > 0 ? (
          data.map((data, index) => {
            return (
              <div className="input-group mb-3 d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Status value"
                  value={data.name}
                  onChange={(e) =>
                    handleTagChange(index, "name", e.target.value)
                  }
                  aria-describedby="basic-addon2"
                />
                <input
                  type="color"
                  className="input-group-text"
                  value={data.color}
                  onChange={(e) =>
                    handleTagChange(index, "color", e.target.value)
                  }
                  id="basic-addon2"
                ></input>
                <FontAwesomeIcon
                  icon={"fa fa-lg fa-minus"}
                  size="xl"
                  className="text-danger ms-lg-3 ms-md-3 ms-2 copy-div"
                  aria-hidden="true"
                  onClick={() => handleTagDelete(index)}
                />
              </div>
            );
          })
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            No tags created yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTags;
