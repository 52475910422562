import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "./Search.scss";

export class Seach extends Component {
  constructor(props) {
    super(props);
    this.callChildMethod = this.callChildMethod.bind(this);
    this.state = {
      dropdownDataString: ["contains"],
      dropdownDataNumber: ["greater than", "less than"],
      string: true,
      inputValue: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.clearValue !== nextProps.clearValue) {
      this.setState({ inputValue: "" });
    }
  }

  // send dropdown data to parent
  dropDownData = (event) => {
    let optionValue = event.target.value;
    this.props.optionData.changedChildOption(optionValue);
  };

  // send input data to parent
  getEnteredData = (event) => {
    this.setState(
      {
        inputValue: event.target.value,
      },
      () => {
        this.props.inpuData.changedChildValue(this.state.inputValue);
      }
    );
  };

  callChildMethod() {
    this.setState({
      inputValue: "",
    });
  }

  render() {
    if (this.props.datatype !== "number") {
      var optionData = this.state.dropdownDataString,
        OptionsArray = function (optionData, index) {
          return (
            <option key={index} value={optionData}>
              {optionData}
            </option>
          );
        };
    }
    if (this.props.datatype === "number") {
      var optionData = this.state.dropdownDataNumber,
        OptionsArray = function (optionData, index) {
          return (
            <option key={index} value={optionData}>
              {optionData}
            </option>
          );
        };
    }

    return (
      <div
        id="datatable_filter "
        className="dataTables_filter search-filter text-start dropdown"
      >
        <div className="show-options" aria-labelledby="dropdownMenu2">
          <div className="grid-options">
            <Form.Select onChange={this.dropDownData}>
              {optionData.map(OptionsArray)}
            </Form.Select>
          </div>
          <div className="grid-search">
            <input
              type="text"
              className="form-control input-sm"
              placeholder="Filter"
              aria-controls="datatable"
              onChange={(event) => this.getEnteredData(event)}
              value={this.state.inputValue}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Seach;
