import React from "react";
import "./schedulegrouplist.scss";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ScheduleGroupList = ({ componentData, displayField }) => {
  let dataList = componentData?.value || [];

  return (
    <Col md={12} className="pb-3">
      <div className="px-3">
        {dataList?.length ? (
          dataList.map((data) => {
            return (
              <ScheduleDay
                date={data._id}
                data={data}
                displayFieldData={displayField}
              />
            );
          })
        ) : (
          <p className="no_table_records">No Record Found</p>
        )}
      </div>
    </Col>
  );
};

export default ScheduleGroupList;

export const ScheduleDay = ({ date, data, displayFieldData }) => {
  const formatDate = (dateString) =>
    new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      month: "short",
      day: "numeric",
    }).format(new Date(dateString));
  return (
    <div className="mb-3">
      <h5 className="mt-0" style={{ fontFamily: "monospace" }}>
        {formatDate(date)}
      </h5>
      <ScheduleDaySingle
        singleData={data}
        displayFieldData={displayFieldData}
      />
    </div>
  );
};

export const ScheduleDaySingle = ({ singleData, displayFieldData }) => {
  let displayList = displayFieldData?.displayKeyList?.split(",");
  let keyList = displayFieldData?.fetchKeyList?.split(",");
  return (
    <>
      {singleData?.data?.map((data, index) => {
        return (
          <div className="singleSchedule mb-2" key={index}>
            <div className="userContainer">
              <div className="d-flex">
                <div className="userImageContainer">
                  {displayFieldData?.dafaultAvtar ? (
                    <img
                      src={displayFieldData?.dafaultAvtar}
                      alt="Image"
                      className="userImage"
                    />
                  ) : data?.[displayFieldData?.dafaultAvtarKey] ? (
                    <img
                      src={data?.[displayFieldData?.dafaultAvtarKey]}
                      alt="Image"
                      className="userImage"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={"circle-user"}
                      className="userImageDefault"
                    />
                  )}
                </div>
                <div className="userDataContainer">
                  <div className="userData">
                    <div className="userName">
                      <span> {data?.label} </span>
                    </div>
                    <div className="userDescription">
                      <span>{data?.description}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dataListContainer">
              <div className="dataList">
                {keyList?.map((key, index) => {
                  return (
                    <div className="seperateLeftRight">
                      <div className="leftText">
                        {displayList?.[index] || key}
                      </div>
                      <div className="rightText">{data?.[key] || ""}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            {displayFieldData?.displayTimeKey ? (
              <div className="timeContainer">
                <div className="time">
                  <div className="time1">
                    {data?.[displayFieldData?.displayTimeKey]}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
};
