import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HierarchyContext } from "./HierarchyTableContext";
import { v4 as uuId } from "uuid";
import { reactAppExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import RootHierarchyModal from "./RootHierarchyModal";
import HierarchyTableRow from "./HierarchyTableRow";
import { useParams } from "react-router-dom";
import { RoutesContext } from "../../../RoutesContext";
import TableHeaderButton from "../SubTable/TableHeaderButton";

const HierarchyTable = ({
  displayField,
  appSessionId,
  filterApply,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
  objData,
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [collapse, setCollapse] = useState(true);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);
  const [hierarchyModalObject, setHierarchyModalObject] = useState("");
  const [componentData, setComponentData] = useState({});
  const [componentDataLoading, setComponentDataLoading] = useState(false);
  const params = useParams();
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const { component_id, isCacheEnabled,autoReloadComponent,autoReloadDelay } = displayField;

  let fields = displayField?.page_field || [];
  let hierarchyColumnKey = displayField?.hierarchyColumnKey;

  let bodyObject = {
    data: {
      logic: [displayField?.componentLogic],
    },
    referenceValue: [{ refValue: displayField?.refValue }],
    app_session_id: appSessionId,
  };

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setComponentData(cachedData?.[component_id]);
      return;
    }
    getComponentData();
    return () => {
      abortController.abort();
    };
  }, [params?.flowId]);

  useEffect(() => {
    if (filterApply) {
      setComponentDataLoading(true);
      getComponentData();
    }
  }, [filterApply]);

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !=="") {
      const intervalId = setInterval(() => getComponentData(), autoReloadDelay);
      return () => clearInterval(intervalId);
    }
  }, []);

  const getComponentData = () => {
    setComponentDataLoading(true);
    reactAppExecutionVP(bodyObject, signal)
      .then((response) => {
        clearFilterApply();
        response?.data?.map((refData) => {
          if (refData[displayField?.refValue]?.value?._id?.$oid) {
            setComponentData(refData[displayField?.refValue]?.value);
            let data = cachedData;
            data[component_id] = refData[displayField?.refValue]?.value;
            setCacheData(data);
          } else {
            setComponentData({});
          }
          setTempStorageCall(false);
        });
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setComponentDataLoading(false));
  };

  const whenNoHierarchyInRoot = () => {
    if (componentData?.hierarchy_id) {
      setHierarchyModalObject({
        showModal: true,
        selectedHierarchy: componentData?.hierarchy_id,
        parentIds: [],
        modalState: "Add",
      });
    } else {
      let id = uuId();
      setHierarchyModalObject({
        showModal: true,
        selectedHierarchy: id,
        initialRootId: id,
        parentIds: [],
        modalState: "Add",
      });
    }
  };

  let executeAppObject = {
    data: {
      logic:
        objData?.logic?.length > 0
          ? objData?.logic
          : [displayField?.componentLogic],
    },
    referenceValue: [{ refValue: displayField?.refValue }],
    app_session_id: appSessionId,
  };

  return (
    <Col md={displayField?.columnWidthTable || "12"} className="my-1 px-0">
      <div className={`${collapse && "h-100"} mx-1 main-content bg-white mb-2`}>
        <div className="py-1 px-1">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">{displayField?.tableName}</h5>
              </div>
              <div className="d-flex align-items-center">
                {!displayField?.hideActions && (
                  <>
                    <TableHeaderButton
                      reloadComponentData={getComponentData}
                      executeAppObject={executeAppObject}
                      objData={objData}
                      formConfig={objData?.page?.form_config}
                      actionButtonList={displayField?.actionDetails}
                      appSessionId={appSessionId}
                      pageId={displayField?.formId}
                    />
                    <button
                      className="btn btn-md m-0"
                      title="Add New Sub-Root"
                      onClick={whenNoHierarchyInRoot}
                    >
                      <FontAwesomeIcon icon="plus" />
                      &nbsp;Add New
                    </button>
                  </>
                )}

                {displayField?.transitionAppId &&
                displayField?.transitionPageId ? (
                  <div className="d-flex align-items-center transition-header-icon">
                    <Link
                      to={`/app_ui/${displayField?.transitionAppId}/${displayField?.transitionPageId}`}
                    >
                      <button
                        className="header-icon-button icon-hover-effect"
                        title={displayField?.transitionActionTitle ?? ""}
                      >
                        <FontAwesomeIcon
                          icon={
                            displayField?.transitionSelectedIcon?.value ??
                            "external-link"
                          }
                          size="md"
                        />
                      </button>
                    </Link>
                  </div>
                ) : null}
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title="Refresh"
                  onClick={getComponentData}
                >
                  <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
                </button>
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={collapse ? "Collapse" : "Expand"}
                  onClick={() => setCollapse(!collapse)}
                  aria-controls="hierarchy-toggle"
                  aria-expanded="true"
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      collapse ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
          <Collapse in={collapse}>
            <div id="hierarchy-toggle">
              <div className="position-relative">
                <HierarchyContext.Provider
                  value={{
                    appSessionId,
                    reloadData: getComponentData,
                    showHierarchyModal,
                    setShowHierarchyModal,
                    hierarchyModalObject,
                    setHierarchyModalObject,
                    displayConfig: displayField,
                    componentDataLoading,
                  }}
                >
                  <div
                    className={`table-responsive ${
                      displayField?.columnHeight === "fixed" &&
                      "maxDisplayToggle"
                    } ${displayField?.tableTheme || ""}`}
                  >
                    <table className="w-100 table mb-0">
                      <thead>
                        <tr>
                          {fields?.map((field, index) => {
                            return (
                              <th
                                className={`text-nowrap ${
                                  field.name === hierarchyColumnKey
                                    ? "text-start"
                                    : "text-end"
                                }`}
                                key={index}
                              >
                                {field?.display_name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="tableNoRecord">
                        {componentData?.sub_hierarchy_childs?.map(
                          (data, index) => {
                            return (
                              <TableTD
                                data={data}
                                paddingPx={0}
                                key={index}
                                path={data?.[hierarchyColumnKey]}
                                parentIds={[data?.hierarchy_id]}
                              />
                            );
                          }
                        )}
                      </tbody>
                      {componentData?.sub_hierarchy_childs?.length >
                      0 ? null : (
                        <tbody className="tableNoData">
                          <tr>
                            <td className="tableNoRecord">
                              <div className="text-center">
                                {!componentDataLoading && "No data to display"}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {componentDataLoading ? (
                        <tbody className="tableDataLoader">
                          <tr className="hierarchy">
                            <td className="p-0">
                              <span>
                                <div className="spinner-border spinner-border-sm me-1"></div>
                                Loading...
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      ) : null}
                    </table>
                  </div>
                  <RootHierarchyModal />
                </HierarchyContext.Provider>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Col>
  );
};

export default HierarchyTable;

export const TableTD = ({ data, paddingPx, path, parentIds }) => {
  const [toggleRow, setToggleRow] = useState(false);
  let childList = data?.sub_hierarchy_childs?.length;
  let px = paddingPx;
  if (childList) {
    px = px + 20;
  }
  const { displayConfig } = useContext(HierarchyContext);
  return (
    <>
      <HierarchyTableRow
        key={paddingPx}
        childList={childList}
        setToggleRow={setToggleRow}
        toggleRow={toggleRow}
        paddingPx={paddingPx}
        rowData={data}
        path={path}
        parentIds={parentIds}
      />
      {toggleRow && childList > 0
        ? data?.sub_hierarchy_childs?.map((data, index) => {
            return (
              <TableTD
                data={data}
                key={index}
                paddingPx={px}
                parentIds={[...parentIds, ...[data?.hierarchy_id]]}
                path={path + "/" + data?.[displayConfig?.hierarchyColumnKey]}
              />
            );
          })
        : null}
    </>
  );
};
