import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const AutoCompleteSearch = ({ data, setCenter, setZoom ,autoFill}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);
    if (e.target.value.trim() !== "") {
      const regex = new RegExp(input, "i");
      const matches = data.filter((item) => regex.test(item.searchText));
      setSuggestions(matches);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (item) => {
    setInputValue(item.searchText);
    autoFill(item?.lat.toString()+','+item?.lng.toString(),17)
    setSuggestions([]);
  };

  const handleClearInput = () => {
    setInputValue("");
    setSuggestions([]);
    setZoom(13);
  };

  return (
    <div>
      <div className="autosearch-searchbox" > 
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type to search..."
          className="form-control border-0 "
        />
         {inputValue && (
          <span
            className="position-absolute top-50 end-0 translate-middle-y"
            style={{ cursor: "pointer", padding: "8px" }}
            onClick={handleClearInput}
          >
            <FontAwesomeIcon icon={'fas fa-times'} size="sm"  ></FontAwesomeIcon>
          </span>)}
      </div>
      <div className=" position-absolute rounded-2 z-1">
        {suggestions?.map((item,index) => (
          <div
            key={index}
            onClick={() => handleSelectSuggestion(item)}
            className="autosearch-suggestions"
          >
            <span className="me-2">
              <FontAwesomeIcon icon="fas fa-map-marker-alt " />
            </span>
            {item.searchText}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoCompleteSearch;
