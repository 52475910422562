import React, { useEffect, useState, useRef } from "react";
import "../TreeComponent/treeComponent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SubTreeComponent(props) {
  let initialObject = {
    hierarchy_id: "",
    hierarchy_name: "",
    parent_hierarchy_id: "",
    sub_hierarchy_childs: [],
  };
  const inputRef = useRef(null);
  const [isExpand, setIsExpand] = useState(false);
  const [addNewState, setAddNewState] = useState(false);
  const [hierarchyChanges, setHierarchyChanges] = useState(initialObject);

  const { _id, ...rootHierarchy } = props?.rootHierarchy;
  let subChildLength = rootHierarchy?.sub_hierarchy_childs?.length || 0;

  useEffect(() => {
    setHierarchyChanges(initialObject);
  }, []);

  useEffect(() => {
    if (Object.keys(props?.rootHierarchy).length > 0) {
      setHierarchyChanges(initialObject);
      setIsExpand(true);
    }
  }, [props?.rootHierarchy]);

  useEffect(() => {
    if (addNewState) {
      inputRef?.current?.focus();
      inputRef?.current?.select();
    }
  }, [addNewState]);

  const returnData = (rootdata) => {
    setIsExpand(!isExpand);

    if (!props?.isSubChildAdding) {
      props.setOnHierarchySelect(rootdata?.hierarchy_id);
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    if (hierarchyChanges?.hierarchy_name) {
      let obj = {
        ...hierarchyChanges,
        hierarchy_name: hierarchyChanges?.hierarchy_name.trim(),
        parent_hierarchy_id: props?.parentId,
        sub_hierarchy_childs: [],
        parent_hierarchy_order: [...props?.parentIdPath],
      };
      if (
        hierarchyChanges?.hierarchy_name?.trim() !==
        rootHierarchy?.hierarchy_name
      ) {
        props?.changedHierarchy(obj);
      }
      let changes = { ...hierarchyChanges };
      changes.hierarchy_name = "";
      setHierarchyChanges(changes);
    }
    if (addNewState) {
      setAddNewState(false);
    }
  };

  const addingNewData = (hierarchyData) => {
    setAddNewState(true);
    let changes = { ...hierarchyChanges };
    setHierarchyChanges(changes);
    props.setSelectedHierarchy(hierarchyData?.hierarchy_id);
  };

  const disableselect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <div className="rootHierarchy">
      <div
        className={`rowCategory ps-1 mb-0 text-nowrap ${
          rootHierarchy.hierarchy_id === props?.onHierarchySelect
            ? "selectedHierarchy"
            : ""
        }`}
      >
        <span className="d-flex justify-content-between">
          <span
            title={props?.pathName?.join("\\")}
            className="pointer flex-grow-1 d-flex align-items-center"
          >
            <span
              onClick={() => returnData(rootHierarchy)}
              onMouseDown={(e) => disableselect(e)}
              onSelect={(e) => disableselect(e)}
            >
              {subChildLength ? (
                <span
                  title={
                    subChildLength ? (isExpand ? "Collapse" : "Expand") : ""
                  }
                >
                  {isExpand ? (
                    <FontAwesomeIcon
                      icon={"fa fa-minus-square-o"}
                      className="me-1"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={"fa fa-plus-square-o"}
                      className="me-1"
                    />
                  )}
                </span>
              ) : null}
              <span>
                <FontAwesomeIcon
                  icon={subChildLength ? "folder-blank" : "file"}
                  className="hierarchyIconColor me-1"
                />
              </span>
            </span>

            {rootHierarchy?.hierarchy_id === props?.isSubChildAdding ? (
              <span className="spinner-border spinner-border-sm  btnLoader"></span>
            ) : (
              <span
                onClick={() => returnData(rootHierarchy)}
                onMouseDown={(e) => disableselect(e)}
                onSelect={(e) => disableselect(e)}
                className="w-100 flex-grow-1"
              >
                {rootHierarchy?.hierarchy_name}
                <span className="text-dark">
                  {subChildLength ? <> ({subChildLength})</> : null}
                </span>
              </span>
            )}
          </span>
          <span className="folderButtons">
            {props?.addEdit && !props?.isSubChildAdding ? (
              <span className="ms-3">
                <span
                  title="Add Category"
                  className="pointer me-2"
                  onClick={() => {
                    addingNewData(rootHierarchy);
                  }}
                >
                  <FontAwesomeIcon
                    icon={"plus"}
                    className="hierarchyEditIconColor"
                  />
                </span>
              </span>
            ) : null}
          </span>
        </span>
      </div>

      <div
        style={{
          display: subChildLength ? (isExpand ? "block" : "none") : "none",
        }}
        className="ms-2 openHierarchyLine"
      >
        {rootHierarchy?.sub_hierarchy_childs?.map((exp, index) => {
          return (
            <div className="text-nowarp" key={index}>
              <div className="d-flex">
                <span className="text-nowrap">--</span>
                <SubTreeComponent
                  addEdit={props?.addEdit}
                  rootHierarchy={exp}
                  selectedHierarchy={props?.selectedHierarchy}
                  setSelectedHierarchy={props?.setSelectedHierarchy}
                  changedHierarchy={props?.changedHierarchy}
                  isSubChildAdding={props?.isSubChildAdding}
                  setOnHierarchySelect={props?.setOnHierarchySelect}
                  onHierarchySelect={props?.onHierarchySelect}
                  rootWithChildPath={props?.rootWithChildPath}
                  parentId={exp?.hierarchy_id}
                  parentIdPath={[
                    ...props?.parentIdPath,
                    ...[exp?.hierarchy_id],
                  ]}
                />
              </div>
            </div>
          );
        })}
      </div>
      {addNewState && (
        <div className="ms-2 openHierarchyLine">
          <span className="text-nowrap">--</span>
          <FontAwesomeIcon icon="file" className="hierarchyIconColor ps-1" />
          <form className="d-inline-flex ps-1" onSubmit={submitData}>
            <input
              required
              ref={inputRef}
              onBlur={() => {
                if (addNewState) {
                  setAddNewState(false);
                }
              }}
              placeholder="Category Name"
              type="text"
              className="addNewHierarchyTextBox"
              value={hierarchyChanges?.hierarchy_name || ""}
              onChange={(e) => {
                let changes = { ...hierarchyChanges };
                changes.hierarchy_name = e.target.value;
                setHierarchyChanges(changes);
              }}
            />
          </form>
        </div>
      )}
    </div>
  );
}

export default SubTreeComponent;
