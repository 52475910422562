import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./SortData.scss";
import {
  getMetaData,
  saveMetaData,
  deleteMetaDataField,
} from "../../../../services/datasetService";
import { getRecordData } from "../../../../services/tableConfigService";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import AddDetails from "../add_details/AddDetails";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class SortData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalSpaceCharacters: [],
      datasetId: "",
      showModal: false,
      demoData: "",
      sendDataToModal: {},
      changeDataFlag: false,
      language: "",
      appdata: [],
      isLoading: true,
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
    };
    this.onOpenModal = this.onOpenModal.bind(this);
  }

  handleShow = (data, indexValue) => {
    this.setState({
      showModal: true,
      sendDataToModal: data,
      sendIndexValue: indexValue,
    });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  componentWillMount() {
    this.props.onRef(this);
    if (this.props.match.path === "/app_page/page_editor/:id") {
      this.loadAppPageFields();
    } else {
      this.onloadGetData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.sendFieldArray !== nextProps.sendFieldArray &&
      this.props.sendFieldArray !== undefined
    ) {
      this.state.appdata.push(this.props.sendFieldArray[0]);
    }
    if (this.props.sendFieldsData) {
      if (
        this.props.sendFieldsData !== nextProps.sendFieldsData &&
        nextProps.sendFieldsData !== undefined
      ) {
        this.setState({
          appdata: nextProps.sendFieldsData,
        });
      }
    }
    if (this.props.datasetFields !== nextProps.datasetFields) {
      this.pushDatasetFields(this.props.datasetFields, nextProps.datasetFields);
    }
  }

  pushDatasetFields(oldDatasets, newDatasets) {
    if (oldDatasets.length === 0) {
      this.setState({ appdata: [...this.state.appdata, ...newDatasets] });
    } else {
      const uniqueLeft = this.state.appdata.filter(
        (item) => !oldDatasets.includes(item)
      );
      this.setState({ appdata: [...uniqueLeft, ...newDatasets] });
    }
  }

  onOpenModal() {
    this.setState({ open: this.props.openModal });
  }

  handleLanguage = (langValue, index) => {
    if (
      this.props.match.path === "/app_page/page_editor/:id" ||
      this.props.match.path === "/custom-attribute-fields/:storeId/:attributeId"
    ) {
      this.state.appdata[index] = langValue;
    } else {
      this.state.finalSpaceCharacters[index] = langValue;
    }
    this.saveData();
    this.handleClose();
  };

  // on save of field call function for dataset editor

  onloadGetData() {
    let dataSetValue = this.props.match.params.id;
    if (dataSetValue === undefined) {
    } else {
      this.setState(
        {
          datasetId: dataSetValue,
        },
        () => {
          let objData = {
            tenant_id: this.state.tenant_Id,
            dataset_id: this.state.datasetId,
          };
          if (dataSetValue !== undefined) {
            this.setState({ isLoading: true });
            if (
              this.props.match.path !==
              "/custom-attribute-fields/:storeId/:attributeId"
            ) {
              getMetaData(objData)
                .then((response) => {
                  if (response.success === true) {
                    let sortdableData = response.data.fields;
                    this.setState(
                      {
                        finalSpaceCharacters: sortdableData,
                        isLoading: false,
                      },
                      () => {
                        this.props.getSortedData(
                          this.state.finalSpaceCharacters
                        );
                        this.props.getdataSetObjectId(response.data.dataset_id);
                      }
                    );
                  } else {
                    toast.error(response.message);
                    this.setState({ isLoading: false });
                  }
                })
                .catch((error) => {
                  this.setState({ isLoading: false });
                });
            }
          }
        }
      );
    }
  }

  // onload for page editor

  loadAppPageFields() {
    let objFieldData = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "fc_app_pages",
      id: this.props.match.params.id,
    };
    getRecordData(objFieldData).then((response) => {
      if (response.success === true && response.data !== null) {
        this.props.getAppId(response.data.app_id);
        if (response.data.page_fields) {
          this.setState({
            appdata: response.data.page_fields,
          });
        }
      }
    });
  }

  deleteField(data) {
    if (window.confirm("Are you sure you want to remove the field?")) {
      let deleteObj = {
        tenant_id: this.state.tenant_Id,
        dataset_id: this.state.datasetId,
        field: data,
      };
      deleteMetaDataField(deleteObj).then((response) => {
        if (response.success === true) {
          this.onloadGetData();
          toast.success("Field Removed Successfully");
        }else {
            toast.error(response.message);
        }
      }).catch((error) => {
        toast.error(error.message);
      });
    }
  }

  // remove field from app_page
  removeFieldAppPage(data, index) {
    if (window.confirm("Are you sure you want to remove the field?")) {
      const objectIndex = this.state.appdata
      .map((element) => {
        return element.name;
      })
      .indexOf(data.name);

      if (objectIndex > -1) {
        this.setState((prevState) => ({
          appdata: [
            ...prevState.appdata.slice(0, objectIndex),
            ...prevState.appdata.slice(objectIndex + 1),
          ],
        }));
      }
    }
  }

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(this.state.finalSpaceCharacters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState(
      {
        finalSpaceCharacters: items,
      },
      () => {
        this.saveData();
        toast.success("Field Sorted Successfully");
      }
    );
  };

  handleOnDragEndAddPage = (result) => {
    if (!result.destination) return;
    const items = Array.from(this.state.appdata);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({
      appdata: items,
    });
  };

  saveData() {
    let sendData = {
      tenant_id: this.state.tenant_Id,
      dataset_id: this.state.datasetId,
      fields: this.state.finalSpaceCharacters,
    };
    if (
      this.props.match.path !== "/app_page/page_editor/:id" &&
      this.props.match.path !== "/custom-attribute-fields/:storeId/:attributeId"
    ) {
      saveMetaData(sendData).then((response) => {
        if (response.success === true) {
          this.onloadGetData();
        }else{
          toast.error(response.message);
        }
      }).catch((error) => {
        toast.error(error.message);
      });
    }
  }

  // send data of app page to data structure
  sendAppDataToParent() {
    let sortedAppFields = Array.from(this.state.appdata);
    this.setState(
      {
        appdata: sortedAppFields,
      },
      () => {
        this.props.sendAppData(this.state.appdata);
      }
    );
  }

  render() {
    return (
      <>
        {this.state.finalSpaceCharacters?.length >= 1 ? (
          <div className="bg-white border mb-2">
            <div className="clearfix m-2 py-2">
              <h5 className="mb-0">Fields</h5>
            </div>
            <div className="sort_app m-2">
              <DragDropContext onDragEnd={this.handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <ul
                      className="characters mb-0"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.state.isLoading ? (
                        <div className="text-center">
                          <div className="spinner-border"></div>
                        </div>
                      ) : (
                        <>
                          {this.state.finalSpaceCharacters.map(
                            (data, index) => {
                              return (
                                <Draggable
                                  key={data.name}
                                  draggableId={data.name}
                                  index={index}
                                >
                                  {(provided) => (
                                    <li
                                      className="list"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <p className="m-0">{data.display_name}</p>
                                      <div className="m-0 d-help">
                                        <FontAwesomeIcon
                                          icon={"fa fa-bars"}
                                          aria-hidden="true"
                                        />
                                        <FontAwesomeIcon
                                          icon={"fa fa-edit"}
                                          aria-hidden="true"
                                          onClick={() =>
                                            this.handleShow(data, index)
                                          }
                                          role="button"
                                          className="ms-2"
                                        />
                                        <FontAwesomeIcon
                                          icon={"fa fa-close"}
                                          color="red"
                                          aria-hidden="true"
                                          role="button"
                                          onClick={(e) =>
                                            this.deleteField(data)
                                          }
                                          className="ms-2"
                                        />
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                        </>
                      )}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <Modal
              show={this.state.showModal}
              onHide={this.handleClose}
              scrollable
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Field</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddDetails
                  sendData={this.state.sendDataToModal}
                  sendIndex={this.state.sendIndexValue}
                  changeDataFlag={(this.state.changeDataFlag = true)}
                  onSelectLanguage={this.handleLanguage}
                  sendFieldArray={
                    this.state.showModal ? this.props.sendFields : []
                  }
                />
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <>
            {this.state.appdata && this.state.appdata.length >= 1 ? (
              <div className="bg-white border mb-2">
                <div className="clearfix m-2 py-2">
                  <h5 className="mb-0">Fields</h5>
                </div>
                <div className="sort_app m-2">
                  <DragDropContext onDragEnd={this.handleOnDragEndAddPage}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul
                          className="characters mb-0"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {this.state.appdata?.map((appdata, index) => {
                            return (
                              <Draggable
                                key={appdata.name}
                                draggableId={appdata.name}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    className="list"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <p className="m-0">
                                      {appdata.display_name}
                                    </p>
                                    <span className="m-0 d-flex align-items-center">
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={"fa fa-bars"}
                                        aria-hidden="true"
                                      />
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={"fa fa-edit"}
                                        className="ms-3"
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.handleShow(appdata, index)
                                        }
                                      />
                                      <div
                                        className="icon-circle ms-2"
                                        onClick={(e) =>
                                          this.removeFieldAppPage(
                                            appdata,
                                            index
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          role="button"
                                          icon={"fa fa-close"}
                                          className="text-danger"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </span>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <div>
                    <button type="button" className="btn btn-secondary btn-md btn-success" onClick={(event) => this.sendAppData()} >
                      Save
                    </button>
                  </div> */}
                </div>
                <Modal
                  show={this.state.showModal}
                  onHide={this.handleClose}
                  scrollable
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Fields</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <AddDetails
                      sendData={this.state.sendDataToModal}
                      sendIndex={this.state.sendIndexValue}
                      changeDataFlag={(this.state.changeDataFlag = true)}
                      onSelectLanguage={this.handleLanguage}
                      sendFieldArray={
                        this.state.showModal ? this.props.sendFields : []
                      }
                    />
                  </Modal.Body>
                </Modal>
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}

export default withRouter(SortData);
