import React, { useEffect, useState } from "react";

function CustomTime({ value = "", onChange, field, disabled = false }) {
  const nowButtonClick = () => {
    const current = new Date();
    let hour =
      current.getHours() < 10 ? "0" + current.getHours() : current.getHours();
    let minutes =
      current.getMinutes() < 10
        ? "0" + current.getMinutes()
        : current.getMinutes();
    if (!disabled) {
      onChange(hour + ":" + minutes);
    }
  };
  return (
    <div className="d-flex customTime fieldMinimumWidth fieldMaximumWidth">
      <input
        type="time"
        className="form-control flex-grow-1 form-control-sm"
        disabled={disabled}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <div className="nowButton my-auto">
        <button
          disabled={disabled}
          type="button"
          className="btn btn-dark me-0 ms-2 btn-md mb-0 "
          onClick={nowButtonClick}
        >
          Now
        </button>
      </div>
    </div>
  );
}

export default CustomTime;
