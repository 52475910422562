import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const CustomDate = ({ field, value = "", onChange, disabled = false }) => {
  const dateOnChange = (e) => {
    const changeValue = e.target.value;
    onChange(changeValue);
  };

  return (
    <div className="fieldMinimumWidth fieldMaximumWidth">
      <Form.Control
        disabled={disabled}
        className="form-control-sm"
        type="date"
        onChange={dateOnChange}
        value={value}
      />
    </div>
  );
};

export default CustomDate;
