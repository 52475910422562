import React, { useContext, useEffect, useState } from "react";
import { actionTypes, inputTypeKey } from "../../../common/model/Model";
import InLineEdit from "./InLineEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { executeAppForTempStorage } from "../../../../services/API";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import LinkingWrap from "./LinkingWrap";
import { TableRowContext } from "../../contexts/TableRowContext";
import { formatCurrency } from "../../../../services/utils";

const RowLineEdit = ({
  pageFieldsList,
  serviceData,
  openModelData,
  openCheckListModal,
  openInlineFormTableModal,
  appSessionId,
  privilegeButtons,
  onButtonActionClick,
  tableButtonLoading,
  actionColumnState,
  actionButtonsList,
  updateAction,
  viewAction,
  updateParentRowData,
  parentRowIndex,
}) => {
  const { fieldApiBackUpObject } = useContext(TableRowContext);
  const [rowData, setRowData] = useState({});
  const [rowEditState, setRowEditState] = useState(false);
  const localStorageUserInfo = JSON.parse(localStorage.getItem("user-info"));
  const history = useHistory();

  useEffect(() => {
    setRowData(serviceData);
  }, [serviceData]);

  const onLinkAction = (action, rowData) => {
    let objBody = {
      app_session_id: appSessionId,
      reference_name: action.referenceValue || "",
      data: [rowData],
    };
    if (action.referenceValue) {
      executeAppForTempStorage(objBody)
        .then((response) => {
          if (response) {
            let link;
            if (action?.transitionAppId && action?.transitionPageId) {
              link = `/app_ui/${action?.transitionAppId}/${action?.transitionPageId}`;
            } else {
              link = `/not-configured`;
            }
            history.push(link);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const showActionButton = (action, rowData) => {
    let showAction = false; 
    if(!action || !rowData){
      return showAction;
    }
    if(action.buttonTextKey && action.buttonTextKey.length > 0){
      if(rowData[action.buttonTextKey] && rowData[action.buttonTextKey].length > 0){
        showAction = true;
      }
    }else{
      showAction = true;
    }
    return showAction;
  }

  const getActionButtonText = (action, rowData) => {
    if(!action || !rowData){
      return "";
    }
    let actionText = action.actionName;
    if(action.buttonTextKey && action.buttonTextKey.length > 0){
      if(rowData[action.buttonTextKey] && rowData[action.buttonTextKey].length > 0){
        actionText = rowData[action.buttonTextKey];
      }
    }
    return actionText;
  }

  return (
    <>
      {pageFieldsList.map((headerField, i) => {
        if (
          headerField.data_type === "arr_obj" ||
          headerField.data_type === "lineItems"
        ) {
          return (
            <td key={i} className="cursor-pointer fw-bold">
              {serviceData?.[headerField.name]?.length ? (
                <div
                  title="Click For More Details"
                  role="button"
                  onClick={() => {
                    openModelData(headerField, serviceData?.[headerField.name]);
                  }}
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" />&nbsp;{serviceData?.[headerField.name]?.length} item/s
                </div>
              ) : (
                0
              )}
            </td>
          );
        } else if (headerField.data_type === inputTypeKey.checkList) {
          let allTrue = serviceData?.[headerField.name]?.filter(
            (item) => item.status === "Yes"
          );
          let allFalse = serviceData?.[headerField.name]?.filter(
            (item) => item.status === "No"
          );
          return (
            <td
              className="cursor-pointer fw-bold"
              key={i}
              onClick={() => {
                openCheckListModal(
                  headerField,
                  serviceData?.[headerField.name]
                );
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" />&nbsp;{serviceData?.[headerField.name]?.length} item/s
              <p>{allTrue.length} <FontAwesomeIcon icon="fa-regular fa-thumbs-up" />&nbsp;&nbsp;&nbsp;&nbsp;
              {allFalse.length} <FontAwesomeIcon icon="fa-regular fa-thumbs-down" /></p>
            </td>
          );
        } else if (headerField.data_type === inputTypeKey.inlineTableForm) {
          return (
            <td key={i} className="cursor-pointer fw-bold">
              {serviceData?.[headerField.name]?.length ? (
                <div
                  className="fw-bold"
                  title="Click For More Details"
                  role="button"
                  onClick={() => {
                    openInlineFormTableModal(
                      headerField,
                      serviceData?.[headerField.name]
                    );
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" />&nbsp;{serviceData?.[headerField.name]?.length} item/s 
                </div>
              ) : (
                0
              )}
            </td>
          );
        } else if (headerField.data_type === inputTypeKey.fileUpload) {
          return (
            <td key={i}>
              {serviceData?.[headerField.name]?.length > 0
                ? serviceData?.[headerField?.name]?.map((fileList, i) => {
                    return (
                      <p>
                        <a
                          href={fileList}
                          target="_blank"
                          download="true"
                          key={i}
                        >
                          <FontAwesomeIcon
                            icon={"fa fa-download"}
                            className="me-2"
                            aria-hidden="true"
                          />&nbsp;{fileList.split("/").pop()}
                        </a>
                      </p>
                    );
                  })
                : "-"}
            </td>
          );
        } else if (headerField.data_type === inputTypeKey.largeText) {
          return (
            <td key={i}>
              <LinkingWrap
                rowKeyData={serviceData}
                linkData={headerField}
                appSessionId={appSessionId}
              >
                {serviceData?.[headerField.name]?.length > 0 &&
                  serviceData?.[headerField.name]?.replace(/(<([^>]+)>)/gi, "")}
              </LinkingWrap>
            </td>
          );
        } else if (headerField.data_type === inputTypeKey.searchableDropdown) {
          const list = fieldApiBackUpObject[headerField.name] || [];
          let displayValue;
          if (
            serviceData[headerField.name] &&
            Array.isArray(serviceData[headerField.name])
          ) {
            if (headerField.key_to_send !== headerField.key_to_show) {
              displayValue = list
                .filter((item) =>
                  serviceData[headerField.name].includes(item.value)
                )
                .map((data) => data.label);
              displayValue = displayValue.join(", ");
            } else {
              displayValue = serviceData[headerField.name];
              displayValue = displayValue.join(", ");
            }
          } else {
            if (headerField.key_to_send !== headerField.key_to_show) {
              displayValue = list
                .filter((data) => data.value === serviceData[headerField.name])
                .map((data) => data.label);
            } else {
              displayValue = serviceData[headerField.name];
            }
          }

          return (
            <td key={i}>
              <LinkingWrap
                rowKeyData={serviceData}
                linkData={headerField}
                appSessionId={appSessionId}
              >
                {displayValue}
              </LinkingWrap>
            </td>
          );
        } else if (headerField.data_type === inputTypeKey.hyperlink) {
          return (
            <td key={i}>
              <a rel="noreferrer" target="_blank" href={serviceData?.[headerField.name]}>
                {serviceData?.[headerField.name]}
              </a>
            </td>
          );
        } else {
          return (
            <td key={i}>
              {headerField?.inline_edit_mode === "yes" ? (
                <InLineEdit
                  setRowData={(data) => {
                    setRowData(data);
                    updateParentRowData(parentRowIndex, data);
                  }}
                  rowData={rowData}
                  field={headerField}
                  appSessionId={appSessionId}
                  rowEditState={rowEditState}
                  setRowEditState={setRowEditState}
                  initialStateData={serviceData}
                />
              ) : (
                <LinkingWrap
                  rowKeyData={serviceData}
                  linkData={headerField}
                  appSessionId={appSessionId}
                >
                  {rowData?.[headerField.name]}
                </LinkingWrap>
              )}
            </td>
          );
        }
      })}
      {actionColumnState && (
        <td action="true" className="text-start">
          <span className="text-center m-0 app_action_button">
            {updateAction.updateOnTable === "Yes" &&
              (privilegeButtons?.includes(updateAction?.action_id) ||
                localStorageUserInfo.role_name.toLowerCase() === "admin") && (
                <span
                  value="Update"
                  className="update-view-button mb-0"
                  onClick={() => onButtonActionClick(updateAction, rowData)}
                  title="Edit"
                >
                  <FontAwesomeIcon icon={"fa fa-edit"} />
                </span>
              )}
            {viewAction.viewOnTable === "Yes" &&
              (privilegeButtons?.includes(viewAction?.action_id) ||
                localStorageUserInfo.role_name.toLowerCase() === "admin") && (
                <span
                  value="View"
                  className="update-view-button mb-0"
                  onClick={() => onButtonActionClick(viewAction, serviceData)}
                  title="View"
                >
                  <FontAwesomeIcon icon={"fa fa-eye"} />
                </span>
              )}
            {actionButtonsList?.map((action, index) => {
              if (action?.actionType === actionTypes.timeLineView) {
                return (
                  <span
                    key={index}
                    value="TimelineView"
                    className="update-view-button mb-0"
                    onClick={() => onButtonActionClick(action, rowData)}
                    title="Time Line View"
                  >
                    <FontAwesomeIcon icon={"fa fa-timeline"} />
                  </span>
                );
              } else if (action?.actionType === actionTypes.link) {
                return (
                  <button
                    actionbutton="true"
                    key={index}
                    onClick={() => onLinkAction(action, rowData)}
                    style={{
                      backgroundColor: action.buttonColor,
                    }}
                    className={`btn text-white btn-sm me-2 mb-0`}
                  >
                    {action?.buttonIcon?.value && (
                      <FontAwesomeIcon
                        className="me-1 my-1"
                        icon={action?.buttonIcon?.value}
                      />
                    )}
                    {action.actionName}
                  </button>
                );
              } else if (
                (action?.actionType === actionTypes.popUp ||
                action?.actionType === undefined ) &&  showActionButton(action, rowData)
              ) {
                return (
                  <button
                    actionbutton="true"
                    key={index}
                    onClick={() => onButtonActionClick(action, rowData)}
                    style={{
                      backgroundColor: action.buttonColor,
                    }}
                    className={`btn text-white btn-sm me-2 mb-0`}
                  >
                    {action?.buttonIcon?.value && (
                      <FontAwesomeIcon
                        className="me-1 my-1"
                        icon={action?.buttonIcon?.value}
                      />
                    )}
                    {getActionButtonText(action, rowData)}
                  </button>
                );
              } else if (action?.actionType === actionTypes.button && showActionButton(action, rowData)) {
                return (
                  <button
                    actionbutton="true"
                    key={index}
                    onClick={() => {
                      if (!tableButtonLoading) {
                        onButtonActionClick(action, rowData, index);
                      }
                    }}
                    style={{
                      backgroundColor: action.buttonColor,
                    }}
                    className={`btn btn-sm me-2 mb-0 text-white`}
                  >
                    <span className="d-flex justify-content-center align-items-center">
                      {tableButtonLoading ===
                        JSON.stringify(rowData) + action.action_id &&
                      action.action_id ? (
                        <div className="spinner-border spinner-border-sm me-1" />
                      ) : (
                        action?.buttonIcon?.value && (
                          <FontAwesomeIcon
                            className="me-1 my-1"
                            icon={action?.buttonIcon?.value}
                          />
                        )
                      )}
                      {getActionButtonText(action, rowData)}
                    </span>
                  </button>
                );
              }
            })}
          </span>
        </td>
      )}
    </>
  );
};

export default RowLineEdit;
