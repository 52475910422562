import React, { Component } from "react";
import Loader from "../../dataset/Loader/Loader";
import { getStoreAttributes } from "../../../services/appService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export class AttributeListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: [
        "Srno",
        "Attribute Name",
        "Attribute Description",
        "Actions",
      ],
      tableData: [],
      storeId: this.props.match.params.store_id,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchAttributeSetListDetails();
  }

  fetchAttributeSetListDetails() {
    this.setState({
      loading: true,
    });
    let bodyObj = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: this.state.storeId,
          filter_field: "store_id",
        },
      ],
      export: true,
    };
    getStoreAttributes(bodyObj)
      .then((response) => {
        this.setState(
          {
            loading: false,
          },
          () => {
            if (response.data.length) {
              this.setState({
                tableData: response.data,
              });
            }
          }
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  addNewAttributeSet() {
    this.props.history.push(`/add-attibuteSet/${this.state.storeId}`);
  }

  routeToEditPage(attributeId) {
    this.props.history.push(
      `/edit-attibuteSet/${this.state.storeId}/${attributeId}`
    );
  }

  // back button
  backToCategoryListings() {
    this.props.history.push(`/store/${this.state.storeId}`);
  }

  // backtoStore
  backToStore() {
    this.props.history.push(`/stores`);
  }

  render() {
    // rendered table heading data
    const renderedHeaderData = this.state.headerData.map((header, index) => (
      <th className="table_head" key={index}>
        {header}
      </th>
    ));

    // rendered table body data
    const renderedTableData = this.state.tableData?.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{data.attribute_name}</td>
        <td>{data.attribute_description}</td>
        <td onClick={() => this.routeToEditPage(data._id.$oid)}>
          <FontAwesomeIcon icon="fa fa-edit" className=" pointer font-14" />
        </td>
      </tr>
    ));
    return this.state.loading ? (
      <Loader></Loader>
    ) : (
      <div className={`col-md-12 pb-4 catlog-main`}>
        <div className="button-wrapper w-100 d-help mt-2 flex-wrap py-2 responsive-heading">
          <div className="d-help flex-wrap">
            <div className="bread-crum d-help me-2">
              <p
                className="m-0 me-2 text-underline font-13 "
                onClick={() => this.backToStore()}
              >
                All Stores
              </p>
              <span>/</span>
              <p
                className="m-0 ms-2 me-2 text-underline font-13"
                onClick={() => this.backToCategoryListings()}
              >
                Store
              </p>
              <span>/</span>
              <h6 className="m-0 ms-2 font-13">Attributes</h6>
            </div>
          </div>
          <div className="d-help">
            <button
              type="button"
              className="btn btn-md btn-success m-0 me-2"
              onClick={() => this.addNewAttributeSet()}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" />
              <span className="ms-1">Add Attribute Set</span>
            </button>
            <button
              type="button"
              className="btn btn-md btn-secondary m-0"
              onClick={() => this.props.history.goBack()}
            >
              <FontAwesomeIcon icon="fa fa-arrow-left" />
              <span className="ms-1">Back</span>
            </button>
          </div>
        </div>
        <div className="main-catlog mt-3">
          <div className="store-list col-md-12">
            <div className="table-responsive table-hover table-bordered">
              <table className="table bulk_action sm-tableLayoutInitial dataTable no-footer w-100">
                <thead>
                  <tr className="head-row">{renderedHeaderData}</tr>
                </thead>
                <tbody>{renderedTableData}</tbody>
              </table>
              {this.state.tableData?.length ? null : (
                <p className="no_table_records">No Record Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AttributeListing;
