import React, { useEffect, useState } from "react";
import CustomizedProduct from "./add_subCategory/CustomizedProduct";
import ProductImages from "./add_subCategory/ProductImages";
import StandardProduct from "./add_subCategory/StandardProduct";
import ProductSpecification from "./add_subCategory/ProductSpecification";
import "./ProductListing.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AddUpdateProductListing(props) {
  const [displayedComponent, openCurrentStep] = useState("standart-product");
  const [objId, setObjId] = useState("");
  const [productName, setProductName] = useState("");
  const [prodCatListType, setProdCatListType] = useState("list_type");
  const storeId = props.match.params.storeId;
  const categoryId = props.match.params.category_id;
  let history = useHistory();

  useEffect(() => {
    setProdCatListType(props?.location?.search?.split("=")[1]);
    if (props.match.params.product_id) {
      setObjId(props.match.params.product_id);
    }
  }, []);

  // back to page
  const backToPage = () => {
    history.push(`/product_list/${storeId}/${categoryId}`);
  };

  // return object/product id from standard product
  const objectIdFromStandardProduct = (objectId) => {
    setObjId(objectId);
    openCurrentStep("customized-product");
  };

  // switch to images tab
  const changeTabToProductImages = (value) => {
    openCurrentStep(value);
  };

  // fetch product name
  const receiveProductName = (productName) => {
    setProductName(productName);
  };

  return (
    <div className="catlog-main catlog-product-view">
      <div className="button-wrapper w-100">
        <div className="d-help py-2">
          <h5>{productName}</h5>
          <div>
            <button className="btn btn-md btn-secondary" onClick={backToPage}>
              <FontAwesomeIcon icon="fa fa-arrow-left" className="me-1" />
              Back
            </button>
          </div>
        </div>
        <div className="mt-2 w-100 ">
          <div className="md-stepper-horizontal">
            <div className="md-step active">
              <div
                className={`md-step-circle ${
                  displayedComponent === "standart-product"
                    ? "circle-active"
                    : null
                }`}
                onClick={() => openCurrentStep("standart-product")}
              >
                <span>1</span>
              </div>
              <div
                className="md-step-title"
                onClick={() => openCurrentStep("standart-product")}
              >
                Product
              </div>
              <div className="md-step-bar-right"></div>
            </div>
            <div className="md-step active">
              <div
                className={`md-step-circle ${
                  displayedComponent === "customized-product"
                    ? "circle-active"
                    : null
                }`}
                onClick={() =>
                  objId.length ? openCurrentStep("customized-product") : null
                }
              >
                <span>2</span>
              </div>
              <div
                className="md-step-title"
                onClick={() =>
                  objId.length ? openCurrentStep("customized-product") : null
                }
              >
                Attributes
              </div>
              <div className="md-step-bar-right"></div>
            </div>
            <div className="md-step active">
              <div
                className={`md-step-circle ${
                  displayedComponent === "images-videos"
                    ? "circle-active"
                    : null
                }`}
                onClick={() =>
                  objId.length ? openCurrentStep("images-videos") : null
                }
              >
                <span>3</span>
              </div>
              <div
                className="md-step-title"
                onClick={() =>
                  objId.length ? openCurrentStep("images-videos") : null
                }
              >
                Images
              </div>
              <div className="md-step-bar-right"></div>
            </div>
            <div className="md-step active">
              <div
                className={`md-step-circle ${
                  displayedComponent === "product-specification"
                    ? "circle-active"
                    : null
                }`}
                onClick={() =>
                  objId.length ? openCurrentStep("product-specification") : null
                }
              >
                <span>4</span>
              </div>
              <div
                className="md-step-title"
                onClick={() =>
                  objId.length ? openCurrentStep("product-specification") : null
                }
              >
                Misc
              </div>
              <div className="md-step-bar-right"></div>
            </div>
          </div>
        </div>
        <div className="border p-2 bg-white">
          {displayedComponent === "standart-product" ? (
            <StandardProduct
              storeId={storeId}
              sendProductId={objId}
              returnObjectId={objectIdFromStandardProduct}
              categoryId={categoryId}
              receiveProductName={receiveProductName}
            ></StandardProduct>
          ) : null}
          {displayedComponent === "customized-product" ? (
            <CustomizedProduct
              storeId={storeId}
              categoryId={categoryId}
              sendObjectId={objId}
              returnToProductImages={changeTabToProductImages}
            ></CustomizedProduct>
          ) : null}
          {displayedComponent === "images-videos" ? (
            <ProductImages
              storeId={storeId}
              sendObjectId={objId}
              returnToProductImages={changeTabToProductImages}
            ></ProductImages>
          ) : null}
          {displayedComponent === "product-specification" ? (
            <ProductSpecification
              storeId={storeId}
              sendObjectId={objId}
              prodCatListType={prodCatListType}
            ></ProductSpecification>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default AddUpdateProductListing;
