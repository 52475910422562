import React, { Component } from "react";
import { toast } from "react-toastify";
import { getTenantDetail, newLogin } from "../../services/appService";
import "./Login.scss";
import fcLogo from "../../images/FLashLogo.png";
import backgroungImg from "../../images/bg_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {checkImageExists, getIconImage} from "../../services/utils";
export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        email: "",
        password: "",
      },
      errors: {},
      errorMessage: "",
      hidden: true,
      forgotPassword: false,
      orgName: "",
      tenantName: "",
      bannerImage: "",
      logoImage: "",
      isLoading: true,
      isResponseLoading: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }
  
  componentDidMount() {
    let query = this.props.location.search;
    let name = "";
    if (query !== "") {
      name = new URLSearchParams(query).get("tenant_name")
        ? new URLSearchParams(query).get("tenant_name")
        : new URLSearchParams(query).get("org_name")
        ? new URLSearchParams(query).get("org_name")
        : "";
    }
    if (name !== "") {
      this.getDetailsOfTanantRecord(name);
      this.setState({ tenantName: name });
    } else {
      this.setState({ tenantName: "", isLoading: false });
    }
    this.redirectLogin();
  }

  getDetailsOfTanantRecord = (tenant_Name) => {
    getTenantDetail(tenant_Name)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            logoImage: response.data[0]?.data_file,
            bannerImage: response.data[0]?.banner_data_file,
            isLoading: false,
            signupLink: response.data[0]?.registration_link,
          });
        } else if (response.success === false) {
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  redirectLogin() {
    const isAuthenticated = localStorage.getItem("user-info");
    if (isAuthenticated) {
      this.routeToDashboard();
    }
  }

  routeToDashboard() {
    this.props.history.push(`/app-dashboard`);
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }
  // validate form date
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter email.";
    }
    var passwordPattern = new RegExp("^[^\\s].+[^\\s]$");
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter password";
    } else if (!passwordPattern.test(fields["password"])) {
      formIsValid = false;
      errors["password"] =
        "*Password should not contain a space at the beginning or end.";
    }

    if (this.state.tenantName === "") {
      if (!this.state.orgName) {
        formIsValid = false;
        errors["orgName"] = "*Please enter ORG name";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  onForgetPasswordClick() {
    this.props.history.push(
      `/forgot_password?tenant_name=${
        this.state.tenantName || this.state.orgName
      }`
    );
  }

  checkSignupLinkAvailable() {
    return (this.state.signupLink && this.state.signupLink.length > 0);
  }

  orgNameChange(e) {
    this.setState({ orgName: e.target.value });
  }

  submitLogin(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      const formData = new FormData();
      formData.append("email", this.state.field.email);
      formData.append("password", this.state.field.password);
      formData.append(
        "org_name",
        this.state.tenantName === ""
          ? this.state.orgName
          : this.state.tenantName
      );
      this.setState({ isResponseLoading: true });
      newLogin(formData)
        .then((response) => {
          if (response.success === true) {
            localStorage.setItem("user-info", JSON.stringify(response.data));
            localStorage.setItem(
              "tenantId",
              JSON.stringify(response.data.tenant_id)
            );
            localStorage.setItem(
              "userid",
              JSON.stringify(response.data.user_id)
            );
            localStorage.setItem(
              "query_param",
              JSON.stringify(
                this.state.tenantName === ""
                  ? this.state.orgName
                  : this.state.tenantName
              )
            );
            localStorage.setItem(
              "role",
              JSON.stringify(response.data.role_name)
            );
            localStorage.setItem(
              "session_id",
              JSON.stringify(response.data.session_id)
            );
            localStorage.setItem(
              "logoImage",
              JSON.stringify(this.state.logoImage)
            );
            this.routeToDashboard();
          } else if (response.success === false) {
            toast.error("Invalid Credentials. Please try again.");
          }
        })
        .catch((err) => {
          if (err.response) {
            //toast.error(err.response.data.message);
            toast.error("Invalid Credentials. Please try again.");
          }
        })
        .finally(() => {
          this.setState({ isResponseLoading: false });
        });
    }
  }

  render() {
    return (
      <div className="login_main login-background">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-7 ps-4 banner">
              {this.state.isLoading ? (
                <div className="shimmer shimmerBanner"></div>
              ) : (
                <>
                  {checkImageExists(this.state.bannerImage) ? (
                    <img
                      src={getIconImage(this.state.bannerImage)}
                      alt="logo"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={backgroungImg}
                      alt="bg"
                      className="img-fluid"
                    ></img>
                  )}
                </>
              )}
            </div>
            <div className="col-md-5 right-side-col">
              <div className="login_main_content">
                <div className="logoContainer">
                  {this.state.isLoading ? (
                    <div className="shimmer shimmerLogo"></div>
                  ) : (
                    <>
                      {checkImageExists(this.state.logoImage) ? (
                        <img
                          src={getIconImage(this.state.logoImage)}
                          alt="logo"
                          className="d-block"
                        />
                      ) : (
                        <img src={fcLogo} alt="logo" className="d-block" />
                      )}
                    </>
                  )}
                </div>
                <div className="x_panel border-0">
                  <div className="x_content">
                    <form className="form-horizontal form-label-left">
                      {this.state.tenantName === "" ? (
                        <div className="form-group row ">
                          <label className="control-label col-md-3 col-sm-3 ">
                            Org Name
                          </label>
                          <div className="col-md-9 col-sm-9 ">
                            <input
                              type="text"
                              autocomplete="on"
                              name="orgName"
                              className="form-control"
                              placeholder="Enter Organization Name"
                              value={this.state.orgName}
                              onChange={(e) => {
                                this.orgNameChange(e);
                              }}
                            />
                            <div className="errorMsg text-danger">
                              {this.state.errors.orgName}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group row ">
                        <label className="control-label col-md-3 col-sm-3 ">
                          Username
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <div className="input-group">
                            <input
                              type="email"
                              autocomplete="on"
                              name="email"
                              className="form-control"
                              placeholder="Enter Username"
                              value={this.state.field.email}
                              onChange={(e) => {
                                this.changeValue(e);
                              }}
                            />
                          </div>
                          <div className="errorMsg text-danger">
                            {this.state.errors.email}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Password
                        </label>
                        <div className="col-md-9 col-sm-9">
                          <div className="input-group">
                            <input
                              name="password"
                              autocomplete="on"
                              type={this.state.hidden ? "password" : "text"}
                              className="form-control"
                              placeholder="Enter Password"
                              value={this.state.field.password}
                              onChange={(e) => {
                                this.changeValue(e);
                              }}
                            />
                            <span className="input-group-text border-0 border-bottom bg-white">
                              <FontAwesomeIcon
                                role="button"
                                icon={
                                  this.state.hidden
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                                onClick={this.toggleShow}
                              />
                            </span>
                          </div>
                          <div className="errorMsg text-danger">
                            {this.state.errors.password}
                          </div>
                          <div
                            role="button"
                            className="text-end col-12"
                            onClick={() => this.onForgetPasswordClick()}
                          >
                            Forgot password ?
                          </div>
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg py-2 float-right"
                          onClick={(event) => this.submitLogin(event)}
                          disabled={this.state.isResponseLoading}
                        >
                          {this.state.isResponseLoading && (
                            <div className="spinner-border spinner-border-sm text-light me-1" />
                          )}
                          &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-right-to-bracket" />&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        {this.checkSignupLinkAvailable() ? (
                          <a role="button" href={this.state.signupLink} target="_blank" rel="noreferrer"
                          className="btn btn-light btn-lg py-2 float-right"
                        >
                          &nbsp;<FontAwesomeIcon icon="fa fa-solid fa-user-plus" />&nbsp;&nbsp;Sign Up&nbsp;
                        </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="footer_title">
                <p className="m-0">
                  Copyright &copy; {new Date().getFullYear()} Flashcorp.All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
