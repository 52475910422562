import React, { Component } from "react";
import * as API from "../../../../../../../services/API";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class AdvanceParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyValue: [],
      keyDetails: {},
      senData: [],
    };
  }

  componentDidMount() {
    this.getReddisKey();
  }

  getReddisKey() {
    API.getRedisKeys()
      .then((response) => {
        if (response.data.length) {
          if (this.props.value) {
            let localData = [];
            this.props.value.map((data) => {
              let obj = {
                keys: response.data,
                value: data,
              };
              localData.push(obj);
            });
            this.setState(
              {
                keyValue: localData,
                keyDetails: response.data,
              },
              () => {}
            );
          } else {
            let obj = {
              keys: response.data,
              value: "",
            };
            this.state.keyValue.push(obj);
            this.setState({
              keyValue: [obj],
              keyDetails: response.data,
            });
          }
        }
      })
      .catch((err) => {});
  }

  changeValueEmg(dropDownData, index) {
    let data = this.state.keyValue;
    data[index].value = dropDownData;
    this.setState(
      {
        keyValue: data,
      },
      () => {
        this.senDataToParent();
      }
    );
  }

  pushData() {
    let objData = {
      keys: this.state.keyDetails,
      value: "",
    };
    this.setState({ keyValue: [...this.state.keyValue, objData] }, () => {
      this.senDataToParent();
    });
  }
  popData(currentElement, index) {
    if (this.state.keyValue.length > 1) {
      const objectIndex = this.state.keyValue
        .map((element) => {
          return element;
        })
        .indexOf(currentElement);
      this.setState(
        (prevState) => ({
          keyValue: [
            ...prevState.keyValue.slice(0, objectIndex),
            ...prevState.keyValue.slice(objectIndex + 1),
          ],
        }),
        () => {
          this.senDataToParent();
        }
      );
    }
  }
  senDataToParent() {
    let obj = [];
    this.state.keyValue.forEach((data, index) => {
      obj.push(data.value);
    });
    this.props.onChange(this.props.keyName, obj);
  }
  render() {
    return (
      <div className="advance_node_height submenu">
        {this.state.keyValue.length >= 1
          ? this.state.keyValue.map((data, index) => (
              <div className="d-help mt-2" key={index}>
                <Form.Select
                  value={data.value}
                  name="role"
                  onChange={(e) => {
                    this.changeValueEmg(e.target.value, index);
                  }}
                >
                  <option value="">Please Select Value</option>
                  {data.keys.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </Form.Select>
                <FontAwesomeIcon
                  icon={"fa fa-plus"}
                  className="cursor-pointer ms-3 me-3"
                  onClick={(e) => {
                    this.pushData();
                  }}
                />
                <FontAwesomeIcon
                  icon={"fa fa-minus"}
                  className="cursor-pointer"
                  onClick={(e) => {
                    this.popData(data, index);
                  }}
                />
              </div>
            ))
          : null}
      </div>
    );
  }
}

export default AdvanceParameter;
