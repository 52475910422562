import React, { useEffect, useRef, useState } from "react";
import ToggleSwitch from "../../../togglebutton/ToggleButton";
import "./inLineEdit.scss";
import * as API from "../../../../services/API";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inputTypeKey } from "../../../common/model/Model";
import LinkingWrap from "./LinkingWrap";
import { Form } from "react-bootstrap";

const InLineEdit = ({
  field,
  rowData,
  setRowData,
  appSessionId,
  rowEditState,
  setRowEditState,
  initialStateData,
}) => {
  const [editState, setEditState] = useState(false);
  const [changeValue, setChangeValue] = useState(rowData?.[field?.name]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setChangeValue(rowData[field.name]);
    setEditState(false);
  }, [rowData[field.name]]);

  useEffect(() => {
    if (editState) {
      setChangeValue(rowData?.[field?.name]);
      inputRef?.current?.focus();
      if (field?.data_type === inputTypeKey.singleSelectStaticDropdownlist) {
        inputRef?.current?.click();
      } else {
        inputRef?.current?.select();
      }
    }
  }, [editState]);

  useEffect(() => {
    if (rowEditState) {
      setEditState(false);
    }
  }, [rowEditState]);

  const onSave = (value, logic, reference) => {
    let obj = { ...rowData, ...{ [field.name]: value } };
    submitData(obj, logic, reference);
  };

  const submitData = (updatedData, logic, reference) => {
    let data = {
      app_session_id: appSessionId,
      logic_name: logic,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      reference_name: reference,
      data: [updatedData],
    };

    if (reference && logic) {
      // Api Calling
      setIsLoading(true);
      setRowEditState(true);
      API.executeAppForActions(data)
        .then((response) => {
          toast.success(response.message);
          setRowData(updatedData);
          setEditState(false);
          setRowEditState(false);
        })
        .catch((err) => {
          setRowData(updatedData);
          setEditState(false);
          setRowEditState(false);
          toast.error(err?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setRowData(updatedData);
    }
  };

  const getInlineStyle = (name) => {
    const matchedTag = field?.tags?.find((data) => data.name === name);
    const textColor =
      field?.textColor === "Dark" ? "text-secondary" : "text-white";
    if (matchedTag) {
      return (
        <span
          className={textColor}
          style={{
            backgroundColor: matchedTag.color,
            textShadow: "1px 0px 2px #000000",
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          {name}
        </span>
      );
    } else {
      return name;
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (changeValue !== rowData?.[field?.name]) {
          onSave(
            changeValue,
            field?.inline_edit_logic,
            field?.inline_edit_reference
          );
        }
      }}
    >
      <div className="d-help inLineEdit">
        <span
          className="w-100"
          style={{ lineHeight: "2" }}
          onDoubleClick={() => setEditState(true)}
        >
          {editState ? (
            ([
              inputTypeKey.numberTextBox,
              inputTypeKey.textBox,
              inputTypeKey.time,
              inputTypeKey.date,
            ].includes(field.data_type) && (
              <InputTypesEdit
                field={field}
                setChangeValue={setChangeValue}
                changeValue={changeValue}
                setRowData={setRowData}
                rowData={rowData}
                initialStateData={initialStateData}
                setEditState={setEditState}
                inputRef={inputRef}
                onSave={onSave}
              />
            )) ||
            ([inputTypeKey.singleSelectStaticDropdownlist].includes(
              field.data_type
            ) && (
              <SingleSelectStaticDropdownList
                field={field}
                setChangeValue={setChangeValue}
                changeValue={changeValue}
                setRowData={setRowData}
                rowData={rowData}
                initialStateData={initialStateData}
                setEditState={setEditState}
                inputRef={inputRef}
                onSave={onSave}
              />
            ))
          ) : (
            <LinkingWrap
              rowKeyData={rowData}
              linkData={field}
              appSessionId={appSessionId}
            >
              {changeValue &&
              (!isNaN(changeValue) || !changeValue?.includes("None"))
                ? getInlineStyle(changeValue)
                : "-"}
            </LinkingWrap>
          )}
        </span>
        <span className="d-flex">
          {rowData?.[field?.name] !== initialStateData?.[field?.name] &&
          !isLoading ? (
            <span className="editIcon ms-2">
              <FontAwesomeIcon
                icon="fa fa-times"
                aria-hidden="true"
                onClick={() => {
                  onSave(
                    initialStateData?.[field?.name],
                    field?.inline_edit_logic,
                    field?.inline_edit_reference
                  );
                  setEditState(false);
                }}
              />
            </span>
          ) : null}

          <span className={`${isLoading ? "" : "editIconContainer"}`}>
            {isLoading ? (
              <span className="ms-2">
                <div className="spinner-border spinner-border-sm text-dark btnLoader"></div>
              </span>
            ) : (
              changeValue === initialStateData?.[field?.name] && (
                <span
                  className="editIcon border p-1 ms-2 pointer"
                  onClick={() => {
                    setEditState(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa fa-pencil" aria-hidden="true" />
                </span>
              )
            )}
          </span>
        </span>

        {[inputTypeKey.toggleButton].includes(field.data_type) && (
          <CustomSection
            field={field}
            onChange={onSave}
            setRowData={setRowData}
            rowData={rowData}
            initialStateData={initialStateData}
          />
        )}
      </div>
    </form>
  );
};

export default InLineEdit;

export const CustomSection = ({
  field,
  onChange,
  setRowData,
  rowData,
  initialStateData,
}) => {
  const [changeValue, setChangeValue] = useState();

  useEffect(() => {
    let value;
    if (initialStateData?.[field?.name] === value) {
      if (field.data_type === inputTypeKey.toggleButton) {
        let obj = {
          ...rowData,
          ...{ [field.name]: field?.unchecked_text || false },
        };
        setChangeValue(field?.unchecked_text || false);
        setRowData(obj);
      } else {
        setChangeValue(rowData?.[field?.name]);
      }
    } else {
      setChangeValue(rowData?.[field?.name]);
    }
  }, [initialStateData]);

  let logic;
  let reference;
  if (field.data_type === inputTypeKey.toggleButton) {
    return (
      <ToggleSwitch
        checkedText={field?.checked_text}
        unCheckedText={field.unchecked_text}
        value={changeValue || false}
        onChange={(e) => {
          setChangeValue(e);
          if (e === field?.checked_text) {
            logic = field?.inline_edit_logic_checked;
            reference = field?.inline_edit_reference_checked;
          } else {
            logic = field?.inline_edit_logic_unChecked;
            reference = field?.inline_edit_reference_unChecked;
          }
          onChange(e, logic, reference);
        }}
      />
    );
  } else {
    return null;
  }
};

export const InputTypesEdit = ({
  field,
  setChangeValue,
  changeValue,
  rowData,
  setEditState,
  inputRef,
  onSave,
}) => {
  let dataType;
  if (field.data_type === inputTypeKey.numberTextBox) {
    dataType = "number";
  } else if (field.data_type === inputTypeKey.textBox) {
    dataType = "text";
  } else if (field.data_type === inputTypeKey.date) {
    dataType = "date";
  } else if (field.data_type === inputTypeKey.time) {
    dataType = "time";
  }
  return (
    <input
      ref={inputRef}
      type={dataType}
      className="w-100 form-control form-control-sm inLineMinWidth"
      value={changeValue}
      onChange={(e) => {
        let value;
        if (dataType === "number" && e.target.value) {
          value = Number(e.target.value);
        } else {
          value = e.target.value;
        }
        setChangeValue(value);
      }}
      onBlur={() => {
        if (changeValue !== rowData?.[field?.name]) {
          onSave(
            changeValue,
            field.inline_edit_logic,
            field.inline_edit_reference
          );
        } else {
          setEditState(false);
        }
      }}
    />
  );
};

export const SingleSelectStaticDropdownList = ({
  field,
  setChangeValue,
  changeValue,
  rowData,
  setEditState,
  inputRef,
  onSave ,
}) => {
  const onChangeHandler = (e) => {
    setChangeValue(e.target.value);
  };

  let optionList = field?.enter_values?.split(",") || [];

  return (
    <Form.Select
      ref={inputRef}
      size="sm"
      className="form-control"
      name="linkingType"
      value={changeValue}
      onChange={onChangeHandler}
      onBlur={() => {
        if (changeValue !== rowData?.[field?.name]) {
          onSave(
            changeValue,
            field.inline_edit_logic,
            field.inline_edit_reference
          );
        } else {
          setEditState(false);
        }
      }}
    >
      <option value="">--Select--</option>
      {optionList?.map((option, index) => {
        return (
          <option key={index} value={option}>
            {option}
          </option>
        );
      })}
    </Form.Select>
  );
};
