import React, { useEffect, useMemo, useState } from "react";
import ActionButtonList from "../../../react_engine/SubTable/ActionButtonList";
import { Form } from "react-bootstrap";
import RelationshipBuilder from "./RelationshipBuilder";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRecordDataById } from "../../../../services/tableConfigService";
import { autoReloadComponentDelaysList } from "../../../common/model/Model";

const DisplayBuilderTable = ({
  displayBuilder,
  columnWidthList,
  selectFormList,
  onChange,
  logicList,
  formsList,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  const [columnList, setColumnList] = useState([]);
  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  useEffect(() => {
    if (displayChanges.formId) {
      let objData = {
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        dataset_name: "fc_app_pages",
        id: displayChanges.formId,
      };
      getRecordDataById(objData).then((response) => {
        response.data?.page_fields?.map((optionData) =>
          setColumnList((columnList) => [
            ...columnList,
            {
              label: optionData.display_name,
              value: optionData.display_name,
            },
          ])
        );
      });
    }
  }, [displayChanges.formId]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const actionListChange = (actionList) => {
    if (actionList?.length) {
      const data = { ...displayChanges };
      data["actionDetails"] = actionList;
      setDisplayChanges(data);
    }
  };

  const relationsChange = (relations) => {
    if (relations?.length) {
      const data = { ...displayChanges };
      data["relations"] = relations;
      setDisplayChanges(data);
    }
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };
  const handleSummary = (e, name) => {
    let updatedConfiguration = displayChanges?.tableSummaryConfiguration;
    updatedConfiguration[name] = e;
    setDisplayChanges({
      ...displayChanges,
      tableSummaryConfiguration: updatedConfiguration,
    });
  };
  const handleSummaryConfiguration = (e, name, index) => {
    let updatedConfiguration = displayChanges?.tableSummaryConfiguration;
    updatedConfiguration.summaryFields[index][name] = e;
    setDisplayChanges({
      ...displayChanges,
      tableSummaryConfiguration: updatedConfiguration,
    });
  };

  const addSummaryConfiguration = () => {
    setDisplayChanges((prevDisplayChanges) => {
      const updatedTableSummaryConfiguration = {
        ...prevDisplayChanges.tableSummaryConfiguration,
      };
      if (!updatedTableSummaryConfiguration.summaryFields) {
        updatedTableSummaryConfiguration.summaryFields = [];
      }
      updatedTableSummaryConfiguration.summaryFields.push({});

      return {
        ...prevDisplayChanges,
        tableSummaryConfiguration: updatedTableSummaryConfiguration,
      };
    });
  };

  const removeAction = (indexOfRow) => {
    let updatedConfiguration = displayChanges?.tableSummaryConfiguration;
    let filteredSummary = updatedConfiguration?.summaryFields?.filter(
      (item, index) => index !== indexOfRow
    );
    updatedConfiguration["summaryFields"] = filteredSummary;
    setDisplayChanges({
      ...displayChanges,
      tableSummaryConfiguration: updatedConfiguration,
    });
  };

  const autoReloadDelays = useMemo(() => 
  autoReloadComponentDelaysList?.map((data, index) => (
    <option key={index} value={data.value}>
      {data.label}
    </option>
  )),[]);

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group ">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columnWidthTable}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columnWidthTable"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Table Name</label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.tableName?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter Table Name"
            name="tableName"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.tableName}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Theme</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.tableTheme || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="tableTheme"
          >
            <option value="">Default</option>
            <option value="theme1">Theme 1</option>
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Form Page</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.formId || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="formId"
          >
            <option value="">Select</option>
            {selectFormList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Disclaimer Note</label>
        <div>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter Disclaimer Note"
            name="disclaimerNote"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.disclaimerNote || ""}
          ></input>
        </div>
      </div>

      <div className="col-md-12 my-2">
        <div className="row">
          <div className="col-md-3 col-sm-6  form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="showFilter"
                checked={
                  displayChanges?.showFilter ||
                  displayChanges?.showFilter === undefined
                    ? true
                    : false
                }
              />
              <label className="mb-0">Show Filter</label>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                checked={
                  displayChanges?.showPagination ||
                  displayChanges?.showPagination === undefined
                    ? true
                    : false
                }
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="showPagination"
              />
              <label className="mb-0">Show Pagination</label>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-12 form-group">
            <div className="col-md-9 col-sm-9 ">
              <label className="form-label p-0">
                <input
                  className="me-2"
                  type="checkbox"
                  onChange={(e) => {
                    checkBoxChangeValue(e);
                  }}
                  name="autoReloadComponent"
                  checked={displayChanges?.autoReloadComponent || false}
                  />
                Auto reload
              </label>
            </div>
            {displayChanges.autoReloadComponent && (
              <div className="col-md-9 col-sm-9 ">
                <label className="form-label p-0 mt-2">Select delay</label>
                <Form.Select
                  className="form-control"
                  name="autoReloadDelay"
                  value={displayChanges.autoReloadDelay}
                  onChange={(e) => {
                    changeValue(e);
                  }}
                >
                  <option value="">--Select--</option>
                  {autoReloadDelays}
                </Form.Select>
              </div>
            )}
          </div>

          <div className="col-md-3 col-sm-6 form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="isCacheEnabled"
                checked={displayChanges?.isCacheEnabled}
              />
              <label className="form-label mb-0">Enable Cache</label>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-2">
        <div className="col-md-12 rounded p-2 border">
          <span className="fs-5">Summary Configuration</span>
          <div className="border rounded mt-2 p-2 actionButtonContainer">
            <div className="row">
              {/* <div className="col-md-4 col-sm-6 col-12 form-group">
                <label className="form-label p-0 mt-2 required">
                  Summary Heading
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Enter value"
                    name="summaryHeading"
                    onChange={(e) => {
                      //handleSummary(e.target.value, "summaryHeading");
                    }}
                    value={
                      displayChanges?.tableSummaryConfiguration?.summaryHeading
                    }
                  />
                </div>
              </div> */}
              <div className="col-md-4 col-sm-6 col-12 form-group">
                <label className="form-label p-0 mt-2 required">
                  Logic Variable Name
                </label>
                <div>
                  <input
                    type="text"
                    className={`${
                      displayChanges?.refValue?.length <= 0 ? "border-red" : ""
                    } form-control form-control-sm`}
                    placeholder="Enter value"
                    name="summaryLogicVariableName"
                    onChange={(e) => {
                      handleSummary(e.target.value, "summaryLogicVariableName");
                    }}
                    value={
                      displayChanges?.tableSummaryConfiguration
                        ?.summaryLogicVariableName
                    }
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 form-group ">
                <label className="form-label p-0 mt-2">Component Logic</label>
                <div>
                  <Select
                    placeholder="Select.."
                    options={logicList}
                    value={logicList?.find(
                      (logic) =>
                        logic.label ===
                        displayChanges?.tableSummaryConfiguration
                          ?.tableSummaryLogic
                    )}
                    onChange={(e) =>
                      handleSummary(e.label, "tableSummaryLogic")
                    }
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 form-group ">&nbsp;</div>
            </div>
            {displayChanges?.tableSummaryConfiguration?.summaryFields?.length >
            0 ? (
              displayChanges?.tableSummaryConfiguration?.summaryFields?.map(
                (item, index) => {
                  return (
                    <div className="d-flex align-items-center border p-2 border my-1">
                      <div className="row flex-grow-1">
                        <div className="col-md-6 col-sm-6 col-12 form-group">
                          <label className="form-label p-0 mt-2 required">
                            Display Name
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Enter value"
                              name="displayName"
                              onChange={(e) => {
                                handleSummaryConfiguration(
                                  e.target.value,
                                  "displayName",
                                  index
                                );
                              }}
                              value={item.displayName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 form-group">
                          <label className="form-label p-0 mt-2 required">
                            Column Name
                          </label>
                          {item?.type?.label === "Value" ? (
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Enter value"
                              name="value"
                              onChange={(e) => {
                                handleSummaryConfiguration(
                                  e.target.value,
                                  "value",
                                  index
                                );
                              }}
                              value={item.value}
                            />
                          ) : item?.type?.label === "Average" ? (
                            <Select
                              placeholder="Select"
                              name="value"
                              options={columnList}
                              onChange={(e) => {
                                handleSummaryConfiguration(e, "value", index);
                              }}
                              value={item.value}
                            />
                          ) : item?.type?.label === "Sum" ? (
                            <Select
                              placeholder="Select"
                              name="value"
                              options={columnList}
                              onChange={(e) => {
                                handleSummaryConfiguration(e, "value", index);
                              }}
                              value={item.value}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Enter value"
                              name="value"
                              onChange={(e) => {
                                handleSummaryConfiguration(
                                  e.target.value,
                                  "value",
                                  index
                                );
                              }}
                              value={item.value}
                            />
                          )}
                        </div>
                      </div>
                      <div className="p-2 d-flex flex-column">
                        <FontAwesomeIcon
                          icon={"fa fa-plus-circle"}
                          className="pointer text-success add_remove_size mx-2 mb-2"
                          aria-hidden="true"
                          onClick={() => addSummaryConfiguration()}
                        />
                        <FontAwesomeIcon
                          icon={"fa fa-minus-circle"}
                          className="pointer text-danger add_remove_size mx-2"
                          aria-hidden="true"
                          onClick={() => removeAction(index)}
                        />
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div>
                <button
                  className="btn btn-md btn-secondary"
                  onClick={() => addSummaryConfiguration()}
                >
                  Add Configuration
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="rounded border">
          <ActionButtonList
            appLogicList={logicList}
            actionList={displayChanges?.actionDetails}
            formsList={formsList}
            onChange={(e) => {
              actionListChange(e);
            }}
          />
        </div>
        <div className="col-md-12 rounded p-2 border mt-2">
          <RelationshipBuilder
            relations={displayChanges?.relations}
            onRelationshipChange={relationsChange}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderTable;
