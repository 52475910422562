import React from "react";
import "./LineItems.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomForm from "../components/CustomForm";

const LineItems = ({
  title,
  lineItemsFields,
  name,
  lineItemData,
  setLineItemData,
  appSessionId,
}) => {
  let lineItemsFormArrayData = lineItemData?.[name] || [];

  const addNewLineItemData = () => {
    setLineItemData({
      ...lineItemData,
      [name]: [...lineItemsFormArrayData, {}],
    });
  };

  const setLineItemFormData = (data, index) => {
    let formArrayData = [...lineItemsFormArrayData];
    let total_count = 0;
    delete data.totalCount;
    lineItemsFields?.map((field) => {
      if (field.data_type === "number") {
        data[field.name]
          ? (total_count += data[field.name])
          : (total_count += 0);
      }
      if (field?.data_type === "file") {
        if (data?.[field?.name]) {
          if (Object.keys(data?.[field?.name])?.length === 0) {
            data[field.name] = [];
          }
        }
      }
    });
    formArrayData[index] = { ...data, totalCount: total_count };
    setLineItemData({
      ...lineItemData,
      [name]: formArrayData,
    });
  };

  const removeLineItemData = (index) => {
    setLineItemData({
      ...lineItemData,
      [name]: lineItemsFormArrayData.filter((_, idx) => idx !== index),
    });
  };

  return (
    <div className="rounded mb-2 border bg-white">
      <div className="d-help border-bottom p-2 flex-wrap">
        <h5 className="m-0 py-1">
          {title}{" "}
          {lineItemsFormArrayData?.length > 0 &&
            `(${lineItemsFormArrayData?.length})`}
        </h5>
        {lineItemsFormArrayData?.length > 0 && (
          <FontAwesomeIcon
            icon={"fa fa-plus-circle"}
            title={`Add New ${title}`}
            className="pointer ms-0 me-1 text-secondary"
            aria-hidden="true"
            onClick={addNewLineItemData}
            size="lg"
          />
        )}
      </div>

      {lineItemsFormArrayData?.length ? (
        <div
          className={`${
            lineItemsFormArrayData?.length > 1 ? "scrollHeightLineItem " : ""
          } ms-1 px-1 line-items-wrapper py-1`}
        >
          {lineItemsFormArrayData?.map((data, index) => {
            return (
              <div className="px-2 py-1 ms-1 me-2" key={index}>
                <div className="row rounded rightMainContainer border">
                  <div className="col-md-10 col-sm-10">
                    <CustomForm
                      pageFields={lineItemsFields}
                      setFormData={(e) => {
                        setLineItemFormData(e, index);
                      }}
                      formData={data}
                      formTitle={""}
                      appSessionId={appSessionId}
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 mt-2">
                    {lineItemsFields.filter(
                      (item) => item.data_type === "number"
                    ).length ? (
                      <div className="row">
                        <div className="col-12 text-center font-style-bolt">
                          <h6>Total</h6>
                        </div>
                        <div className="col-12 text-center fw-bold	">
                          {data?.totalCount || 0}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-1 col-sm-1 text-center text-sm-end text-md-end my-2">
                    <FontAwesomeIcon
                      title="Remove"
                      role="button"
                      icon={"fa fa-minus-circle"}
                      className="text-danger"
                      aria-hidden="true"
                      onClick={() => {
                        removeLineItemData(index);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="set_height d-flex align-items-center justify-content-center bg-white rounded m-2 border">
          <div className="text-center">
            <div className="mb-2">No Record Added</div>
            <FontAwesomeIcon
              icon={"fa fa-plus-circle"}
              title={`Add New ${title}`}
              className="pointer ms-0 me-1 text-secondary"
              aria-hidden="true"
              onClick={addNewLineItemData}
              size="lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LineItems;
