import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FormContext } from "../../contexts/FormContext";
import { handleAutoFillData } from "../ShareAppAutoFill";
import {
  getLastNodeData,
  reactAppFormExecutionVP,
} from "../../../../services/API";
import { getRecordDataById } from "../../../../services/tableConfigService";
import { componentType } from "../../../common/model/Model";
import CustomForm from "../CustomForm";
import { getFieldDefaultValue } from "../fieldsDisplayComponent/getDefaultValue";

function SearchableDropdown({ onChange, value, field, disabled = false }) {
  const {
    formData,
    setAutoFillData,
    appSessionId,
    apiCallDataBackup,
    setApiCallDataBackup,
  } = useContext(FormContext);
  const [selectedOption, setSelectedOption] = useState([]);
  const [logicDropdownList, setLogicDropdownList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiError, setIsApiError] = useState("");
  const [isDisable, setIsDisable] = useState(disabled);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [formConfigData, setFormConfigData] = useState({});
  const [modalFormData, setModalFormData] = useState({});
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [autoFillLoading, setAutoFillLoading] = useState(false);

  let isMulti = field?.is_multi === "yes";
  let logic_to_call = field?.logic_to_call;
  let staticDropdown = field?.enter_values;
  let key_to_send = field?.key_to_send;
  let key_to_show = field?.key_to_show;
  let addFormData = field?.addFormData;
  const defaultValue = field?.default_value
    ? field?.default_value?.split(",")
    : [];

  useEffect(() => {
    if (logicDropdownList?.length) {
      let options = logicDropdownList
        ?.filter((item) => item?.[key_to_show] && item?.[key_to_send])
        ?.map((item) => {
          if (item?.[key_to_show]) {
            return {
              value: item[key_to_send],
              label: item[key_to_show],
            };
          }
        });
      let optionsList = options?.filter((opt, index) => {
        return !options.slice(index + 1).find((o) => o?.value === opt?.value);
      });
      setOptionList(optionsList);
    }
  }, [logicDropdownList]);

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        setSelectedOption(valueToOptionValue(value));
      } else {
        setSelectedOption(valueToOptionValue([value]));
        onChange([value]);
      }
    } else {
      setSelectedOption(valueToOptionValue([]));
    }
  }, [optionList, value]);

  useEffect(() => {
    if (field?.visibleWhenField) {
      if (formData?.[field?.visibleWhenField] === field?.visibleWhenValue) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }
  }, [formData?.[field?.visibleWhenField], disabled]);

  useEffect(() => {
    if (logic_to_call) {
      if (apiCallDataBackup?.[field?.name]?.length) {
        setLogicDropdownList(apiCallDataBackup?.[field?.name]);
      } else {
        getLogicListData();
      }
    } else {
      let options = staticDropdown?.split(",").map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      setOptionList(options);
    }
  }, []);

  const onChangeHandler = (option = "") => {
    if (option) {
      if (Array.isArray(option)) {
        onChange(option?.map((option) => option.value));
        handleSearchDropdownClick({
          [field?.name]: option?.map((option) => option.value),
        });
      } else {
        onChange([option]?.map((option) => option.value));
        handleSearchDropdownClick({
          [field?.name]: [option]?.map((option) => option.value),
        });
      }
    } else {
      onChange(getFieldDefaultValue(field) || []);
    }
    setSelectedOption(option || []);
  };

  const handleSearchDropdownClick = (searchDetails) => {
    if (field?.autoFillReference) {
      handleAutoFillData(
        searchDetails,
        field?.name,
        appSessionId,
        field?.autoFillLogic,
        field?.autoFillReference,
        setAutoFillData,
        setAutoFillLoading
      );
    }
  };

  const valueToOptionValue = (selectedValue) => {
    let value = [];
    if (Array.isArray(selectedValue)) {
      value = optionList.filter((option) => {
        if (selectedValue?.includes(option.value)) {
          return option;
        }
      });
    }
    return value;
  };

  const getLogicListData = () => {
    setIsLoading(true);
    getLastNodeData(appSessionId, logic_to_call)
      .then((res) => {
        let logicDataList = res?.data?.value?.data?.data || [];
        setLogicDropdownList(logicDataList);
        setApiCallDataBackup((previous) => {
          return {
            ...previous,
            [field?.name]: logicDataList,
          };
        });
      })
      .catch((error) => {
        setIsApiError(error?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const noOptionMessage = () => {
    if (isApiError) {
      return <span className="text-danger">{isApiError}</span>;
    } else {
      return <span>No Options</span>;
    }
  };

  const getPageDetails = () => {
    if (addFormData) {
      setModalState(true);
      let objData = {
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        dataset_name: "fc_app_pages",
        id: addFormData,
      };
      setIsFormLoading(true);
      getRecordDataById(objData)
        .then((response) => {
          if (response.success === true) {
            let {
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              formGroupData,
            } = response?.data;
            setFormConfigData({
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              formGroupData,
            });
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsFormLoading(false);
        });
    }
  };

  const addSubmitForm = () => {
    let objectBody = {
      app_session_id: appSessionId,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      data: {
        page_fields: [
          formConfigData?.page_fields.map((field) => {
            return {
              is_required: field.is_required,
              display_name: field?.display_name,
              name: field?.name,
            };
          }),
        ],
        formData: [modalFormData],
        logic_to_send: [formConfigData?.logic_name_value],
      },
      page_type: componentType.formComponent,
      store_output_key: formConfigData?.form_output_key,
    };
    setSubmitResponseLoading(true);
    reactAppFormExecutionVP(objectBody)
      .then((response) => {
        toast.success(response?.message);
        setModalState(false);
        getLogicListData();
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setSubmitResponseLoading(false);
      });
  };

  return (
    <div className="d-flex fieldMinimumWidth fieldMaximumWidth">
      <Select
        classNamePrefix={"react-select"}
        closeMenuOnSelect={!isMulti}
        isClearable
        menuPosition="fixed"
        isMulti={isMulti}
        options={optionList}
        isDisabled={isDisable}
        value={selectedOption}
        onChange={onChangeHandler}
        isLoading={isLoading}
        noOptionsMessage={noOptionMessage}
        className="flex-grow-1 searchableDropdown"
        classNames={{
          control: () => "searchableDropdownControl",
          menuPortal: () => "searchableDropdownMenuPortal",
          singleValue: () => "searchableDropdownValue",
          multiValue: () => "searchableDropdownValue",
          menuList: () => "searchableDropdownMenuList",
        }}
      />

      {addFormData && logic_to_call ? (
        <div className="px-1 d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            role="button"
            icon={"fa fa-plus"}
            size="xl"
            aria-hidden="true"
            color="gray"
            onClick={getPageDetails}
          />
          <Modal
            scrollable
            centered
            size="lg"
            show={modalState}
            onHide={() => {
              setModalState(false);
              setModalFormData({});
              setFormConfigData({});
            }}
            backdrop={submitResponseLoading ? "static" : true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="position-relative react_ui px-2">
                {isFormLoading && (
                  <div className="set_height d-flex justify-content-center align-items-center">
                    <span>
                      <div className="spinner-border" />
                    </span>
                  </div>
                )}
                <CustomForm
                  formGroupData={formConfigData?.formGroupData}
                  pageFields={formConfigData?.page_fields}
                  isEdit={false}
                  setFormData={setModalFormData}
                  formData={modalFormData}
                  appSessionId={appSessionId}
                  formTitle={""}
                  isResponseLoading={submitResponseLoading}
                  onSubmitClick={addSubmitForm}
                  submitButtonText={"Submit"}
                  submitButtonIcon={
                    <FontAwesomeIcon icon="fa fa-check-double" />
                  }
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
    </div>
  );
}

export default SearchableDropdown;
