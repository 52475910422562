import { useContext, useState } from "react";
import { RoutesContext } from "../../../../RoutesContext";
import { addToCart } from "../../../../services/appService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuId } from "uuid";
import { toast } from "react-toastify";

export const AddToCart = ({ productData }) => {
  const [isAdding, setIsAdding] = useState(false);
  const { routesData, setRoutesData } = useContext(RoutesContext);

  const cartClickHandle = () => {
    let body = {
      productData: [
        {
          product_id: productData?._id?.$oid,
          quantity: 1,
        },
      ],
    };
    setIsAdding(true);
    addToCart(body)
      .then((response) => {
        if (response?.success) {
          toast.success(response?.message);
          setRoutesData({ ...routesData, cartChange: uuId() });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsAdding(false);
      });
  };
  return (
    <button
      type="button"
      className="btn btn-outline-primary btn-md m-0"
      onClick={() => cartClickHandle()}
    >
      {isAdding && <div className="spinner-border spinner-border-sm" />}
      <span className="mx-1">Add to Cart</span>
      <FontAwesomeIcon icon={"fa fa-cart-plus"} aria-hidden="true" />
    </button>
  );
};
