import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CloneModal(props) {
  const [name, setname] = useState("");
  const [error, seterror] = useState("");

  useEffect(() => {
    setname("");
    seterror("");
  }, [props.showModal]);

  const submit = () => {
    if (name !== "") {
      props.cloneSubmit(name);
      seterror("");
    } else {
      seterror(`*Please enter ${props.name} name.`);
    }
  };
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.toggleShow()}
      backdrop={props.isResponseWaiting ? "static" : true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Clone</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group row">
          <label className="col-form-label col-md-3 col-sm-3 required">
            {props.name} Name
          </label>
          <div className="col-md-9 col-sm-9 ">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              name="name"
              disabled={props.isResponseWaiting}
              onChange={(e) => {
                setname(e.target.value);
              }}
              value={name}
            />
            <div className="errorMsg text-danger">
              {error !== "" ? error : null}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => props.closeModal()}
          type="button"
          className="btn btn-danger btn-md m-0 text-nowrap"
        >
          Cancel
        </button>
        &nbsp;
        <button
          type="button"
          disabled={props.isResponseWaiting}
          onClick={() => submit()}
          className="btn btn-success btn-md m-0 text-nowrap"
        >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
          {props.isResponseWaiting ? (
            <>
              <div className="spinner-border text-light btnLoader"></div>
              Cloning {props.name}
            </>
          ) : (
            <>Clone {props.name}</>
          )}&nbsp;&nbsp;
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CloneModal;
