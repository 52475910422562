import React, { useContext, useState } from "react";
import { FormContext } from "../../components/react_engine/contexts/FormContext";
import "./togglebutton.scss";

function ToggleSwitch(props) {
  let checked_text = props?.checkedText || true;
  let unchecked_text = props?.unCheckedText || false;
  const { autoFillData } = useContext(FormContext);
  const [autoFillLoading, setAutoFillLoading] = useState(false);

  const isChecked = () => {
    if(autoFillData && autoFillData[props?.element?.name] && !autoFillLoading){
      setAutoFillLoading(true);
      return autoFillData[props?.element?.name] === checked_text ? true : false;
    }
    return props?.value === checked_text ? true : false;
  };

  return (
    <div className={`toggleSwitch mt-1 ${props?.className || ""}`}>
      <div className="d-flex">
        <label
          className={`switch me-2 mb-0 ${
            props?.readonly ? "disableSwitch" : ""
          }`}
        >
          <input
            disabled={props?.readonly}
            type="checkbox"
            checked={isChecked()}
            onChange={(e) => {
              let changedData = e.target.checked
                ? checked_text
                : unchecked_text;
              props?.onChange(changedData);
            }}
          ></input>
          <span className="slider round"></span>
        </label>
        <span className="toggleSwitchText"> {props?.value}</span>
      </div>
    </div>
  );
}

export default ToggleSwitch;
