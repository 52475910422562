import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { getRecordDataById } from "../../../services/tableConfigService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { inputTypeKey } from "../../common/model/Model";
import AppLoader from "../../dataset/Loader/AppLoader";
import { getLastNodeData } from "../../../services/API";
import { getAllDropdownList } from "../../../services/appService";

const InlineFormModal = ({
  handleModal,
  inlineFormModalData,
  appSessionId,
}) => {
  const { fieldData, data } = inlineFormModalData;

  const [fieldList, setFieldList] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [calcField, setCalcField] = useState({});
  const [currentIndex, setIndex] = useState(null);
  const [fieldApiBackUpObject, setFieldApiBackUpObject] = useState({});

  useEffect(() => {
    if (fieldData?.addFormData && data) {
      getFormPageField();
    }

    return (_) => setIndex(null);
  }, [data]);

  useEffect(() => {
    setCalcField(
      fieldList?.filter(
        (field) => field.data_type === inputTypeKey?.calculatedField
      )[0]
    );
    getDropDownList();
  }, [fieldList]);

  const getDropDownList = () => {
    let fields =
      fieldList?.filter(
        (dataField) =>
          dataField.data_type === inputTypeKey.searchableDropdown &&
          dataField.show_key_to_table !== "no" &&
          dataField.key_to_send &&
          dataField.key_to_show &&
          dataField.key_to_send !== dataField.key_to_show
      ) || [];
    fields.forEach((dataField) => {
      getLastNodeData(appSessionId, dataField.logic_to_call).then((res) => {
        let { data } = res?.data?.value || {};
        if (data) {
          setFieldApiBackUpObject((prev) => ({
            ...prev,
            [dataField.name]: data.data,
          }));
        }
      });
    });

    // for DynamicDependentDropdown
    let dynamicFields =
      fieldList?.filter(
        (dataField) =>
          dataField.data_type === inputTypeKey.dynamicDependentDropdown &&
          dataField.show_key_to_table !== "no" &&
          dataField.key_to_send &&
          dataField.key_to_show &&
          dataField.key_to_send !== dataField.key_to_show
      ) || [];
    dynamicFields.forEach((dataField) => {
      let bodyObj = {
        body: {
          FilterCriteria: [],
          export: true,
        },
        datasetName: dataField.dependentDataset,
      };
      getAllDropdownList(bodyObj).then((response) => {
        setFieldApiBackUpObject((prev) => ({
          ...prev,
          [dataField.name]: response.data,
        }));
      });
    });
  };

  const getFormPageField = () => {
    let objData = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      id: fieldData?.addFormData,
    };
    setIsFormLoading(true);
    getRecordDataById(objData)
      .then((response) => {
        if (response.success) {
          let field = response.data.page_fields || [];
          setFieldList(
            field.filter(
              (data) =>
                data.data_type !== inputTypeKey.inlineTableForm &&
                data.show_key_to_table !== "no"
            )
          );
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setIsFormLoading(false));
  };

  return (
    <div className="position-relative">
      <Modal
        show={fieldData ? true : false}
        onHide={() => handleModal({})}
        scrollable
        size="xl"
      >
        <Modal.Header closeButton>
          <h5>
            {fieldData?.display_name}
            {data?.length > 0 && `(${data?.length})`}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {isFormLoading && <AppLoader></AppLoader>}
          <div className="table-responsive border">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="text-nowrap"> # </th>
                  {fieldList?.map((field, index) => (
                    <th className="text-nowrap" key={index}>
                      {field?.display_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.length ? (
                  data?.map((data, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className="text-nowrap">{index + 1}</td>
                          {fieldList?.map((field, subIndex) => {
                            if (field.data_type === inputTypeKey.fileUpload) {
                              return (
                                <td key={subIndex}>
                                  {data?.[field?.name]?.files?.map((subData) => (
                                    <a
                                      href={subData}
                                      target="_blank"
                                      download="true"
                                    >
                                      <FontAwesomeIcon
                                        icon={"fa fa-download"}
                                        className="me-2"
                                        aria-hidden="true"
                                        color="red"
                                      />
                                    </a>
                                  ))}
                                </td>
                              );
                            } else if (field.data_type === "calculatedField") {
                              return (
                                <td>
                                  <div
                                    title="Click For More Details"
                                    role="button"
                                    onClick={() => {
                                      setIndex((prev) =>
                                        prev === index ? -1 : index
                                      );
                                    }}
                                    className="fw-bold link-primary "
                                  >
                                    {data?.[field?.name]?.length
                                      ? data?.[field?.name]?.length
                                      : 0}
                                  </div>
                                </td>
                              );
                            } else if (
                              field.data_type ===
                                inputTypeKey.searchableDropdown ||
                              field.data_type ===
                                inputTypeKey.dynamicDependentDropdown
                            ) {
                              const value = data?.[field?.name];
                              const list =
                                fieldApiBackUpObject[field.name] || [];
                              if (Array.isArray(value)) {
                                if (field.key_to_send !== field.key_to_show) {
                                  let displayValue = list
                                    ?.filter((item) =>
                                      value.includes(item[field.key_to_send])
                                    )
                                    .map((data) => data[field.key_to_show]);
                                  return (
                                    <td>{displayValue?.join(",") || "-"}</td>
                                  );
                                } else {
                                  return <td>{value?.join(",") || "-"}</td>;
                                }
                              } else {
                                if (field.key_to_send !== field.key_to_show) {
                                  let displayValue = list
                                    ?.filter((data) => data.value === value)
                                    .map((data) => data.label);
                                  return <td>{displayValue || "-"}</td>;
                                } else {
                                  return <td>{value || "-"}</td>;
                                }
                              }
                            } else {
                              return (
                                <td key={index + subIndex}>
                                  {data?.[field?.name] || "-"}
                                </td>
                              );
                            }
                          })}
                        </tr>
                        {currentIndex === index && (
                          <tr>
                            <td colSpan={fieldList.length + 1}>
                              <CalculatedField
                                calcData={data?.[calcField?.name]}
                              ></CalculatedField>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={fieldList?.length + 2}>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InlineFormModal;

const CalculatedField = ({ calcData }) => {
  return (
    <>
      {calcData?.length > 0 ? (
        <table className="table rounded border mb-0">
          <thead>
            <tr>
              {Object.keys(calcData[0]).map((key, i) => (
                <th key={i} className="p-2">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {calcData?.map((item, index) => (
              <tr key={index}>
                {Object.entries(item).map(([key, value], i) => (
                  <td key={i} className="p-2">
                    {index !== calcData?.length - 1 ? (
                      <span>{value}</span>
                    ) : (
                      <>
                        <label className="control-label text-left fw-bold  ">
                          {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                        </label>
                        <span className="ms-3">{value}</span>
                      </>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="set_height border rounded d-flex justify-content-center align-items-center ">
          No Data
        </div>
      )}
    </>
  );
};
