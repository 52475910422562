import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { filterData, deleteWishlist } from "../../../../services/appService";
import Loader from "../../../dataset/Loader/Loader";
import no_wishlist_found from "../../../../images/empty-wishlist.png";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import "./Wishlist.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Wishlist(props) {
  const [wishlist, setWishlist] = useState([]);
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const user_id = JSON.parse(localStorage.getItem("userid"));
  const { store_id } = useParams();
  const tenant_Id = JSON.parse(localStorage.getItem("tenantId"));
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipSection, setTooltipSection] = useState(null);
  const [show, setShow] = useState(false);
  const [deleteObj, setDeleteObj] = useState({});

  useEffect(() => {
    fetchWishlistDetailsByStore();
  }, []);

  const fetchWishlistDetailsByStore = () => {
    let bodyData = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: user_id,
          filter_field: "user_id",
        },
        {
          filter_type: "text",
          type: "equal",
          filter: store_id,
          filter_field: "store_id",
        },
      ],
    };
    filterData(bodyData, "wishlist")
      .then((response) => {
        setLoader(false);
        if (response.data) {
          setWishlist(response.data);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (showTooltip) setTimeout(() => setShowTooltip(false), 1500);
  }, [showTooltip]);

  const routeToWishlistProducts = (data) => {
    const wishlist_id = data._id.$oid;
    history.push(`/shared_product_list/${tenant_Id}/${wishlist_id}`);
  };

  const copysharedUrl = (data, index) => {
    setTooltipSection(index);
    setShowTooltip(true);
    const wishlist_id = data._id.$oid;
    let urlGenerated = `${window.location.origin}/share_wishlist_product_list/${tenant_Id}/${wishlist_id}`;
    navigator.clipboard.writeText(urlGenerated);
  };

  const deleteWishlistDetails = (data, index) => {
    setDeleteObj(data);
    setShow(true);
  };

  const deleteWishlistData = () => {
    let body = {
      data: [deleteObj._id.$oid],
    };
    deleteWishlist(body, "wishlist")
      .then((response) => {
        if (response.success) {
          setDeleteObj({});
          setShow(false);
          toast.error("Wishlist Deleted Successfully");
          fetchWishlistDetailsByStore();
        }
      })
      .catch((err) => {
        toast.error("Internal Issue");
      });
  };

  const wishListData = wishlist?.map((data, index) => (
    <div className="col-md-3 mb-4" key={index}>
      <div className="d-help p-4 wishlist-card ">
        <div className="wishlist-action-button">
          <span className="text-capitalize">{data.wishlist_name}</span>
        </div>
        <div className="actions cursor">
          <FontAwesomeIcon
            icon={"fa fa-file"}
            className="pe-3 pointer"
            onClick={() => {
              copysharedUrl(data, index);
            }}
          >
            {showTooltip ? (
              <div className={tooltipSection == index ? "d-block" : "d-none"}>
                <Tooltip id="overlay-example" {...props}>
                  Link Copied
                </Tooltip>
              </div>
            ) : null}
          </FontAwesomeIcon>
          <FontAwesomeIcon
            icon={"fa fa-external-link"}
            className="pe-3 pointer"
            onClick={() => routeToWishlistProducts(data)}
          />
          <FontAwesomeIcon
            icon="fa fa-trash"
            className="pointer"
            onClick={() => {
              deleteWishlistDetails(data, index);
            }}
          />
        </div>
      </div>
    </div>
  ));

  const handleClose = () => setShow(false);

  return (
    <div className="col-md-12 col-sm-12 pb-2 p-0">
      <div className="catergory-wrap-screen p-2">
        <div className="col-md-12 mb-4">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div className="d-flex">
                <div className="bread-crum d-help me-2">
                  <p
                    className="m-0 me-2 text-underline cursor font-13"
                    onClick={() => props.history.goBack()}
                  >
                    Product Categories
                  </p>

                  <span>/</span>
                  <h6 className="m-0 ms-2 cursor font-13">Wishlist</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 text-end">
              <button
                className="btn btn-secondary btn-md"
                onClick={() => props.history.goBack()}
              >
                <FontAwesomeIcon icon="fa fa-arrow-left" className="me-1" />
                Back
              </button>
            </div>
          </div>
        </div>
        {loader ? (
          <Loader></Loader>
        ) : wishlist.length ? (
          <div className="col-md-12">
            <div className="row">{wishListData}</div>
          </div>
        ) : (
          <div className="col-md-12">
            <h5>No Wishlist Found</h5>
            <div className="no-wishlist text-center mt-4">
              <img src={no_wishlist_found}></img>
            </div>
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Do You Want To Delete Wishlist</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="action-button text-end">
            <button
              type="button"
              className="btn btn-md btn-success"
              onClick={() => {
                deleteWishlistData();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-md btn-danger"
              onClick={() => {
                setShow(false);
              }}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Wishlist;
