import React from "react";
import { Mention, MentionsInput } from "react-mentions";

const CommentBox = ({ userName, onChange, commentData }) => {
  const defaultStyle = {
    control: {
      width: "100%",
      height: "100px",
    },

    suggestions: {
      marginTop: "30px",
      marginLeft: "10px",

      list: {
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
      },

      item: {
        padding: "5px 15px",
        borderBottom: "1px solid #d7d7d7",

        "&focused": {
          backgroundColor: "#f6f7fb",
          color: "#2c89b7",
        },
      },
    },
  };

  const alterUserNameList = () => {
    let tempUserNameList = [];
    let returnList = [];
    let tempString = commentData;
    if (commentData !== "") {
      tempUserNameList = userName.filter((name) =>
        name.display
          .toLowerCase()
          .match(
            tempString.toLowerCase().substring(tempString.lastIndexOf("@") + 1)
          )
      );
    } else {
      tempUserNameList = userName;
    }
    if (tempUserNameList.length > 0) {
      tempUserNameList.map((name, index) => {
        if (index < 5) {
          returnList.push(name);
        }
      });
    }
    return returnList;
  };

  return (
    <>
      <h5>Comments</h5>
      <div className="mb-2 bg-white">
        <MentionsInput
          allowSpaceInQuery
          style={defaultStyle}
          value={commentData}
          onChange={(e) => onChange(e)}
          className="custom-comment"
          placeholder="Type @ to tag someone and email them"
        >
          <Mention trigger="@" data={alterUserNameList()} />
        </MentionsInput>
      </div>
    </>
  );
};

export default CommentBox;
