import { Form } from "react-bootstrap";

function CustomRadioButton({ field, value = [], onChange, disabled = false }) {
  const onchangeValue = (changeValue) => {
    if (value !== changeValue) onChange(changeValue);
  };
  let boolEnumList = field?.enter_values?.split(",") || [];

  return (
    <div className="d-flex">
      {boolEnumList?.map((data, index) => (
        <div
          role="button"
          key={index}
          className="m-1 me-2 d-flex justify-content-center align-items-center"
          onClick={() => onchangeValue(data)}
        >
          <Form.Check
            checked={value === data}
            className="me-1 mt-0"
            readOnly
            key={index}
            type={"radio"}
          />
          <span>{data}</span>
        </div>
      ))}
    </div>
  );
}

export default CustomRadioButton;
