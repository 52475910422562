import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { executeAppForTempStorage } from "../../../services/API";
import CustomForm from "../components/CustomForm";
import "./FilterForm.scss";
import { Col, Collapse } from "react-bootstrap";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { useParams } from "react-router";
import { RoutesContext } from "../../../RoutesContext";

const FilterForm = ({
  filterFormExecution,
  formFields,
  appSessionId,
  isFilterFormOnTop,
  objData,
}) => {
  const { filterFormData, setFilterFormData } = useContext(FilterFormContext);
  const [toggleFilterView, setToggleFilterView] = useState(true);
  const [responseLoading, setResponseLoading] = useState(false);
  const [filterMode, setFilterMode] = useState("");
  const { flowId } = useParams();
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const [isFormValid, setFilterFormValid] = useState(false);

  const applyClearFilter = (filterMode) => {
    setFilterMode(filterMode);
    let formDataToSend = {};

    if (filterFormData) {
      const filteredObj = Object.fromEntries(
        Object.entries(filterFormData).filter(([key, value]) => {
          return value !== "" && !(Array.isArray(value) && value.length === 0);
        })
      );
      formDataToSend = filteredObj;
    }
    setCacheData({ ...cachedData, [flowId]: formDataToSend });

    const payloadForTempStorage = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      data: filterMode === "apply" ? formDataToSend : {},
      reference_name: formFields?.form_output_key,
      app_session_id: appSessionId,
    };
    setResponseLoading(true);
    executeAppForTempStorage(payloadForTempStorage)
      .then((response) => {
        if (response.status === 200) {
          filterFormExecution();
          if (filterMode === "clear") {
            setFilterFormData({});
          }
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setResponseLoading(false);
      });
  };

  useEffect(() => {
    const component = objData?.page?.display_fields.filter(
      (field) => field?.componentType === "Filter Form"
    );
    if (cachedData?.hasOwnProperty(flowId) && component[0]?.isCacheEnabled) {
      setFilterFormData(cachedData[flowId]);
      return;
    }
    let payloadForTempStorage = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      data: {},
      reference_name: formFields?.form_output_key,
      app_session_id: appSessionId,
    };
    executeAppForTempStorage(payloadForTempStorage)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  }, [flowId]);

  useEffect(() => {
    const page_field = formFields?.page_field?.filter(
      (field) => field?.is_required === true && field?.is_hidden === false
    );
    const isValid = page_field?.every(
      (field) => filterFormData[field.name] !==undefined && filterFormData[field.name] !== ""
    );
    setFilterFormValid(!isValid);
  }, [filterFormData]);

  return (
    <Col className="pb-2 px-0">
      <div className={"h-100 mx-1"}>
        <div className="py-1 px-1 main-content bg-white mb-2">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-1 borderBottom">
            <h6 className="filter-heading m-0">Filter</h6>
            <div>
              <button
                className="arrowDownContainer header-icon-button icon-hover-effect"
                title={toggleFilterView ? "Collapse Filter" : "Expand Filter"}
                onClick={() => setToggleFilterView(!toggleFilterView)}
                aria-controls="filter-toggle"
                aria-expanded={toggleFilterView}
              >
                <FontAwesomeIcon
                  icon={"fa fa-angle-down"}
                  className={`arrow-icon ${
                    toggleFilterView ? "arrow-icon-up" : "arrow-icon-down"
                  }`}
                />
              </button>
            </div>
          </div>

          <Collapse in={toggleFilterView}>
            <div>
              <div
                className={`m-0 px-1 ${
                  isFilterFormOnTop ? "filterForm" : "verticalFilterForm"
                }`}
              >
                <CustomForm
                  pageFields={formFields?.page_field}
                  isFilterForm
                  appSessionId={appSessionId}
                  formData={filterFormData}
                  setFormData={setFilterFormData}
                />
              </div>
              <div className="borderBottom mt-2" />
              <div className="mt-2 d-flex justify-content-end">
                <div className="me-1" title="Apply Filter">
                  <button
                    className="btn btn-success btn-sm m-0 mt-1"
                    type="button"
                    onClick={() => applyClearFilter("apply")}
                    disabled={isFormValid}
                  >&nbsp;
                    {responseLoading && filterMode === "apply" ? (
                      <div className="spinner-border spinner-border-sm text-light" />
                    ) : (
                      <FontAwesomeIcon icon="fa fa-filter" />
                    )}
                    <span className="ms-1">&nbsp;Filter&nbsp;</span>
                  </button>
                </div>
                <div className="ms-1" title="Clear Filter">
                  <button
                    className="btn btn-clear btn-sm m-0 mt-1"
                    type="button"
                    onClick={() => applyClearFilter("clear")}
                  >&nbsp;
                    {responseLoading && filterMode === "clear" ? (
                      <div className="spinner-border spinner-border-sm" />
                    ) : (
                      <FontAwesomeIcon icon="fa fa-filter-circle-xmark" />
                    )}
                    <span className="ms-1">&nbsp;Clear&nbsp;</span>
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Col>
  );
};

export default FilterForm;
