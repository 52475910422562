import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const CheckListModal = ({
  checkListModalState,
  checkListHeaderData,
  setCheckListModalState,
  checkListData,
}) => {
  const [obj, setObj] = useState();
  useEffect(() => {
    setObj(checkListData);
  }, []);
  useEffect(() => {
    setObj(checkListData);
  }, [checkListData]);
  return (
    <Modal
      show={checkListModalState}
      onHide={() => setCheckListModalState(false)}
    >
      <Modal.Header>
        <h5>CheckList</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="m-2 p-2 border">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-2 m-2">
            <h5>{checkListHeaderData?.checkList?.checkList_title}</h5>
          </div>

          {obj?.map((item) => {
            return (
              <div className="d-flex justify-content-between align-items-center px-2 pb-1">
                <label>{item?.description}</label>
                <FontAwesomeIcon
                  size="2xl"
                  role="button"
                  className={`stauspointer toggleBtn toggleFormBtnSize ms-0 ${
                    item?.status === "Yes" ? "text-success" : "text-danger"
                  }`}
                  icon={
                    item?.status === "Yes"
                      ? "fa fa-toggle-on"
                      : "fa fa-toggle-off"
                  }
                  aria-hidden="true"
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CheckListModal;
