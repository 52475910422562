import React, { Component } from "react";
import ReactFileReader from "react-file-reader";
import Loader from "../dataset/Loader/Loader";
import { getTenantDetail, upsertSingleRecord } from "../../services/appService";
import { toast } from "react-toastify";
import dummyImg from "../../images/dummy-header-img.png";
import "./companyProfile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class CompanyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: {
        tenant_name: "",
        companyWebsite: "",
        firstName: "",
        lastName: "",
        contactName: "",
        contactNo: 0,
        email: "",
        entityname: "",
        pancardno: 0,
        gstno: 0,
        addressL1: "",
        addressL2: "",
        city: "",
        pincode: 0,
        map_key: "",
        registration_link: "",
      },
      iconImage: "",
      errors: {},
      bannerImage: "",
      object_id: "",
      isLoading: true,
      tenantName: JSON.parse(localStorage.getItem("query_param")),
      tenantId: "11edc52b-2918-4d71-9058-f7285e29d894",
    };
  }

  componentDidMount() {
    this.getDetailsOfTanantRecord(this.state.tenantName);
  }

  getDetailsOfTanantRecord = (tenant_Name) => {
    getTenantDetail(tenant_Name)
      .then((response) => {
        if (response.success === true) {
          const data_file = response.data[0]?.data_file;
          const banner_data_file = response.data[0]?.banner_data_file;
          this.setState({
            file: (data_file && Array.isArray(data_file) && data_file.length > 0) ? data_file[0] : (data_file ? data_file: dummyImg),
            banner_file: (banner_data_file && Array.isArray(banner_data_file) && banner_data_file.length > 0) ? banner_data_file[0] : banner_data_file,
            field: {
              tenant_name: response.data[0]?.tenant_name,
              companyWebsite: response.data[0]?.tenant_website,
              firstName: response.data[0]?.first_name,
              lastName: response.data[0]?.last_name,
              contactName: response.data[0]?.contact_name,
              contactNo: response.data[0]?.contact,
              email: response.data[0]?.email,
              entityname: response.data[0].entityname,
              pancardno: response.data[0].pancardno,
              gstno: response.data[0].gstno,
              addressL1: response.data[0].addressL1,
              addressL2: response.data[0].addressL2,
              city: response.data[0].city,
              pincode: response.data[0].pincode,
              map_key: response.data[0].map_key,
              registration_link: response.data[0].registration_link,
            },
            isLoading: false,
            object_id: response.data[0]._id.$oid,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  changeValue = (e) => {
    let fields = this.state.field;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields,
    });
  };

  // get files from button
  handleFiles = (files) => {
    let err_state = this.state.errors;

    if (files.fileList[0].size >= 1048576) {
      err_state[`fileSize`] = "*File size must be less than 1MB.";
      this.setState({ errors: err_state });
    } else {
      this.setState({
        iconImage: `name=${files.fileList[0].name};${files.base64}`,
        file: URL.createObjectURL(files.fileList[0]),
      });
      delete err_state[`fileSize`];
      this.setState({ errors: err_state });
    }
  };

  // get files from button
  handleFile = (files) => {
    let err_state = this.state.errors;

    if (files.fileList[0].size >= 1048576) {
      err_state[`bannerSize`] = "*File size must be less than 1MB.";
      this.setState({ errors: err_state });
    } else {
      this.setState({
        bannerImage: `name=${files.fileList[0].name};${files.base64}`,
        banner_file: URL.createObjectURL(files.fileList[0]),
      });
      delete err_state[`bannerSize`];
      this.setState({ errors: err_state });
    }
  };

  // validate form date
  validateFieldForm = () => {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter First name.";
    }
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "*Please enter Last name.";
    }
    if (!fields["contactName"]) {
      formIsValid = false;
      errors.contactName = "*Please enter contact name";
    }
    if (!fields["contactNo"]) {
      formIsValid = false;
      errors.contactNo = "*Please enter contact no";
    } else if (!/^[6-9]{1}[0-9]{9}$/.test(fields["contactNo"])) {
      formIsValid = false;
      errors.contactNo = "*Please enter valid contact No";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors.email = "*Please enter email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields["email"])
    ) {
      formIsValid = false;
      errors.email = "*Please enter valid email address";
    }
    if (!fields["companyWebsite"]) {
      formIsValid = false;
      errors["companyWebsite"] = "*Please enter company website";
    } else if (
      !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
        fields["companyWebsite"]
      )
    ) {
      formIsValid = false;
      errors.companyWebsite = "*Please enter valid company website";
    }
    if (!fields["entityname"]) {
      formIsValid = false;
      errors["entityname"] = "*Please enter entity name.";
    }
    if (!fields["pancardno"]) {
      formIsValid = false;
      errors["pancardno"] = "*Please enter Pancard no";
    } else if (
      !/^[A-Z]{3}[ABCFGHLJPT][A-Z][0-9]{4}[A-Z]$/.test(fields["pancardno"])
    ) {
      formIsValid = false;
      errors["pancardno"] = "Invalid Pancard No";
    }
    if (!fields["gstno"]) {
      formIsValid = false;
      errors["gstno"] = "*Please enter GST no";
    } else if (
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        fields["gstno"]
      )
    ) {
      formIsValid = false;
      errors["gstno"] = "*Please enter valid GST no";
    }

    if (!fields["addressL1"]) {
      formIsValid = false;
      errors["addressL1"] = "*Please enter address line1.";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "*Please enter city.";
    }
    if (!fields["pincode"]) {
      formIsValid = false;
      errors["pincode"] = "*Please enter PIN code number.";
    } else if (fields["pincode"].length !== 6) {
      formIsValid = false;
      errors["pincode"] = "*Please Enter 6 Digits PinCode.";
    }

    if (!this.state.file) {
      formIsValid = false;
      errors["file"] = "*Please upload logo";
    }

    if (this.state.banner_file && this.state.banner_file.size >= 1048576) {
      formIsValid = false;
      errors["bannerImage"] = "Banner size must be less than 1MB.";
    }

    if (this.state.iconImage && this.state.iconImage.size >= 1048576) {
      formIsValid = false;
      errors["iconSize"] = "*Logo size must be less than 1MB.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  addProfile(obj) {
    upsertSingleRecord(obj)
      .then((response) => {
        if (response) {
          toast.success(response.message);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ loading: false });
      });
  }

  submitCompanyDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      let obj = {
        tenant_id: this.state.tenantId,
        dataset_name: "sys_tenant",
        object_id: this.state.object_id,
        user_id: JSON.parse(localStorage.getItem("userid")),
        fields: {
          tenant_website: this.state.field.companyWebsite,
          first_name: this.state.field.firstName,
          last_name: this.state.field.lastName,
          contact_name: this.state.field.contactName,
          contact: this.state.field.contactNo,
          email: this.state.field.email,
          pancardno: this.state.field.pancardno,
          gstno: this.state.field.gstno,
          entityname: this.state.field.entityname,
          addressL1: this.state.field.addressL1,
          addressL2: this.state.field.addressL2,
          city: this.state.field.city,
          pincode: this.state.field.pincode,
          map_key: this.state.field.map_key,
          registration_link: this.state.field.registration_link,
        },
      };

      if (this.state.bannerImage.length > 0) {
        obj.fields["banner_data_file"] = {
          file: [this.state.bannerImage],
        };
      }

      if (this.state.iconImage.length > 0) {
        obj.fields["data_file"] = {
          file: [this.state.iconImage],
        };
      }
      this.addProfile(obj);
    }
  }

  render() {
    return (
      <div>
        <div className="d-help py-2">
          <h5>Company Information</h5>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={this.props.history.goBack}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="border border-secondary border-opacity-25 p-3 bg-white mb-2">
              <div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Company Name
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                      name="tenant_name"
                      value={this.state.field.tenant_name}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Company Website
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="url"
                      className="form-control"
                      placeholder="http://website.com"
                      name="companyWebsite"
                      value={this.state.field.companyWebsite}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.companyWebsite}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Upload Logo
                  </label>

                  <div className="col-md-9 col-sm-9 ">
                    <ReactFileReader
                      fileTypes={[".jpg", ".png", ".gif"]}
                      handleFiles={this.handleFiles}
                      multipleFiles={false}
                      base64={true}
                    >
                      <div className="col-md-9 col-sm-9 pe-1">
                        <div className="d-flex align-items-center">
                          <button className="btn btn-primary btn-md btn-block text-nowrap">
                            <span className="me-2">Upload Logo</span>
                            <FontAwesomeIcon icon="fa-solid fa-upload" />
                          </button>
                          {this.state.file ? (
                            <div className="mx-2">
                              <img
                                src={this.state.file}
                                alt="logo-img"
                                width="100px"
                                height="50px"
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </ReactFileReader>
                  </div>
                  <div className="text-danger">
                    {this.state.errors.file}
                    {this.state.errors.fileSize}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Upload Banner Image
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <ReactFileReader
                      fileTypes={[".jpg", ".png", ".gif"]}
                      handleFiles={this.handleFile}
                      multipleFiles={false}
                      base64={true}
                    >
                      <div className="col-md-9 col-sm-9">
                        <div className="d-flex align-items-center">
                          <button className="btn btn-primary btn-md btn-block text-nowrap">
                            <span className="me-2">Upload Banner</span>
                            <FontAwesomeIcon icon="fa-solid fa-upload" />
                          </button>
                          {this.state.banner_file &&
                          this.state.banner_file.length > 0 ? (
                            <div className="mx-2">
                              <img
                                src={this.state.banner_file}
                                alt="Banner-img"
                                width="100px"
                                height="50px"
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </ReactFileReader>
                  </div>

                  <div className="text-danger">
                    {this.state.errors.bannerImage}
                    {this.state.errors.bannerSize}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  First Name
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    name="firstName"
                    value={this.state.field.firstName}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.firstName}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Last Name
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={this.state.field.lastName}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.lastName}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Contact Name
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Contact Name"
                    name="contactName"
                    value={this.state.field.contactName}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.contactName}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Contact Number
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter contact number"
                    name="contactNo"
                    value={this.state.field.contactNo}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.contactNo}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Email
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.field.email}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.email}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Entity Name
                </label>

                <div className="col-md-9 col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Entity Name"
                    name="entityname"
                    aria-describedby="basic-addon2"
                    value={this.state.field.entityname}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.entityname}
                  </div>
                </div>
              </div>

              <div className="form-group row ">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  PAN Card Number
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter PAN card Number"
                    name="pancardno"
                    value={this.state.field.pancardno}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                    pattern="[a-zA-Z0-9][a-zA-Z0-9 ]"
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.pancardno}
                  </div>
                </div>
              </div>

              <div className="form-group row ">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  GST Number
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter GST number"
                    name="gstno"
                    value={this.state.field.gstno}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                    pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.gstno}
                  </div>
                </div>
              </div>

              <div className="address-details">
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Address Line1
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter address Line1"
                      name="addressL1"
                      value={this.state.field.addressL1}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.addressL1}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Address Line2
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <textarea
                      type="address"
                      className="form-control"
                      placeholder="Enter address Line2"
                      name="addressL2"
                      value={this.state.field.addressL2}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    City
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter city"
                      name="city"
                      value={this.state.field.city}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.city}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    PIN Code
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter pincode"
                      name="pincode"
                      value={this.state.field.pincode}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.pincode}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Google Maps API Key
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Map key"
                      name="map_key"
                      value={this.state.field.map_key}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Self Registration Link <p><small>(This link is visible on your login page. Anyone with this link can directly register into your system)</small></p>
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Link here"
                      name="registration_link"
                      value={this.state.field.registration_link}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  onClick={(event) => this.submitCompanyDetails(event)}
                  type="submit"
                  className="btn btn-success btn-md m-0"
                >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                  Update&nbsp;&nbsp;
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
