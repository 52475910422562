import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import "./cardRender.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reactAppExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import AppLoader from "../../dataset/Loader/AppLoader";
import { RoutesContext } from "../../../RoutesContext";
import { formatCurrency } from "../../../services/utils";

function CardsRender({
  displayField,
  appSessionId,
  filterApply,
  reloadComponents,
  objData,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) {
  let displayKeysList = displayField?.displayKeys?.split(",") || [];
  let displayDataKeys = displayField?.page_field?.filter((field) =>
    displayKeysList.includes(field.name)
  );
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [counterData, setCounterData] = useState({});
  const [cardLoading, setCardLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const {
    component_id,
    isCacheEnabled,
    displayCurrencyFormat,
    dataAttribute,
    autoReloadComponent,
    autoReloadDelay,
  } = displayField;

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(() => getCardData(), autoReloadDelay);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (reloadComponents.includes(displayField?.refValue)) {
      getCardData();
    }
  }, [reloadComponents]);

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setCardData(cachedData?.[component_id]);
      return;
    }
    getCardData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (filterApply) {
      getCardData();
    }
  }, [filterApply]);

  const getCardData = () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayField?.componentLogic],
      },
      referenceValue: [{ refValue: displayField?.refValue }],
      app_session_id: appSessionId,
    };

    setCardLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayField?.refValue;
          })?.[displayField.refValue];
          setCardData(componentData);
          if (isCacheEnabled) {
            let data = cachedData;
            data[component_id] = componentData;
            setCacheData(data);
          }
          setTempStorageCall(false);
          clearFilterApply();
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setCardLoading(false);
      });
  };

  useEffect(() => {
    if (Array.isArray(cardData?.value)) {
      let [obj, ..._] = cardData?.value || [];
      setCounterData(obj);
    }
    return () => {
      setCounterData({});
    };
  }, [cardData]);

  let uiSection = (
    <div className="counterContainer h-100 mx-1">
      <div className="w-100 d-flex ">
        <div className="couter_icon" style={{ color: displayField?.iconColor }}>
          <div>
            {displayField?.selectedIcon?.value ? (
              <FontAwesomeIcon icon={displayField?.selectedIcon?.value} />
            ) : null}
          </div>
        </div>
        <div className="counter_no">
          {displayField?.dataAttribute ? (
            <div>
              <p className="total_no">
                <CountUp
                  decimals={
                    Number.isInteger(counterData?.[dataAttribute] || 0) ? 0 : 2
                  }
                  end={counterData?.[dataAttribute] || 0}
                  duration={1}
                  start={0}
                  formattingFn={(value) => {
                    return displayCurrencyFormat
                      ? formatCurrency(value * 10, displayCurrencyFormat)
                      : value;
                  }}
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </p>
              <p className="head_couter">{displayField?.displayAttribute}</p>
            </div>
          ) : (
            <div className="displayFields">
              {displayDataKeys?.map((key, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="label">{key?.display_name}</div>
                    <div className="value">
                      {displayCurrencyFormat
                        ? formatCurrency(
                            counterData?.[key.name],
                            displayCurrencyFormat
                          )
                        : counterData?.[key.name] || "0"}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {!displayField?.dataAttribute && displayKeysList?.length > 0 ? (
        <div className="head_couter border-top">
          {displayField?.displayAttribute}
        </div>
      ) : null}
    </div>
  );

  return (
    <div
      className={`col-md-${displayField?.card || 4} col-sm-6 col-12 col-lg-${
        displayField?.card || 4
      } px-0 mb-2 card-details position-relative`}
    >
      {cardLoading && <AppLoader />}
      <button
        className="arrowDownContainer header-icon-button icon-hover-effect reload-button reload-button-card"
        title="Refresh"
        onClick={getCardData}
      >
        <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} size="xs" />
      </button>
      {displayField?.transitionAppId && displayField?.transitionPageId ? (
        <Link
          to={`/app_ui/${displayField?.transitionAppId}/${displayField?.transitionPageId}`}
        >
          {uiSection}
        </Link>
      ) : (
        uiSection
      )}
    </div>
  );
}

export default CardsRender;
