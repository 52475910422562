import { Calendar, momentLocalizer } from "react-big-calendar";
import "./Bigcalender.scss";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Bigcalender(props) {
  const localizer = momentLocalizer(moment);
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [slotValue, setSlotValue] = useState({});
  const [myEventsList, setEventList] = useState(
    props?.calenderData && props?.calenderData.length ? props?.calenderData : []
  );
  const [resetFlag, setResetFlag] = useState(0);
  const [checkValue, setCheckValue] = useState(false);
  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    setEventList(
      props?.calenderData && props?.calenderData.length
        ? props?.calenderData.map((item) => ({
            ...item,
            start: new Date(item.start),
            end: new Date(item.end),
          }))
        : []
    );
  }, [props?.calenderData]);

  useEffect(() => {
    if (show === false) {
      setResetFlag(0);
      setInputValue("");
      setCheckValue(false);
    }
  }, [show]);

  const callPopupModal = (slotInfo) => {
    setSlotValue(slotInfo);
    setShow(true);
  };

  const addTask = () => {
    if (inputValue.length) {
      let objData = {
        start: slotValue.start,
        end: slotValue.end,
        title: inputValue,
        checked: checkValue,
      };
      props.submitFormFromDisplayComponent(
        objData,
        props?.displayData?.input_value
      );
      setShow(false);
      setInputValue("");
    }
  };

  const editPopupModal = (data) => {
    setCheckValue(data.checked === "True" ? true : false);
    setUpdatedData(data);
    setShow(true);
    setInputValue(data.title);
    setResetFlag(1);
  };

  const updateTask = () => {
    if (resetFlag) {
      let updatedDataObj = {
        ...updatedData,
        title: inputValue,
        checked: checkValue,
      };
      props.submitFormFromDisplayComponent(
        updatedDataObj,
        props?.displayData?.input_value
      );
      setShow(false);
      setUpdatedData({});
      setResetFlag(0);
    }
  };

  const handleClose = () => setShow(false);

  const eventWrapper = () => {
    return <FontAwesomeIcon icon="fa fa-plus" />;
  };
  const tileDisabled = ({ activeStartDate, date, view }) => {
    return date < new Date();
  };

  return (
    <div
      className={`col-md-${
        props.displayData?.columenWidth ? props.displayData?.columenWidth : 12
      } my-2 shadowAsCard p-3`}
    >
      <div className="linkToPage ms-1 linkToPageChart">
        {props.displayData?.transitionAppId &&
        props.displayData?.transitionPageId ? (
          <Link
            to={`/app_ui/${props.displayData?.transitionAppId}/${props.displayData?.transitionPageId}`}
          >
            <FontAwesomeIcon icon="fa fa-external-link" aria-hidden="true" />
          </Link>
        ) : null}
      </div>
      <Calendar
        className={`cursor  ${
          props?.componentDataLength > 1 ? "" : "set-calender-height"
        }`}
        localizer={localizer}
        events={myEventsList}
        eventPropGetter={(event, start, end, isSelected) => {
          if (event.checked === "True") {
            return {
              className: "task-completed",
            };
          }
        }}
        // startAccessor="start"
        startAccessor={(event) => {
          return new Date(event.start);
        }}
        endAccessor="end"
        style={{ height: 520 }}
        popup={true}
        selectable={{
          eventWrapper,
        }}
        onSelectSlot={(slotInfo) => {
          callPopupModal(slotInfo);
        }}
        onSelectEvent={(event) => {
          editPopupModal(event);
        }}
        tileDisabled={tileDisabled}
      ></Calendar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <small>{resetFlag ? "Update" : "Create"} Event</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="dataObj ">
              <div className="form-group">
                <label>Enter Task</label>
                <div className="col-12 ps-2">
                  <div className="row align-items-center">
                    <div className="col-12 p-0">
                      <textarea
                        className="form-control me-4 task-details"
                        placeholder="Enter Task Details"
                        name="taskdetails"
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                        value={inputValue}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12  mb-2">
                <div className="row ">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      value={checkValue}
                      name="checkValue"
                      onChange={(e) => {
                        setCheckValue(e.target.checked);
                      }}
                      className="form-check-input"
                      checked={checkValue}
                    ></input>
                    <label className="form-check-label">
                      Task {checkValue}
                      {checkValue.toString() === "true"
                        ? "Completed"
                        : "Incomplete"}
                    </label>
                  </div>
                </div>
              </div>
              {resetFlag ? (
                <button
                  className="btn btn-success btn-md cursor m-0"
                  onClick={updateTask}
                >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-floppy-disk" />
                  &nbsp;&nbsp;Update Task&nbsp;&nbsp;
                </button>
              ) : (
                <button
                  className="btn btn-success btn-md cursor m-0 me-2"
                  onClick={addTask}
                >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-plus" />
                  &nbsp;&nbsp;Add Task&nbsp;&nbsp;
                </button>
              )}
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Bigcalender;
