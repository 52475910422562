import React, { useMemo, useState } from "react";
import { filterData } from "../../../services/appService";
import { toast } from "react-toastify";
import CustomPagination from "../../common/CustomPagination";

function RelationTable(props) {
  const [tableData, setTableData] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useMemo(() => {
    getTableData();
  }, [activePage, props?.viewData]);

  async function getTableData() {
    try {
      setIsLoading(true);
      const requiredDatasetName = props?.relationData?.["childDatasetName"];
      const queryFields = props?.relationData?.["fieldsMapping"];
      const query = queryFields.map((queryField) => {
        return {
          [queryField["childField"]]:
            props?.viewData?.[queryField["parentField"]],
        };
      });
      let payload = {
        query,
        PaginationCriteria: { limit: 10, skip: (activePage - 1) * 10 },
      };
      const res = await filterData(payload, requiredDatasetName);
      const { data, headers, totalcount } = res;
      setTableData(data);
      const headerFields = headers?.filter(
        (header) =>
          header.data_type !== "arr_obj" && header.data_type !== "lineItems"
      );
      setTableHeadings(headerFields);
      setTotalRecordCount(totalcount);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center set_height border">
          <div className="loader-circle" />
        </div>
      ) : tableData?.length ? (
        <div className="table-responsive border">
          <table className="table mb-0">
            <thead>
              <tr>
                <th scope="col">#</th>
                {tableHeadings?.map((heading, index) => {
                  return (
                    heading?.name !== "_id" &&
                    heading?.name !== "srno" && (
                      <th scope="col" className="text-nowrap" key={index}>
                        {heading?.display_name}
                      </th>
                    )
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.map((td, index) => {
                return (
                  <tr>
                    <td>{index + 1 + (activePage * 10 - 10)}</td>
                    {tableHeadings?.map((heading) => {
                      return (
                        heading?.name !== "_id" &&
                        heading?.name !== "srno" && (
                          <td>{td[heading?.name] || "-"}</td>
                        )
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <span>No Data To Display</span>
      )}
      <CustomPagination
        activePage={activePage}
        totalRecordCount={totalRecordCount}
        handlePageChange={handlePageChange}
      />
    </>
  );
}

export default RelationTable;
