import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function CustomCheckBox({ field, value = [], onChange, disabled = false }) {
  const onchangeValue = (changeValue) => {
    let changeData;
    if (value?.includes(changeValue)) {
      changeData = value?.filter((list) => changeValue !== list);
    } else {
      changeData = [...value, changeValue];
    }
    if (!disabled) {
      onChange(changeData);
    }
  };

  let boolEnumList = field?.enter_values?.split(",") || [];

  return (
    <div className="d-flex">
      {boolEnumList?.map((data, index) => (
        <div
          role="button"
          key={index}
          className="m-1 me-2 d-flex justify-content-center align-items-center"
          onClick={() => onchangeValue(data)}
        >
          <Form.Check
            size={"sm"}
            readOnly
            defaultChecked={value?.includes(data)}
            className="me-1"
            disabled={disabled}
            key={index}
            type={"checkbox"}
          />
          <span>{data}</span>
        </div>
      ))}
    </div>
  );
}

export default CustomCheckBox;
