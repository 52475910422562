import React, { Component } from "react";
import { getStoreDetails } from "../../../../services/appService";
import Select from "react-select";

export class StoreBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeDropDownValue: [],
      dropDownValue: this.props?.sendReturnedDataToStore?.length
        ? this.props?.sendReturnedDataToStore
        : [],
      errorValue: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.props.onRef(this);

    this.fetchStoreDetails();
  }
  fetchStoreDetails() {
    getStoreDetails()
      .then((response) => {
        if (response.data.length) {
          const dropDownData = response.data.map((storeDetails) => {
            return {
              value: storeDetails.store_id,
              label: storeDetails.store_name,
            };
          });
          this.setState({ storeDropDownValue: dropDownData });
        } else {
        }
      })
      .catch(() => {});
  }

  handleChange(selectedItem) {
    this.setState({
      dropDownValue: selectedItem,
    });
  }

  storedDropdowndata() {
    if (this.state.dropDownValue.length === 0) {
      this.setState({
        errorValue: !this.state.errorValue,
      });
    } else {
      this.props.returnStoreDetailsData(this.state.dropDownValue);
    }
  }
  render() {
    return (
      <div className="p-3">
        <div className="d-flex align-items-center w-100 mb-4">
          <label className="me-2 required">Select Store</label>
          <Select
            className="w-50"
            isMulti={true}
            name="store_selection"
            options={this.state.storeDropDownValue}
            value={this.state.dropDownValue}
            onChange={this.handleChange}
          ></Select>
        </div>
        {this.state.errorValue ? (
          <span className="text-danger fw-bold font-14">
            Please Select Store
          </span>
        ) : null}
      </div>
    );
  }
}

export default StoreBuilder;
