import React, { useEffect, useMemo, useState } from "react";
import DisplayBuilderKanbanLanes from "./DisplayBuilderKanbanLanes";
import DisplayBuilderKanbanCardKeyMapping from "./DisplayBuilderKanbanCardKeyMapping";
import Select from "react-select";
import { formPagesList } from "../../../../../services/appService";
import { toast } from "react-toastify";

const DisplayBuilderKanban = (props) => {
  const [displayChanges, setDisplayChanges] = useState({});
  const [formOptions, setFormOptions] = useState([]);

  useMemo(() => {
    getAllFormList();
    setDisplayChanges(props?.displayKanbanObj);
  }, []);

  useEffect(() => {
    props?.onChange(displayChanges);
  }, [displayChanges]);

  function getAllFormList() {
    let bodyObj = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: "form_component",
          filter_field: "page_type",
        },
      ],
      export: true,
    };
    formPagesList(bodyObj)
      .then((response) => {
        if (response?.success) {
          const formList = response?.data.map((form) => {
            return {
              label: form.function_name,
              value: form._id.$oid,
            };
          });
          setFormOptions(formList);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const kanbanLaneChange = (lane) => {
    if (lane?.length) {
      const data = { ...displayChanges };
      data["lanes"] = lane;
      setDisplayChanges(data);
    }
  };

  const searchableValueChange = (event, name) => {
    const data = { ...displayChanges };
    if (name === "form") {
      data["formId"] = event?.value || "";
      data["formName"] = event?.label || "";
      setDisplayChanges(data);
    }
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Board Name</label>
        <div>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter Board Name"
            name="boardName"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.boardName}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Board Background Color</label>
        <div>
          <input
            type="color"
            placeholder="Choose Color"
            name="boardBgColor"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.boardBgColor || "#ffffff"}
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Form</label>
        <div>
          <Select
            placeholder="Select..."
            options={formOptions}
            value={
              displayChanges?.formId
                ? {
                    label: displayChanges?.formName,
                    value: displayChanges?.formId,
                  }
                : ""
            }
            name="form"
            onChange={(e) => {
              searchableValueChange(e, "form");
            }}
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
            isClearable
          />
        </div>
      </div>
      <DisplayBuilderKanbanCardKeyMapping
        displayChanges={displayChanges}
        setDisplayChanges={setDisplayChanges}
      />
      <div className="col-12">
        <div className="rounded p-2 border">
          <DisplayBuilderKanbanLanes
            lanes={displayChanges?.lanes}
            onChange={(lane) => kanbanLaneChange(lane)}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderKanban;
