import React, { useEffect } from "react";
import { useHistory } from "react-router";

const CustomRedirectShareApp = () => {
  let history = useHistory();
  let query = "";
  if (history?.location.search) {
    query = "&" + history.location.search?.substring(1);
  }
  let url =
    "/app/648bf02515cac6b69b78f246?user_profile=gAWVmwAAAAAAAAB9lCiMCXRlbmFudF9pZJSMJDg4N2NkOWZmLTUxY2QtNDI2MC1hM2Y0LTBlYTMzMTQ5ZjUxOJSMB3VzZXJfaWSUjAEylIwHYXBpX2tleZSMJDZkMzYyNGQxLTM1YTMtNGE0YS1iMjVkLWVjYzg5ZDY4Y2RiNJSMBmFwcF9pZJSMGDY0OGJmMDI1MTVjYWM2YjY5Yjc4ZjI0NpR1Lg==&logic_var=gAWVBwAAAAAAAABdlIwAlGEu" +
    query;
  useEffect(() => {
    if (history?.location.search) {
      history.push(url);
    }
  }, []);

  return <></>;
};

export default CustomRedirectShareApp;
