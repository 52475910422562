import axios from "axios";
import config from "../config";

// Generate axios instance
const instance = axios.create({
  baseURL: config.env("REACT_APP_BASE_URL"),
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use((req) => {
  return req;
});

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        // In case of unauthenticated, refresh screen, so app authenticates again
        window.location.reload();
      }
    }
    throw err;
  }
);

export function getCancelTokenRef() {
  return axios.CancelToken.source();
}

export default instance;
