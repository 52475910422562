function env(key, defaultValue) {
  try {
    const envVal =
      (process.env.REACT_APP_ENV_MODE === "production" ||
      process.env.REACT_APP_ENV_MODE === "development"
        ? process.env[key]
        : window._env_[key]) || defaultValue;
    if (!envVal) {
      console.error(
        `Environment variable ${key} missing with no default value`
      );
    }
    return envVal;
  } catch (e) {
    console.error("Not able to set env variable");
  }
}

export default {
  env,
};
