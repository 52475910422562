import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavDropdown } from "react-bootstrap";

const ListActions = ({
  actionDetails,
  rowData,
  onButtonActionClickHandler,
}) => {
  const filteredActionDetails = actionDetails?.slice(2);
  const filteredButtons = filteredActionDetails?.filter(
    (item) => item.buttonPlacement !== "Header"
  );

  return (
    <div className="ellipsis-button">
      <NavDropdown
        className="p-0 m-0 ellipsis-button"
        title={
          <div className="p-2">
            <FontAwesomeIcon icon="fa fa-ellipsis-vertical" />
          </div>
        }
        id="nav-dropdown"
      >
        {filteredButtons.length > 0 ? (
          filteredButtons?.map((obj) => (
            <NavDropdown.Item eventKey="4.1" className="text-center m-0">
              {obj.buttonPlacement !== "Header" &&
                obj.actionName !== "View" &&
                obj.actionName !== "Update" && (
                  <span
                    className="btn btn-md"
                    style={{
                      backgroundColor: `${obj.buttonColor}`,
                      width: "100%",
                    }}
                    onClick={() => onButtonActionClickHandler(obj, rowData)}
                  >
                    {obj.actionName}
                  </span>
                )}
            </NavDropdown.Item>
          ))
        ) : (
          <span className="ps-2 text-center">No actions to display</span>
        )}
      </NavDropdown>
    </div>
  );
};

export default ListActions;
