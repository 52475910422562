import React from "react";
import { Modal } from "react-bootstrap";
import LineItemTable from "./LineItemTable";

const LineItemModal = ({
  setLineItemsModalState,
  lineItemsModalState,
  showLineItemData,
  lineItemHeaderFieldData,
}) => {
  let fields = lineItemHeaderFieldData?.line_items_form_page_fields || [];
  return (
    <Modal
      show={lineItemsModalState}
      onHide={() => setLineItemsModalState(false)}
      scrollable
    > 
      <Modal.Header closeButton>
        <h5>
          {lineItemHeaderFieldData?.display_name}{" "}
          {showLineItemData?.length > 0 && `(${showLineItemData?.length})`}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <LineItemTable fields={fields} tableData={showLineItemData} />
      </Modal.Body>
    </Modal>
  );
};

export default LineItemModal;
