import React, { Component } from "react";
import { toast } from "react-toastify";
import ReactFileReader from "react-file-reader";
import { importExcelData } from "../../../services/tableConfigService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ImportExcel extends Component {
  // get files from button
  handleFiles = (files) => {
    let hasError = false;
    if (files[0].name.indexOf(".csv") === -1) {
      hasError = true;
      toast.error("Please Enter CSV File Format");
    }
    if (!hasError) {
      let formData = new FormData();
      formData.append("data_file", files[0]);
      this.uploadFile(formData);
    }
  };

  // call import api
  uploadFile = (formData) => {
    this.props.sendLoaderData(true);
    importExcelData(formData)
      .then((response) => {
        if(!response.success){
          this.props.sendLoaderData(false);
          this.props.getTableDataImport();
          toast.error(response.message);
        }else{
          // update table data from table component
          this.props.sendLoaderData(false);
          this.props.getTableDataImport();
        }
      })
      .catch((error) => {
        this.props.sendLoaderData(false);
        this.props.getTableDataImport();
        toast.error(error.message);
      });
  };
  render() {
    return (
      <div>
        <ReactFileReader
          fileTypes={[".xlsx", ".xls", ".csv"]}
          handleFiles={this.handleFiles}
          multipleFiles={false}
        >
          <button className="btn btn-primary btn-md">
            <FontAwesomeIcon icon="fa fa-file-import" />
            <span className="ms-1">Import</span>
          </button>
        </ReactFileReader>
      </div>
    );
  }
}

export default ImportExcel;
