import React, { useContext } from "react";
import horLogo from "../../images/logo.png";
import { RoutesContext } from "../../RoutesContext";

const TenantInformation = () => {
  const { routesData } = useContext(RoutesContext);
  return routesData?.logo ? (
    <img src={routesData.logo} />
  ) : (
    <img src={horLogo} className="p-2" alt="logo" />
  );
};

export default TenantInformation;
