import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./FilterComponent.scss";
import { Modal, Button, Form, Collapse } from "react-bootstrap";
import {
  getSavedFilterdList,
  saveFiltersList,
} from "../../../services/appService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FilterComponent({
  filterCriteriaList,
  savedFilterApiBody,
  datasetName,
  columnList,
  show,
  setResetFilter,
  onChange,
  savefilterId,
}) {
  const conditionalTextOperator = ["contains", "equal"];
  const conditionalNumOperator = [
    "less than",
    "less than or equals",
    "greater than",
    "greater than or equals",
    "equal",
  ];

  const initialCondition = {
    type: "", //conditional operator value
    filter: "", // text box value
    filter_field: "", //table header name
    filter_type: "text",
  };
  const initialSelect = {
    label: "",
    value: "",
  };
  const [savedFilterList, setFilterNameList] = useState([]);
  const [savedFilterListData, setFilterNameListData] = useState([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState(initialSelect);
  const [conditionalFilterList, setConditionalFilterList] = useState(
    filterCriteriaList?.length ? filterCriteriaList : [initialCondition]
  );
  const [toggleModal, setToggleModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [modalNameValue, setModalNameValue] = useState("");
  const [errors, setErrors] = useState({});
  const [savingFilterResponse, setSavingFilterResponse] = useState(false);
  const [loadingSavedFilter, setLoadingSavedFilter] = useState(false);

  useEffect(() => {
    saveFilterList();
  }, []);

  useEffect(() => {
    if (filterCriteriaList?.length > 0) {
      setConditionalFilterList(filterCriteriaList);
    } else {
      setFilterName("");
      setConditionalFilterList([initialCondition]);
      setSelectedFilterValue(initialSelect);
    }
  }, [filterCriteriaList]);

  useEffect(() => {
    setFilterNameList(
      savedFilterListData.map((data) => {
        return { label: data.filter_name, value: data._id.$oid };
      })
    );
  }, [savedFilterListData]);

  const saveFilterList = () => {
    if (savedFilterApiBody && datasetName) {
      setLoadingSavedFilter(true);
      getSavedFilterdList(savedFilterApiBody, datasetName)
        .then((response) => {
          if (response?.success) {
            setFilterNameListData(response?.data);
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoadingSavedFilter(false);
        });
    }
  };

  // header data to dropdown
  const columnDropdownData = columnList?.map((data, index) => {
    return (
      <option key={index} value={data.value}>
        {data.label}
      </option>
    );
  });

  // conditions to dropdown text
  const conditionalOperatorDropdownText = conditionalTextOperator.map(
    (data, index) => {
      return (
        <option value={data} key={index}>
          {data}
        </option>
      );
    }
  );

  // conditions to dropdown number
  const conditionalOperatorDropdownNum = conditionalNumOperator.map(
    (data, index) => {
      return (
        <option value={data} key={index}>
          {data}
        </option>
      );
    }
  );

  // add new condition
  const addConditionalFilters = () => {
    if (checkError()) {
      setConditionalFilterList([...conditionalFilterList, initialCondition]);
    }
  };

  // remove condition
  const removeConditionalFilters = (index) => {
    if (conditionalFilterList.length > 1) {
      setConditionalFilterList(
        conditionalFilterList.filter(
          (_, conditionIndex) => conditionIndex !== index
        )
      );
    }
  };

  // set values to obj
  const onChangeCondition = (event, index) => {
    let updateConditionalFilterList = [...conditionalFilterList];
    updateConditionalFilterList[index][event.target.name] = event.target.value;
    setErrors({ ...errors, [event.target.name]: "" });
    setConditionalFilterList(updateConditionalFilterList);
  };

  const onChangeSetDefault = (key, index) => {
    let updateConditionalFilterList = [...conditionalFilterList];
    updateConditionalFilterList[index][key] = "";
    setConditionalFilterList(updateConditionalFilterList);
  };

  // closeModal
  const closeModal = () => {
    setToggleModal(false);
  };

  // send filterData to parent
  const sendFilterDataToParent = () => {
    let filter_criteria = conditionalFilterList.map((conditionalList) => {
      return { ...conditionalList, filter: conditionalList.filter.trim() };
    });

    onChange(filter_criteria);
  };

  const saveFilter = (obj) => {
    setSavingFilterResponse(true);
    saveFiltersList(obj)
      .then((response) => {
        if (response?.success) {
          sendFilterDataToParent();
          setToggleModal(false);
          if (!selectedFilterValue?.value) {
            toast.success(response.message);
          }
          saveFilterList();
          setSelectedFilterValue({
            label: filterName,
            value: response.object_id,
          });
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setSavingFilterResponse(false);
      });
  };

  // sendData to parent
  const sendFilteredApplyDataToParent = () => {
    if (validFilterName(filterName)) {
      let savingFilter = {
        sys_filter: {
          FilterCriteria: conditionalFilterList.map((conditionalList) => {
            return {
              ...conditionalList,
              filter: conditionalList.filter.trim(),
            };
          }),
        },
        filter_name: filterName,
      };
      if (datasetName === "sys_app_data_filter") {
        savingFilter["page_id"] = savefilterId;
      }
      if (selectedFilterValue?.value) {
        savingFilter["object_id"] = selectedFilterValue.value;
      }
      saveFilter(savingFilter);
    }
  };

  const validFilterName = (filter_name) => {
    let valid = false;
    if (filter_name?.trim()?.length === 0) {
      setErrors({
        ...errors,
        filterName: "Filter Name Cannot Be Empty",
      });
      valid = false;
    } else {
      setErrors({
        ...errors,
        filterName: "",
      });
      valid = true;
    }
    return valid;
  };

  // on FilterChange
  const handleChange = (selectedValue) => {
    setErrors({});
    setSelectedFilterValue(selectedValue);
    setFilterName(selectedValue?.label ? selectedValue?.label : "");
    savedFilterListData?.map((data) => {
      if (data._id.$oid === selectedValue?.value) {
        setConditionalFilterList(data.filter_value);
      }
    });
    if (!selectedValue?.label) {
      setConditionalFilterList([initialCondition]);
      setResetFilter();
    }
  };

  // check any Fields is empty
  const checkError = () => {
    let state = true;
    let errors = {};
    conditionalFilterList.map((list) => {
      if (list.filter.trim() === "") {
        errors["filter"] = "Please enter search text.";
      }
      if (list.filter_field === "") {
        errors["filter_field"] = "Please select Column.";
      }
      if (list.type === "") {
        errors["type"] = "Please select Condition.";
      }
      Object.values(list)?.map((value) => {
        if (value.trim() === "") {
          state = false;
        }
      });
    });
    setErrors(errors);
    return state;
  };

  return (
    <div className={`mb-2 toggleFilter bg-white`}>
      <Collapse in={show}>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendFilterDataToParent();
              setModalNameValue("Save");
            }}
          >
            <div className="row p-3 m-0 filterBorder">
              <div className="col-md-3 border-end mb-2">
                <label>Select Saved Filters</label>

                <Select
                  classNamePrefix={"react-select"}
                  placeholder="Select..."
                  options={savedFilterList}
                  onChange={handleChange}
                  value={
                    selectedFilterValue?.label
                      ? selectedFilterValue
                      : selectedFilterValue?.label
                  }
                  isClearable
                  isLoading={loadingSavedFilter}
                />
              </div>
              <div className={`col-md-7 border-end`}>
                <div className="d-flex pe-2">
                  <span className="me-3">Add Conditions</span>
                  <span>
                    <FontAwesomeIcon
                      title="Add New Condition"
                      icon={"fa fa-plus-circle"}
                      className="pointer text-success add_remove_size"
                      onClick={() => {
                        addConditionalFilters();
                      }}
                    />
                  </span>
                </div>
                <div className="height_scroll" id="scrollList">
                  {conditionalFilterList.map((data, index) => (
                    <div
                      className="border my-2 singleFilterBackground rounded position-relative overflow-hidden"
                      key={index}
                    >
                      <div className="row m-1">
                        <div className="col-md-12 px-1">
                          Condition {index + 1}
                        </div>
                        <div className="col-md-4 col-sm-6 my-1 p-0">
                          <div className="px-1">
                            <Form.Select
                              size="sm"
                              className="border-1"
                              name="filter_field"
                              value={data.filter_field}
                              onChange={(e) => {
                                onChangeSetDefault("type", index);
                                onChangeCondition(e, index);
                              }}
                            >
                              <option value="" disabled>
                                Select Column
                              </option>
                              {columnDropdownData}
                            </Form.Select>
                            {index === conditionalFilterList.length - 1 &&
                            errors.filter_field ? (
                              <span className="text-danger text-nowrap">
                                {errors.filter_field}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 my-1 p-0">
                          <div className="px-1">
                            <Form.Select
                              size="sm"
                              className="w-40 border-1"
                              name="type"
                              value={data.type}
                              onChange={(e) => {
                                onChangeCondition(e, index);
                              }}
                            >
                              <option disabled value="">
                                Select Condition
                              </option>
                              {columnList.find((column) => {
                                if (
                                  column.value === data.filter_field &&
                                  column.dataType === "number"
                                ) {
                                  return column;
                                }
                              })
                                ? conditionalOperatorDropdownNum
                                : conditionalOperatorDropdownText}
                            </Form.Select>
                            {index === conditionalFilterList.length - 1 &&
                            errors.type ? (
                              <span className="text-danger text-nowrap">
                                {errors.type}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1 p-0">
                          <div className="px-1">
                            <Form.Control
                              size="sm"
                              type="text"
                              name="filter"
                              placeholder="Search.."
                              value={data.filter}
                              onChange={(e) => {
                                onChangeCondition(e, index);
                              }}
                            />
                            {index === conditionalFilterList.length - 1 &&
                            errors.filter ? (
                              <span className="text-danger text-nowrap">
                                {errors.filter}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {conditionalFilterList.length > 1 && (
                        <div
                          className="iconTopRight border"
                          role="button"
                          title="Remove Condition"
                        >
                          <FontAwesomeIcon
                            icon={"fa fa-minus"}
                            className="pointer text-white add_remove_size"
                            onClick={() => {
                              removeConditionalFilters(index);
                              setErrors({});
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-md-2 mt-3">
                <button
                  type="submit"
                  className="btn btn-success btn-sm me-2 text-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    if (checkError()) {
                      setToggleModal(true);
                      setModalNameValue("Save");
                      setErrors({});
                    }
                  }}
                >
                  {savingFilterResponse && !toggleModal ? (
                    <div className="spinner-border text-light btnLoader"></div>
                  ) : null}
                  <FontAwesomeIcon icon="far fa-check-circle" />
                  <span className="ms-1">&nbsp;Apply&nbsp;</span>
                </button>
                <button
                  type="button"
                  className="btn btn-sm clear-filter-btn text-nowrap border"
                  onClick={() => {
                    setToggleModal(true);
                    setModalNameValue("Clear");
                  }}
                >
                  <FontAwesomeIcon
                    icon="far fa-times-circle"
                    className="clear-filter-icon"
                  />
                  <span className="ms-1 clear-filter-span">&nbsp;Clear&nbsp;</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Collapse>
      <Modal
        show={toggleModal}
        onHide={closeModal}
        backdrop={savingFilterResponse ? "static" : true}
      >
        <form>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>Do You Want To {modalNameValue} Filter ?</h5>
            </Modal.Title>
          </Modal.Header>
          {modalNameValue === "Save" ? (
            <Modal.Body>
              <label>Filter Name</label>
              <input
                disabled={savingFilterResponse}
                type="text"
                className={`form-control rounded ${
                  errors.filterName ? "border-red" : ""
                }`}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  validFilterName(e.target.value);
                }}
              ></input>
              {errors.filterName && (
                <p className="text-sm text-danger">{errors.filterName}</p>
              )}
            </Modal.Body>
          ) : null}
          <Modal.Footer>
            {modalNameValue === "Save" ? (
              <>
                <Button
                  variant="success"
                  className="btn-sm"
                  onClick={() => {
                    sendFilteredApplyDataToParent();
                  }}
                >
                  {savingFilterResponse ? (
                    <div className="filterComponentSpinner">
                      <div className="spinner-border text-light" />
                    </div>
                  ) : (
                    "Yes"
                  )}
                </Button>
                <Button
                  disabled={savingFilterResponse}
                  className="btn-sm"
                  variant="secondary"
                  onClick={() => {
                    closeModal();
                    sendFilterDataToParent();
                    setModalNameValue("");
                  }}
                >
                  No
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="success"
                  className="btn-sm"
                  onClick={() => {
                    setResetFilter();
                    setConditionalFilterList([initialCondition]);
                    closeModal();
                    setModalNameValue("");
                    setErrors({});
                    setSelectedFilterValue({ label: "", value: "" });
                    setFilterName("");
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="btn-sm"
                  variant="secondary"
                  onClick={closeModal}
                >
                  No
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default FilterComponent;
