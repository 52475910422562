import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuId } from "uuid";
import SubTreeComponent from "./SubTreeComponent";
import { getLastNodeData, executeAppForActions } from "../../../services/API";
import { toast } from "react-toastify";

const TreeComponent = ({
  onSelectChange,
  treeConfig,
  appSessionId,
  addEdit,
  isResponseLoading,
}) => {
  const [rootHierarchy, setRootHierarchy] = useState({});
  const [selectedHierarchy, setSelectedHierarchy] = useState("");
  const [onHierarchySelect, setOnHierarchySelect] = useState("");
  const [newChangedData, setNewChangedData] = useState({});
  const [loadingHierarchy, setLoadingHierarchy] = useState(true);
  const [isApiError, setIsApiError] = useState("");
  const [sendingData, setSendingData] = useState({});
  const [allReadyAddedToState, setAllReadyAddedToState] = useState(false);
  const [isSubChildAdding, setIsSubChildAdding] = useState("");
  const [newId, setNewId] = useState("");

  let objectBody = {
    app_session_id: appSessionId,
    logic_name: treeConfig?.updateHierarchyLogic?.label,
    role_name: JSON.parse(localStorage.getItem("role")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
    reference_name: treeConfig?.updateHierarchyReference,
  };

  useEffect(() => {
    getRootHierarchyList();
    setNewId(uuId());
  }, []);

  useEffect(() => {
    if (isResponseLoading) {
      setSelectedHierarchy("");
      onSelectChange({});
    }
  }, [isResponseLoading]);

  useEffect(() => {
    if (
      rootHierarchy?.sub_hierarchy_childs?.length > 0 &&
      allReadyAddedToState
    ) {
      updateInDataBase();
    }
  }, [rootHierarchy]);

  useEffect(() => {
    if (onHierarchySelect) {
      let obj = {
        hierarchy_id: sendingData?.hierarchy_id,
        hierarchy_name: sendingData.hierarchy_name,
        hierarchy_childs: sendingData?.sub_hierarchy_childs?.map(
          (data) => data.hierarchy_id
        ),
        parent_hierarchy_id: sendingData?.parent_hierarchy_id,
        parent_hierarchy_order: sendingData.parent_hierarchy_order,
      };
      onSelectChange(obj);
    }
  }, [sendingData]);

  useEffect(() => {
    if (onHierarchySelect) {
      findHierarchyData();
    }
  }, [onHierarchySelect, rootHierarchy]);

  useEffect(() => {
    if (Object.keys(newChangedData).length > 0) {
      updateHierarchy();
    }
  }, [newChangedData, newChangedData?.sub_hierarchy_childs?.length]);

  const getRootHierarchyList = () => {
    if (treeConfig?.selectedHierarchyLogic?.label) {
      setLoadingHierarchy(true);
      getLastNodeData(appSessionId, treeConfig?.selectedHierarchyLogic?.label)
        .then((res) => {
          let [ResponseData] = res?.data?.value?.data?.data || [];
          let rootData;
          if (ResponseData?._id?.$oid) {
            rootData = ResponseData;
            setRootHierarchy(rootData);
            setAllReadyAddedToState(true);
            setLoadingHierarchy(false);
          } else {
            let id = uuId();
            rootData = {
              hierarchy_name: "Root Category",
              hierarchy_id: id,
              parent_hierarchy_id: id,
              sub_hierarchy_childs: [],
              parent_hierarchy_order: [],
            };
            setLoadingHierarchy(true);
            initialUpdateInDataBase(rootData);
          }
        })
        .catch((error) => {
          setIsApiError(error?.message);
        });
    }
  };

  const updateHierarchy = () => {
    if (rootHierarchy.hierarchy_id === selectedHierarchy) {
      if (!newChangedData?.hierarchy_id) {
        setRootHierarchy({
          ...rootHierarchy,
          sub_hierarchy_childs: [
            ...rootHierarchy?.sub_hierarchy_childs,
            {
              ...newChangedData,
              hierarchy_id: newId,
              parent_hierarchy_order: [
                ...newChangedData.parent_hierarchy_order,
                ...[newId],
              ],
            },
          ],
        });
      }
    } else {
      setRootHierarchy({
        ...rootHierarchy,
        sub_hierarchy_childs: rootHierarchy?.sub_hierarchy_childs?.map(iterate),
      });
    }
  };

  const iterate = (singleData) => {
    if (singleData.hierarchy_id === selectedHierarchy) {
      if (!newChangedData?.hierarchy_id) {
        return {
          ...singleData,
          sub_hierarchy_childs: [
            ...singleData?.sub_hierarchy_childs,
            {
              ...newChangedData,
              hierarchy_id: newId,
              parent_hierarchy_order: [
                ...newChangedData.parent_hierarchy_order,
                ...[newId],
              ],
            },
          ],
        };
      }
    } else {
      return {
        ...singleData,
        sub_hierarchy_childs: singleData?.sub_hierarchy_childs?.map(iterate),
      };
    }
  };

  const changedHierarchy = (changes) => {
    setNewChangedData(changes);
  };

  const findHierarchyData = () => {
    if (rootHierarchy.hierarchy_id === onHierarchySelect) {
      setSendingData({ ...rootHierarchy });
    } else {
      rootHierarchy?.sub_hierarchy_childs?.map(iterateToFind);
    }
  };

  const iterateToFind = (toFindData) => {
    if (toFindData.hierarchy_id === onHierarchySelect) {
      setSendingData({ ...toFindData });
    } else {
      toFindData?.sub_hierarchy_childs?.map(iterateToFind);
    }
  };

  const updateInDataBase = () => {
    let data = {
      ...objectBody,
      data: [rootHierarchy],
    };
    if (treeConfig?.updateHierarchyLogic?.label) {
      setIsSubChildAdding(newChangedData.hierarchy_id || newId);
      executeAppForActions(data)
        .then(() => {
          setNewId(uuId());
        })
        .catch((err) => {
          toast.error(err?.message);
        })
        .finally(() => {
          setIsSubChildAdding("");
          setSelectedHierarchy("");
        });
    }
  };

  const initialUpdateInDataBase = (initialRootHierarchy) => {
    let data = {
      ...objectBody,
      data: [initialRootHierarchy],
    };
    if (treeConfig?.updateHierarchyLogic?.label) {
      executeAppForActions(data)
        .then(() => {
          getRootHierarchyList();
        })
        .catch((err) => {
          toast.error(err?.message);
        })
        .finally(() => {
          setIsSubChildAdding("");
        });
    }
  };

  return (
    <>
      {loadingHierarchy ? (
        <div className="text-center mt-4">
          <div className="spinner-border"></div>
        </div>
      ) : isApiError ? (
        <h5 className="text-danger mt-4 font-italic text-center">
          {isApiError}
        </h5>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <SubTreeComponent
            addEdit={addEdit}
            rootHierarchy={rootHierarchy}
            selectedHierarchy={selectedHierarchy}
            setSelectedHierarchy={setSelectedHierarchy}
            changedHierarchy={changedHierarchy}
            isSubChildAdding={isSubChildAdding}
            setOnHierarchySelect={setOnHierarchySelect}
            onHierarchySelect={onHierarchySelect}
            parentId={rootHierarchy?.hierarchy_id}
            parentIdPath={[]}
          />
        </div>
      )}
    </>
  );
};

export default TreeComponent;
