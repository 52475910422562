import React, { Component } from "react";
import { getRecordDataById } from "../../services/tableConfigService";
import { profileUpdate, changePassword } from "../../services/appService";
import { toast } from "react-toastify";
import ReactFileReader from "react-file-reader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UserProfile extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    field: {
      fname: "",
      lname: "",
      email: "",
      contact: "",
      password: "",
    },
    passwordFields: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    file: "",
    fileImage: "",
    errors: {},
    updatePassword: false,
    hidden: true,
    tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
    userData: JSON.parse(localStorage.getItem("user-info")),
    tenant_name: JSON.parse(localStorage.getItem("query_param")),
    responseLoading: false,
  };

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData() {
    let objData = {
      dataset_name: "fc_users",
      tenant_id: this.state.tenant_Id,
      id: this.state.userData.object_id,
    };
    getRecordDataById(objData)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            field: {
              fname: response.data.first_name,
              lname: response.data.last_name,
              email: response.data.email,
              password: response.data.password,
              contact: response.data.contact,
            },
            file: response.data.image_file,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }

  changePasswordFields(e) {
    let passwordFields = this.state.passwordFields;
    passwordFields[e.target.name] = e.target.value;
    this.setState({
      passwordFields,
    });
  }

  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;
    let passwordFields = this.state.passwordFields;

    if (this.state.updatePassword) {
      if (!passwordFields["oldPassword"]) {
        formIsValid = false;
        errors["oldPassword"] = "*Please enter existing password";
      }
      if (!passwordFields["newPassword"]) {
        formIsValid = false;
        errors["newPassword"] = "*Please enter new password";
      }

      if (!passwordFields["confirmPassword"]) {
        formIsValid = false;
        errors["confirmPassword"] = "*Please enter confirm password";
      } else if (
        passwordFields["newPassword"] !== passwordFields["confirmPassword"]
      ) {
        formIsValid = false;
        errors["confirmPassword"] =
          "*Those passwords didn't match. Please try again.";
      }
    } else {
      if (!fields["fname"]) {
        formIsValid = false;
        errors["fname"] = "*Please enter first name.";
      }
      if (!fields["lname"]) {
        formIsValid = false;
        errors["lname"] = "*Please enter last name.";
      }
      var contactPattern = new RegExp(/^[6-9]{1}[0-9]{9}$/i);
      if (!fields["contact"]) {
        formIsValid = false;
        errors["contact"] = "*Please enter contact number.";
      } else if (!contactPattern.test(fields["contact"])) {
        formIsValid = false;
        errors["contact"] = "*Please enter valid contact number.";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  // get files from button
  handleFiles = (files) => {
    if (files[0].size / 1000 <= 512) {
      this.setState({
        fileImage: files[0],
        file: URL.createObjectURL(files[0]),
      });
    } else {
      toast.error("Maximun size of the picture should be 512kbs");
    }
  };

  submitUpdatedUserPassword(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      let userDetails = {
        old_password: this.state.passwordFields.oldPassword,
        new_password: this.state.passwordFields.newPassword,
        confirm_new_password: this.state.passwordFields.confirmPassword,
        tenant_name: this.state.tenant_name,
        object_id: this.state.userData.object_id,
      };

      changePassword(userDetails)
        .then((response) => {
          if (response.success === true) {
            toast.success(response.message);
            this.setState({
              updatePassword: false,
              passwordFields: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              },
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        });
    }
  }

  submitUpdateUserDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      const formData = new FormData();
      if (this.state.fileImage) {
        formData.append("file", this.state.fileImage);
      }
      formData.append("first_name", this.state.field.fname);
      formData.append("last_name", this.state.field.lname);
      formData.append("contact", this.state.field.contact);
      formData.append("user_email", this.state.field.email);
      this.setState({ responseLoading: true });
      profileUpdate(formData)
        .then((response) => {
          if (response.success === true) {
            toast.success(response.message);
            this.fetchUserData();
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        })
        .finally(() => {
          this.setState({ responseLoading: false });
        });
    }
  }

  onChangePasswordClick(password) {
    this.setState({ updatePassword: password });
    if (password === false) {
      this.setState({
        passwordFields: {
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
      });
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    return (
      <div>
        <div className="d-help py-2">
          <h5>User Profile</h5>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>

        <div className="p-2">
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">
              First Name
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                name="fname"
                value={this.state.field.fname}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.fname}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">
              Last Name
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Last Name"
                name="lname"
                value={this.state.field.lname}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.lname}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">
              Contact Number
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Contact Number"
                name="contact"
                value={this.state.field.contact}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.contact}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">
              User Email
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                disabled={true}
                type="email"
                className="form-control"
                placeholder="Enter User Email"
                name="email"
                value={this.state.field.email}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">
              Upload User Profile Picture
            </label>
            <div className="col-md-9 col-sm-9 ">
              <ReactFileReader
                // fileTypes={[".jpg", ".png", ""]}
                handleFiles={this.handleFiles}
                multipleFiles={false}
              >
                <button className="btn btn-primary btn-md">
                  <span className="me-2">Upload Picture</span>
                  <FontAwesomeIcon icon="fa-solid fa-upload" />
                </button>
                {this.state.file ? (
                  <span>
                    <img
                      src={this.state.file}
                      alt="profile-img"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: "20px",
                      }}
                    />
                  </span>
                ) : null}
              </ReactFileReader>
              <div className="errorMsg text-danger">
                {this.state.errors.logo}
              </div>
            </div>
          </div>
          {this.state.updatePassword ? (
            <div>
              <div className="x_content p-2">
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Existing Password
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type={this.state.hidden ? "password" : "text"}
                      autocomplete="on"
                      className="form-control"
                      placeholder="Enter Existing Password"
                      name="oldPassword"
                      value={this.state.passwordFields.oldPassword}
                      onChange={(e) => {
                        this.changePasswordFields(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.oldPassword}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    New Password
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      autocomplete="off"
                      type={this.state.hidden ? "password" : "text"}
                      className="form-control"
                      placeholder="Enter New Password"
                      name="newPassword"
                      value={this.state.passwordFields.newPassword}
                      onChange={(e) => {
                        this.changePasswordFields(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.newPassword}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Confirm Password
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      autocomplete="off"
                      type={this.state.hidden ? "password" : "text"}
                      className="form-control"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={this.state.passwordFields.confirmPassword}
                      onChange={(e) => {
                        this.changePasswordFields(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.confirmPassword}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="float-end">
            <div className="d-help">
              {this.state.updatePassword ? (
                <div className="d-help me-1 mb-1">
                  <div
                    className="pe-2 buttonAlignment"
                    onClick={() => this.toggleShow()}
                  >
                    Show password
                  </div>
                  <button
                    onClick={(event) => {
                      this.submitUpdatedUserPassword(event);
                    }}
                    className="btn btn-success btn-md mb-0"
                  >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-lock" />
                    &nbsp;&nbsp;Change Password&nbsp;&nbsp;
                  </button>
                </div>
              ) : null}
              <button
                onClick={() => {
                  this.onChangePasswordClick(!this.state.updatePassword);
                }}
                className="btn btn-success btn-md"
              >
                {!this.state.updatePassword ? "Update password" : "Cancel"}
              </button>
              {!this.state.updatePassword ? (
                <button
                  onClick={(event) => {
                    this.submitUpdateUserDetails(event);
                  }}
                  type="submit"
                  className="btn btn-success btn-md"
                >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-pen" />
                  {this.state.responseLoading && (
                    <div className="spinner-border spinner-border-sm btnLoader"></div>
                  )}
                  <span className="me-2">&nbsp;&nbsp;Change User Details&nbsp;&nbsp;</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
