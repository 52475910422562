import React, { Component } from "react";
import "./Loader.scss";
export class AppLoader extends Component {
  render() {
    return (
      <>
        <div className="position-absolute  appLoader minimumHeight" >
            <div className="spinner-border spinner-border-sm"></div>
        </div>
      </>
    );
  }
}

export default AppLoader;
