import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KanbanCard from "./KanbanCard";
import { Droppable } from "react-beautiful-dnd";
import AddUpdateKanbanCard from "./AddUpdateKanbanCard";

const KanbanLane = ({
  lane,
  cards,
  boardData,
  setBoardData,
  displayData,
  objData,
  appSessionId,
  submitFormPayload,
}) => {
  let heightThreshold = 1;

  const [isAddingCard, setIsAddingCard] = useState(false);

  useMemo(() => {
    Object.values(boardData.lanes).forEach((field) => {
      if (heightThreshold < field.cardIds.length) {
        heightThreshold = field.cardIds.length;
      }
    });
  }, [boardData.lanes]);

  const handleAddCard = () => {
    setIsAddingCard(true);
  };

  return (
    <Droppable droppableId={lane.id}>
      {(provided) => (
        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="m-2">
            <div
              className="custom-lane p-2 rounded"
              style={{
                backgroundColor: lane.style.backgroundColor,
                minHeight: heightThreshold * 200,
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div
                  style={{ backgroundColor: lane.style.color }}
                  className="text-dark rounded col-9 d-flex kanban-lane-header pe-0 ps-1"
                >
                  <div className="bg-white rounded-end col-12 py-1">
                    <span
                      className="fw-bold ms-2"
                      style={{ color: lane.style.color }}
                    >
                      {lane.title}
                    </span>
                  </div>
                </div>

                {/* Render the add card icon */}
                <div
                  className="py-1 px-1 bg-white text-dark rounded col-1 d-flex justify-content-center cursor-pointer"
                  onClick={handleAddCard}
                  title="Add new card"
                >
                  <FontAwesomeIcon icon={"fa fa-add"} />
                </div>
              </div>

              {cards.map((card, index) => {
                return (
                  <KanbanCard
                    key={card?.id}
                    card={card}
                    index={index}
                    lane={lane}
                    boardData={boardData}
                    setBoardData={setBoardData}
                    displayData={displayData}
                    objData={objData}
                    appSessionId={appSessionId}
                    submitFormPayload={submitFormPayload}
                  />
                );
              })}

              {provided.placeholder}
              {/* Render the new card form */}
              {isAddingCard && (
                <AddUpdateKanbanCard
                  setIsAddingCard={setIsAddingCard}
                  lane={lane}
                  boardData={boardData}
                  setBoardData={setBoardData}
                  displayData={displayData}
                  objData={objData}
                  appSessionId={appSessionId}
                  submitFormPayload={submitFormPayload}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default KanbanLane;
