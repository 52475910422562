import React from "react";
import Pagination from "react-js-pagination";

function CustomPagination({ activePage, totalRecordCount, handlePageChange }) {
  return (
    <div className="dynamic_pagination bg-white justify-content-between">
      <div className="my-2" id="datatable_info">
        {`Showing ${(activePage - 1) * 10 + 1} to ${
          activePage * 10 > totalRecordCount
            ? totalRecordCount
            : activePage * 10
        } of ${totalRecordCount} entries`}
      </div>
      {totalRecordCount > 10 && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalRecordCount}
          onChange={(e) => {
            handlePageChange(e);
          }}
        />
      )}
    </div>
  );
}

export default CustomPagination;
