import React, { Component } from "react";
import "./SideMenu.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TenantInformation from "../TenantInformation";
import {checkImageExists, getIconImage} from "../../../services/utils";
import {
  getTenantDetail,
} from "../../../services/appService";
export class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      logoImage: getIconImage(JSON.parse(localStorage.getItem("logoImage"))),
      showComponents: this.initialPrivilege(),
      isSubMenuOpen: "",
    };
  }

  componentDidMount() {
    let tenantName = JSON.parse(localStorage.getItem("query_param"));
    this.getTenantDetails(tenantName);
    this.getPrivilages();
  }

  getTenantDetails = (tenant_Name) => {
    getTenantDetail(tenant_Name)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            logoImage: getIconImage(response.data[0]?.data_file),
          });
          localStorage.setItem("logoImage",JSON.stringify(response.data[0]?.data_file));
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getPrivilages = () => {
    if (this.state.userInfo?.privileges) {
      let { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      const showComponents = this.state.showComponents;

      standard_privileges?.forEach((data, index) => {
        let key = "";
        let val = {};
        Object.keys(data).forEach((row_key) => {
          key = row_key;
        });
        Object.values(data).forEach((row_val) => {
          val = row_val;
        });
        if (val.read === val.write) {
          val = { ...val, readwrite: val.read || val.write };
        } else {
          val = { ...val, readwrite: val.read || val.write };
        }
        showComponents[key] = val;
      });

      let apps =
        this.state.showComponents["Available Apps"]?.readwrite ||
        this.state.showComponents["My Apps"]?.readwrite ||
        this.state.showComponents["Create Apps"]?.readwrite;
      showComponents["apps"] = apps;

      let schedule =
        this.state.showComponents["Create Schedules"]?.write ||
        this.state.showComponents.Schedules?.readwrite ||
        this.state.showComponents["History / Log"]?.readwrite;
      showComponents["schedule"] = schedule;

      let API =
        this.state.showComponents["List of Api"]?.readwrite ||
        this.state.showComponents["Logs"]?.readwrite;
      showComponents["API"] = API;
    }
  };

  initialPrivilege() {
    let show = [
      "Roles",
      "Privileges",
      "AddUser",
      "Datasets",
      "Dataset  Editor",
      "My Apps",
      "Available Apps",
      "Create Apps",
      "Create Schedules",
      "Schedules",
      "History / Log",
      "List of Api",
      "Logs",
    ];
    let showComponents = {};
    show.forEach((menu) => {
      showComponents[menu] = {
        read: false,
        write: false,
        readwrite: false,
      };
    });
    showComponents["apps"] = false;
    showComponents["schedule"] = false;
    showComponents["API"] = false;

    return showComponents;
  }

  handleOpenSubChildMenu(menuName) {
    this.setState({
      isSubMenuOpen: this.state.isSubMenuOpen !== menuName ? menuName : "",
    });
  }

  render() {
    let isUserAdmin = false;
    if (this.state.userInfo != null) {
      if (this.state.userInfo.role_name.toLowerCase() === "admin") {
        isUserAdmin = true;
      }
    }
    const isAdmin = sessionStorage.getItem("admin-info");
    const isRedirecting = JSON.parse(localStorage.getItem("isRedirecting"));
    return (
      <div className={`left_col ${isRedirecting && "d-none"}`}>
        <div className="left_col scroll-view d-flex flex-column position-fixed">
          <div className="navbar nav_title">
            <div className="fc-logo">
              {this.state.logoImage ? (
                <img src={this.state.logoImage} alt="FC"/>
              ) : (
                <TenantInformation />
              )}
            </div>
          </div>

          <div id="sidebar-menu" className="p-0 hidden-print main_menu">
            <div className="menu_section active">
              <React.Fragment>
                {this.state.showComponents?.apps || isUserAdmin || true ? (
                  <ul className="nav side-menu">
                    <li>
                      <Link to={`/app-dashboard`} title="Apps">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-house"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                      <ul className="nav child_menu">
                        {this.state.showComponents["My Apps"]?.readwrite ||
                        isUserAdmin ||
                        true ? (
                          <li>
                            <Link to={`/app-dashboard`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-cubes" />&nbsp;&nbsp;My Apps</Link>
                          </li>
                        ) : null}

                        {this.state.showComponents["Available Apps"]
                          ?.readwrite || isUserAdmin ? (
                          <li>
                            <Link to={`/app-list`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-cart-arrow-down" />&nbsp;&nbsp;Available Apps</Link>
                          </li>
                        ) : null}

                        {this.state.showComponents["Create Apps"]?.write ||
                        isUserAdmin ? (
                          <li>
                            <Link to={`/create-app`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-folder-plus" />&nbsp;&nbsp;Create New App</Link>
                          </li>
                        ) : null}
                      </ul>
                    </li>
                  </ul>
                ) : null}

                {this.state.showComponents?.["Store"]?.readwrite ||
                isUserAdmin ? (
                  <ul className="nav side-menu">
                    <li>
                      <Link to="/stores" title="Store">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-cart-shopping"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                ) : null}

                {isUserAdmin ? (
                  <ul className="nav side-menu">
                    <li>
                      <Link to="/search" title="Search configuration">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon="fa-solid fa-magnifying-glass"
                            size="xl"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                ) : null}

                {this.state.showComponents.Roles?.readwrite ||
                this.state.showComponents.Privileges?.readwrite ||
                this.state.showComponents.AddUser?.readwrite ||
                isUserAdmin ? (
                  <ul className="nav side-menu mt-0">
                    <li>
                      <Link to="#" title="Security">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-lock"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                      <ul className="nav child_menu mt-0">
                        {this.state.showComponents.Privileges?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <div
                              className="d-flex align-items-center text-white justify-content-between"
                              onClick={() =>
                                this.handleOpenSubChildMenu("privileges")
                              }
                            >
                              <Link to="#" className="text-white">
                              &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-road-barrier" />&nbsp;&nbsp;Privileges
                              </Link>
                              <FontAwesomeIcon
                                icon={
                                  this.state.isSubMenuOpen === "privileges"
                                    ? "fa fa-angle-up"
                                    : "fa fa-angle-down"
                                }
                              />
                            </div>
                            <ul
                              className={`nav ${
                                (this.state.isSubMenuOpen === "" ||
                                  this.state.isSubMenuOpen !== "privileges") &&
                                "d-none"
                              } sub_child_menu`}
                            >
                              <li className="sub_childs">
                                <Link
                                  to={`/standard-privileges`}
                                  className="text-white"
                                >
                                  Standard Privileges
                                </Link>
                              </li>
                              <li className="sub_childs">
                                <Link
                                  to={`/custom-privileges`}
                                  className="text-white"
                                >
                                  Custom Privileges
                                </Link>
                              </li>
                            </ul>
                          </li>
                        ) : null}
                        {this.state.showComponents.Roles?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <Link to="/roles">&nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-users-between-lines" />&nbsp;&nbsp;Roles</Link>
                          </li>
                        ) : null}

                        {this.state.showComponents.AddUser?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <Link to="/add-user">&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-users" />&nbsp;&nbsp;Users</Link>
                          </li>
                        ) : null}
                      </ul>
                    </li>
                  </ul>
                ) : null}

                {this.state.showComponents.Datasets?.readwrite ||
                this.state.showComponents["Dataset  Editor"]?.readwrite ||
                isUserAdmin ? (
                  <ul className="nav side-menu mt-0">
                    <li>
                      <Link to="#" title="Database">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-database"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                      <ul className="nav child_menu">
                        {this.state.showComponents.Datasets?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <Link to="/dataset_list">&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-table" />&nbsp;&nbsp;All Datasets</Link>
                          </li>
                        ) : null}
                        {this.state.showComponents["Dataset  Editor"]
                          ?.readwrite || isUserAdmin ? (
                          <li>
                            <Link to="/datasets">&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />&nbsp;&nbsp;Dataset Field Editor</Link>
                          </li>
                        ) : null}
                      </ul>
                    </li>
                  </ul>
                ) : null}

                {this.state.showComponents?.["API"] || isUserAdmin ? (
                  <ul className="nav side-menu">
                    <li>
                      <Link to="#" title="API">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-globe"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                      <ul className="nav child_menu">
                        {this.state.showComponents["List of Api"]?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <Link to={`/list-of-api`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-globe" />&nbsp;&nbsp;Expose API's</Link>
                          </li>
                        ) : null}

                        {this.state.showComponents["Logs"]?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <Link to={`/api-logs`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-book" />&nbsp;&nbsp;API Logs</Link>
                          </li>
                        ) : null}
                      </ul>
                    </li>
                  </ul>
                ) : null}

                {this.state.showComponents?.schedule || isUserAdmin ? (
                  <ul className="nav side-menu">
                    <li>
                      <Link to="#" title="Schedules">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-calendar"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>

                      <ul className="nav child_menu">
                        {this.state.showComponents["Create Schedules"]?.write ||
                        isUserAdmin ? (
                          <li>
                            <Link to={`/create-schedule`}>
                            &nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-calendar-plus" />&nbsp;&nbsp;Create Schedule
                            </Link>
                          </li>
                        ) : null}

                        {this.state.showComponents["Schedules"]?.readwrite ||
                        isUserAdmin ? (
                          <li>
                            <Link to={`/schedule`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-calendar-days" />&nbsp;&nbsp;Schedules</Link>
                          </li>
                        ) : null}

                        {this.state.showComponents["History / Log"]
                          ?.readwrite || isUserAdmin ? (
                          <li>
                            <Link to={`/history-log`}>&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-book" />&nbsp;&nbsp;Schedule Logs</Link>
                          </li>
                        ) : null}
                      </ul>
                    </li>
                  </ul>
                ) : null}

                {isAdmin ? (
                  <ul className="nav side-menu mt-0">
                    <li>
                      <Link to="/subscriptionlist" title="Subscriptions">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-solid fa-bell"}
                            className="sidemenuIcon"
                          />
                        </div>
                        <div className="sidemenuTitle">Subscriptions</div>
                      </Link>
                    </li>
                  </ul>
                ) : null}

                {isAdmin ? (
                  <ul className="nav side-menu mt-0">
                    <li>
                      <Link to="/clone-tenant" title="Tenant Clone">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-clone"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                ) : null}

                {isAdmin ? (
                  <ul className="nav side-menu mt-0">
                    <li>
                      <Link to="/tenant" title="New Tenant">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-building"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                ) : null}

                {this.state.showComponents?.["Company Profile"]?.readwrite ||
                isUserAdmin ? (
                  <ul className="nav side-menu">
                    <li>
                      <Link to="/companyprofile" title="Company Profile">
                        <div className="mb-1">
                          <FontAwesomeIcon
                            icon={"fa fa-circle-info"}
                            className="sidemenuIcon"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                ) : null}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SideMenu;
