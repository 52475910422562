import React, { useContext, useEffect } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { runApp } from "../../services/appService";
import { RoutesContext } from "../../RoutesContext";
import { callVPApiRedis } from "../../services/API";

const ShareAppUrl = (props) => {
  const { routesData, setRoutesData } = useContext(RoutesContext);
  const parsed = queryString.parse(props?.history.location.search);

  let sharedAppId = "";
  useEffect(() => {
    localStorage.setItem("isRedirecting", JSON.stringify(false));
    if (parsed?.user_profile) {
      sharedAppId = props?.match?.params.app_id;
      let bodyObj = {
        user_profile: parsed?.user_profile,
        logic_key: parsed?.logic_var,
      };
      runApp(bodyObj)
        .then((response) => {
          if (response.success === true) {
            localStorage.clear();
            localStorage.setItem("user-info", JSON.stringify(response.data));
            localStorage.setItem(
              "tenantId",
              JSON.stringify(response.data.tenant_id)
            );
            localStorage.setItem(
              "logoImage",
              JSON.stringify(response.data.logo_image)
            );
            localStorage.setItem(
              "tenant_website",
              JSON.stringify(response.data.tenant_website)
            );
            localStorage.setItem(
              "userid",
              JSON.stringify(response.data.user_id)
            );
            localStorage.setItem(
              "role",
              JSON.stringify(response.data.role_name)
            );
            localStorage.setItem(
              "session_id",
              JSON.stringify(response.data.sessionid)
            );
            setRoutesData({
              ...routesData,
              shareAppUrl: { ...parsed },
            });
            localStorage.setItem("isRedirecting", JSON.stringify(true));

            callRedisAPI(
              response.data.user_id,
              response.data.tenant_id,
              response.data.sessionid
            );
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, []);

  const callRedisAPI = () => {
    let objData = {
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    };
    callVPApiRedis(objData)
      .then((response) => {
        if (response) {
          props?.history?.replace(`/app_ui/${sharedAppId}`);
        }
      })
      .catch((err) => {
        props?.history?.replace(`/app_ui/${sharedAppId}`);
      });
  };

  return <></>;
};

export default ShareAppUrl;
