import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Help.scss";
const Help = () => {
  return (
    <div className="col-md-12 p-4 bg-white">
      <div>
        <div className="helpContainer">
          <h5 className="fw-bold">Welcome to Our Help Page</h5>
          <p>
            We are here to assist you with any questions or issues you may have
          </p>
          <h5 className="fw-bold">Contacting Customer Support</h5>
          <p>
            If you need further assistance, our dedicated customer support team
            is ready to help you. You can reach us through the following
            channels:
          </p>
          <ul className="list">
            <li>
              <FontAwesomeIcon
                icon={"fa fa-whatsapp"}
                className="me-2"
                aria-hidden="true"
              />
              Whatsapp: +918828845555
            </li>
            <li>
              <FontAwesomeIcon
                className="me-1"
                icon={"fa fa-envelope"}
                aria-hidden="true"
              />
              Email: support@datamoulds.com
            </li>
          </ul>
          <p>
            We strive to provide prompt and helpful support to all our
            customers. Don't hesitate to reach out to us if you have any
            questions, feedback, or concerns. We are here to make your
            experience as smooth as possible. Thank you for choosing our
            services!
          </p>
        </div>
      </div>
    </div>
  );
};
export default Help;
