import React, { Component, createRef } from "react";
import AddDetails from "./add_details/AddDetails";
import DatasetForm from "./form/Form";
import SortData from "./sort/SortData";
import {
  addMetaDataField,
  saveFormDataSet,
  saveAppResonseByJson,
  getMetaData,
} from "../../../services/datasetService";
import {
  getLogicsByAppId,
  importDatasetlData,
  importFieldsDataset,
} from "../../../services/appService";
import { getRecordData } from "../../../services/tableConfigService";
import { toast } from "react-toastify";
import DisplayBuilder from "./display_builder/DisplayBuilder";
import ReactFileReader from "react-file-reader";
import { v4 as uuidv4 } from "uuid";
import PageTransitions from "../dataset_structure/page_transition/PageTransition";
import StoreBuilder from "../dataset_structure/store_builder/StoreBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Form } from "react-bootstrap";
import AddFormGroup from "../section_in_form/AddFormGroup";
import QueryBuilderComponent from "./query_builder/QueryBuilderComponent";
import "./DatasetStructure.scss";

export class DatasetStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveFormData: {},
      saveFormFieldData: {},
      parentDatasetId: "",
      appPageName: "",
      appPageDescription: "",
      addPageVariable: true,
      getDataSetHeader: ["Name", "Display Name", "Data Type", "Default Value"],
      getPageFieldArray: [],
      getPageField: [],
      sortedFields: [],
      sortedAppFields: [],
      loadPageFields: [],
      appId: "",
      showQueryGenerator: false,
      queryData: "",
      errorMessage: "",
      page_type: "Form Component",
      mongoQuery: "",
      componentType: "",
      pageType: ["Form Component", "Display Component", "Store Component"],
      displayData: [],
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      form_output_key: "",
      appLogicArr: [],
      logicNameValue: "",
      logicDescriptionValue: "",
      logicNameId: "",
      getAppLogicData: [],
      editDropdown: false,
      logicName: "",
      DataStructureObjId: "",
      datasetName: "",
      datasetNames: [],
      datasetFields: [],
      isLoading: false,
      isDatasetLoading: true,
      getPageFieldsDetails: [],
      pageTransition: {},
      saveStoreDetailsData: [],
      form_disclaimer: "",
      showHierarchyTree: "No",
      logicOptions: [],
      formGroupData: "",
      fetchFormGroupData: "",
      loading: false,
      updateLogic: "",
    };
    this.getSortedData = this.getSortedData.bind(this);
    this.sendAppData = this.sendAppData.bind(this);
    this.fetchQueryData = this.fetchQueryData.bind(this);
    this.receiveSortData = this.receiveSortData.bind(this);
    this.fetchMongoData = this.fetchMongoData.bind(this);
    this.fetchDisplayData = this.fetchDisplayData.bind(this);
    this.fetchPageTransition = this.fetchPageTransition.bind(this);
    this.returnStoreDetails = this.returnStoreDetails.bind(this);
    this.displayComponentRef = createRef();
  }

  componentWillMount() {
    this.getCurrentDataSetId();
    this.getdataSetFieldsList();
  }

  handleCallChildFunction = () => {
    if (this.displayComponentRef.current) {
      this.displayComponentRef.current.sendDisplaydataToParent();
    }
  };

  getdataSetFieldsList = () => {
    this.setState({ isDatasetLoading: true });
    importFieldsDataset()
      .then((response) => {
        if (response?.success) {
          this.setState({
            datasetNames: response.data,
          });
        } else {
          toast.error(response.message);
          console.info(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({ isDatasetLoading: false });
      });
  };

  getAppLogicData() {
    this.setState({ isDatasetLoading: true });
    getLogicsByAppId(this.state.appId)
      .then((response) => {
        if (response.success === true) {
          let logicOptions = response.data.map((logic) => {
            return { label: logic.function_name, value: logic._id.$oid };
          });
          this.setState({
            getAppLogicData: response.data,
            isDatasetLoading: false,
            logicOptions: logicOptions,
          });
        } else {
          this.setState({ isDatasetLoading: false });
          toast.error(response.message);
          console.info(response.message);
        }
      })
      .catch((err) => {
        this.setState({ isDatasetLoading: false });
        toast.error(err.message);
      });
  }

  getCurrentDataSetId() {
    let datasetId = this.props.match.params.id;
    if (this.props.match.path === "/app_page/page_editor/:id") {
      this.setState(
        {
          addPageVariable: false,
        },
        () => {
          this.loadAppPageFields();
        }
      );
    } else if (datasetId === undefined && this.props.match.path !== "/add_app_page/:appId") {
      this.setState({
        parentDatasetId: "add dataset",
      });
    } else {
      this.setState({
        parentDatasetId: datasetId,
      });
    }
  }

  loadAppPageFields() {
    let objFieldData = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "fc_app_pages",
      id: this.props.match.params.id,
    };
    this.setState({
      loading: true,
    });
    getRecordData(objFieldData)
      .then((response) => {
        if (response.success === true && response.data !== null) {
          this.setState({
            appId: response.data.app_id,
          });
          this.setState({
            displayData: response.data.display_fields || [],
            page_type:
              response?.data?.page_type === "display_component"
                ? "Display Component"
                : response?.data?.page_type === "form_component"
                ? "Form Component"
                : response?.data?.page_type === "store_component"
                ? "Store Component"
                : "Form Component",
            form_output_key: response.data.form_output_key || "",
            logicName: response.data.logic_name_value || "",
            logicNameId: response.data.logic_name_id || "",
            getPageFieldsDetails: response.data.page_fields || [],
            pageTransition: response.data.pageTransition,
            fetchFormGroupData: response.data.formGroupData || [],
            saveStoreDetailsData: response.data.storeComponentData,
            form_disclaimer: response.data?.form_disclaimer,
            showHierarchyTree: response.data?.showHierarchyTree || "No",
            hierarchyTreeConfig: response.data?.hierarchyTreeConfig,
            updateLogic: response.data?.updateLogic || "",
            readAutoFillReference: response.data?.readAutoFillReference || "",
            autoFillLogic: response.data?.autoFillLogic || "",
            autoFillReference: response.data?.autoFillReference || "",
            logicNameValue: response.data?.function_name || "",
            logicDescriptionValue: response.data?.description || "",
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getAppIdfromSort = (returnValue) => {
    this.setState({ appId: returnValue }, () => {
      this.getAppLogicData();
    });
  };

  getdataSetObjectId = (id) => {
    this.setState({ DataStructureObjId: id });
  };

  getSortedData(data) {
    this.setState({
      sortedFields: data,
    });
  }

  sendAppData(data) {
    this.setState(
      {
        sortedAppFields: data,
      },
      () => {
        this.savePageEditiorData();
      }
    );
  }

  fetchQueryData(data) {
    this.setState({
      queryData: data,
    });
  }

  fetchMongoData(data) {
    if (data === undefined) {
      this.setState({
        mongoQuery: "",
      });
    } else {
      this.setState({
        mongoQuery: data,
      });
    }
  }

  getFormFieldData = (data) => {
    this.setState(
      {
        saveFormFieldData: data,
      },
      () => {}
    );
    this.setState({
      getPageFieldsDetails: [...this.state.getPageFieldsDetails, data.field],
    });
    this.state.getPageFieldArray.push(data.field);
    this.state.getPageFieldArray = [];
  };

  getFormData = (data) => {
    this.state.saveFormData = data;
    if (this.props.match.path === "/add_app_page/:appId") {
      this.saveAppPage();
    } else {
      this.saveData();
    }
  };

  // this function gets called on adding page ++
  onClickFetchData = (event) => {
    this.formFieldComponent.submitFormFieldData(event);
    this.formComponent.submitData(event);
  };

  saveAppPage() {
    let pageobj = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "fc_app_pages",
      fields: {
        function_name: this.state.saveFormData.dataset_name,
        description: this.state.saveFormData.description,
        app_id: this.props.match.params.appId,
      },
    };
    saveAppResonseByJson(pageobj)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            objId: response.object_id,
          });
          toast.success("Page Saved Successfully");
          this.props.history.push(
            `/app_page/page_editor/${response.object_id}`
          );
          this.getCurrentDataSetId();
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: err.message }, () => {
          toast.error(err.message);
        });
      });
  }

  saveData() {
    if (this.state.saveFormData.dataset_object_id === "") {
      let objData = {
        author: this.state.saveFormData.author,
        dataset_name: this.state.saveFormData.dataset_name,
        description: this.state.saveFormData.description,
      };
      saveFormDataSet(objData).then((response) => {
        if (response.success === true) {
          toast.success("Dataset Added Successfully");
          let responseObjectId = response.object_id;
          this.props.history.push(`/dataset/${responseObjectId}`);
          window.location.reload();
        }
        if (response.success === false) {
          toast.error("Requested Dataset Already Exist");
        }
      }).catch((error) => {
        toast.error(error.message);
      });
    } else {
      saveFormDataSet(this.state.saveFormData).then((response) => {
        if (response.success === true) {
          if (Object.keys(this.state.saveFormFieldData)?.length !== 0) {
            let isExist = this.state.sortedFields?.find(
              (field) => field.name === this.state.saveFormFieldData.field.name
            );
            if (isExist) {
              toast.error("Field Already Exist");
            } else {
              addMetaDataField(this.state.saveFormFieldData)
                .then((response) => {
                  if (response.success === true) {
                    toast.success("Field Added Successfully");
                    this.getMetaData.onloadGetData();
                    this.setState({
                      saveFormFieldData: {},
                    });
                  }else{
                    this.setState({ errorMessage: response.message });
                    toast.error(response.message);
                  }
                })
                .catch((err) => {
                  this.setState({ errorMessage: err.message });
                  if (this.state.errorMessage) {
                    toast.error(this.state.errorMessage);
                  }
                });
            }
          }
        }
        if (response.success === false) {
          toast.error(response.message);
        }
      }).catch((error) => {
        toast.error(error.message);
      });
    }
  }

  // return data from display builder
  fetchDisplayData(data) {
    this.setState({ displayData: data }, () => {
      this.savePageEditiorData();
    });
  }

  // submit data for page editor

  receiveSortData() {
    // call function from sort component
    if (this.state.page_type === "Display Component") {
      this.handleCallChildFunction();
    }
    if (this.state.page_type === "Form Component") {
      this.getMetaData.sendAppDataToParent();
    }
    if (this.state.page_type === "Store Component") {
      this.getStoreDetails.storedDropdowndata();
    }
  }

  savePageEditiorData() {
    let objData = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "fc_app_pages",
      id: this.props.match.params.id,
    };
    getRecordData(objData)
      .then((response) => {
        if (response.success === true && response.data !== null) {
          this.setState(
            {
              appPageName: response.data.function_name,
              appPageDescription: response.data.description,
              appId: response.data.app_id,
            },
            () => {
              this.submitAppFields();
            }
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  handleFiles = (files) => {
    let hasError = false;
    if (
      files[0].name.includes(".csv") ||
      files[0].name.includes(".xlsx") ||
      files[0].name.includes(".xlx")
    ) {
      hasError = false;
    }
    if (!hasError) {
      let formData = new FormData();
      formData.append("data_file", files[0]);
      this.uploadFile(formData);
    } else {
      toast.error("Please Select Proper File Format");
    }
  };

  uploadFile = (formData) => {
    let obj = {
      parentDatasetId: this.state.parentDatasetId,
      DataStructureObjId: this.state.DataStructureObjId,
    };
    this.setState({ isLoading: true });
    importDatasetlData(formData, obj)
      .then((response) => {
        toast.success(response.message);
        this.getMetaData.onloadGetData();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ isLoading: false });
      });
  };

  submitAppFields() {
    if (this.state.page_type === "Form Component") {
      if (this.state.sortedAppFields.length) {
        let sendFields = {
          tenant_id: this.state.tenant_Id,
          dataset_name: "fc_app_pages",
          object_id: this.props.match.params.id,
          fields: {
            function_name: this.state.appPageName,
            description: this.state.appPageDescription,
            app_id: this.state.appId,
            page_fields: this.state.sortedAppFields,
            query_data: this.state.queryData,
            generated_query: this.state.mongoQuery,
            page_type: "form_component",
            display_fields: [],
            form_output_key: this.state.form_output_key,
            logic_name_value: this.state.logicName,
            logic_name_id: this.state.logicNameId,
            pageTransition: this.state.pageTransition,
            storeComponentData: [],
            form_disclaimer: this.state.form_disclaimer,
            showHierarchyTree: this.state.showHierarchyTree,
            hierarchyTreeConfig: this.state.hierarchyTreeConfig,
            formGroupData: this.state.formGroupData || [],
            updateLogic: this.state.updateLogic,
            readAutoFillReference: this.state.readAutoFillReference,
            autoFillLogic: this.state.autoFillLogic,
            autoFillReference: this.state.autoFillReference,
          },
        };
        this.saveDisplayTypeData(sendFields);
      } else {
        toast.error("Fields Cannot Be Empty");
      }
    }
    if (this.state.page_type === "Display Component") {
      if (this.state.displayData.length) {
        let display_data_with_Id = this.state.displayData.map((row) => {
          if (row.componentType === "Table") {
            return row.component_id
              ? {
                  ...row,
                  actionDetails: row?.actionDetails.map((action) =>
                    action.action_id
                      ? action
                      : { ...action, action_id: uuidv4() }
                  ),
                }
              : {
                  ...row,
                  actionDetails: row?.actionDetails.map((action) =>
                    action.action_id
                      ? action
                      : { ...action, action_id: uuidv4() }
                  ),
                  component_id: uuidv4(),
                };
          } else {
            return { ...row, component_id: uuidv4() };
          }
        });

        let objData = {
          tenant_id: this.state.tenant_Id,
          dataset_name: "fc_app_pages",
          object_id: this.props.match.params.id,
          fields: {
            function_name: this.state.appPageName,
            description: this.state.appPageDescription,
            app_id: this.state.appId,
            page_fields: [],
            query_data: "",
            generated_query: "",
            page_type: "display_component",
            display_fields: display_data_with_Id,
            form_output_key: "",
            pageTransition: this.state.pageTransition,
            storeComponentData: [],
          },
        };
        this.saveDisplayTypeData(objData);
      } else {
        toast.error("Fields Cannot Be Empty");
      }
    }
    if (this.state.page_type === "Store Component") {
      this.storeComponentDetails();
    }
  }

  storeComponentDetails() {
    let sendFields = {
      tenant_id: this.state.tenant_Id,
      dataset_name: "fc_app_pages",
      object_id: this.props.match.params.id,
      fields: {
        function_name: this.state.appPageName,
        description: this.state.appPageDescription,
        app_id: this.state.appId,
        page_type: "store_component",
        storeComponentData: this.state.saveStoreDetailsData,
        page_transition_data: [],
      },
    };
    this.saveDisplayTypeData(sendFields);
  }

  saveDisplayTypeData(sendFields) {
    saveAppResonseByJson(sendFields)
      .then((response) => {
        if (response.success === true) {
          toast.success("Data Saved Successfully");
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: err.message });
        if (this.state.errorMessage) {
          toast.error(this.state.errorMessage);
        }
      });
  }

  changePageType = (event) => {
    let optionValue = event.target.value;
    if (optionValue === "Table Component") {
      this.setState({
        showQueryGenerator: true,
        page_type: optionValue,
      });
    } else {
      this.setState({
        showQueryGenerator: false,
        page_type: optionValue,
      });
    }
  };

  changeValue = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSearchableDropdown = (option, action) => {
    this.setState({
      [action?.name]: option || "",
    });
  };

  datasetChange(event) {
    this.setState({ datasetName: event.target.value });
    let bodyObj = {
      dataset_id: event.target.value,
      tenant_id: this.state.tenant_Id,
    };
    getMetaData(bodyObj)
      .then((response) => {
        if (response.success) {
          this.setState({
            datasetFields:
              response?.data?.fields?.length > 0 ? response?.data?.fields : [],
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  }

  // return pageTransition data

  fetchPageTransition(data) {
    this.setState({
      pageTransition: data,
    });
  }

  returnFormGroup = (data) => {
    this.setState({ formGroupData: data });
  };

  // return store details data
  returnStoreDetails(data) {
    this.setState(
      {
        saveStoreDetailsData: data,
      },
      () => {
        this.savePageEditiorData();
      }
    );
  }

  render() {
    let pageTypeList = this.state.pageType.map((data, index) => (
      <option key={index} value={data}>
        {data}
      </option>
    ));

    let showOnApp = ["No", "Yes"];
    const showOnAppOptionList = showOnApp.map((data, index) => (
      <option key={index} value={data}>
        {data}
      </option>
    ));

    const dataSetNameOptions = this.state.datasetNames?.map(
      (dataset, index) => (
        <option key={index} value={dataset.dataset_id}>
          {dataset.dataset_name}
        </option>
      )
    );

    return (
      <div>
        <div
          className={`py-2 ${
            this.props.match.path !== "/app_page/page_editor/:id" &&
            "d-flex align-items-center justify-content-between"
          }`}
        >
          {this.props.match.path === "/add_app_page/:appId" ? (
            <div className="d-help py-2 col-12 flex-wrap">
              <h5>Add Page</h5>
              <button
                type="button"
                className="btn btn-secondary btn-sm m-0"
                onClick={(e) => {
                  e.preventDefault();
                  this.props?.history?.goBack();
                }}
              >
                <FontAwesomeIcon icon="fa fa-arrow-left" />
                <span className="ms-1">Back</span>
              </button>
            </div>
          ) : this.state.parentDatasetId === "add dataset" ? (
            <div className="d-help py-2 col-12 flex-wrap">
              <h5>Add Dataset</h5>
              <button
                type="button"
                className="btn btn-secondary btn-sm m-0"
                onClick={(e) => {
                  e.preventDefault();
                  this.props?.history?.goBack();
                }}
              >
                <FontAwesomeIcon icon="fa fa-arrow-left" />
                <span className="ms-1">Back</span>
              </button>
            </div>
          ) : !this.state.addPageVariable ? (
            <div className="row">
              <div className="col-12 d-help flex-wrap m-2 p-2 data-structure-header">
                    <h5>{this.state.logicNameValue}- ({this.state.logicDescriptionValue})</h5>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props?.history?.goBack();
                  }}
                >
                  <FontAwesomeIcon icon="fa fa-arrow-left" />
                  <span className="ms-1">Back</span>
                </button>
              </div>
              <div className="col-md-6 col-sm-12 mb-2">
                <label className=" text-nowrap mb-0">Component Type</label>
                {this.props.match.path === "/app_page/page_editor/:id" ? (
                  <div>
                    {!this.props.changeDataFlag ? (
                      <div>
                        <Form.Select
                          onChange={this.changePageType}
                          className="form-control"
                          name="is_hidden"
                          value={this.state.page_type}
                        >
                          {pageTypeList}
                        </Form.Select>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {this.props.match.path === "/app_page/page_editor/:id" &&
              this.state.page_type === "Form Component" ? (
                <div className="col-md-6 col-sm-12 mb-2">
                  <label className="text-nowrap mb-0">
                    Form Input Key for Logic
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="form_output_key"
                      placeholder="Enter Form Key"
                      value={this.state.form_output_key}
                      onChange={this.changeValue}
                      required
                    ></input>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <h5 className="mb-0">Dataset Editor</h5>
          )}
          {this.props.match.path === "/app_page/page_editor/:id" ? (
            this.state.page_type === "Form Component" ? (
              <div className="row px-0 mb-2">
                <div className="col-md-4 col-sm-12">
                  <label className="mb-1">Select Insert Logic</label>
                  <Select
                    options={this.state.logicOptions}
                    isClearable
                    value={
                      this.state.logicNameId
                        ? {
                            label: this.state.logicName,
                            value: this.state.logicNameId,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      this.setState({
                        logicName: e?.label,
                        logicNameId: e?.value,
                      });
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label className="mb-1">Select Update Logic</label>
                  <Select
                    options={this.state.logicOptions}
                    isClearable
                    value={
                      this.state.updateLogic?.value
                        ? this.state.updateLogic
                        : ""
                    }
                    onChange={(e) => {
                      this.setState({
                        updateLogic: e || "",
                      });
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label className="mb-0">Import Fields from DataSet</label>
                  <div className="x_content">
                    <Form.Select
                      onChange={(event) => {
                        this.datasetChange(event);
                      }}
                      className="form-control"
                      name="datasetName"
                      value={this.state.datasetName}
                    >
                      <option value={""} disabled>
                        {this.state.isDatasetLoading ? "Loading.." : "Select"}
                      </option>
                      {dataSetNameOptions}
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 mb-2">
                  <label className="mb-1">Form Disclaimer</label>
                  <input
                    type="text"
                    className="form-control"
                    name="is_hidden"
                    placeholder="Enter Form Disclaimer"
                    value={this.state.form_disclaimer}
                    onChange={(e) => {
                      this.setState({ form_disclaimer: e.target.value });
                    }}
                    required
                  ></input>
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                  <label className="mb-1">Show Hierarchy Tree</label>
                  <Form.Select
                    onChange={(event) => {
                      this.setState({
                        showHierarchyTree: event.target.value,
                      });
                    }}
                    className="form-control"
                    name="showHierarchyTree"
                    value={this.state.showHierarchyTree}
                  >
                    {showOnAppOptionList}
                  </Form.Select>
                </div>

                {this.state.showHierarchyTree === "Yes" && (
                  <div className="form-group">
                    <div className="bg-white border p-2">
                      <h5>Hierarchy Tree</h5>
                      <div className="row">
                        <div className="col-md-4 col-sm-12 mb-2">
                          <label className="">Logic Name</label>
                          <Select
                            menuPosition="fixed"
                            classNames={{
                              control: (state) => "searchableDropdownControl",
                              menuPortal: () => "searchableDropdownMenuPortal",
                              singleValue: () => "searchableDropdownValue",
                              multiValue: () => "searchableDropdownValue",
                              menuList: () => "searchableDropdownMenuList",
                            }}
                            placeholder="Select.."
                            options={this.state.logicOptions}
                            value={
                              this.state?.hierarchyTreeConfig
                                ?.selectedHierarchyLogic
                            }
                            onChange={(e) => {
                              let hierarchyObj = {
                                ...this.state.hierarchyTreeConfig,
                              };
                              hierarchyObj.selectedHierarchyLogic = e || "";
                              this.setState({
                                hierarchyTreeConfig: hierarchyObj,
                              });
                            }}
                            isClearable
                          />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-2">
                          <label className="">Update Hierarchy Logic</label>
                          <Select
                            menuPosition="fixed"
                            classNames={{
                              control: (state) => "searchableDropdownControl",
                              menuPortal: () => "searchableDropdownMenuPortal",
                              singleValue: () => "searchableDropdownValue",
                              multiValue: () => "searchableDropdownValue",
                              menuList: () => "searchableDropdownMenuList",
                            }}
                            placeholder="Select.."
                            options={this.state.logicOptions}
                            value={
                              this.state?.hierarchyTreeConfig
                                ?.updateHierarchyLogic
                            }
                            onChange={(e) => {
                              let hierarchyObj = {
                                ...this.state.hierarchyTreeConfig,
                              };
                              hierarchyObj.updateHierarchyLogic = e || "";
                              this.setState({
                                hierarchyTreeConfig: hierarchyObj,
                              });
                            }}
                            isClearable
                          />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-2">
                          <label className="text-nowrap">
                            Update Hierarchy Reference
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Reference"
                            value={
                              this.state?.hierarchyTreeConfig
                                ?.updateHierarchyReference
                            }
                            onChange={(e) => {
                              let hierarchyObj = {
                                ...this.state.hierarchyTreeConfig,
                              };
                              hierarchyObj.updateHierarchyReference =
                                e.target.value || "";
                              this.setState({
                                hierarchyTreeConfig: hierarchyObj,
                              });
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-4 col-sm-12 mb-2 form-group">
                  <label className="text-nowrap mb-0">
                    Read AutoFill Reference
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="readAutoFillReference"
                      placeholder="Reference"
                      value={this.state.readAutoFillReference}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                      required
                    ></input>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mb-2">
                  <label className="">Auto Fill Logic</label>
                  <Select
                    name="autoFillLogic"
                    menuPosition="fixed"
                    classNames={{
                      control: () => "searchableDropdownControl",
                      menuPortal: () => "searchableDropdownMenuPortal",
                      singleValue: () => "searchableDropdownValue",
                      multiValue: () => "searchableDropdownValue",
                      menuList: () => "searchableDropdownMenuList",
                    }}
                    placeholder="Select.."
                    options={this.state.logicOptions}
                    value={this.state?.autoFillLogic}
                    onChange={this.handleSearchableDropdown}
                    isClearable
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-2 form-group">
                  <label className="text-nowrap mb-0">AutoFill Reference</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="autoFillReference"
                      placeholder="Reference"
                      value={this.state.autoFillReference}
                      onChange={(e) => {
                        this.changeValue(e);
                      }}
                      required
                    ></input>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}

          {this.props.match.path === "/add_app_page/:appId" ? (
            <div className="dataset-btn"></div>
          ) : this.state.addPageVariable ? (
            <div>
              <div className="dataset-btn d-flex">
                {this.props.match.path === "/dataset/:id" ? (
                  <ReactFileReader
                    disabled={this.state.isLoading}
                    fileTypes={[".xlsx", ".xls", ".csv"]}
                    handleFiles={this.handleFiles}
                    multipleFiles={false}
                  >
                    <button
                      disabled={this.state.isLoading}
                      className="btn btn-primary btn-md"
                    >
                      <FontAwesomeIcon icon="fa fa-file-import" />
                      <span className="ms-1">Import</span>
                    </button>
                  </ReactFileReader>
                ) : null}
                <button
                  className="btn btn-sm btn-secondary text-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props?.history?.goBack();
                  }}
                >
                  <FontAwesomeIcon icon="fa fa-arrow-left" />
                  <span className="ms-2">Back</span>
                </button>
              </div>
            </div>
          ) : null}
        </div>
        {(this.props.match.path === "/app_page/page_editor/:id" &&
          this.state.page_type === "Form Component") ||
        this.state.page_type === "Display Component" ? (
          <div className="page-transitions">
            <PageTransitions
              onChange={this.fetchPageTransition}
              value={this.state.pageTransition}
              pageTransitionType={this.state.page_type}
            />
          </div>
        ) : null}
        {this.state.page_type === "Store Component" ? (
          <StoreBuilder
            onRef={(ref) => (this.getStoreDetails = ref)}
            returnStoreDetailsData={this.returnStoreDetails}
            sendReturnedDataToStore={this.state.saveStoreDetailsData}
          ></StoreBuilder>
        ) : null}

        <div
          className={
            this.props.match.path === "/app_page/page_editor/:id"
              ? "row flex-row-reverse"
              : "row"
          }
        >
          <div
            className={
              this.state.parentDatasetId === "add dataset"
                ? "col-md-12"
                : "col-md-6"
            }
          >
            <div className="row">
              {this.state.addPageVariable ? (
                <div className="col-md-12">
                  <DatasetForm
                    onRef={(ref) => (this.formComponent = ref)}
                    returnObjDataToParent={this.getFormData}
                  />
                </div>
              ) : null}
              <div className="col-md-12 sort-data">
                {this.state.page_type === "Form Component" ? (
                  <SortData
                    datasetFields={this.state.datasetFields}
                    onRef={(ref) => (this.getMetaData = ref)}
                    sendFieldArray={this.state.getPageFieldArray}
                    getSortedData={this.getSortedData}
                    sendAppData={this.sendAppData}
                    load={this.load}
                    getAppId={this.getAppIdfromSort}
                    getPageType={this.getPageTypeFromSort}
                    getdataSetObjectId={this.getdataSetObjectId}
                    sendFields={
                      this.state.getPageFieldsDetails
                        ? this.state.getPageFieldsDetails
                        : []
                    }
                  ></SortData>
                ) : null}

                {this.state.addPageVariable === false &&
                this.state.showQueryGenerator === true ? (
                  <div className="col-md-12 p-0">
                    <QueryBuilderComponent
                      getAppId={this.props.match}
                      fetchQueryData={this.fetchQueryData}
                      fetchMongoDbData={this.fetchMongoData}
                    ></QueryBuilderComponent>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={
              this.state.page_type === "Display Component"
                ? "col-md-12"
                : "col-md-6"
            }
          >
            <div className="add-field">
              <AddDetails
                onRef={(ref) => (this.formFieldComponent = ref)}
                formFieldData={this.getFormFieldData}
                addPageUrl={this.state.addPageVariable}
                pageTypeDetails={this.state.page_type}
                appId={this.state.appId}
                sendFields={
                  this.state.getPageFieldsDetails
                    ? this.state.getPageFieldsDetails
                    : []
                }
              ></AddDetails>
            </div>
          </div>
          {this.state.addPageVariable === false &&
          this.state.page_type === "Display Component" ? (
            <div className="col-md-12">
              <div className="col-md-12 p-0">
                <DisplayBuilder
                  ref={this.displayComponentRef}
                  getAppId={this.props.match}
                  appId={this.state.appId}
                  returnData={this.fetchDisplayData}
                  sendDisplayData={this.state.displayData}
                ></DisplayBuilder>
              </div>
            </div>
          ) : null}
        </div>
        {this.props.match.path === "/app_page/page_editor/:id" &&
        this.state.page_type === "Form Component" ? (
          <div className="page-transitions">
            {!this.state.loading && (
              <AddFormGroup
                fieldsList={this.state?.getPageFieldsDetails}
                funtionToCall={this.returnFormGroup}
                sectionInFormData={this.state.fetchFormGroupData}
              />
            )}
          </div>
        ) : null}
        <div className="text-end mt-2">
          {this.state.addPageVariable ? (
            <div>
              {this.props.match.path === "/add_app_page/:appId" ? (
                <button
                  className="btn btn-secondary btn-md btn-success me-0 floating-button"
                  onClick={this.onClickFetchData}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className="me-1">Next</span>&nbsp;&nbsp;
                  <FontAwesomeIcon icon="fa fa-arrow-right" />&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
              ) : (
                <button
                  className="btn btn-secondary btn-md btn-success me-0 floating-button"
                  onClick={this.onClickFetchData}
                >
                  {this.state.parentDatasetId === "add dataset" ? (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;<span className="me-1">Next</span>&nbsp;&nbsp;
                      <FontAwesomeIcon icon="fa fa-arrow-right" />&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                  ) : (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-plus" />&nbsp;&nbsp;
                      <span className="ms-1">Add Field</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                  )}
                </button>
              )}
            </div>
          ) : (
            <div className="btnpos">
              <button
                className="btn btn-secondary btn-md btn-success floating-button"
                onClick={() => this.receiveSortData()}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                <span className="me-1">Submit</span>&nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          )}
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default DatasetStructure;
