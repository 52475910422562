import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import AdminLogin from "./components/adminlogin/AdminLogin";
import Login from "../src/components/login/Login";
import Tenant from "./components/tenant/Tenant";
import CustomerTenant from "./components/customerTenant/CustomerTenant";
import ForgotPassword from "./components/login/ForgotPassword";
import ShareProduct from "./components/common/share/Shareproduct";
import ShareproductList from "./components/common/share/ShareproductList";
import LoginRoutes from "./LoginRoutes";
import ShareAppUrl from "./components/shareapp/ShareAppUrl";
import CloneTenant from "./components/tenant/CloneTenant";
import SideMenu from "./components/common/sideMenu/SideMenu";
import Header from "./components/common/header/Header";
import SubscriptionList from "./components/subscription/SubscriptionList";
import SubscriptionForm from "./components/subscription/SubscriptionForm";
import { RoutesContext } from "./RoutesContext";
import CustomRedirectShareApp from "./components/shareapp/CustomRedirectShareApp";
import { getTenantDetail } from "./services/appService";

function Routes() {
  return (
    <BrowserRouter>
      <div id="main" className="nav-sm">
        <div className="body bodyBackground">
          <Main />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Routes;

const Main = withRouter(() => {
  const [routesData, setRoutesData] = useState({});
  const isAdmin =
    JSON.parse(
      sessionStorage.getItem("admin-info")
    )?.role_name?.toLowerCase() === "admin";

  let isUserAdmin = JSON.parse(localStorage.getItem("role"));
  let queryParam = JSON.parse(localStorage.getItem("query_param"));

  useEffect(() => {
    if (queryParam) {
      getTenantDetail(queryParam).then((res) => {
        const [obj] = res.data;
        setRoutesData({
          ...routesData,
          logo: obj.data_file,
        });
      });
    }
  }, [queryParam]);

  const [cachedData, setCacheData] = useState({});

  return (
    <RoutesContext.Provider
      value={{ routesData, setRoutesData, cachedData, setCacheData }}
    >
      <Switch>
        <Route path={"/"} exact={true} component={Login} />
        <Route path={"/login"} exact={true} component={Login} />
        <Route path={"/app/:app_id"} exact={true} component={ShareAppUrl} />
        <Route path={"/a"} exact={true} component={CustomRedirectShareApp} />
        <Route
          path={"/forgot_password"}
          exact={true}
          component={ForgotPassword}
        />
        <Route
          path={"/reset_password"}
          exact={true}
          component={ForgotPassword}
        />
        <Route path={"/on-boarding"} exact={true} component={CustomerTenant} />
        <Route path={"/dmadmin"} exact={true} component={AdminLogin} />

        {!isAdmin && !isUserAdmin ? (
          <Route
            path={"/share_wishlist_product_list/:tenant_Id/:wishlist_Id"}
            exact={true}
            component={ShareproductList}
          />
        ) : null}
        {!isAdmin && !isUserAdmin ? (
          <Route
            path={"/share_product/:product_id/:tenant_Id"}
            exact={true}
            component={ShareProduct}
          />
        ) : null}

        {/* Super Admin Login Routes Here Without Sidemenu And Header */}
        {isAdmin ? (
          <Route path={"/tenant"} exact={true} component={Tenant} />
        ) : null}

        {isAdmin ? (
          <>
            <SideMenu />
            <div className="right_col">
              <Header />
              <div className="row">
                <div className="col-md-12">
                  <div className="px-3 pt-2 pb-3 rightMainContainer">
                    <>
                      {/* Super Admin Login Routes Here With Sidemenu And Header */}
                      <Switch>
                        <Route
                          path={"/clone-tenant"}
                          exact={true}
                          component={CloneTenant}
                        />
                        <Route
                          path={"/subscriptionlist"}
                          exact={true}
                          component={SubscriptionList}
                        />
                        <Route
                          path={"/subscriptionform/:id/:tenant_id/:tenant_name"}
                          exact={true}
                          component={SubscriptionForm}
                        />
                        <Route
                          path={"/share_product/:product_id/:tenant_Id"}
                          exact={true}
                          component={ShareProduct}
                        />
                        <Route
                          path={
                            "/share_wishlist_product_list/:tenant_Id/:wishlist_Id"
                          }
                          exact={true}
                          component={ShareproductList}
                        />
                        <Route path={"*"} exact={true}>
                          <h1>404 Error</h1>
                        </Route>
                      </Switch>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* Tenant User Admin Route Here */}
        {isUserAdmin ? <LoginRoutes /> : null}

        <Route path={"*"} exact={true}>
          <Redirect to="/login?tenant_name=" />
        </Route>
      </Switch>
    </RoutesContext.Provider>
  );
});
