import { Form } from "react-bootstrap";

const CustomNumberTextBox = ({ field, value, onChange, disabled = false }) => {
  const dateOnChange = (e) => {
    const changeValue = e.target.value;
    if (isNaN(changeValue)) {
      onChange("");
    } else {
      onChange(Number(changeValue));
    }
  };

  return (
    <Form.Control
      disabled={disabled}
      size="sm"
      className="fieldMinimumWidth fieldMaximumWidth"
      type="number"
      onChange={dateOnChange}
      value={value}
    />
  );
};

export default CustomNumberTextBox;
