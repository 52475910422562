import React, { useContext, useMemo, useState } from "react";
import "./Kanban.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import KanbanLane from "./KanbanLane";
import { DragDropContext } from "react-beautiful-dnd";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { reactAppFormExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import { filterFormDataGetter } from "./kanbanCardUtils";

const Kanban = ({
  appSessionId,
  componentData,
  displayData,
  objData,
  submitFormPayload,
}) => {
  const { filterFormData } = useContext(FilterFormContext);
  const [toggleBoardView, setToggleBoardView] = useState(true);
  const [boardData, setBoardData] = useState({
    pageName: objData?.page?.function_name,
    boardId: displayData?.component_id,
    lanes: displayData?.lanes.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: { ...item, cardIds: [] } }),
      {}
    ),
    laneOrder: displayData?.lanes.map((lane) => lane.id),
  });
  const [isLoading, setIsLoading] = useState(true);

  useMemo(() => {
    if (componentData?.kanban_structure?.cards) {
      setBoardData(componentData?.kanban_structure);
    } else {
      if (Object.values(filterFormData).every((value) => value === "")) {
        setBoardData({
          ...boardData,
          cards: {},
          lanes: displayData?.lanes.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.id]: { ...item, cardIds: [] } }),
            {}
          ),
        });
      } else {
        setBoardData(boardData);
      }
    }
  }, [componentData, filterFormData]);

  useMemo(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [componentData]);

  const handleOnDragEnd = async (result) => {
    try {
      const { destination, source, draggableId } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const start = boardData.lanes[source.droppableId];
      const finish = boardData.lanes[destination.droppableId];

      if (start === finish) {
        try {
          const newCardIds = Array.from(start.cardIds);
          newCardIds.splice(source.index, 1);
          newCardIds.splice(destination.index, 0, draggableId);

          const newLane = {
            ...start,
            cardIds: newCardIds,
          };

          const newBoardData = {
            ...boardData,
            lanes: {
              ...boardData.lanes,
              [newLane.id]: newLane,
            },
          };

          //when the filter is not applied then this data will be send otherwise the filterform data will be send
          let filterformDataToSend = filterFormDataGetter(
            filterFormData,
            boardData?.cards?.[draggableId]
          );

          const kanbanStructure = {
            kanban_structure: newBoardData,
            card_data: boardData.cards[draggableId],
            lane_id: destination.droppableId,
            card_id: draggableId,
            board_id: newBoardData.boardId,
            ...filterformDataToSend,
            ...boardData.cards[draggableId],
          };

          const newSubmitFormPayload = {
            ...submitFormPayload,
            data: {
              ...submitFormPayload.data,
              formData: [
                {
                  ...submitFormPayload.data.formData?.[0],
                  ...kanbanStructure,
                },
              ],
            },
          };

          setBoardData(newBoardData);

          const res = await reactAppFormExecutionVP(newSubmitFormPayload);
          if (res.status === 200) {
            setBoardData(newBoardData);
          }
        } catch (error) {
          setBoardData(boardData);
          toast.error(error.message);
        }
        return;
      }

      // Moving from one lane to another
      const startCardIds = Array.from(start.cardIds);
      startCardIds.splice(source.index, 1);
      const newStart = {
        ...start,
        cardIds: startCardIds,
      };

      const finishCardIds = Array.from(finish.cardIds);
      finishCardIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        cardIds: finishCardIds,
      };

      const newBoardData = {
        ...boardData,
        lanes: {
          ...boardData.lanes,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };

      //when the filter is not applied then this data will be send otherwise the filterform data will be send
      let filterformDataToSend = filterFormDataGetter(
        filterFormData,
        boardData?.cards?.[draggableId]
      );

      const kanbanStructure = {
        kanban_structure: newBoardData,
        card_data: boardData.cards[draggableId],
        lane_id: destination.droppableId,
        card_id: draggableId,
        board_id: newBoardData.boardId,
        ...filterformDataToSend,
        ...boardData.cards[draggableId],
      };

      const newSubmitFormPayload = {
        ...submitFormPayload,
        data: {
          ...submitFormPayload.data,
          formData: [
            {
              ...submitFormPayload.data.formData?.[0],
              ...kanbanStructure,
            },
          ],
        },
      };

      setBoardData(newBoardData);

      const res = await reactAppFormExecutionVP(newSubmitFormPayload);
      if (res.status === 200) {
        setBoardData(newBoardData);
      }
    } catch (error) {
      setBoardData(boardData);
      toast.error(error.message);
    }
  };

  let boardStyle = {
    backgroundColor: displayData?.boardBgColor || "#f6f7fb",
  };

  return (
    <div className="overflow-auto kanban-board-container mb-2 p-1">
      <div className="main-content bg-white py-1 px-1 mb-2">
        <div className="borderBottom">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
            <div className="text-capitalize">
              <h5 className="m-0 kanban-board-heading">
                {displayData?.boardName || "Board"}
              </h5>
            </div>
            <div className="d-flex align-items-center">
              {displayData?.transitionAppId && displayData?.transitionPageId ? (
                <div className="d-flex align-items-center transition-header-icon">
                  <Link
                    to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                  >
                    <button
                      className="header-icon-button icon-hover-effect"
                      title={displayData?.transitionActionTitle ?? ""}
                    >
                      <FontAwesomeIcon
                        icon={
                          displayData?.transitionSelectedIcon?.value ??
                          "fa fa-circle-question"
                        }
                        size="md"
                      />
                    </button>
                  </Link>
                </div>
              ) : null}
              <button
                className="arrowDownContainer header-icon-button icon-hover-effect"
                title={toggleBoardView ? "Collapse Board" : "Expand Board"}
                onClick={() => setToggleBoardView(!toggleBoardView)}
                aria-controls="board-toggle"
                aria-expanded={toggleBoardView}
              >
                <FontAwesomeIcon
                  icon={"fa fa-angle-down"}
                  className={`arrow-icon ${
                    toggleBoardView ? "arrow-icon-up" : "arrow-icon-down"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
        <Collapse in={toggleBoardView}>
          <div id="board-toggle" className="kanban-board-container">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <div style={boardStyle}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <div className="kanban-board">
                    <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row">
                      {boardData.laneOrder?.map((laneId) => {
                        const lane = boardData.lanes?.[laneId];
                        const cards = lane.cardIds.map(
                          (cardId) => boardData.cards?.[cardId]
                        );
                        return (
                          <KanbanLane
                            key={lane.id}
                            boardData={boardData}
                            setBoardData={setBoardData}
                            lane={lane}
                            cards={cards}
                            displayData={displayData}
                            objData={objData}
                            appSessionId={appSessionId}
                            submitFormPayload={submitFormPayload}
                          />
                        );
                      })}
                    </div>
                  </div>
                </DragDropContext>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Kanban;
