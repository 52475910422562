import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Collapse, Form } from "react-bootstrap";

const SortAndFilter = ({
  column,
  sysFilter,
  setFilterCriteria,
  setActiveColumnFilter,
  showFilter,
  setFilterSortCriteria,
}) => {
  const [filterObject, setFilterObject] = useState({});

  const initialFilter = {
    type: "equal",
    filter: "",
    filter_field: column.name,
    filter_type: "text",
  };

  const isFilterObject = sysFilter.FilterCriteria?.find(
    (filter) => filter.filter_field === column.name
  );

  useEffect(() => {
    if (isFilterObject) {
      setFilterObject(isFilterObject);
    } else {
      setFilterObject(initialFilter);
    }
  }, [JSON.stringify(isFilterObject)]);

  useEffect(() => {
    setFilterObject(initialFilter);
  }, []);

  useEffect(() => {
    let timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      if (filterObject.filter) {
        if (isFilterObject) {
          let filterData = sysFilter.FilterCriteria?.filter(
            (filter) => filter.filter_field !== column.name
          );
          setFilterCriteria([...filterData, filterObject]);
        } else {
          setFilterCriteria([...sysFilter.FilterCriteria, filterObject]);
        }
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [JSON.stringify(filterObject)]);

  const sortHeaderData = () => {
    let filter_sort_criteria = [];
    let find = sysFilter.SortCriteria.find(
      (criteria) => criteria.sort_field === column.name
    );
    if (find) {
      filter_sort_criteria = sysFilter.SortCriteria.map((criteria) => {
        if (criteria.sort_field === find.sort_field) {
          if (criteria.sort_type === "asc") {
            return { ...criteria, sort_type: "desc" };
          } else if (criteria.sort_type === "desc") {
            return null;
          }
        } else {
          return criteria;
        }
      });
    } else {
      filter_sort_criteria = [
        ...sysFilter.SortCriteria,
        {
          sort_field: column.name,
          sort_type: "asc",
        },
      ];
    }
    setFilterSortCriteria(filter_sort_criteria.filter((data) => data != null));
  };

  const getSortType = () => {
    let find = sysFilter.SortCriteria.find(
      (criteria) => criteria?.sort_field === column.name
    );
    if (find) {
      return find.sort_type === "asc" ? "fa fa-arrow-up" : "fa fa-arrow-down";
    } else {
      return false;
    }
  };

  const changeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFilterObject({ ...filterObject, [name]: value });
  };

  const valueChangeHandler = (e) => {
    let value = e.target.value;
    if (value) {
      changeHandler(e);
    } else {
      setFilterObject({ ...filterObject, filter: "" });
      let filterData = sysFilter.FilterCriteria?.filter(
        (filter) => filter.filter_field !== column.name
      );
      setFilterCriteria(filterData);
    }
  };

  const clearFilterColumn = () => {
    if (isFilterObject) {
      let filterCriteria = [...sysFilter.FilterCriteria];
      setFilterCriteria(
        filterCriteria.filter((data) => data.filter_field !== column.name)
      );
      setFilterObject(initialFilter);
      setActiveColumnFilter("");
    }
  };

  const conditionalTextOperator = ["contains", "equal"].map((data, index) => {
    return (
      <option value={data} key={index}>
        {data}
      </option>
    );
  });

  const conditionalNumOperator = [
    "equal",
    "less than",
    "less than or equals",
    "greater than",
    "greater than or equals",
  ].map((data, index) => {
    return (
      <option value={data} key={index}>
        {data}
      </option>
    );
  });

  return (
    <>
      <div className="d-flex align-items-center justify-content-center ">
        <FontAwesomeIcon
          icon={getSortType() ? getSortType() : "fa fa-sort"}
          onClick={sortHeaderData}
          className={`ms-2 pointer`}
        />

        <div
          title="Double Click To Clear Filter"
          onClick={() => setActiveColumnFilter(showFilter ? "" : column.name)}
          className={`ms-2 pointer position-relative`}
          onDoubleClick={clearFilterColumn}
        >
          <FontAwesomeIcon icon={"fa fa-bars"} />
          {isFilterObject && <div className="bg-danger isFiltered" />}
        </div>
      </div>
      <Collapse in={showFilter}>
        <div className="border rounded position-absolute filterColumnContainer p-2 rightMainContainer">
          <Form.Select
            size="sm"
            name="type"
            className="mb-2"
            value={filterObject.type}
            onChange={changeHandler}
          >
            {column.data_type === "number"
              ? conditionalNumOperator
              : conditionalTextOperator}
          </Form.Select>
          <Form.Control
            size="sm"
            type="text"
            name="filter"
            placeholder="Search.."
            value={filterObject.filter}
            onChange={valueChangeHandler}
          />
        </div>
      </Collapse>
    </>
  );
};

export default SortAndFilter;
