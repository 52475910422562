import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import ActionButtonList from "../../../react_engine/SubTable/ActionButtonList";
import RelationshipBuilder from "./RelationshipBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { autoReloadComponentDelaysList, displayFormatList } from "../../../common/model/Model";

const DisplayBuilderListView = ({
  displayBuilder,
  columnWidthList,
  selectFormList,
  onChange,
  logicList,
  formsList,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  const [sectionNames, setSectionNames] = useState([]);
  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, []);
  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const actionListChange = (actionList) => {
    if (actionList?.length) {
      const data = { ...displayChanges };
      data["actionDetails"] = actionList;
      setDisplayChanges(data);
    }
  };

  const relationsChange = (relations) => {
    if (relations?.length) {
      const data = { ...displayChanges };
      data["relations"] = relations;
      setDisplayChanges(data);
    }
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  const handleSectionNameChange = (index, event) => {
    const newSectionNames = [...displayChanges.sectionNames];
    newSectionNames[index] = event.target.value;
    const data = { ...displayChanges, sectionNames: newSectionNames };
    setDisplayChanges(data);
  };

  const addSectionName = () => {
    const newSectionNames = [...displayChanges.sectionNames, ""];
    const data = { ...displayChanges, sectionNames: newSectionNames };
    setDisplayChanges(data);
  };

  const deleteSectionName = (index) => {
    const newSectionNames = [...displayChanges.sectionNames];
    newSectionNames.splice(index, 1);
    setSectionNames(newSectionNames);

    const data = { ...displayChanges, sectionNames: newSectionNames };
    setDisplayChanges(data);
  };

  const autoReloadDelays = useMemo(
    () =>
      autoReloadComponentDelaysList?.map((data, index) => (
        <option key={index} value={data.value}>
          {data.label}
        </option>
      )),
    []
  );

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.listRefValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="listRefValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.listRefValue}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group ">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.listComponentLogic
            )}
            onChange={(e) => searchableValueChange(e, "listComponentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columnWidthTable}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columnWidthTable"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">List Name</label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.listName?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter Table Name"
            name="listName"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.listName}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Form Page</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.formId || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="formId"
          >
            <option value="">Select</option>
            {selectFormList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-12 my-2">
        <div className="row">
          <div className="col-md-3 col-sm-6  form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="showFilter"
                checked={
                  displayChanges?.showFilter ||
                  displayChanges?.showFilter === undefined
                    ? true
                    : false
                }
              />
              <label className="mb-0">Show Filter</label>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                checked={
                  displayChanges?.showPagination ||
                  displayChanges?.showPagination === undefined
                    ? true
                    : false
                }
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="showPagination"
              />
              <label className="mb-0">Show Pagination</label>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group border m-2">
        <div className="p-2">
          <button onClick={addSectionName} className="btn btn-md btn-primary ">
            Add Section name
          </button>
          {displayChanges?.sectionNames?.map((sectionName, index) => (
            <div
              key={index}
              className="form-group d-flex justify-content-center align-items-center"
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Enter section name"
                value={sectionName}
                onChange={(e) => handleSectionNameChange(index, e)}
              />
              <FontAwesomeIcon
                icon={"fa fa-minus"}
                className="cursor-pointer ms-2 "
                onClick={() => deleteSectionName(index)}
              />
            </div>
          ))}
        </div>
      </div>
      
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="col-md-9 col-sm-9 ">
          <label className="form-label p-0 mt-2">
            <input
              className="me-2"
              type="checkbox"
              onChange={(e) => {
                checkBoxChangeValue(e);
              }}
              name="autoReloadComponent"
              checked={displayChanges?.autoReloadComponent || false}
            />
            Auto reload
          </label>
        </div>
        {displayChanges.autoReloadComponent && (
          <div className="col-md-9 col-sm-9 ">
            <label className="form-label p-0 mt-2">Select delay</label>
            <Form.Select
              className="form-control"
              name="autoReloadDelay"
              value={displayChanges.autoReloadDelay}
              onChange={(e) => {
                changeValue(e);
              }}
            >
              <option value="">--Select--</option>
              {autoReloadDelays}
            </Form.Select>
          </div>
        )}
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="d-flex">
          <input
            className="me-2"
            type="checkbox"
            onChange={(e) => {
              checkBoxChangeValue(e);
            }}
            name="isCacheEnabled"
            checked={displayChanges?.isCacheEnabled}
          />
          <label className="form-label p-0 mt-2">Enable Cache</label>
        </div>
      </div>

      <div>
        <div className="col-md-12 rounded p-2 border">
          <ActionButtonList
            appLogicList={logicList}
            actionList={displayChanges?.actionDetails}
            formsList={formsList}
            onChange={(e) => {
              actionListChange(e);
            }}
          />
        </div>
        <div className="col-md-12 rounded p-2 border mt-2">
          <RelationshipBuilder
            relations={displayChanges?.relations}
            onRelationshipChange={relationsChange}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderListView;
