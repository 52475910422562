import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import KanbanCardPreview from "./KanbanCardPreview";
import { getRecordDataById } from "../../../../../services/tableConfigService";
import Select from "react-select";

function DisplayBuilderKanbanCardKeyMapping({
  displayChanges,
  setDisplayChanges,
}) {
  const [showKanbanCardPreview, setShowKanbanCardPreview] = useState(false);
  const [kanbanCardPageFields, setKanbanCardPageFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});

  useMemo(() => {
    setPageFields();
  }, [displayChanges.formId]);

  function setPageFields() {
    const payloadForFetchingPageFields = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      id: displayChanges?.formId,
    };
    getRecordDataById(payloadForFetchingPageFields).then((res) => {
      const pageFieldsName = res.data.page_fields.map((field) => {
        return { label: field.name, value: field.name };
      });
      setKanbanCardPageFields(pageFieldsName);
    });
  }

  const handleSelectChange = (key, value) => {
    setSelectedFields({ ...selectedFields, [key]: { label: value, value } });
    const data = { ...displayChanges };
    data["key_mapping"] = {
      ...data?.["key_mapping"],
      [key]: value,
    };
    setDisplayChanges(data);
  };

  return (
    <>
      <div className="col-md-6 form-group">
        <div className="kanban-card-display-builder mb-2 rounded">
          <div className="py-2 m-2">
            <div className="border-bottom border-secondary border-opacity-25">
              <div className="d-flex justify-content-between align-items-center">
                <div className="col-md-4 col-lg-4 col-6">
                  <div className="input-group">
                    <Select
                      closeMenuOnSelect={selectedFields?.["label"]}
                      isClearable
                      menuPosition="fixed"
                      classNames={{
                        control: (state) => 'searchableDropdownControl',
                        menuPortal: () => 'searchableDropdownMenuPortal',
                        singleValue: () => 'searchableDropdownValue',
                        multiValue: () => 'searchableDropdownValue',
                        menuList: () => 'searchableDropdownMenuList'
                      }}
                      options={kanbanCardPageFields}
                      onChange={(e) => {
                        handleSelectChange("label", e.value);
                      }}
                      value={selectedFields?.["label"]}
                    />
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={"fa fa-eye"}
                    className="me-2"
                    color="#696969"
                    role="button"
                    title="Preview Card"
                    onClick={() => setShowKanbanCardPreview(true)}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={selectedFields?.["title"]}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("title", e.value);
                }}
                value={selectedFields?.["title"]}
              />
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={selectedFields?.["description"]}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("description", e.value);
                }}
                value={selectedFields?.["description"]}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="col-6 col-md-4 col-lg-4">
                <div className="my-2">
                  <Select
                    closeMenuOnSelect={selectedFields?.["from_section"]}
                    isClearable
                    menuPosition="fixed"
                    classNames={{
                      control: (state) => 'searchableDropdownControl',
                      menuPortal: () => 'searchableDropdownMenuPortal',
                      singleValue: () => 'searchableDropdownValue',
                      multiValue: () => 'searchableDropdownValue',
                      menuList: () => 'searchableDropdownMenuList'
                    }}
                    options={kanbanCardPageFields}
                    onChange={(e) => {
                      handleSelectChange("from_section", e.value);
                    }}
                    value={selectedFields?.["from_section"]}
                  />
                </div>
              </div>

              <div className="col-6 col-md-4 col-lg-4">
                <div className="my-2">
                  <Select
                    closeMenuOnSelect={selectedFields?.["to_section"]}
                    isClearable
                    menuPosition="fixed"
                    classNames={{
                      control: (state) => 'searchableDropdownControl',
                      menuPortal: () => 'searchableDropdownMenuPortal',
                      singleValue: () => 'searchableDropdownValue',
                      multiValue: () => 'searchableDropdownValue',
                      menuList: () => 'searchableDropdownMenuList'
                    }}
                    options={kanbanCardPageFields}
                    onChange={(e) => {
                      handleSelectChange("to_section", e.value);
                    }}
                    value={selectedFields?.["to_section"]}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={selectedFields?.["tags"]}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("tags", e.value);
                }}
                value={selectedFields?.["tags"]}
              />
            </div>

            <div className="border-top border-secondary border-opacity-25 pt-2 mt-2 d-flex justify-content-end">
              <div className="col-md-4 col-lg-4 col-6">
                <Select
                  closeMenuOnSelect={selectedFields?.["assignees"]}
                  isClearable
                  menuPosition="fixed"
                  classNames={{
                    control: (state) => 'searchableDropdownControl',
                    menuPortal: () => 'searchableDropdownMenuPortal',
                    singleValue: () => 'searchableDropdownValue',
                    multiValue: () => 'searchableDropdownValue',
                    menuList: () => 'searchableDropdownMenuList'
                  }}
                  options={kanbanCardPageFields}
                  onChange={(e) => {
                    handleSelectChange("assignees", e.value);
                  }}
                  value={selectedFields?.["assignees"]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <KanbanCardPreview
        show={showKanbanCardPreview}
        onHide={setShowKanbanCardPreview}
        displayData={displayChanges}
      />
    </>
  );
}

export default DisplayBuilderKanbanCardKeyMapping;
