import React from "react";
import "./CalculatedField.scss";

const CalculatedField = (props) => {

  return (
    <div className="position-relative">
      <span className="cursor-pointer details-label">
        {props?.field?.display_name}
      </span>
    </div>
  );
};

export default CalculatedField;
