import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { createNewCustomerTenant } from "../../services/appService";
import "./CustomerTenant.scss";
import logo from "../../images/Logo-10.png";
import backgroungImg from "../../images/bg_img.png";
import ReactFileReader from "react-file-reader";
import queryString from "query-string";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class CustomerTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        companyname: "",
        website: "",
        firstName: "",
        lastName: "",
        contact: "",
        user_email: "",
      },
      fileImage: "",
      banngerFileImage: "",
      errors: {},
      file: "",
      fileBanner: "",
      selected_tenant_id: "",
      isLoading: false,
    };
  }

  routeToDashboard() {
    this.props.history.push(
      "/login?tenant_name=" + this.state.field.companyname
    );
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }

  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;
    var companyNameRegex = /^[A-Za-z]*$/;

    if (!fields["companyname"]) {
      formIsValid = false;
      errors["companyname"] = "*Please enter company name.";
    } else if (!companyNameRegex.test(fields["companyname"])) {
      formIsValid = false;
      errors["companyname"] = "*Only alphabets are allowed.";
    } else if (fields["companyname"].length <= 2) {
      formIsValid = false;
      errors["companyname"] = "*Company name should be more than two letters.";
    }

    if (!fields["contact"]) {
      formIsValid = false;
      errors["contact"] = "*Please enter contact number";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter first name";
    } else if (fields["firstName"].length <= 2) {
      formIsValid = false;
      errors["firstName"] = "*First name should be more than two letters.";
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "*Please enter last Name";
    } else if (fields["lastName"].length <= 2) {
      formIsValid = false;
      errors["lastName"] = "*Last name should be more than two letters.";
    }

    if (!fields["user_email"]) {
      formIsValid = false;
      errors["user_email"] = "*Please enter email";
    }

    if (this.state.fileImage.length <= 0) {
      formIsValid = false;
      errors["logo"] = "*Please upload  a logo";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleFiles = (files) => {
    this.setState({
      fileImage: files[0],
      file: URL.createObjectURL(files[0]),
    });
  };

  handleBannerFiles = (files) => {
    this.setState({
      banngerFileImage: files[0],
      fileBanner: URL.createObjectURL(files[0]),
    });
  };

  submitTenant(event) {
    event.preventDefault();
    const id = uuidv4();
    const selected_tenant_id = queryString.parse(this.props.location.search);
    if (this.validateFieldForm()) {
      this.setState({
        isLoading: true,
      });

      const formData = new FormData();

      if (this.state.banngerFileImage !== "") {
        formData.append("banner_file", this.state.banngerFileImage);
      }
      formData.append("file", this.state.fileImage);
      formData.append("tenant_name", this.state.field.companyname);
      formData.append("tenant_website", this.state.field.website);
      formData.append("tenant_contact", this.state.field.contact);
      formData.append("user_tenant_email", this.state.field.user_email);
      formData.append("first_name", this.state.field.firstName);
      formData.append("last_name", this.state.field.lastName);
      formData.append("tenant_id", id);

      if (selected_tenant_id.selected_tenant !== undefined) {
        formData.append(
          "selected_tenant_id",
          selected_tenant_id.selected_tenant
        );
        createNewCustomerTenant(formData)
          .then((response) => {
            if (response.success === true) {
              if (
                response.message === "Tenant created and cloned successfully"
              ) {
                this.setState({
                  isLoading: false,
                });
                swal({
                  button: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "btn btn-primary",
                    closeModal: true,
                  },
                  title: "Welcome",
                  text: "Thank you for registering with us. The login details has been sent to your email.",
                  icon: "success",
                }).then((res) => {
                  if (res) {
                    this.routeToDashboard();
                  }
                });
              }
              if (response.message === "Tenant already exist") {
                this.setState({
                  isLoading: false,
                });
                toast.error(response.message);
              }
            } else {
              this.setState({
                isLoading: false,
              });
              toast.error(response.message);
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            toast.error(err.message);
          });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error("Tenant not found");
      }
    }
  }

  render() {
    return (
      <div className="customer_tenant_main">
        <div className="col-md-12">
          <div className="row bg-white">
            <div className="col-md-7 p-0 bg-white d-sm-none d-md-block d-lg-block d-none">
              <img
                src={backgroungImg}
                alt="bg"
                className="img-fluid backimg"
              ></img>
            </div>
            <div className="col-md-5 right-side-col">
              <div className="customer_tenant_main_content sm-border-0 p-sm-0">
                <div className="x_panel border-0">
                  <div className="logo logo d-flex justify-content-center">
                    <img className="flashcorpLogo" src={logo} alt="logo"></img>
                  </div>
                  <div className="x_content">
                    <form className="form-horizontal form-label-left">
                      <div className="form-group row ">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Company
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="companyname"
                            className="form-control"
                            placeholder="Enter Company name"
                            value={this.state.field.companyname}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.companyname}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 ">
                          Website link
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="website"
                            className="form-control"
                            placeholder="Enter Website link"
                            value={this.state.field.website}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.website}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          First Name
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            placeholder="Enter First Name"
                            value={this.state.field.firstName}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.firstName}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Last Name
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            placeholder="Enter Last Name"
                            value={this.state.field.lastName}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.lastName}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Contact
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="number"
                            name="contact"
                            className="form-control"
                            placeholder="Enter Contact Number"
                            value={this.state.field.contact}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.contact}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Email ID
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="user_email"
                            className="form-control"
                            placeholder="Enter Email Id"
                            value={this.state.field.email}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.user_email}
                          </div>
                        </div>
                      </div>

                      <div className="form-group px-2">
                        <label className="control-label col-md-4 col-sm-4 required">
                          Upload Logo
                        </label>
                        <div className="px-2 d-inline-block">
                          <ReactFileReader
                            handleFiles={this.handleFiles}
                            multipleFiles={false}
                          >
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-md"
                              >
                                Upload
                              </button>
                              <span>
                                {this.state.fileImage ? (
                                  <img
                                    src={this.state.file}
                                    alt="logo-img"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      marginLeft: "20px",
                                    }}
                                  />
                                ) : null}
                              </span>
                            </>
                          </ReactFileReader>
                          <div className="errorMsg text-danger">
                            {this.state.errors.logo}
                          </div>
                        </div>
                      </div>

                      <div className="form-group px-2">
                        <label className="control-label col-md-4 col-sm-4">
                          Upload Background
                        </label>
                        <div className="d-inline-block px-2 ">
                          <ReactFileReader
                            handleFiles={this.handleBannerFiles}
                            multipleFiles={false}
                          >
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-md"
                              >
                                Upload
                              </button>
                              <span>
                                {this.state.banngerFileImage ? (
                                  <img
                                    src={this.state.fileBanner}
                                    alt="logo-img"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      marginLeft: "20px",
                                    }}
                                  />
                                ) : null}
                              </span>
                            </>
                          </ReactFileReader>
                          <div className="errorMsg text-danger">
                            {this.state.errors.banner}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-9 col-sm-12  offset-md-3">
                          <button
                            type="button"
                            className="btn btn-success btn-md tensub"
                            onClick={(event) => this.submitTenant(event)}
                          >
                            {this.state.isLoading && (
                              <div className="spinner-border text-light btnLoader" />
                            )}&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                            Submit&nbsp;&nbsp;
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-md tensub d-none"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.history.goBack();
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="footer_title">
                <p className="m-0 ps-5 ps-sm-0">
                  Copyright &copy; {new Date().getFullYear()} Flashcorp.All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerTenant;
