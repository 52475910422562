import React, { useContext } from "react";
import { inputTypeKey } from "../../../common/model/Model";
import DisplayFile from "../fieldsDisplayComponent/DisplayFile";
import { FormContext } from "../../contexts/FormContext";

const GetDisplayField = ({ value, field }) => {
  const { apiCallDataBackup } = useContext(FormContext);
  if (field.data_type === inputTypeKey.checkBox) {
    return <>{value?.join(",") || "-"}</>;
  } else if (field.data_type === inputTypeKey.fileUpload) {
    return <DisplayFile value={value} field={field} />;
  } else if (
    field.data_type === inputTypeKey.searchableDropdown ||
    field.data_type === inputTypeKey.dynamicDependentDropdown
  ) {
    const list = apiCallDataBackup[field.name] || [];
    if (Array.isArray(value)) {
      if (field.key_to_send !== field.key_to_show) {
        let displayValue = list
          ?.filter((item) => value.includes(item[field.key_to_send]))
          .map((data) => data[field.key_to_show]);
        return <>{displayValue?.join(",") || "-"}</>;
      } else {
        return <>{value?.join(",") || "-"}</>;
      }
    } else {
      if (field.key_to_send !== field.key_to_show) {
        let displayValue = list
          ?.filter((data) => data.value === value)
          .map((data) => data.label);
        return <>{displayValue || "-"}</>;
      } else {
        return <>{value || "-"}</>;
      }
    }
  } else if (field.data_type === inputTypeKey.calculatedField) {
    if (Array.isArray(value)) {
      return <>{value?.length || "-"}</>;
    } else {
      return <>{value || "-"}</>;
    }
  } else {
    return <>{value || "-"}</>;
  }
};

export default GetDisplayField;
