import React, { useContext, useEffect, useState } from "react";
import {
  executeAppForActions,
  reactAppExecutionVP,
} from "../../../services/API";
import { toast } from "react-toastify";
import AppLoader from "../../dataset/Loader/AppLoader";
import { Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutesContext } from "../../../RoutesContext";
import { ViewPageTab } from "../SubTable/view update/ViewUpdateAdd";
import TimeLineView from "../TimeLineView/TimeLineView";
import LineItemModal from "../SubTable/LineItemModal";
import { mentionUserEmail } from "../../../services/appService";
import { actionTypes } from "../../common/model/Model";
import "./ViewPageComponent.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const ViewPageComponent = ({
  displayData,
  appSessionId,
  objData,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const userInfo = JSON.parse(localStorage.getItem("user-info"));
  const [privilegeButtons, setPrivilegeButtons] = useState([]);
  const [toggleViewPage, setToggleViewPage] = useState(true);
  const [componentData, setComponentData] = useState([]);
  const [componentDataLoading, setComponentLoading] = useState(false);
  const [viewUpdateAddData, setViewUpdateAddData] = useState({});
  const [viewWidthState, setViewWidthState] = useState([100, 0]);
  const [lineItemsModalState, setLineItemsModalState] = useState(false);
  const [lineItemData, setLineItemData] = useState([]);
  const [lineItemHeaderFieldData, setLineItemHeaderFieldData] = useState("");
  const [userData, setUserData] = useState([]);
  const { component_id, refValue, isCacheEnabled } = displayData;
  const [isLoadingButtonResponse, setIsLoadingButtonResponse] = useState(false);
  const params = useParams();
  const [showActionButtons, toggleActionButtons] = useState(false);

  let executeAppObject = {
    data: {
      logic:
        objData?.logic?.length > 0
          ? objData?.logic
          : [displayData?.componentLogic],
    },
    referenceValue: [{ refValue: refValue }],
    app_session_id: appSessionId,
  };

  const getPrivileges = () => {
    if (userInfo?.privileges) {
      const { app_previleges } = userInfo?.privileges;
      app_previleges?.forEach((data) => {
        const { _id, displayName, ...restData } = data;
        let app_id = "";
        Object.keys(restData).map((key) => {
          app_id = key;
        });
        if (app_id === params?.id) {
          data?.[app_id]?.pageList?.forEach((page) => {
            let buttonLists = [];
            if (page?.page_id === objData?.page?.page_id) {
              page.componentList.map((component) => {
                component.componentButtonList.map((buttonList) => {
                  buttonLists.push(buttonList);
                });
              });
              setPrivilegeButtons(buttonLists);
            }
          });
        }
      });
    }
  };

  let actionButtonsList =
    displayData?.actionDetails?.filter(
      (action) =>
        privilegeButtons?.includes(action?.action_id) ||
        userInfo.role_name.toLowerCase() === "admin"
    ) || [];

  useEffect(() => {
    getPrivileges();
    setViewWidthState([45, 55]);
    callUserEmails();
    appExecutionEngine(executeAppObject);
  }, []);

  useEffect(() => {
    const { autoReloadComponent, autoReloadDelay } = displayData;
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setTimeout(() => {
        appExecutionEngine(executeAppObject, signal);
      }, parseInt(autoReloadDelay, 10));
      return () => clearInterval(intervalId);
    }
  }, []);

  const appExecutionEngine = (bodyObject) => {
    setComponentLoading(true);
    reactAppExecutionVP(bodyObject)
      .then((response) => {
        response?.data?.forEach((refData) => {
          if (refData[refValue]) {
            setComponentData(refData[refValue].value);
            setViewUpdateAddData(refData[refValue]?.value?.data[0]);
            setTempStorageCall(false);
          }
        });
        clearFilterApply();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  let pageFieldsList = [];
  if (displayData?.page_field?.length > 0) {
    pageFieldsList = displayData?.page_field;
  } else {
    pageFieldsList = componentData?.value?.fields;
  }

  const openModelData = (fieldData, data) => {
    setLineItemsModalState(true);
    setLineItemData(data);
    setLineItemHeaderFieldData(fieldData);
  };

  const callUserEmails = () => {
    mentionUserEmail()
      .then((response) => {
        if (response) {
          setUserData(response.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const onActionButtonClick = (action, data) => {
    let objBody = {
      app_session_id: appSessionId,
      logic_name: action.logicNameValue || "",
      reference_name: action.referenceValue || "",
      data: [data],
    };
    setIsLoadingButtonResponse(action.action_id);
    executeAppForActions(objBody)
      .then((response) => {
        if (response) {
          if (response?.message) {
            toast.success(response?.message);
            appExecutionEngine(executeAppObject);
            callUserEmails();
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setIsLoadingButtonResponse("");
      });
  };

  return (
    <Col md={displayData.columnWidth || 12} className="my-1 px-0">
      <div className={`h-100 position-relative  mx-1 main-content bg-white mb-2`}>
        <div className="py-1 px-1">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">{displayData?.pageTitle}</h5>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title="Refresh"
                  onClick={() => appExecutionEngine(executeAppObject)}
                >
                  <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
                </button>
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={toggleViewPage ? "Collapse View" : "Expand View"}
                  onClick={() => setToggleViewPage(!toggleViewPage)}
                  aria-controls="view-toggle"
                  aria-expanded={toggleViewPage}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      toggleViewPage ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {componentDataLoading && <AppLoader />}
        {componentData?.data?.length ? (
          <Collapse in={toggleViewPage}>
            <div id="view-toggle">
              {!componentDataLoading && (
                <>
                  <div className="d-flex justify-content-end px-2">
                    <span
                      onClick={(e) => {
                        toggleActionButtons(!showActionButtons);
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa fa-ellipsis-vertical"
                        color="grey"
                      />
                    </span>
                    {showActionButtons && (
                      <div className="toggle-div">
                        {!actionButtonsList?.length && <span>No Actions</span>}
                        {actionButtonsList?.map((action, index) => {
                          if (action?.actionType === actionTypes.button) {
                            return (
                              <button
                                actionButton="true"
                                key={index}
                                onClick={() => {
                                  if (!isLoadingButtonResponse) {
                                    onActionButtonClick(
                                      action,
                                      viewUpdateAddData
                                    );
                                  }
                                }}
                                style={{
                                  backgroundColor: action.buttonColor,
                                }}
                                className={`btn btn-md me-2 mb-0 text-white`}
                              >
                                <span className="d-flex justify-content-center align-items-center">
                                  {isLoadingButtonResponse ===
                                    action?.action_id && action?.action_id ? (
                                    <div className="spinner-border text-light spinner-border-sm me-1" />
                                  ) : null}
                                  {viewUpdateAddData?.[action?.buttonTextKey] ||
                                    action.actionName}
                                </span>
                              </button>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>

                  <TimeLineView
                    actions={displayData}
                    data={viewUpdateAddData}
                    openModelData={openModelData}
                    showTable={false}
                    showTimeLine={true}
                  ></TimeLineView>
                  <ViewPageTab
                    actions={displayData}
                    viewData={viewUpdateAddData}
                    pageFieldsList={pageFieldsList}
                    viewWidthState={viewWidthState}
                    relationData={displayData?.relations}
                    userName={userData}
                    datasetName={componentData.table_name}
                    openModelData={openModelData}
                    changeViewPageState={() => {
                      setViewWidthState([45, 55]);
                    }}
                  />
                  <LineItemModal
                    lineItemsModalState={lineItemsModalState}
                    setLineItemsModalState={setLineItemsModalState}
                    lineItemHeaderFieldData={lineItemHeaderFieldData}
                    showLineItemData={lineItemData}
                  />
                </>
              )}
            </div>
          </Collapse>
        ) : (
          !componentDataLoading && (
            <div className="row">
              <div className="col-md-12 text-center">No Data</div>
            </div>
          )
        )}
      </div>
    </Col>
  );
};

export default ViewPageComponent;
