import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { autoReloadComponentDelaysList } from "../../../common/model/Model";

const DisplayBuilderDynamicTable = ({
  displayBuilder,
  columnWidthList,
  selectFormList,
  onChange,
  logicList,
  formsList,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  const options = Array.from({ length: 50 }, (_, index) => index + 1);

  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  const autoReloadDelays = useMemo(() => 
  autoReloadComponentDelaysList?.map((data, index) => (
    <option key={index} value={data.value}>
      {data.label}
    </option>
  )),[]);

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group ">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columnWidthTable}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columnWidthTable"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Table Name</label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.tableName?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter Table Name"
            name="tableName"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.tableName}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Form Page</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.formId || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="formId"
          >
            <option value="">Select</option>
            {selectFormList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">
          Select No. Of Rows to freeze at Top
        </label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.rowFreezeTop || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="rowFreezeTop"
          >
            <option value="">Select</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">
          Select No. Of Rows to freeze at Bottom
        </label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.rowFreezeBottom || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="rowFreezeBottom"
          >
            <option value="">Select</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">
          Enter No. Of Columns to Freeze At Left
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter column index"
            name="columnFreezeLeft"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.columnFreezeLeft}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">
          Enter No. Of Columns to Freeze At Right
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter column index"
            name="columnFreezeRight"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.columnFreezeRight}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">
          Enter column keys to span rows
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter column key"
            name="rowSpan"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.rowSpan}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">
          Enter column key to add border on data change
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter column key"
            name="columnKey"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.columnKey}
          ></input>
        </div>
      </div>

      <div className="col-md-12 my-2">
        <div className="row">
          <div className="col-md-3 col-sm-6  form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="showTotalRow"
                checked={
                  displayChanges?.showTotalRow ||
                  displayChanges?.showTotalRow === undefined
                    ? true
                    : false
                }
              />
              <label className="mb-0">Show Total Row At Bottom</label>
            </div>
          </div>

          <div className="col-md-3 col-sm-6  form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="showFilter"
                checked={
                  displayChanges?.showFilter ||
                  displayChanges?.showFilter === undefined
                    ? true
                    : false
                }
              />
              <label className="mb-0">Show Filter</label>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-12 form-group">
            <div className="col-md-9 col-sm-9 ">
              <label className="form-label p-0 mt-2">
                <input
                  className="me-2"
                  type="checkbox"
                  onChange={(e) => {
                    checkBoxChangeValue(e);
                  }}
                  name="autoReloadComponent"
                  checked={displayChanges?.autoReloadComponent || false}
                  />
                Auto reload
              </label>
            </div>
            {displayChanges.autoReloadComponent && (
              <div className="col-md-9 col-sm-9 ">
                <label className="form-label p-0 mt-2">Select delay</label>
                <Form.Select
                  className="form-control"
                  name="autoReloadDelay"
                  value={displayChanges.autoReloadDelay}
                  onChange={(e) => {
                    changeValue(e);
                  }}
                >
                  <option value="">--Select--</option>
                  {autoReloadDelays}
                </Form.Select>
              </div>
            )}
          </div>

          <div className="col-md-3 col-sm-6 col-12 form-group">
            <div className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                onChange={(e) => {
                  checkBoxChangeValue(e);
                }}
                name="isCacheEnabled"
                checked={displayChanges?.isCacheEnabled}
              />
              <label className="mb-0">Enable Cache</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderDynamicTable;
