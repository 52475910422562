import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { fetchproductByCategory } from "../../../../../services/appService";
import "./ProductMap.scss";
import { toast } from "react-toastify";
import AutocompleteComponent from "./AutoFill";
import dummyImage from "../../../../../images/dummy-image.jpg";
import RelatedProducts from "../relatedProducts/RelatedProducts";
import { useHistory } from "react-router";
import { getTenantDetail } from "../../../../../services/appService";
import { Modal } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../dataset/Loader/Loader";

function ProductMap({ category_id, storeId }) {
  const history = useHistory();
  const [showSideMenuFlag, setSideMenuFlag] = useState(false);
  const [loader, setLoader] = useState(true);
  const [productData, setProductData] = useState([]);
  const store_id = storeId;
  const [productDetails, setProductDetails] = useState({});
  const [counterFlag, setCounterFlag] = useState(0);
  const [center, setCenter] = useState({ lat: 21.14, lng: 79.08 });
  const [zoom, setZoom] = useState(6);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [mapKey, setMapKey] = useState([]);
  const [show, setShow] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  // call products api

  useEffect(() => {
    let tenant_name = JSON.parse(localStorage.getItem("query_param"));
    getTenantDetail(tenant_name)
      .then((response) => {
        if (
          response.data[0]?.map_key &&
          response.data[0].map_key !== undefined
        ) {
          setMapKey(response.data[0]?.map_key);
        } else {
          toast.error("Please Enter Google Map Api Key");
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    let bodyData = {
      category_id: [category_id],
      FilterCriteria: [],
      SortCriteria: [],
      PaginationCriteria: {
        limit: 10,
        skip: 0,
      },
    };
    fetchproductByCategory(bodyData, store_id)
      .then((response) => {
        if (response.data) {
          setProductData(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
      })
      .finally(() => setLoader(false));
  }, [productDetails]);

  const relatedProductList = productDetails.related_product?.filter(
    (product) => productDetails.sku_id !== product.product_data.sku_id
  );

  const Marker = (props) => {
    let productValue = props.productData;
    return (
      <div
        className="markers"
        onClick={() => {
          setMapData(productValue);
        }}
      >
        <FontAwesomeIcon icon="fa fa-map-marker" aria-hidden="true" />
        <div className="marker-model">
          <p className="mb-1">{productValue.product_name}</p>
          <p className="mb-1">
            &#x20b9;&nbsp;{productValue?.price?.toLocaleString("en-IN")}
          </p>
        </div>
      </div>
    );
  };

  const setMapData = (data) => {
    let objdata = { ...center };
    if (data?.latitude && data?.longitude) {
      objdata.lat = parseFloat(data.latitude);
      objdata.lng = parseFloat(data.longitude);
      setCenter(objdata);
    } else {
      setCenter({ lat: 21.14, lng: 79.08 });
    }
    setProductDetails(data);
    checkVendorDetails(data);
    setCounterFlag(counterFlag + 1);
    if (counterFlag === 0) {
      setSideMenuFlag(!showSideMenuFlag);
    }
  };

  const markerData = productData.map((data, index) => (
    <Marker
      key={index}
      productData={data}
      lat={data.latitude}
      lng={data.longitude}
    />
  ));

  const customAttributeField =
    productDetails?.customized_fields?.formFields &&
    Object.keys(productDetails.customized_fields.formFields).length >= 1
      ? Object.keys(productDetails.customized_fields.formFields).map(
          (key, index) => (
            <div className="row" key={index}>
              <div className="col-md-4">
                <p className="mb-3">{key.replace(/_/g, " ")}</p>
              </div>
              <div className="col-8">
                <p className="mb-3">
                  <span className="text-dark fw-bold ">
                    {productDetails.customized_fields.formFields[key]}
                  </span>
                </p>
              </div>
            </div>
          )
        )
      : null;

  const fetchAutoFillData = (data) => {
    let longValue = data.split(",")[1];
    let latValue = data.split(",")[0];
    let objdata = { ...center };
    objdata.lat = parseFloat(latValue);
    objdata.lng = parseFloat(longValue);
    setZoom(15);
    setCenter(objdata);
  };

  const returnViewMapData = (productData) => {
    setMapData(productData?.product_data);
    setProductDetails(productData?.product_data);
    checkVendorDetails(productData?.product_data);
  };

  const checkVendorDetails = (productData) => {
    if (productData.vendor_details) {
      setVendorDetails(productData.vendor_details);
    } else {
      setVendorDetails([]);
    }
  };

  const openGallery = (images) => {
    let tempArr = [];
    if (images.length >= 1)
      images.map((data, index) => {
        let obj = {
          original: data,
          thumbnail: data,
        };
        tempArr.push(obj);
      });
    setGalleryImages(tempArr);
  };

  useEffect(() => {
    if (galleryImages.length) {
      setShow(true);
    }
  }, [galleryImages]);

  const handleClose = () => {
    setShow(false);
    setGalleryImages([]);
  };

  useEffect(() => {});

  const renderVendorDetails = (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Vendor Name</th>
          <th>Maximum Price</th>
          <th>Minimum Price</th>
          <th>Available Days</th>
        </tr>
      </thead>
      <tbody>
        {vendorDetails.length >= 1
          ? vendorDetails.map((value, index) => (
              <tr key={index}>
                <td className="text-capitalize">{value?.vendorData?.label}</td>
                <td>{value?.max_value}</td>
                <td>{value?.min_value}</td>
                <td>{value?.available_in}</td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );

  return (
    <div className="px-2 product-map text-capitalize">
      {mapKey.length ? (
        <>
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md-4 mt-2">
              <AutocompleteComponent autoFill={fetchAutoFillData} />
            </div>
          </div>

          <div>
            <div className="row">
              <div
                className={`${
                  productDetails !== undefined &&
                  Object.keys(productDetails).length
                    ? "col-md-9 col-12"
                    : "col-md-12"
                } map-view my-2`}
              >
                <GoogleMapReact
                  key={new Date().getTime()}
                  bootstrapURLKeys={{
                    key: { mapKey },
                  }}
                  defaultCenter={center}
                  defaultZoom={zoom}
                >
                  {markerData}
                </GoogleMapReact>
              </div>
              {productDetails !== undefined &&
              Object.keys(productDetails).length ? (
                <div className="col-md-3 col-12 my-2">
                  <div className="col-12 p-0">
                    <div className="row">
                      <div className="col-12">
                        <p className="mb-3 img-height responsive-margin">
                          <img
                            src={
                              productDetails?.product_images.length
                                ? productDetails.product_images[0]
                                : dummyImage
                            }
                            className="cursor"
                            onClick={() => {
                              openGallery(productDetails.product_images);
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-6">
                        <p className="mb-3">Product Name </p>
                      </div>
                      <div className="col-md-8 col-6">
                        <p className="mb-3">
                          <span className="text-dark fw-bold ">
                            {productDetails?.product_name}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-6">
                        <p className="mb-3">Product Short Description </p>
                      </div>
                      <div className="col-md-8 col-6">
                        <p className="mb-3">
                          <span className="text-dark fw-bold ">
                            {productDetails?.product_short_description}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-6">
                        <p className="mb-3">Tags</p>
                      </div>
                      <div className="col-md-8 col-6">
                        <p className="mb-3">
                          <span className="text-dark fw-bold d-flex flex-wrap">
                            {productDetails.product_tag?.length > 0 ? (
                              productDetails.product_tag.map((tags, index) => (
                                <div className="custom-tag mb-2" key={index}>
                                  <FontAwesomeIcon
                                    icon="fa fa-tag"
                                    className="me-2"
                                  />
                                  <span>{tags.text}</span>
                                </div>
                              ))
                            ) : (
                              <div className="custom-tag">
                                <FontAwesomeIcon
                                  icon="fa fa-tag"
                                  className="me-2"
                                />
                                <span>No Tags</span>
                              </div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-6">
                        <p className="mb-3">Price </p>
                      </div>
                      <div className="col-md-8 col-6">
                        <p className="mb-3">
                          <span className="text-dark fw-bold ">
                            &#x20b9;&nbsp;
                            {productDetails?.price?.toLocaleString("en-IN")}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-6">
                        <p className="mb-3">Supplier Name </p>
                      </div>
                      <div className="col-md-8 col-6">
                        <p className="mb-3">
                          <span className="text-dark fw-bold ">
                            {productDetails?.supplier_name}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-6">
                        <p className="mb-3">Brand Name </p>
                      </div>
                      <div className="col-md-8 col-6">
                        <p className="mb-3">
                          <span className="text-dark fw-bold ">
                            {productDetails?.brand_name}
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* attribute fields */}
                    {customAttributeField}
                  </div>
                </div>
              ) : null}
            </div>
            {vendorDetails.length ? (
              <div className="vendor-details mt-2">
                <h5>Vendor Details</h5>
                {renderVendorDetails}
              </div>
            ) : null}
            <div className="related-products mt-2 px-3">
              <RelatedProducts
                relatedProductsData={relatedProductList}
                margin={30}
                nav={false}
                items={4}
                clickValue="product_map"
                selectedMapdataValue={returnViewMapData}
              ></RelatedProducts>
            </div>
          </div>
        </>
      ) : (
        <div className="d-help">
          {!loader && (
            <h5 className="text-danger">
              Enter Google Map Api Key In Company Profile Section
            </h5>
          )}
        </div>
      )}

      {loader && <Loader />}

      {/* galery slider model */}
      <Modal show={show} onHide={handleClose} className="product-map-modal">
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <ImageGallery
            items={galleryImages}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductMap;
