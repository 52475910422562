import React, { useEffect, useState } from "react";
import { Modal, Offcanvas, Placeholder } from "react-bootstrap";
import { getDynamicFlowData } from "../../../../services/appService";
import { toast } from "react-toastify";
import DisplayComponent from "../../components/DisplayComponent";
import { componentType, transition } from "../../../common/model/Model";

const TransitionDisplay = ({
  show,
  hideLinkTransition,
  appId,
  flowId,
  displayType,
  appSessionId,
}) => {
  const [flowDataLoading, setFlowDataLoading] = useState(true);
  const [flowObjectList, setFlowObjectList] = useState([]);

  useEffect(() => {
    if (appSessionId) {
      getFlowData();
    }
  }, []);

  const getFlowData = () => {
    setFlowDataLoading(true);
    getDynamicFlowData(appId, appSessionId)
      .then((response) => {
        setFlowObjectList(response?.data || []);
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setFlowDataLoading(false);
      });
  };

  let currentFlow = flowObjectList?.find(
    (flowObject) => flowObject?.data.flow_id === flowId
  );

  if (displayType === transition.popUp) {
    return (
      <Modal
        size="xl"
        show={show}
        onHide={hideLinkTransition}
        centered
        scrollable
      >
        <Modal.Header as closeButton>
          <h5 className="m-0">{currentFlow?.data?.function_name}</h5>
        </Modal.Header>
        <Modal.Body className="py-0">
          {flowDataLoading ? (
            <div className="position-relative set_height">
              <div className="position-absolute top-0 end-0 bottom-0 start-0 d-flex justify-content-center align-items-center">
                <div className="loader-circle" />
              </div>
            </div>
          ) : (
            currentFlow?.data.page.page_type ===
              componentType.displayComponent && (
              <div>
                <div className="row">
                  <DisplayComponent
                    appSessionId={appSessionId}
                    displayComponentData={currentFlow}
                  />
                </div>
              </div>
            )
          )}
        </Modal.Body>
      </Modal>
    );
  } else if (displayType === transition.sideBar) {
    return (
      <Offcanvas show={show} onHide={hideLinkTransition} placement={"end"}>
        <Offcanvas.Header className="border-bottom bg-white" closeButton>
          <Offcanvas.Title className="flex-grow-1">
            {flowDataLoading ? (
              <Placeholder as="div" animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            ) : (
              currentFlow?.data?.function_name
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative bg-white pt-0">
          {flowDataLoading ? (
            <div className="position-absolute top-0 end-0 bottom-0 start-0 d-flex justify-content-center align-items-center">
              <div className="loader-circle" />
            </div>
          ) : (
            currentFlow?.data.page.page_type ===
              componentType.displayComponent && (
              <div className="row pt-2">
                <DisplayComponent
                  appSessionId={appSessionId}
                  displayComponentData={currentFlow}
                />
              </div>
            )
          )}
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default TransitionDisplay;
