import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  checkSameValueData,
  getPagesByAppId,
  pageClone,
} from "../../../../services/appService";
import Loader from "../../../dataset/Loader/Loader";
import { toast } from "react-toastify";
import CloneModal from "../../../clone/CloneModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { componentTypeList } from "../../../common/model/Model";
import { Form } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { saveAppResonseByJson } from "../../../../services/datasetService";

const AppPages = (props) => {
  const params = useParams();
  const [headerFields] = useState([
    "Sr. No.",
    "Name",
    "Description",
    "Component",
    "Actions",
  ]);
  const [getPageServiceData, setGetPageServiceData] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [cloneObjId, setCloneObjId] = useState("");
  const [isResponseWaiting, setIsResponseWaiting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editRow, setEditRow] = useState("");
  const [editRowChange, setEditRowChange] = useState("");

  useEffect(() => {
    setLoading(true);
    getPageListData();
  }, []);

  const toggleShow = () => {
    setShowModal(!showModal);
  };

  const cloneSubmit = (pageName) => {
    let objData = {
      dataset_name: "fc_app_pages",
      fields: {
        function_key: "function_name",
        function_value: pageName,
        app_id: params.app_id,
      },
    };
    setIsResponseWaiting(true);
    checkSameValueData(objData)
      .then((response) => {
        if (!response.data) {
          let obj = {
            page_name: pageName,
            object_id: cloneObjId,
          };
          pageClone(obj, params.app_id)
            .then((response) => {
              if (response.success) {
                setCloneObjId("");
                setShowModal(false);
                setIsResponseWaiting(false);
                getPageListData();
                toast.success(response.data.message);
              }
            })
            .catch((err) => {
              setIsResponseWaiting(false);
              if (err.response) toast.error(err.response.data.message);
            });
        } else {
          toast.error(`Page name already exist.`);
          setIsResponseWaiting(false);
        }
      })
      .catch(() => setIsResponseWaiting(false));
  };

  const getPageListData = () => {
    getPagesByAppId(params.app_id)
      .then((response) => {
        if (response.success === true) {
          setGetPageServiceData(response.data);
          setTotalRecordCount(response.data.length);
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoading(false);
        setEditRow("");
      });
  };

  const submitChanges = (rowData, key) => {
    if(key === "function_name"){
      if(!window.confirm("If you change the name you will lose all existing references to this page. Do you want to still change the name?")){
        return;
      }
    }
    let obj = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      object_id: rowData._id.$oid,
      fields: {
        ...rowData,
        [key]: editRowChange,
      },
    };
    saveAppResonseByJson(obj)
      .then((response) => {
        if (response.success) {
          getPageListData();
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // Pagination
  const indexOfLastRecord = currentPage * 10;
  const indexOfFirstRecord = indexOfLastRecord - 10;
  const currentData = getPageServiceData
    ?.filter((data) =>
      data.function_name?.toLowerCase()?.includes(searchText.toLowerCase()) || data.description?.toLowerCase()?.includes(searchText.toLowerCase())
    )
    .slice(indexOfFirstRecord, indexOfLastRecord);

  const headers = headerFields?.map((header, index) => (
    <th key={index}>{header}</th>
  ));

  const setTableData = currentData.map((tableData, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td className="inLineEdit">
        <div className="d-flex justify-content-between align-items-center">
          {editRow === tableData._id.$oid + "function_name" ? (
            <div className="flex-grow-1">
              <Form.Control
                size="sm"
                type="text"
                value={editRowChange || ""}
                onChange={({ target }) => setEditRowChange(target.value)}
              />
            </div>
          ) : (
            <span>{tableData.function_name}</span>
          )}
          <span className="d-flex editIconContainer ">
            <span>
              <span
                role="button"
                className="editIcon border p-1 ms-2"
                onClick={() => {
                  setEditRowChange(tableData.function_name);
                  setEditRow(tableData._id.$oid + "function_name");
                }}
              >
                <FontAwesomeIcon icon="fa fa-pencil" aria-hidden="true" />
              </span>
            </span>
            {tableData.function_name !== editRowChange.trim() &&
              editRow === tableData._id.$oid + "function_name" && (
                <span>
                  <span className={`editIcon border p-1 ms-2 editIconHovered `}>
                    <FontAwesomeIcon
                      icon="fa fa-check"
                      aria-hidden="true"
                      onClick={() => submitChanges(tableData, "function_name")}
                    />
                  </span>
                </span>
              )}
          </span>
        </div>
      </td>
      <td className="inLineEdit">
        <div className="d-flex justify-content-between align-items-center">
          {editRow === tableData._id.$oid + "description" ? (
            <div className="flex-grow-1">
              <Form.Control
                size="sm"
                type="text"
                value={editRowChange || ""}
                onChange={({ target }) => setEditRowChange(target.value)}
              />
            </div>
          ) : (
            <span>{tableData.description}</span>
          )}
          <span className="d-flex editIconContainer">
            <span
              role="button"
              className="editIcon border p-1 ms-2"
              onClick={() => {
                setEditRowChange(tableData.description);
                setEditRow(tableData._id.$oid + "description");
              }}
            >
              <FontAwesomeIcon icon="fa fa-pencil" aria-hidden="true" />
            </span>

            {tableData.description !== editRowChange.trim() &&
              editRow === tableData._id.$oid + "description" && (
                <span
                  role="button"
                  className="editIcon border p-1 ms-2"
                  onClick={() => submitChanges(tableData, "description")}
                >
                  <FontAwesomeIcon icon="fa fa-check" aria-hidden="true" />
                </span>
              )}
          </span>
        </div>
      </td>
      <td>
        {componentTypeList?.find(
          (component) => component?.value === tableData.page_type
        )?.label || "-"}
      </td>
      <td>
        <div className="action-icons">
          <FontAwesomeIcon
            icon={"fa fa-pen-to-square"}
            className="me-2"
            title="Edit Page"
            size="lg"
            role="button"
            onClick={() =>
              props.history.push(`/app_page/page_editor/${tableData._id.$oid}`)
            }
          />
          <FontAwesomeIcon
            icon={"fa fa-clone"}
            className="me-2"
            title="Clone Page"
            onClick={() => {
              setShowModal(true);
              setCloneObjId(tableData._id.$oid);
            }}
            size="lg"
            role="button"
          />
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-sm-12 d-flex justify-content-end mb-2 dataset_table">
          <div className="searchbar_wrapper">
            <div className="input-group input-group-sm m-0 me-1">
              <span className="input-group-text" id="basic-addon1">
                <FontAwesomeIcon icon={"fa fa-search"} />
              </span>
              <input
                type="text"
                className="dataset_search form-control"
                placeholder="Search"
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <Link to={`/add_app_page/${params.app_id}`}>
              <button
                type="button"
                className="btn btn-primary btn-md m-0 text-nowrap"
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
                <span className="ms-1">Add Page</span>
              </button>
            </Link>
          </div>
        </div>

        <div className="col-md-12 col-sm-12">
          <div className="card-boxset_height mb-4">
            <div
              id="datatable-checkbox_wrapper"
              className="dataTables_wrapper container-fluid dt-bootstrap no-footer p-0"
            >
              <div className="table-responsive table-hover ">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr className="head-row">{headers}</tr>
                      </thead>
                      <tbody>{setTableData}</tbody>
                    </table>
                    {totalRecordCount === 0 || currentData.length === 0 ? (
                      <p className="no_table_records">
                        No matching records found
                      </p>
                    ) : null}
                  </>
                )}
              </div>

              {totalRecordCount > 10 && (
                <div className="dynamic_pagination my-2">
                  <Pagination
                    pageRangeDisplayed={3}
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalRecordCount}
                    onChange={(activePage) => setCurrentPage(activePage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <CloneModal
        name="Page"
        toggleShow={toggleShow}
        closeModal={() => setShowModal(false)}
        showModal={showModal}
        cloneSubmit={cloneSubmit}
        isResponseWaiting={isResponseWaiting}
      />
    </>
  );
};

export default AppPages;
